import React, { useCallback } from 'react';
import { Box, Button, Flex, FlexboxProps, Image, Text } from '@chakra-ui/react';
import { IntroProps } from './utils';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { isMobile } from 'react-device-detect';

type TutorialScreenProps = {
  intro: IntroProps;
  isLast: boolean;
  isFirst: boolean;
  isFarEnd: boolean;
  onNext: () => void;
  onPrevious: () => void;
  handleButtonClick: () => void;
};

const TutorialScreenContent = ({
  intro,
  isLast,
  isFirst,
  isFarEnd,
  onNext,
  onPrevious,
  handleButtonClick,
}: TutorialScreenProps) => {
  const { title, text, img_src, buttonLabel } = intro;

  return (
    <Flex
      flexDirection="row"
      alignItems={'center'}
      justifyContent={'center'}
      maxW={isMobile ? '100vw' : '615px'}
      maxH={isMobile ? '100vh' : '205px'}>
      {!isFirst && <MdArrowBack size={'40px'} onClick={onPrevious} />}
      <Flex
        flexDir={isMobile ? 'column' : 'row'}
        pl={isFirst ? '40px' : 0}
        pr={isLast ? '40px' : 0}
        alignItems={'center'}
        justifyContent={'center'}>
        <Flex
          justifyContent={'flex-start'}
          alignItems={'center'}
          height={'256px'}
          width={isMobile ? '60vw' : '280px'}>
          <Image src={img_src} width="auto" height="auto" />
        </Flex>
        {/* Text - Title + Paragraph */}
        <Flex
          maxW={isMobile ? '80vw' : '256px'}
          flexDir={'column'}
          paddingY={8}
          paddingX={15}
          justifyContent={isMobile ? 'center' : 'flex-start'}
          textAlign={'left'}>
          <Text
            textAlign={isMobile ? 'center' : 'left'}
            fontSize={'1.3rem'}
            fontFamily={'Poppins, sans-serif'}
            fontWeight={'600'}
            color={'#474747'}>
            {title}
          </Text>

          <Text
            textAlign={isMobile ? 'center' : 'left'}
            marginBottom={'1rem'}
            fontSize={isMobile ? '1rem' : '0.8rem'}
            fontFamily={'Poppins, sans-serif'}
            fontWeight={'400'}
            color={'#848484'}>
            {text}
          </Text>
          <Button
            w="8rem"
            my={'1rem'}
            ml={isMobile ? '25%' : 0}
            fontFamily={'Roboto'}
            fontSize={'0.8rem'}
            onClick={handleButtonClick}>
            {buttonLabel}
          </Button>
        </Flex>
      </Flex>
      {!isLast && <MdArrowForward size={40} onClick={onNext} />}
    </Flex>
  );
};

export default TutorialScreenContent;
