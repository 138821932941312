import React, { FC, useRef } from 'react';

import { Image } from '@chakra-ui/image';
import { Center, Stack, Text } from '@chakra-ui/layout';

import { MdCloudUpload } from 'react-icons/md';

import { useField } from 'formik';
import { useDropzone } from 'react-dropzone';

import { Cropper, CropperRef } from './Cropper';

import { Bg, IconBg, PickerContainer } from './PreviousWorkSection.style';

const colorScheme = '#6728BB';

export const ThumbnailPicker: FC = ({ children }) => {
  const [{ value }, meta, { setValue, setError }] = useField('file');

  const cropperRef = useRef<CropperRef>(null);

  const { getInputProps, getRootProps } = useDropzone({
    accept: ['image/*'],
    maxSize: 5000000,
    onDrop: (acceptedFiles, rejection) => {
      if (rejection.length && rejection[0]?.errors.length) {
        setError('The picture must be smaller than 5MB');
      }
      if (acceptedFiles.length) {
        const file = acceptedFiles[0];

        const fileUrl = URL.createObjectURL(file);

        cropperRef.current?.onOpen(file, fileUrl);
      }
    },
  });

  return (
    <Stack maxW="700px" w="100%" alignSelf="center">
      <Text mt={4} fontSize="12px" fontWeight="600">
        Upload your thumbnail
      </Text>
      {Boolean(value) && Boolean(value?.preview) ? (
        <Center
          {...getRootProps()}
          h="150px"
          w="220px"
          position="relative"
          alignSelf="center">
          <Image objectFit="contain" src={value?.preview} h="100%" w="100%" />
          {children}
          <input {...getInputProps()} />
        </Center>
      ) : (
        <PickerContainer mx={0} {...getRootProps()}>
          <input {...getInputProps()} />
          <Bg />
          <Center w="80px" h="80px" position="relative">
            <IconBg />
            <MdCloudUpload fill={colorScheme} size="40px" color="#d8d8d8" />
          </Center>
          <Text ml={2} color="brand.500">
            Select your photos or drag and drop to upload them
          </Text>
          {children}
        </PickerContainer>
      )}
      {meta.error && (
        <Text color="red.500" fontSize="12px" textAlign="center">
          {meta.error}
        </Text>
      )}
      <Cropper
        aspectRatio={220 / 150}
        ref={cropperRef}
        onSuccess={(file, fileUrl) => {
          setValue(
            Object.assign(file, {
              preview: fileUrl,
            }),
          );
        }}
      />
    </Stack>
  );
};
