import React from 'react';

import { MissionsProps } from './Missions.props';

import MissionsView from './Missions.view';

const MissionsContainer = (_: MissionsProps) => {
  return <MissionsView />;
};

export default MissionsContainer;
