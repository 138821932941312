import * as Yup from 'yup';

export const MissionCheckoutSchema = Yup.object().shape({
  ccName: Yup.string().required('Name is required!'),
  ccNumber: Yup.string(),
  ccExp: Yup.string(),
  ccCVC: Yup.string(),
});

export const defaultFormCheckout = {
  ccName: '',
  ccNumber: '',
  ccExp: '',
  ccCVC: '',
};
