import React, { FC } from 'react';

import { Flex, Text } from '@chakra-ui/react';

import { CustomMission, CustomStatus } from 'types/mission';

type Props = {
  mission: CustomMission;
};

const STATUSES = [CustomStatus.PILOT_REJECTED, CustomStatus.CLIENT_REJECTED];

export const RejectedReasons: FC<Props> = ({ mission }) => {
  const logs = mission.logs || [];

  if (STATUSES.includes(mission.status) == false) {
    return null;
  }

  if (logs?.length <= 0) {
    return null;
  }

  const rejections = logs.find((c) => STATUSES.includes(c.type));

  if (rejections === undefined) {
    return null;
  }

  return (
    <Flex
      mb={6}
      pb={6}
      px={6}
      flex={1}
      direction="column"
      borderBottom="1px solid #ccc">
      <Text color="#908361" fontSize="14px" fontWeight="bold">
        Rejected Reason
      </Text>
      <Text mt={2} fontSize="14px">
        {rejections.reason || ''}
      </Text>
    </Flex>
  );
};
