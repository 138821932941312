import React, { FC, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Center,
  Avatar,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

import { AnimatePresence, motion } from 'framer-motion';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

import { useHistory } from 'react-router-dom';

import { useQueryCache } from 'react-query';

import * as session from 'effector/session';
import { logoutEvent } from 'effector/session/events';

import { useCMS } from './Context';

const MotionBox = motion.custom(Box);

const _contprops = {
  mr: 1,
  w: '100%',
  minW: '200px',
  maxW: '200px',
  position: 'relative' as any,
};

const _defaultprops = {
  px: 2,
  py: 2,
  w: '100%',
  zIndex: 1,
  maxW: '200px',
  color: '#fff',
  align: 'center',
  borderRadius: '10px',
  alignSelf: 'flex-end',
  border: '2px solid #fff',
};

const _textprops = {
  mx: 2,
  flex: 1,
  noOfLines: 1,
  fontSize: '13px',
  fontWeight: 'bold',
  color: '#fff',
};

const _dropdnprops = {
  left: 0,
  right: 0,
  h: '120px',
  pt: '30px',
  px: '10px',
  pb: '10px',
  top: '70%',
  shadow: 'md',
  borderBottomRadius: '10px',
  border: '2px solid #fff',
  borderTop: 'none',
  position: 'absolute' as any,
  initial: { opacity: 0, y: 0 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 0 },
  transition: { duration: 0.3 },
};

export const UserPill: FC = (props) => {
  const history = useHistory();
  const queryCache = useQueryCache();
  const ref = useRef<HTMLDivElement>(null);
  const user = session.selectors.useUser();

  const { brandColor } = useCMS();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const onLogout = () => {
    logoutEvent();

    queryCache.removeQueries();

    window.location.reload();
  };

  const goToDashboard = () => history.push('/missions');

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const nameOrEmail = Boolean(user?.firstName)
    ? user?.firstName
    : Boolean(user?.email)
    ? user?.email
    : 'User';

  return (
    <Flex {..._contprops} bg={brandColor}>
      <Flex {..._defaultprops} {...props} onClick={onToggle}>
        <Avatar size="md" />
        <Text isTruncated {..._textprops}>
          {nameOrEmail || ''}
        </Text>
        <Box
          size="20px"
          cursor="pointer"
          as={isOpen ? MdArrowDropUp : MdArrowDropDown}
        />
      </Flex>
      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <MotionBox ref={ref} bg={brandColor} {..._dropdnprops}>
            <Center flexDirection="column">
              <Button
                color="#fff"
                w="100%"
                size="sm"
                variant="ghost"
                onClick={() => {
                  onToggle();

                  goToDashboard();
                }}>
                Go to DroneAdair
              </Button>
              <Button
                w="100%"
                color="#fff"
                mt={2}
                size="sm"
                variant="ghost"
                onClick={() => {
                  onToggle();

                  onLogout();
                }}>
                Log out
              </Button>
            </Center>
          </MotionBox>
        )}
      </AnimatePresence>
    </Flex>
  );
};
