import React, { forwardRef, memo, useImperativeHandle } from 'react';

import { useDisclosure, useToast } from '@chakra-ui/react';

import { useMutation, useQueryCache } from 'react-query';
import { cancelMissionRequest } from 'api/mission';

import { selectors } from 'effector/session';

import { MissionStatuses } from 'types/mission';
import { CancelMissionModalProps, RefType } from './CancelMissionModal.props';

import CancelMissionModalView from './CancelMissionModal.view';

const Container = forwardRef<RefType, CancelMissionModalProps>((props, ref) => {
  const toast = useToast();
  const queryCache = useQueryCache();

  const accountType = selectors.useAccountType();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [submit, { isLoading }] = useMutation(cancelMissionRequest, {
    onSuccess: () => {
      onClose();

      toast({
        status: 'success',
        title: 'Cancellation request sent',
        isClosable: true,
        duration: 3000,
      });

      queryCache.invalidateQueries(`fetch-mission-${props.missionId}`);
      queryCache.invalidateQueries(`fetch-missions-undefined-CLIENT`);
      queryCache.invalidateQueries(`fetch-missions-undefined-PILOT`);
      queryCache.invalidateQueries(
        `fetch-missions-${MissionStatuses.COMPLETED}-PILOT`,
      );
      queryCache.invalidateQueries(`fetch-client-missions`);
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      onOpen,
    }),
    [],
  );

  const handleSubmit = (reason: string) => {
    if (reason.trim() === '') {
      return;
    }

    submit({
      missionId: props.missionId,
      reason,
      requestedBy: accountType as any,
    });
  };

  return (
    <CancelMissionModalView
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
    />
  );
});

const CancelMissionModal = memo(Container);

export default CancelMissionModal;
