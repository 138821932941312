import { createStore, forward } from 'effector';
import { useStore } from 'effector-react';
import { logoutEvent } from 'effector/session/events';
import { ConnectedAccount } from 'types/stripe';
import {
  fetchStripeKeyFx,
  fetchConnectedAccFx,
  fetchConnectedAccEv,
} from './effects';

interface StripeKeyStore {
  public_key: string | null;
}

const initialState: StripeKeyStore = {
  public_key: null,
};

export const StripeKeyStore = createStore<StripeKeyStore>(initialState).on(
  fetchStripeKeyFx.doneData,
  (_, public_key) => ({
    public_key,
  }),
);

export const $connectedAccount = createStore<ConnectedAccount | null>(null)
  .on(fetchConnectedAccFx.doneData, (_, account) => ({ ...account }))
  .reset(logoutEvent);

const $pending = fetchConnectedAccFx.pending;

const $null = $connectedAccount.map((c) => c == null);
const $isConnected = $connectedAccount.map((c) => {
  if (c?.details_submitted) {
    return true;
  } else if (c?.tos_acceptance) {
    if ('date' in c?.tos_acceptance && c?.tos_acceptance?.date !== null) {
      return true;
    }
  } else {
    return false;
  }

  return false;
});

const $accEnabled = $connectedAccount.map((c) => Boolean(c?.charges_enabled));

forward({
  from: fetchConnectedAccEv,
  to: fetchConnectedAccFx,
});

export const selectors = {
  useIsNull: () => useStore($null),
  useIsPending: () => useStore($pending),
  useIsEnabled: () => useStore($accEnabled),
  useIsConnected: () => useStore($isConnected),
  useConnectedAcc: () => useStore($connectedAccount),
  useStripeKey: () => useStore(StripeKeyStore),
};
