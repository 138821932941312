import React from 'react';

import { CustomCalendarProps } from './Calendar.props';

import { CustomCalendar } from './Calendar.style';

import 'react-calendar/dist/Calendar.css';

const CalendarView = (props: CustomCalendarProps): JSX.Element => {
  return (
    <CustomCalendar
      value={props.defaultValue}
      calendarType="US"
      prev2Label={null}
      next2Label={null}
      onChange={props.onChange}
      view="month"
      minDate={props.minDate}
      maxDate={props.maxDate}
      selectRange={props.selectRange}
      returnValue={props.returnValue}
      boxShadow={props.boxShadow}
      brandColor={props.brandColor}
      defaultValue={[new Date(), new Date()]}
    />
  );
};

export default CalendarView;
