import React, { FC } from 'react';

import { Flex, Image, Stack, Text } from '@chakra-ui/react';

import { useTimeoutId } from 'utils/hooks';
import { useFormikContext } from 'formik';

import { Asterik } from './Asterick';
import { ColorPicker } from './ColorPicker';
import { ProfilePicker } from '../ProfilePicker';

import skyrise from 'res/skyrise.png';
import aeroview from 'res/aeroview.png';

import { CMS } from 'types/cms';
import { uploadProfileToStripe } from 'api/stripe';
import { getStripeId } from 'effector/session/store';

const labelProps = {
  fontSize: '16px',
  color: '#707070',
  fontWeight: 'bold',
};

const css = {
  '&::-webkit-scrollbar': {
    height: '10px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#F6EEFF',
    borderRadius: '20px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#6728BB',
    borderRadius: '24px',
    width: '20px',
  },
};

export const TemplateDetails: FC<{
  onSave: (shouldPublish?: boolean) => void;
}> = ({ onSave }) => {
  const timeoutIdRef = useTimeoutId();

  const { values, setFieldValue } = useFormikContext<CMS>();

  const templateType = values?.templateType;

  React.useEffect(() => {
    setFieldValue('templateType', 0);
  }, [setFieldValue]);

  const handleFileUploadToStripe = async (file: File) => {
    const stripeId = getStripeId() || '';

    await uploadProfileToStripe(file, stripeId);
  };

  return (
    <Flex flex={1} overflowX="auto" css={css}>
      <Flex flex={1} gridGap="50px" justify="space-between">
        <Flex direction="column" gridGap="10px">
          <Text {...labelProps}>
            Upload your pictures <Asterik />
          </Text>

          <Flex gridGap="20px" align="center">
            <ProfilePicker
              type={0}
              aspectRatio={1}
              uploadFileToStripe={handleFileUploadToStripe}
            />
            <ProfilePicker type={1} aspectRatio={1920 / 480} />
          </Flex>

          <Flex mt="20px" direction="column">
            <Text {...labelProps}>
              Select your template color <Asterik />
            </Text>
            <ColorPicker onSave={onSave} />
          </Flex>
        </Flex>

        <Flex
          direction="column"
          gap="10px"
          overflowX="auto"
          pb="20px"
          px="10px">
          <Text {...labelProps}>
            Select your template <Asterik />
          </Text>
          <Flex gridGap="30px" h="100%">
            <Flex
              border="2px solid"
              borderColor={templateType === 0 ? '#6418C3' : '#fff'}
              onClick={() => {
                setFieldValue('templateType', 0);

                timeoutIdRef.current = setTimeout(() => {
                  onSave();
                }, 500);
              }}
              cursor="pointer"
              direction="column"
              w="260px"
              borderRadius="10px"
              overflow="hidden"
              shadow="lg">
              <Image
                src={skyrise}
                h="178px"
                w="100%"
                objectFit="contain"
                objectPosition="top"
                userSelect="none"
                draggable={false}
              />
              <Stack p={4}>
                <Text fontWeight="bold" color="#6418C3">
                  SkyRise
                </Text>
                <Text fontSize="12px">
                  This template name evokes the idea of soaring heights and
                  elevating your online presence.
                </Text>
              </Stack>
            </Flex>
            <Flex
              border="2px solid"
              borderColor={templateType === 1 ? '#6418C3' : '#fff'}
              onClick={() => {
                setFieldValue('templateType', 1);

                timeoutIdRef.current = setTimeout(() => {
                  onSave();
                }, 500);
              }}
              cursor="pointer"
              direction="column"
              w="260px"
              borderRadius="10px"
              overflow="hidden"
              shadow="lg">
              <Image
                src={aeroview}
                h="178px"
                w="100%"
                objectFit="contain"
                objectPosition="top"
                userSelect="none"
                draggable={false}
              />
              <Stack p={4}>
                <Text fontWeight="bold" color="#6418C3">
                  AeroView
                </Text>
              </Stack>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
