import { Flex, Stack, Text, Image, Button } from '@chakra-ui/react';
import React from 'react';

import { UpdateToProCardProps } from './UpdateToProCard.props';

import bgImage from 'res/vector.png';
import announcement from 'res/logout-img-croped.png';
import { useHistory, useLocation } from 'react-router-dom';

const UpdateToProCardView = (props: UpdateToProCardProps): JSX.Element => {
  const history = useHistory();

  return (
    <Stack
      borderRadius="12px"
      bg={'#ECE8DF'}
      mt={5}
      backgroundImage={`url("${bgImage}")`}>
      <Flex>
        <Flex marginY={5} marginRight={-20}>
          <Text fontSize="15px" fontFamily="Roboto" fontWeight="500" px={7}>
            Unleash the full potential of Drone Adair with
            <Text
              fontSize="15px"
              fontFamily="Roboto"
              as="span"
              fontWeight="700">
              {' '}
              our Pro Subscription.
            </Text>{' '}
            Get access to tools like customizable booking pages, client/payment
            management, and resources/courses to help you grow!{' '}
            <Text
              fontSize="15px"
              fontFamily="Roboto"
              as="span"
              fontWeight="700">
              {'    '}
              Take your drone business to new heights!
            </Text>
          </Text>
        </Flex>
        <Image
          maxH="140px"
          paddingTop={3}
          objectFit="unset"
          src={announcement}
        />
        <Flex
          flexDir="column"
          marginY={5}
          ml={-10}
          paddingRight={5}
          fontFamily="roboto">
          <Text
            fontFamily="roboto"
            fontSize="14px"
            textAlign="center"
            fontWeight="500">
            Take your drone
          </Text>
          <Text
            fontFamily="roboto"
            fontSize="14px"
            textAlign="center"
            fontWeight="500">
            passion to the next level.
          </Text>
          <Button onClick={() => history.replace('/pilot/welcome')}>
            UPGRADE TO PRO NOW!
          </Button>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default UpdateToProCardView;
