import React, { useEffect } from 'react';

import { Flex } from '@chakra-ui/layout';

import { Switch } from 'react-router';

import NotFound from 'routes/auth/NotFound';
import PilotWebsite from './PilotWebsite';
import PremiumMissionCreate from './PremiumMissionCreate';
import PilotWebsitePreview from './PilotWebsitePreview';

import ROUTES, { routesSettings } from 'constants/routes';
import RouteWithSubRoutes from 'components/core/RouteWithSubRoutes';
import { getUserType } from 'effector/session/store';
import { AccountTypes } from 'types/accountTypes';
import { fetchConnectedAccEv } from 'effector/stripe';

const routeConfig = Object.freeze({
  [ROUTES.PILOT_PILOT_WEBSITE_PREVIEW]: {
    component: PilotWebsitePreview,
    path: routesSettings.PILOT_PILOT_WEBSITE_PREVIEW.path,
  },
  [ROUTES.PILOT_WEBSITE]: {
    component: PilotWebsite,
    path: routesSettings.PILOT_WEBSITE.path,
  },
  [ROUTES.CLIENT_PREMIUM_MISSION_CREATE]: {
    component: PremiumMissionCreate,
    path: routesSettings.CLIENT_PREMIUM_MISSION_CREATE.path,
  },
  [ROUTES.AUTH_NOT_FOUND]: {
    component: NotFound,
    path: routesSettings.AUTH_NOT_FOUND.path,
  },
});

const PilotWebsiteRoutes = (): JSX.Element => {
  useEffect(() => {
    const accountType = getUserType();

    if (accountType === AccountTypes.pilot) {
      fetchConnectedAccEv(0);
    }
  }, []);

  return (
    <Flex
      flex={1}
      w="100%"
      className="premium-site"
      justifyContent="center"
      alignItems="center"
      direction="column">
      <Switch>
        {Object.entries(routeConfig).map(([key, val]) => (
          <RouteWithSubRoutes key={key} {...val} exact />
        ))}
      </Switch>
    </Flex>
  );
};

export default PilotWebsiteRoutes;
