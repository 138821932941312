import React, { forwardRef, memo, useImperativeHandle, useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { useLocation } from 'react-router-dom';

import {
  TabIndex,
  ModalRefType,
  OnboardingModalProps,
} from './OnboardingModal.props';

import OnboardingModalView from './OnboardingModal.view';

const OnboardingModalContainer = forwardRef<ModalRefType, OnboardingModalProps>(
  ({ cms, isStripeConnected }, ref) => {
    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const fromStripeSuccess = search.get('fromStripe') === 'true';

    const isDefaultOpen = fromStripeSuccess || !isStripeConnected;

    const { isOpen, onOpen, onClose } = useDisclosure({
      defaultIsOpen: isDefaultOpen,
    });

    const [index, setIndex] = useState<TabIndex>(fromStripeSuccess ? 3 : 0);

    useImperativeHandle(
      ref,
      () => ({
        onOpen: () => {
          setIndex(1);
          onOpen();
        },
      }),
      [],
    );

    return (
      <OnboardingModalView
        cms={cms}
        index={index}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        setIndex={setIndex}
      />
    );
  },
);

const OnboardingModal = memo(OnboardingModalContainer);

export default OnboardingModal;
