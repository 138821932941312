import React, { FC, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Input,
  Checkbox,
  FormLabel,
  useDisclosure,
  useOutsideClick,
  Stack,
} from '@chakra-ui/react';

import {
  isTodayOrTomorrow,
  isDifferenceGreaterThanTwoDays,
} from 'utils/isTodayOrTomorrow';
import { addDays, format } from 'date-fns';
import { ErrorMessage, useFormikContext } from 'formik';

import { MdChevronLeft, MdChevronRight, MdEditCalendar } from 'react-icons/md';

import { CustomCalendar } from 'components/primitives/Calendar/Calendar.style';

import { FormikCalendarProps } from './FormikCalendar.props';
import { IoMdCalendar } from 'react-icons/io';

export const FormikCalendar: FC<FormikCalendarProps> = ({
  css,
  fontSize,
  fontColor,
  brandColor,
  labelProps,
  renderLabel,
  checkBoxProps,
  inputSize = 'lg',
  isMobile,
  calendarProps = {},
}) => {
  const flexRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormikContext<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useOutsideClick({
    ref: flexRef,
    handler: () => {
      onClose();

      if (errors.eventStart) {
        const dueDate = new Date(values.dueDate);
        const eventStart = new Date(values.eventStart);

        if (isTodayOrTomorrow(eventStart)) {
          if (isDifferenceGreaterThanTwoDays(eventStart, dueDate)) {
            return;
          }

          setTimeout(() => {
            setFieldValue('rush', true);
          }, 10);
        }
      }
    },
  });

  const isRush = values?.rush;
  const isLive = values?.liveEvent;

  return (
    <Flex mt={3} ref={flexRef} w="100%" position="relative" direction="column">
      {renderLabel ? (
        renderLabel()
      ) : isMobile ? (
        <Flex justifyItems="center" alignItems="center" color="#908361">
          <IoMdCalendar size="15px" />
          <FormLabel fontWeight="bold" {...labelProps}>
            Date Range
          </FormLabel>
        </Flex>
      ) : (
        <FormLabel fontWeight="bold" {...labelProps}>
          Date Range
        </FormLabel>
      )}
      <Input
        isDisabled={isRush || isLive}
        ref={inputRef}
        isReadOnly
        isInvalid={Boolean(errors.eventStart && touched.eventStart)}
        size={inputSize}
        css={css}
        bg="#F3F3F3"
        fontSize={fontSize}
        placeholder="MM/DD/YYYY - MM/DD/YYYY"
        onFocus={onOpen}
      />
      {Boolean(errors.eventStart) && (
        <Text color={'red.500'} fontSize={'13px'}>
          <ErrorMessage name="eventStart" />
        </Text>
      )}
      <Checkbox
        color="#4D4D4D"
        mt={2}
        alignItems="flex-start"
        fontSize="14px"
        colorScheme="brand"
        isChecked={isRush}
        {...checkBoxProps}
        sx={{
          '.chakra-checkbox__control[data-checked]': {
            background: brandColor,
            borderColor: brandColor,
            color: fontColor,
          },
          '.chakra-checkbox__control[data-focus]': {
            boxShadow: 'none',
          },
        }}
        onChange={(e) => {
          setFieldValue('rush', e.target.checked);

          if (isRush) {
            const eventStart = new Date(values.eventStart);
            const dueDate = new Date(values.dueDate);

            if (isTodayOrTomorrow(eventStart)) {
              if (isDifferenceGreaterThanTwoDays(eventStart, dueDate)) {
                return;
              }

              setFieldTouched('eventStart', true);

              setTimeout(() => {
                setFieldError(
                  'eventStart',
                  'Please select a date range other than today or tomorrow if its not a Rush.',
                );
              }, 200);

              onOpen();
            }
          } else {
            const today = new Date().toISOString();
            const tomorrow = addDays(new Date(), 1).toISOString();

            setFieldValue('eventStart', today);
            setFieldValue('dueDate', tomorrow);

            (inputRef.current as any).value = `${format(
              new Date(today),
              'MM/dd/yyyy',
            )} - ${format(new Date(tomorrow), 'MM/dd/yyyy')}`;
          }
        }}>
        I need the mission to be done in the next 48 hours (this may change
        prices)
      </Checkbox>
      {isOpen && (
        <Box zIndex={5} bg="#fff" position="absolute" top="100px">
          <CustomCalendar
            brandColor={brandColor}
            onChange={(dates) => {
              if (!Array.isArray(dates)) {
                return;
              }

              const [eventStart, dueDate] = dates;

              (inputRef.current as any).value = `${format(
                new Date(eventStart),
                'MM/dd/yyyy',
              )} - ${format(new Date(dueDate), 'MM/dd/yyyy')}`;

              setFieldValue('eventStart', new Date(eventStart).toISOString());
              setFieldValue('dueDate', new Date(dueDate).toISOString());

              let isStartTodayOrTomorrow = false;

              if (eventStart) {
                isStartTodayOrTomorrow = isTodayOrTomorrow(eventStart);
              }

              if (isStartTodayOrTomorrow) {
                if (isDifferenceGreaterThanTwoDays(eventStart, dueDate)) {
                  return;
                }

                setFieldValue('rush', true);
              } else {
                setFieldValue('rush', false);
              }

              setTimeout(() => {
                setFieldTouched('eventStart', true);
              }, 10);
            }}
            defaultValue={[new Date(), new Date()]}
            minDate={new Date()}
            selectRange={true}
            returnValue="range"
            prevLabel={
              <MdChevronLeft
                size="20px"
                style={{ marginLeft: 40, color: brandColor }}
              />
            }
            nextLabel={
              <MdChevronRight
                size="20px"
                style={{ marginRight: 40, color: brandColor }}
              />
            }
            next2Label={null}
            prev2Label={null}
            {...calendarProps}
          />
        </Box>
      )}
    </Flex>
  );
};
export default FormikCalendar;
