import { Session } from 'types/session';

import { SessionDomain } from './domain';

export const loginEvent = SessionDomain.createEvent<{
  session: Session;
  rememberMe: boolean;
}>();
export const signupEvent = SessionDomain.createEvent<Session>();
export const logoutEvent = SessionDomain.createEvent();
export const forgotPasswordEvent = SessionDomain.createEvent();
export const proSubscriptionEvent = SessionDomain.createEvent();
export const addActionRangeEvent = SessionDomain.createEvent<any>();
export const clearActionRangeEvent = SessionDomain.createEvent<any>();
export const refreshTokenEvent = SessionDomain.createEvent<Session['tokens']>();

export const toggleProEvent = SessionDomain.createEvent<boolean>();
export const toggleNewUser = SessionDomain.createEvent<boolean>();

export const updateUserEvent = SessionDomain.createEvent<Session['user']>();

export const fetchUser = SessionDomain.createEvent<number | undefined>();

export const hideTutorialEvent = SessionDomain.createEvent();
