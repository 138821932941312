import React, { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/toast';
import mission, { updateMission } from 'api/mission';
import { useMutation, useQueryCache } from 'react-query';
import { useHistory } from 'react-router';
import { MissionStatuses } from 'types/mission';

import { PilotMissionActionsProps } from './PilotMissionActions.props';
import PilotMissionActionsView from './PilotMissionActions.view';
import { useMissionAssets } from 'utils/hooks/useMissionAssets';

const PilotMissionActionsContainer = (
  props: PilotMissionActionsProps,
): JSX.Element => {
  const toast = useToast();
  const history = useHistory();
  const queryCache = useQueryCache();
  const assets = useMissionAssets(props.mission?.id);
  const [assetsUploaded, setAssetsUploaded] = useState(false);

  //verify the existence of assets data
  useEffect(() => {
    assets.data?.results.length
      ? setAssetsUploaded(true)
      : setAssetsUploaded(false);
  }, [assets.data?.results]);

  const invalidateQueries = async () => {
    await queryCache.invalidateQueries(
      [
        'fetch-missions-undefined-PILOT',
        `fetch-missions-${MissionStatuses.COMPLETED}-PILOT`,
        `fetch-mission-${props.mission.id}`,
      ],
      { exact: true },
    );

    queryCache.invalidateQueries([`fetch missions accepted`]);
  };

  const [complete, { isLoading }] = useMutation(
    async () =>
      await updateMission(
        {
          status: MissionStatuses.COMPLETED,
        },
        props.mission.id.toString(),
      ),
    {
      onSuccess: async () => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Mission Completed',
          isClosable: true,
          duration: 5000,
        });

        invalidateQueries();

        history.goBack();
      },
    },
  );

  // const [cancel, { isLoading: isCancelling }] = useMutation(
  //   async () =>
  //     await updateMission(
  //       {
  //         status: MissionStatuses.ORDERED,
  //         pilot: null,
  //       },
  //       props.mission.id.toString(),
  //     ),
  //   {
  //     onSuccess: async () => {
  //       toast({
  //         status: 'success',
  //         title: 'Success',
  //         description: 'Mission Cancelled',
  //         isClosable: true,
  //         duration: 5000,
  //       });

  //       invalidateQueries();

  //       history.goBack();
  //     },
  //   },
  // );

  return (
    <PilotMissionActionsView
      mission={props.mission}
      complete={complete}
      isLoading={isLoading}
      // cancel={cancel}
      // isCancelling={isCancelling}
      assetsUploaded={assetsUploaded}
    />
  );
};

export default PilotMissionActionsContainer;
