import React, { FC, useState } from 'react';
import {
  Flex,
  Box,
  Text,
  Image,
  IconButton,
  extendTheme,
  ChakraProvider,
} from '@chakra-ui/react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { mockTestimonialData } from 'components/modules/EquipmentsSection/MockData';
import { useIsMobile } from 'utils/hooks';
import { useCMS } from './Context';

import placeholder from 'res/placeholder.png';

const arrowStyles = {
  variant: 'solid',
  fontSize: '20px',
  borderWidth: '2px',
};

const TestimonialsSection: FC<any> = ({ color }) => {
  const [selectedTestimony, setSelectedTestimony] = useState(0);
  const isMobile = useIsMobile();
  const { cms } = useCMS();
  const testimonials = cms?.testimonials ?? [];

  const theme = extendTheme({
    colors: {
      toggled: {
        500: color, // To style the switch botggle buttom
      },
    },
  });

  if (testimonials.length === 0) {
    return null;
  }

  const handlePrev = () => {
    setSelectedTestimony(selectedTestimony - 1);
  };
  const handleNext = () => {
    setSelectedTestimony(selectedTestimony + 1);
  };

  const limitleft = () => {
    return testimonials.length < 10
      ? `0${selectedTestimony + 1}`
      : `${selectedTestimony + 1}`;
  };

  const limitRight = () => {
    return testimonials.length < 10
      ? `/ 0${testimonials.length}`
      : `/ ${testimonials.length}`;
  };

  const renderLeftSide = () => {
    return (
      <Flex flexDir="column" gridGap={5} mt={isMobile ? '8' : ''}>
        <Text
          style={{
            color: '#222222',
            fontFamily: 'Poppins',
            fontWeight: 'bold',
          }}
          fontSize={{ base: '28px', md: '33px' }}>
          Testimonials
        </Text>
        <Flex alignContent="center" justifyContent="flex-start" gridGap={2}>
          <Text
            fontSize={{ base: '28px', md: '54px' }}
            style={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              lineHeight: '28px',
            }}
            color={color}>
            {limitleft()}
          </Text>
          <Text
            style={{
              fontSize: '15px',
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              color: '#838383',
            }}>
            {limitRight()}
          </Text>
        </Flex>
        <Flex flexDir="row" gridGap={4}>
          <IconButton
            isRound={true}
            colorScheme="white"
            aria-label="Done"
            styles={arrowStyles}
            borderColor={color}
            borderWidth={2}
            icon={<MdChevronLeft color={color} />}
            onClick={() => handlePrev()}
            isDisabled={selectedTestimony === 0}
          />
          <IconButton
            styles={arrowStyles}
            isRound={true}
            colorScheme="white"
            borderWidth={2}
            isDisabled={selectedTestimony + 1 >= testimonials.length}
            borderColor={color}
            aria-label="Done"
            onClick={() => handleNext()}
            icon={<MdChevronRight color={color} />}
          />
        </Flex>
      </Flex>
    );
  };

  const renderRightSide = () => {
    if (testimonials.length === 0) {
      return null;
    }

    const {
      testimony = '',
      signedUrl = '',
      name = '',
      position = '',
    } = testimonials?.[selectedTestimony];

    return (
      <Flex
        flexDir="column"
        w={{ base: '60vw', md: '40vw' }}
        justifyContent="flex-start"
        color="#838383"
        fontWeight="400px">
        <Flex flexDir="column" gridGap={8}>
          <Text fontSize="1rem">"{testimony}"</Text>
          <Flex align="center" gridGap={4}>
            <Image
              src={signedUrl}
              style={{
                width: '96px',
                height: '96px',
                borderRadius: '100rem',
              }}
              objectFit="cover"
              fallbackSrc={placeholder}
            />
            <Flex flexDir="column" gridGap={1}>
              <Text
                fontSize="1rem"
                color="#222222"
                fontWeight="bold"
                fontFamily="Poppins">
                {name}
              </Text>
              <Text
                fontSize="0.8rem"
                fontWeight="bold"
                fontFamily="Poppins"
                lineHeight="16px"
                flexWrap="wrap">
                {position}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      alignItems={isMobile ? 'center' : 'start'}
      justifyContent="center"
      p={10}
      flexDir={isMobile ? 'column-reverse' : 'row'}>
      <ChakraProvider theme={theme}>
        {renderLeftSide()}
        <Box w="10vw" h="auto" bg="white" />
        {renderRightSide()}
      </ChakraProvider>
    </Flex>
  );
};

export default TestimonialsSection;
