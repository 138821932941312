import React, { FC, useEffect, useState } from 'react';

import { Button } from '@chakra-ui/button';
import { useToast } from '@chakra-ui/toast';
import { Checkbox } from '@chakra-ui/checkbox';
import { Flex, Link, Text } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/media-query';

import {
  Elements,
  useStripe,
  useElements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { loadStripe, StripeCardNumberElement } from '@stripe/stripe-js';

import { useMutation } from 'react-query';
import { useFormikContext } from 'formik';

import { createMission } from 'api/mission';

import { useStore } from 'effector-react';
import { StripeKeyStore } from 'effector/stripe';
import { SessionStore } from 'effector/session/store';

import { MissionSideBar } from '../MissionSideBar';
import { SaveACard } from 'components/primitives/SaveACard';
import { SavedCards } from 'components/primitives/SavedCards';

import { setTabSelected } from 'routes/client/Dashboard/Dashboard.utils';
import { ResposiveContainerWithSidebar } from 'routes/client/Dashboard/Dashboard.style';

import {
  PayButtonProps,
  MissionCheckoutPanelProps,
} from './MissionCheckoutPanel.props';
import { PaymentMethod } from 'types/stripe';
import { MobileMissionCreationFooter } from '../MobileMissionCreationFooter';

// temp walkaround to force component render after delay inorder for stripe elements to mount
function useForceUpdate() {
  const [value, setValue] = useState(false);

  useEffect(() => {
    const forceUpdate = async () => {
      await new Promise((resolve: (value: null) => void) =>
        setTimeout(() => {
          setValue(true);
          resolve(null);
        }, 3000),
      );
    };

    forceUpdate();
  }, []);

  return value;
}

const PayButton: FC<PayButtonProps> = (props) => {
  const elements = useElements();

  const [isExpDisabled, setIsExpDisabled] = useState(true);
  const [isCvcDisabled, setIsCvcDisabled] = useState(true);
  const [isNumberDisabled, setIsNumberDisabled] = useState(true);

  const isUpdated = useForceUpdate();

  const { isLoading, payNow, isChecked, selectedCard } = props;

  const cardCvcEl = elements?.getElement(CardCvcElement);
  const cardExpEl = elements?.getElement(CardExpiryElement);
  const cardNumberEl = elements?.getElement(CardNumberElement);

  useEffect(() => {
    const unsubscribe = cardCvcEl?.on('change', (e) => {
      if (e.empty) {
        setIsCvcDisabled(true);
      } else if (e.error) {
        setIsCvcDisabled(true);
      } else if (!e.complete) {
        setIsCvcDisabled(true);
      } else if (e.complete) {
        setIsCvcDisabled(false);
      }
    });

    return () => {
      unsubscribe?.clear();
    };
  }, [cardCvcEl, isUpdated]);

  useEffect(() => {
    const unsubscribe = cardExpEl?.on('change', (e) => {
      if (e.empty) {
        setIsExpDisabled(true);
      } else if (e.error) {
        setIsExpDisabled(true);
      } else if (!e.complete) {
        setIsExpDisabled(true);
      } else if (e.complete) {
        setIsExpDisabled(false);
      }
    });

    return () => {
      unsubscribe?.clear();
    };
  }, [cardExpEl, isUpdated]);

  useEffect(() => {
    const unsubscribe = cardNumberEl?.on('change', (e) => {
      if (e.empty) {
        setIsNumberDisabled(true);
      } else if (e.error) {
        setIsNumberDisabled(true);
      } else if (!e.complete) {
        setIsNumberDisabled(true);
      } else if (e.complete) {
        setIsNumberDisabled(false);
      }
    });

    return () => {
      unsubscribe?.clear();
    };
  }, [cardNumberEl, isUpdated]);

  return (
    <Button
      fontSize="xs"
      w={['100px', '200px']}
      isLoading={isLoading}
      onClick={() => payNow()}
      isDisabled={
        !isChecked ||
        (selectedCard
          ? false
          : isNumberDisabled || isCvcDisabled || isExpDisabled)
      }>
      PAY NOW
    </Button>
  );
};

const MissionCheckoutPanelView = (props: MissionCheckoutPanelProps) => {
  const toast = useToast();
  const stripe = useStripe();
  const elements = useElements();

  const formik = props.mission ? undefined : useFormikContext<any>();

  const [isChecked, setIsChecked] = useState(false);
  const [isSmall] = useMediaQuery('(max-width: 600px)');
  const [selectedCard, setSelectedCard] = useState<PaymentMethod>();

  const isAuth = !!SessionStore.getState()?.session?.user?.id;

  const [payNow, { isLoading }] = useMutation(
    async () => {
      if (props.onMissionCreate) {
        await props.onMissionCreate();

        await new Promise((resolve) => setTimeout(resolve, 300));
      }

      if (!props.clientSecretRef.current && !props.onMissionCreate) {
        const res = await createMission(props.mission);
        props.clientSecretRef.current = res.data.clientSecret;
      }
      if (!stripe || !props.clientSecretRef.current) {
        throw 'Something went wrong';
      }

      if (!selectedCard && !elements) {
        throw 'Stripe not loaded';
      }

      const stripeResponse = await stripe.confirmCardPayment(
        props.clientSecretRef.current,
        {
          payment_method: selectedCard
            ? selectedCard.id
            : {
                card: elements?.getElement(
                  CardNumberElement,
                ) as StripeCardNumberElement,
              },
        },
      );

      if (stripeResponse.error) {
        throw stripeResponse.error;
      }

      return stripeResponse;
    },
    {
      onError: (err: any) => {
        toast({
          status: 'error',
          title: 'Payment Error',
          description: err?.message ?? '',
          isClosable: true,
          duration: 5000,
        });
      },
      onSuccess: (res: any) => {
        console.log(res);
        toast({
          status: 'success',
          title: 'Payment Confirmed',
          isClosable: true,
          duration: 5000,
        });

        props.setSelectedTab
          ? props.setSelectedTab(isAuth ? 3 : 4)
          : setTabSelected(3);
      },
    },
  );

  useEffect(() => {
    window.scrollTo({
      top: 10,
    });
  }, []);

  return (
    <ResposiveContainerWithSidebar
      w="100%"
      justifyContent="space-evenly"
      alignItems="flex-start">
      <Flex
        w={2 / 3}
        flex={1}
        mb="20px"
        mr={[0, '20px', '20px', '20px']}
        p={['30px', '30px 50px', '30px 50px', '30px 50px']}
        background="#fff"
        borderRadius="10px"
        border="1px"
        borderColor="#ccc"
        flexDirection="column">
        <Text w="100%" mb="20px" ml="-15px" fontWeight="bold">
          Fill your Payment info
        </Text>
        <SaveACard
          isPilotStripeAcc={Boolean(props?.pilotStripeId)}
          userEmail={formik?.values?.email}
        />
        {Boolean(props.pilotStripeId) ? null : (
          <SavedCards setSelectedCard={setSelectedCard} />
        )}
        <Checkbox
          w="100%"
          my="20px"
          size="md"
          display="flex"
          alignItems="flex-start"
          colorScheme="brand"
          isChecked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}>
          <Text fontSize="xs">
            I acknowledge that I have read and agree the{' '}
            <Link textDecoration="underline" color="buttonBgColor">
              terms and confitions
            </Link>
          </Text>
        </Checkbox>
        {isSmall ? (
          <MobileMissionCreationFooter>
            <Flex>
              <Button
                w="100px"
                mr="10px"
                fontSize="xs"
                variant="outline"
                onClick={() => {
                  props.setSelectedTab
                    ? props.setSelectedTab(1)
                    : setTabSelected(0);
                }}>
                CANCEL
              </Button>
              <PayButton
                payNow={payNow}
                isLoading={isLoading}
                isChecked={isChecked}
                selectedCard={selectedCard}
              />
            </Flex>
          </MobileMissionCreationFooter>
        ) : (
          <Flex direction={['column', 'column', 'row']}>
            <Button
              w="200px"
              mr="10px"
              fontSize="xs"
              variant="outline"
              onClick={() => {
                props.setSelectedTab
                  ? props.setSelectedTab(1)
                  : setTabSelected(0);
              }}>
              CANCEL
            </Button>
            <PayButton
              payNow={payNow}
              isLoading={isLoading}
              isChecked={isChecked}
              selectedCard={selectedCard}
            />
          </Flex>
        )}
      </Flex>
      {props.renderSideBar ? (
        props.renderSideBar
      ) : (
        <MissionSideBar packages={props.packages} />
      )}
    </ResposiveContainerWithSidebar>
  );
};

export default (props: MissionCheckoutPanelProps): JSX.Element | null => {
  const { public_key } = useStore(StripeKeyStore);

  if (!public_key) {
    return null;
  }

  const options = props?.pilotStripeId
    ? { stripeAccount: props.pilotStripeId }
    : undefined;

  return (
    <Elements stripe={loadStripe(public_key, options)}>
      <MissionCheckoutPanelView {...props} />
    </Elements>
  );
};
