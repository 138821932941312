import { format } from 'date-fns';

export default function calculateDueDate(dueDate: Date | Date[]): any {
  if (!dueDate) {
    return null;
  }

  if (Array.isArray(dueDate)) {
    return '';
  }

  return format(new Date(dueDate), 'dd MMMM yyyy');
}
