import React, { useLayoutEffect } from 'react';

import { HomeProps } from './Home.props';
// import HomeView from './Home.view';

const HomeContainer = (props: HomeProps) => {
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });

    props.history.replace('/login');
  }, []);

  return null;

  // return <HomeView />;
};

export default HomeContainer;
