import { useToast } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useMutation } from 'react-query';

import { EquipmentsSectionProps } from './EquipmentsSection.props';
import EquipmentsSectionView from './EquipmentsSection.view';
import { mockTestimonialData } from './MockData';

const Container: FC<EquipmentsSectionProps> = (props) => {
  const toast = useToast();

  const isLoading = false;

  return (
    <EquipmentsSectionView
      {...props}
      onSave={props.onSave}
      isLoading={isLoading}
    />
  );
};

export default Container;
