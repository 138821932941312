import React, { useEffect, useState } from 'react';

import { Avatar, Button, Flex, Image } from '@chakra-ui/react';
import { useField } from 'formik';
import { useDropzone } from 'react-dropzone';

import { FormikImageUploadProps } from './FormikImageUpload.props';

const FormikImageUploadView = ({
  preview = true,
  buttonText,
  buttonProps,
  containerProps,
  ...props
}: FormikImageUploadProps): JSX.Element => {
  const [file, setFile] = useState<File & { preview: string }>();

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        setFile(
          Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0]),
          }),
        );
      }
    },
  });
  const [{ value }, , field] = useField(props.name);

  useEffect(() => {
    const doUpload = async (newFile: File) => {
      field.setValue(newFile);
    };
    if (file) {
      doUpload(file);
    }
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (file) URL.revokeObjectURL(file.preview);
    };
  }, [file]);

  if (!preview) {
    return (
      <Flex
        align="center"
        {...props.flexProps}
        {...getRootProps()}
        h="40px"
        borderWidth="1px"
        borderColor="#ccc"
        borderRadius="">
        <input
          {...getInputProps()}
          placeholder={file?.name || 'Select an Image'}
          style={{
            width: '100%',
            overflow: 'hidden',
          }}
        />
      </Flex>
    );
  }
  return (
    <Flex
      flexDir="column"
      justify="center"
      align="center"
      {...containerProps}
      {...getRootProps()}>
      <Avatar
        h={['100px', '120px']}
        w={['100px', '120px']}
        bg="white"
        backgroundColor="#ccc"
        borderWidth="1px"
        src={file?.preview}>
        <input {...getInputProps()} />
      </Avatar>
      {buttonText && (
        <Button
          mt={5}
          size="xs"
          w="8.75rem"
          h="2rem"
          fontFamily="roboto"
          backgroundColor={buttonProps?.backgroundColor}>
          {buttonText}
        </Button>
      )}
    </Flex>
  );
};

export default FormikImageUploadView;
