import React, {
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import {
  Slider,
  SliderMark,
  SliderThumb,
  SliderTrack,
  SliderFilledTrack,
} from '@chakra-ui/slider';

import {
  Box,
  Text,
  Modal,
  Stack,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

import { METERS } from 'constants/common';

import { useFormikContext } from 'formik';

import GoogleMapsView from 'components/primitives/GoogleMaps';

import { CMS } from 'types/cms';

type Props = { onSave: () => void };
type RadiusModalRef = { onOpen: () => void };

const containerStyle = {
  width: '100%',
  height: '200px',
};

const mapOptions = {
  zoomControl: true,
};

const RadiusModal = forwardRef<RadiusModalRef, Props>((props, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { values, setFieldValue } = useFormikContext<CMS>();

  const [miles, setMiles] = useState(() => {
    const _miles = (values?.radius || 0) / METERS;

    return _miles < 30 ? 30 : _miles;
  });

  useImperativeHandle(
    ref,
    () => ({
      onOpen,
    }),
    [],
  );

  const coordinates = useMemo(
    () => ({
      lat: +values?.gps?.coordinates?.[1] || 40.712675,
      lng: +values?.gps?.coordinates?.[0] || -74.006652,
    }),
    [values.gps],
  );

  const radius = useMemo(() => miles * METERS, [miles]);

  const onSubmit = () => {
    setFieldValue('radius', Number(miles * METERS).toFixed(0));

    onClose();

    setTimeout(() => {
      props.onSave();
    }, 500);
  };

  return (
    <Modal size="xl" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">Set Radius</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <GoogleMapsView
              mapContainerStyle={containerStyle}
              initialCenter={coordinates}
              marker={coordinates}
              zoom={5}
              setFieldValue={setFieldValue}
              isRadius
              radius={radius}
              mapOptions={mapOptions}
            />
          </Stack>

          <Box mt="40px" mb="20px" px="10px" fontSize="14px">
            <Slider
              aria-label="slider-ex-1"
              value={miles}
              min={30}
              max={1000}
              onChange={setMiles}
              step={10}>
              <SliderTrack bg="#f1f1f1">
                <SliderFilledTrack bg="brand.500" />
              </SliderTrack>
              <SliderMark value={0} mt={2} ml={4}>
                30 miles
              </SliderMark>
              <SliderMark value={900} mt={2} ml="-20px" whiteSpace="pre">
                1000 miles
              </SliderMark>
              <SliderThumb boxSize={4} position="relative">
                <Text position="absolute" left={1} top={-6} whiteSpace="pre">
                  {miles}
                </Text>
              </SliderThumb>
            </Slider>
          </Box>
        </ModalBody>

        <ModalFooter justifyContent="center">
          <Button w="200px" onClick={onSubmit}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export { RadiusModal };
export type { RadiusModalRef };
