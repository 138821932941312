import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const ResponsiveButtonFlex = styled(Flex)`
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 1180px) {
    > div {
      flex-basis: calc(50% - 20px);
      flex-wrap: wrap;
    }
  }

  @media (max-width: 850px) {
    flex-direction: column;

    > div {
      flex-basis: 100%;
    }
  }

  @media (min-width: 500px) and (max-width: 800px) {
    flex-direction: row;

    > div {
      flex-basis: calc(50% - 20px);
      flex-wrap: wrap;
    }
  }
`;

export const ResponsivePackageBoxContainer = styled(Flex)`
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 5px;

  @media (max-width: 1200px) {
    > div {
      margin-bottom: 60px;
      flex-basis: calc(50% - 10px);
      flex-wrap: wrap;
    }
  }

  @media (min-width: 800px) and (max-width: 900px) {
    > div {
      margin-bottom: 50px;
      flex-basis: 100%;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 500px) {
    > div {
      margin-bottom: 50px;
      flex-basis: 100%;
      flex-wrap: wrap;
    }
  }
`;
