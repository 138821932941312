import React, { FC } from 'react';

import welcome from 'res/welcome.png';

import { Button, Flex, Heading, Image, Text } from '@chakra-ui/react';

import { animationConfig, MotionFlex } from './Motion';

import { CommonProps } from '../OnboardingModal.props';

const Welcome: FC<CommonProps> = ({ setIndex }) => {
  return (
    <MotionFlex
      {...animationConfig}
      direction="row"
      flex={1}
      gridGap="20px"
      justify="center"
      align="center">
      <Image src={welcome} maxH="200px" />
      <Flex direction="column" gridGap="10px">
        <Heading fontSize="22px">Welcome To Your Website!</Heading>
        <Text>
          We're so excited to have you here, please take a few minutes to
          complete you website info!
        </Text>
        <Button onClick={() => setIndex(1)}>CONTINUE</Button>
      </Flex>
    </MotionFlex>
  );
};

export default Welcome;
