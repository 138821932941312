import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Image,
  Button,
  Divider,
  Center,
} from '@chakra-ui/react';

import { MobileLandingScreenProps } from './MobileLandingScreen.props';

import bgImage from 'res/welcomepageimg.png';
import logo from 'res/logos/logo6.png';
import { GoogleLogin } from '@react-oauth/google';

const basicBoxStyles = {
  display: 'flex',
  flexDir: 'column',
  alignItems: 'center',
  textAlign: 'center',
  color: 'white',
  fontSize: '1.32rem',
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  px: 4,
  background: `url("${bgImage}") center no-repeat`,
  opacity: '1',
  backgroundSize: 'cover', // Ensure the background image covers the entire box
  height: '100vh', // Set the height to 100% of the viewport height
  width: '100vw',
  brightness: '10%',
};

const MobileLandingScreenView = (
  props: MobileLandingScreenProps,
): JSX.Element => {
  return (
    <Box sx={basicBoxStyles} filter="auto" brightness="40%">
      <Flex
        flexDir="column"
        alignItems="center"
        gridGap="2.5rem"
        mt="2rem"
        p="3rem">
        <Text>Welcome to the new wave of how to grow your drone business!</Text>
        <Text fontSize="1.5rem">Welcome to</Text>
      </Flex>
      <Image
        objectFit="contain"
        src={logo}
        width="11.37438rem"
        height=" 2.84369rem"
      />
      <Flex gridGap="1.5rem">
        <Button
          mb={5}
          type="submit"
          mt="10"
          variant="solid"
          color="#ffff"
          background="#908361"
          onClick={() => {
            props.onSingupPress();
          }}>
          SIGN UP
        </Button>
        <Button
          mb={5}
          type="submit"
          mt="10"
          variant="solid"
          color="#ffff"
          background="#908361"
          onClick={props.onLoginPress}>
          LOG IN
        </Button>
      </Flex>
      <Flex align="center">
        <Divider opacity="2" />
        <Text padding="1" fontSize="1rem">
          or
        </Text>
        <Divider width="50vw" />
      </Flex>
      <Flex width="100vw">
        <GoogleLogin
          containerProps={{
            style: {
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
          onSuccess={(res) => {
            if (res?.credential) {
              props.onSuccess(res.credential);
            }
          }}
          auto_select={false}
          shape="circle"
          size="large"
          width="100vw"
        />
      </Flex>
    </Box>
  );
};

export default MobileLandingScreenView;
