import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { MissionDetailsProps } from './MissionDetails.props';
import MissionDetailsView from './MissionDetails.view';

import useMission from 'utils/hooks/useMission';
import { Mission, MissionStatuses } from 'types/mission';
import { markNotificationAsRead } from 'utils/hooks';
import { useMutation, useQueryCache } from 'react-query';
import { deleteMission, updateMission } from 'api/mission';
import { useToast } from '@chakra-ui/react';

const MissionDetailsContainer = (props: MissionDetailsProps): JSX.Element => {
  const toast = useToast();
  const queryCache = useQueryCache();

  const { push } = useHistory();
  const params = useParams<{ missionId: string }>();

  const { isLoading, data } = useMission(params?.missionId);

  const [onMissionDelete, { isLoading: isDeleting }] = useMutation(
    deleteMission,
    {
      onSuccess: () => {
        toast({ title: 'Mission Deleted' });

        props.history.replace('/');

        queryCache.invalidateQueries(`fetch-mission-${params?.missionId}`);
        queryCache.invalidateQueries(`fetch-missions-undefined-CLIENT`);
        queryCache.invalidateQueries(`fetch-missions-undefined-PILOT`);
        queryCache.invalidateQueries(
          `fetch-missions-${MissionStatuses.COMPLETED}-PILOT`,
        );
        queryCache.invalidateQueries(`fetch-client-missions`);
      },
    },
  );

  const mission = data as Mission;
  const onGoBack = () => {
    push('/missions');
  };
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState(mission?.pilotNotes ?? '');
  const [tempNotes, setTempNotes] = useState(mission?.pilotNotes ?? '');

  const handleEditClick = () => {
    setIsEditing(true);
    setTempNotes(notes);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setTempNotes(notes);
  };

  const [handleSaveClick] = useMutation(
    async () =>
      updateMission(
        {
          pilotNotes: tempNotes,
        },
        mission.id,
      ),
    {
      onError: (err: any) => {
        toast({
          status: 'error',
          title: 'Error',
          description: err?.response?.data?.message ?? '',
          isClosable: true,
          duration: 5000,
        });
      },
      onSuccess: (res: any) => {
        queryCache.invalidateQueries(`fetch-mission-${params?.missionId}`);
        setIsEditing(false);
        setNotes(tempNotes);
        toast({
          title: 'Pilot Notes Edited Successfully',
          status: 'success',
          duration: 5000,
          description: 'Your notes have been updated successfully',
        });
      },
    },
  );

  useEffect(() => {
    markNotificationAsRead(params?.missionId, 0);

    if (mission?.pilotNotes) {
      setNotes(mission?.pilotNotes);
      setTempNotes(mission?.pilotNotes);
    }
  }, [mission]);

  return (
    <MissionDetailsView
      onGoBack={onGoBack}
      mission={mission}
      isLoading={isLoading}
      isDeleting={isDeleting}
      onMissionDelete={onMissionDelete}
      isEditable={isEditing}
      handleEditClick={handleEditClick}
      handleCancelClick={handleCancelClick}
      handleSaveClick={handleSaveClick}
      changeNotes={(value: any) => {
        setTempNotes(value);
      }}
      tempNotes={tempNotes}
    />
  );
};

export default MissionDetailsContainer;
