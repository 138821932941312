import React, { useCallback } from 'react';
import { Box, Button, Center, Flex, Spinner, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import {
  CustomMission,
  CustomStatus,
  Mission,
  MissionStatuses,
} from 'types/mission';

import noMissionsImg from 'res/no_missions.png';
import noMissionsImg2 from 'res/person-laptop.svg';

import {
  customStatusColorsMap,
  missionStatusColorsMap,
} from 'constants/assetStatusMap';
import { useHistory } from 'react-router-dom';
import { m } from 'framer-motion';

type Props = {
  missions: Mission[];
  customMissions: CustomMission[];
  isLoadingCustomMissions: boolean;
  isLoadingMissions: boolean;
  renderHeaderContent: any;
  setType: any;
};

const MobileDashboardContentView = ({
  missions,
  customMissions,
  isLoadingCustomMissions,
  isLoadingMissions,
  renderHeaderContent,
  setType,
}: Props) => {
  const history = useHistory();

  const getMissionColor = useCallback(
    (status?: MissionStatuses) => {
      if (!status) {
        return '#000';
      }
      return missionStatusColorsMap[status];
    },
    [missions],
  );

  return (
    <>
      <Box
        w="90vw"
        p="10px 10px"
        my="25px"
        bg="brand.500"
        color="#fff"
        backgroundColor="primaryColor"
        fontWeight="bold"
        borderRightRadius="30px">
        PACKAGE MISSIONS
      </Box>
      <Flex direction="column">
        {isLoadingMissions ? (
          <Center minH="200px" flex={1}>
            <Spinner />
          </Center>
        ) : missions.length > 0 ? (
          <Flex direction="column">
            {missions?.map((mission) => (
              <Flex
                gridGap="10px"
                key={mission.id}
                p="10px 20px"
                borderBottom="1px solid #ccc"
                direction="column"
                onClick={() => {
                  history.push(`mission/details/${mission.id}`);
                }}>
                <Flex justify="space-between" align="flex-end">
                  <Box>
                    <Text fontWeight="600" maxW="100%" noOfLines={1}>
                      {mission?.client?.firstName || ''}{' '}
                      {mission?.client?.lastName || ''}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="12px" noOfLines={1}>
                      {format(new Date(mission?.dueDate), 'dd MMM')}
                    </Text>
                  </Box>
                </Flex>
                <Flex justify="space-between" align="flex-end">
                  <Box>
                    <Text fontSize="12px" maxW="80%" noOfLines={1}>
                      {mission?.location || ''}
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontSize="12px"
                      fontWeight="bold"
                      noOfLines={1}
                      textTransform="capitalize"
                      color={getMissionColor(mission.status as any)}>
                      {mission.status?.toLocaleLowerCase()}
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            ))}
          </Flex>
        ) : (
          <Center>
            <Flex
              flexDirection="column"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <img
                src={noMissionsImg}
                alt="No missions available"
                style={{ width: '256px', height: 'auto' }}
              />
              <Text px={4} textAlign="center" fontSize={14}>
                Start showcasing your drone adventures and missions by creating
                and publishing your website. <br />
                Reach out to a wider audience.
              </Text>
            </Flex>
          </Center>
        )}

        <Center mt="20px">
          <Button
            size="sm"
            variant="ghost"
            onClick={() => {
              renderHeaderContent('company');
              setType('company');
            }}>
            See All
          </Button>
        </Center>
      </Flex>

      <Box
        w="90vw"
        p="10px 20px"
        my="20px"
        bg="brand.500"
        color="#fff"
        backgroundColor="primaryColor"
        fontWeight="bold"
        borderRightRadius="30px">
        CUSTOM MISSIONS
      </Box>
      <Flex direction="column">
        {isLoadingCustomMissions ? (
          <Center minH="200px" flex={1}>
            <Spinner />
          </Center>
        ) : customMissions.length > 0 ? (
          <Flex direction="column">
            {customMissions?.map((mission) => (
              <Flex
                gridGap="10px"
                key={mission.id}
                p="10px 20px"
                borderBottom="1px solid #ccc"
                direction="column"
                onClick={() => {
                  history.push(`/custom-mission/${mission.id}`);
                }}>
                <Flex justify="space-between" align="flex-end">
                  <Box>
                    <Text fontWeight="600" maxW="100%" noOfLines={1}>
                      {mission?.clientDetails?.name}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="12px" noOfLines={1}>
                      {format(new Date(mission?.dueDate), 'dd MMM')}
                    </Text>
                  </Box>
                </Flex>
                <Flex justify="space-between" align="flex-end">
                  <Box>
                    <Text fontSize="12px" maxW="90%" noOfLines={1}>
                      {mission.clientDetails?.location?.address || ''}
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontSize="12px"
                      noOfLines={1}
                      textTransform="capitalize"
                      fontWeight="bold"
                      color={customStatusColorsMap[mission.status]}>
                      {mission.status === CustomStatus.PILOT_ACCEPTED
                        ? 'Open'
                        : mission.status?.toLocaleLowerCase()}
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            ))}
          </Flex>
        ) : (
          <Center>
            <Flex
              flexDirection="column"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <img
                src={noMissionsImg2}
                alt="No missions available"
                style={{ width: '256px', height: 'auto', marginBottom: '16px' }}
              />
              <Text px={4} textAlign="center" fontSize={14}>
                Start showcasing your drone adventures and missions by creating
                and publishing your website. <br />
                Reach out to a wider audience.
              </Text>
            </Flex>
          </Center>
        )}
      </Flex>
      <Center mb="100px">
        <Button
          size="sm"
          variant="ghost"
          onClick={() => {
            renderHeaderContent('custom');
            setType('custom');
          }}>
          See All
        </Button>
      </Center>
    </>
  );
};

export default MobileDashboardContentView;
