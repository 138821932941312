import React, { useEffect } from 'react';

import { CreateWebsiteProps } from './CreateWebsite.props';
import CreateWebsiteView from './CreateWebsite.view';

import { useMutation, useQueryCache } from 'react-query';
import { createCMS, uploadImage } from 'api/cms';

import { useToast } from '@chakra-ui/toast';
import { CMS } from 'types/cms';
import api from 'api/instance';

type FileType = File & { signedUrl: string; id: string };

const CreateWebsiteContainer = (props: CreateWebsiteProps): JSX.Element => {
  const toast = useToast();
  const queryCache = useQueryCache();
  const [upload] = useMutation(uploadImage);
  const [create] = useMutation(createCMS);

  const onSubmit = async (values: CMS) => {
    try {
      const res = await create(values);

      if (!res) {
        return;
      }

      const previousWorkUploads = res.previousWork?.map(async (item) => {
        const file = {};

        return await upload({
          ...file,
          signedUrl: item.signedUrl || '',
          id: item.id || '',
        } as FileType).then(async () => {
          const { data } = await api.patch(`portfolio/${item.id}/confirmed`);

          return data;
        });
      });

      const equipmentUploads = res.equipments?.map(async (item) => {
        const file = {};

        return await upload({
          ...file,
          signedUrl: item.signedUrl || '',
          id: item.id || '',
        } as FileType).then(async () => {
          const { data } = await api.patch(`equipment/${item.id}/confirmed`);

          return data;
        });
      });

      return Promise.all([...previousWorkUploads, ...equipmentUploads])
        .then(() => {
          toast({
            status: 'success',
            title: 'Data Saved Succesfully',
            isClosable: true,
            duration: 5000,
          });

          queryCache.invalidateQueries('fetch-my-cms', { exact: true });
          queryCache.invalidateQueries(`fetch-my-cms-DRAFT`, {
            exact: true,
          });

          props.history.goBack();
        })
        .catch((e) => {
          console.log(e);

          toast({
            status: 'error',
            title: 'Error',
            description: e?.response?.data?.message ?? '',
            isClosable: true,
            duration: 5000,
          });
        });
    } catch (error: any) {
      console.log(error);

      toast({
        status: 'error',
        title: 'Error',
        description: error?.response?.data?.message ?? '',
        isClosable: true,
        duration: 5000,
      });
    }
  };

  return <CreateWebsiteView onSubmit={onSubmit} />;
};

export default CreateWebsiteContainer;
