import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    brand: {
      500: '#6728BB',
    },
    primaryColor: '#92835C',
    secondaryColor: '#373737',
    tertiaryColor: '#EFEDEA',
    bgColor: '#E2E2E2',
    buttonBgColor: '#6728BB',
    buttonBgColorHover: '#F3EAFF',
    solidButtonBgHoverColor: '#52248E',
    solidButtonBgClickColor: '#300765',
    outlineButtonBgHoverColor: '#F1ECF8',
    outlineButtonBgClickColor: '#DDCEF2',
    complementaryColor: '#7AB462',
    textColor: '#707070',
    inputColor: '#FAFAFA',
    inputBorderColor: '#E2E2E2',
    successColor: '#34D17D',
    paymentSuccessIconColor: '#7AAF62',
    errorColor: '#E9243F',
    colorScheme1: '#215287',
    colorScheme2: '#7B244A',
    colorScheme3: '#A9A133',
  },
  fonts: {
    body: 'Roboto, sans-serif',
    heading: '',
    mono: '',
  },
  fontSizes: {
    xs: '12px',
    sm: '13px',
    md: '14px',
    lg: '18px',
    xl: '24px',
    '2xl': '26px',
    '3xl': '28px',
  },
  lineHeights: {
    normal: 'normal',
    none: '1',
    shorter: '1.25',
    short: '1.375',
    base: '1.5',
    tall: '1.625',
    taller: '2',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        backgroundColor: 'buttonBgColor',
        borderRadius: '20px',
        letterSpacing: 'widest',
        _focus: {
          boxShadow: 'none',
        },
      },
      sizes: {
        xl: {
          h: '56px',
          fontSize: 'lg',
          px: '32px',
        },
      },
      variants: {
        'with-shadow': {
          bg: 'red.400',
        },
        solid: (props) => ({
          bg: 'buttonBgColor',
          color: '#fff',
          px: '20px',
          _hover: {
            backgroundColor: 'solidButtonBgHoverColor',
          },
          _active: {
            backgroundColor: 'solidButtonBgClickColor',
          },
        }),
        outline: (props) => ({
          px: '20px',
          borderColor: 'buttonBgColor',
          backgroundColor: '#fff',
          color: 'buttonBgColor',
          _hover: {
            backgroundColor: 'outlineButtonBgHoverColor',
          },
          _active: {
            backgroundColor: 'outlineButtonBgClickColor',
          },
        }),
        ghost: (props) => ({
          px: '20px',
          color: 'buttonBgColor',
          bg: 'transparent',
          _hover: {
            color: 'solidButtonBgHoverColor',
          },
          _active: {
            color: 'solidButtonBgClickColor',
          },
        }),
      },
    },
    Tabs: {
      variants: {
        'enclosed-colored': (props) => ({
          tab: {
            border: 'primaryColor',
            borderColor: 'primaryColor',
          },
          tabpanel: {
            bg: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: '0px 0px 8px 8px',
            p: '20px',
          },
        }),
      },
    },
    Link: {
      baseStyle: {
        color: 'buttonBgColor',
      },
    },
  },
});

export default theme;
