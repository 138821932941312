import React from 'react';

import { Flex, Image, Stack, Text } from '@chakra-ui/react';

import Image2 from 'res/DA_DL.png';

import COURSES, { Course, CourseTypes } from 'utils/educationCourses';
import CourseCard from 'components/primitives/CourseCard/CourseCard';

const sectionTitle = ['Basics', 'Prep', 'Business'];

const MobileEducationView = () => {
  const getCoursesByType = (typeIndex: CourseTypes) => {
    const coursesFilteredByTypes = COURSES.filter(
      (course: Course) => course.type === typeIndex,
    );
    return coursesFilteredByTypes;
  };

  return (
    <>
      <Flex
        bg="#f1f1f1"
        flexDirection={'column'}
        alignItems={'center'}
        marginX={10}>
        <Stack mt={7} alignItems="center">
          <Text
            fontSize="14px"
            fontWeight="400"
            fontFamily="roboto"
            color="#373737"
            lineHeight="16px"
            align="center">
            We are happy to help you to improve your skills!
          </Text>
          <Image src={Image2} objectFit="contain" paddingY={3} />
        </Stack>

        <Flex flexDirection={'column'}>
          {Object.keys(CourseTypes).map((type, index) => (
            <Flex flexDirection={'column'} key={`${type}-${index}`}>
              <Text fontSize="16px" fontFamily={'Roboto'} as="b" mt={2}>
                {sectionTitle[index]}
              </Text>
              <Flex
                flexWrap="wrap"
                alignItems="center"
                justifyContent={'center'}>
                {getCoursesByType(index).map(
                  (course: Course, index: number) => (
                    <CourseCard key={`${course.id}-${index}`} course={course} />
                  ),
                )}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </>
  );
};

export default MobileEducationView;
