import React, { FC, useState } from 'react';

import { Tab, Tabs, TabList, TabPanel, TabPanels } from '@chakra-ui/react';

import { useIsMobile, useSearchParamState } from 'utils/hooks';

import { PremiumMissionTabsProps } from './PremiumMissionTabs.props';

import { Filters } from '../Filters';
import { PremiumMissionList } from '../PremiumMissionList';

const tabSpacing = [5, 8, 8];

const PremiumMissionTabsView: FC<PremiumMissionTabsProps> = () => {
  const isMobile = useIsMobile();

  const [index, setIndex] = useState<number>(0);
  const [, , clearPage] = useSearchParamState('page');

  if (isMobile) {
    return (
      <Tabs
        mt={3}
        isFitted
        isLazy
        variant="enclosed"
        index={index}
        onChange={setIndex}
        width="100%"
        alignItems="center"
        borderBottomRadius="20px">
        <TabList>
          <Tab
            backgroundColor="#E0E0E0"
            color="#7A7A7A"
            justifyContent="center"
            _focus={{ borderColor: 'none' }}
            _selected={{ backgroundColor: '#F5F5F5', color: 'secondaryColor' }}
            fontSize="md"
            fontWeight="bold"
            py={4}>
            CURRENT MISSIONS
          </Tab>
          <Tab
            backgroundColor="#E0E0E0"
            color="#7A7A7A"
            _focus={{ borderColor: 'none' }}
            _selected={{ backgroundColor: '#F5F5F5', color: 'secondaryColor' }}
            fontSize="md"
            fontWeight="bold"
            py={4}>
            PAST MISSIONS
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <PremiumMissionList type={0} />
          </TabPanel>
          <TabPanel>
            <PremiumMissionList type={1} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }

  return (
    <Tabs
      mt={2}
      isLazy
      variant="enclosed"
      index={index}
      onChange={(_index) => {
        setIndex(_index);
        clearPage();
      }}
      overflowX="hidden">
      <TabList>
        <Tab
          _focus={{ borderColor: 'none' }}
          _selected={{ backgroundColor: 'primaryColor', color: 'white' }}
          fontSize="md"
          fontWeight="bold"
          px={tabSpacing}
          py="3">
          CURRENT MISSIONS
        </Tab>
        <Tab
          _focus={{ borderColor: 'none' }}
          _selected={{ backgroundColor: 'primaryColor', color: 'white' }}
          fontSize="md"
          fontWeight="bold"
          px={tabSpacing}
          py="3">
          PAST MISSIONS
        </Tab>
        <Tab
          flex={1}
          fontSize="md"
          style={{ visibility: 'hidden' }}
          fontWeight="bold"
          color="white"
          px={tabSpacing}
          py="3">
          Tab Placeholder
        </Tab>

        <Filters />
      </TabList>

      <TabPanels bg="white" border="none" minH="500px">
        <TabPanel p={0}>
          <PremiumMissionList type={0} />
        </TabPanel>
        <TabPanel p={0}>
          <PremiumMissionList type={1} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default PremiumMissionTabsView;
