import React, { FC } from 'react';

import {
  Text,
  Flex,
  Image,
  Stack,
  Button,
  Center,
  Heading,
} from '@chakra-ui/react';

import character from 'res/stripe-success.png';

import { StripeConnectSuccessGeneratedProps } from './StripeConnectSuccess.props';

const View: FC<StripeConnectSuccessGeneratedProps> = (props) => {
  const { fname, goToDashboard } = props;

  return (
    <Flex
      direction="column"
      flex={1}
      align="center"
      justify="center"
      padding={5}
      width="100%"
      bg="gray.50">
      <Center
        mt={8}
        mb={20}
        p={6}
        minW={{ base: 'auto', md: '700px' }}
        bg="#ffff"
        shadow="lg"
        borderRadius="10px"
        alignSelf="center"
        flexDirection="column">
        <Heading
          fontSize="4xl"
          color="primaryColor"
          fontFamily="Monument Extended">
          Welcome {fname}
        </Heading>
        <Text mt={2} opacity={0.5} fontSize="lg" fontFamily="Monument Extended">
          Your account is all set!
        </Text>
        <Image mt={8} maxH="300px" maxW="300px" src={character} />
        <Stack mt={6} spacing={4}>
          <Button w="300px" onClick={goToDashboard}>
            GO TO DASHBOARD
          </Button>
        </Stack>
      </Center>
    </Flex>
  );
};

export default View;
