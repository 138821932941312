import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import {
  Text,
  Flex,
  Image,
  Modal,
  Button,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useToast,
  useDisclosure,
  ImageProps,
} from '@chakra-ui/react';

import * as Yup from 'yup';

import asset from 'res/login.png';

import { Form, Formik } from 'formik';

import FormikInput from 'components/primitives/FormikInput';

import authApi from 'api/auth';
import { useMutation } from 'react-query';
import { loginEvent, signupEvent } from 'effector/session/events';
import { AccountTypes } from 'types/accountTypes';
import {
  phoneRegExp,
  responsiveFlexDir,
  responsiveSpacing,
} from 'constants/registration';
import FormikImageUpload from 'components/modules/FormikImageUpload';
import PlacesAutocompleteView from 'components/primitives/PlacesAutocomplete/PlacesAutocomplete.view';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { getRememberMe } from 'effector/session/store';

const UserRegistrationSchema = Yup.object().shape({
  fullName: Yup.string().required('Required').trim(),
  email: Yup.string().email('Invalid email').required('Required').trim(),
  password: Yup.string().required('Required').trim(),
  confirmPassword: Yup.string().required('Required').trim(),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is invalid').trim(),
  address: Yup.string().trim(),
  state: Yup.string().trim(),
  zipcode: Yup.number(),
});

export const LoginForm: FC<{
  onClose: () => void;
  onSignUpClick: () => void;
  isSignUp?: boolean;
  customAsset?: string;
  assetProps?: ImageProps;
  loginApi?: (_payload: any) => Promise<any>;
}> = ({
  loginApi,
  isSignUp,
  customAsset,
  assetProps,
  onClose,
  onSignUpClick,
}) => {
  const toast = useToast();

  const [login, { isLoading }] = useMutation(
    loginApi ? loginApi : authApi.login,
    {
      onSuccess: (data) => {
        if (data?.user?.accountType === AccountTypes.pilot) {
          toast({
            title: 'Unauthorised',
            status: 'error',
            description: 'Pilots cannot login here',
            duration: 2000,
            isClosable: true,
          });

          return;
        }

        loginEvent({
          session: data,
          rememberMe: getRememberMe(),
        });
      },
    },
  );

  return (
    <Flex>
      <Image
        src={customAsset ? customAsset : asset}
        h="450px"
        w={{ base: '0px', md: '400px' }}
        objectFit="cover"
        {...assetProps}
      />
      <Flex flex={1} direction="column" justify="center" px={2} maxW="400px">
        <Text fontSize="24px" fontWeight="bold">
          Login with your Account
        </Text>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={(values) => login({ ...values })}>
          <Form>
            <FormikInput name="email" label="Email" />
            <FormikInput name="password" label="Password" />
            <Text my={1} textAlign="right" fontSize="14px" color="brand.500">
              Forgot password?
            </Text>
            <Flex my={4}>
              <Button
                isDisabled={isLoading}
                onClick={onClose}
                w="185px"
                variant="outline">
                Cancel
              </Button>
              <Button isLoading={isLoading} type="submit" ml={2} w="185px">
                Login
              </Button>
            </Flex>
            {isSignUp && (
              <Flex justify="center">
                <Text fontSize="14px">Don't have an account?</Text>
                <Text
                  cursor="pointer"
                  ml={1}
                  fontSize="14px"
                  fontWeight="bold"
                  color="brand.500"
                  onClick={onSignUpClick}>
                  Sign up
                </Text>
              </Flex>
            )}
          </Form>
        </Formik>
      </Flex>
    </Flex>
  );
};

export const SignupForm: FC<{
  onClose: () => void;
  onLoginClick: () => void;
}> = ({ onClose, onLoginClick }) => {
  const toast = useToast();

  const [uploadImage, { isLoading: isUploading }] = useMutation(
    authApi.uploadImage,
  );

  const [signup, { isLoading }] = useMutation(
    ({ image, ..._payload }: any) => authApi.signup(_payload),
    {
      onSuccess: async (session, payload: any) => {
        if (session?.signedUrl || session?.user?.signedUrl) {
          try {
            await uploadImage({
              image: payload.image,
              signedUrl: session?.user?.signedUrl ?? '',
            });
          } catch (e) {
            console.log(e);
          }
        }

        signupEvent(session);
      },
    },
  );

  const errorMessage = (message: string) => {
    toast({
      status: 'error',
      title: 'Signup failed',
      description: message,
      duration: 3000,
      isClosable: true,
      position: 'top',
    });
  };

  const payloadWithFormattedNames = (payload: any) => {
    // delete payload.image;
    const { fullName } = payload;
    const splittedFullName = fullName.split(' ');
    let lastName = fullName.split(' ').slice(-1).join(' ');
    let firstName = fullName.split(' ').slice(0, -1).join(' ');
    let middleName = '';

    if (splittedFullName.length === 1) {
      firstName = fullName;
      lastName = '';
    }

    if (splittedFullName.length >= 3) {
      firstName = fullName.split(' ').slice(0, -2).join(' ');
      middleName = fullName.split(' ').slice(-2)[0];
    }

    delete payload.fullName;
    delete payload.confirmPassword;

    return {
      ...payload,
      firstName,
      middleName,
      lastName,
    };
  };

  const onSubmit = async (payload: any) => {
    if (payload.password !== payload.confirmPassword) {
      errorMessage('Passwords do not match');
      return;
    }

    if (payload.fullName) {
      const values = Object.assign({}, payload);
      const formattedPayload = payloadWithFormattedNames(values);
      const accountType = AccountTypes.client;
      delete formattedPayload.companyLicenseNumber;

      const signupData = {
        ...formattedPayload,
        // value for profileImage is going to be a hash generated on BE.
        profileImage: payload?.image?.path || 'profileImageSample.jpg',

        accountType,
      };

      signup(signupData);
    }
  };

  return (
    <Flex flex={1} direction="column" justify="center" px={10} py={5}>
      <Text textAlign="center" fontSize="24px" fontWeight="bold">
        Client Registration
      </Text>
      <Formik
        initialValues={{
          fullName: '',
          email: '',
          password: '',
          confirmPassword: '',
          phone: '',
          address: '',
          state: '',
          zipcode: '',
          image: undefined,
        }}
        validationSchema={UserRegistrationSchema}
        enableReinitialize
        onSubmit={(values) => onSubmit(values)}>
        {({ values, setFieldValue }) => (
          <Form>
            <Flex
              flexDir={['column-reverse', 'column-reverse', 'row']}
              justifyContent="space-between">
              <Flex flexDir="column" w="100%">
                <FormikInput
                  id="user-full-name"
                  name="fullName"
                  label="Full Name*"
                />
                <FormikInput
                  id="user-email"
                  name="email"
                  label="Email Address*"
                />
                <FormikInput id="user-phone" name="phone" label="Phone" />
              </Flex>
              <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
                mt={[10, 10, 0]}
                w="100%">
                <FormikImageUpload
                  name="image"
                  uploadHandler={() => null}
                  buttonText="Select Picture"
                />
              </Flex>
            </Flex>
            <Flex flexDir={responsiveFlexDir}>
              <Flex flexDir="column" w="100%">
                <PlacesAutocompleteView
                  inputStyle={{
                    bg: 'inputColor',
                  }}
                  labelProps={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                  noPlaceholder
                  value={values?.address || ''}
                  name="address"
                  labelText="Address"
                  searchOptions={{
                    componentRestrictions: {
                      country: 'us',
                    },
                  }}
                  setFieldValue={setFieldValue}
                  onSelect={async (address: string) => {
                    const result = await geocodeByAddress(address);
                    const { lat, lng } = await getLatLng(result?.[0]);

                    const state =
                      result?.[0]?.address_components?.find((i) =>
                        i.types.includes('administrative_area_level_1'),
                      )?.long_name ?? '';

                    const zipcode =
                      result?.[0]?.address_components?.find((i) =>
                        i.types.includes('postal_code'),
                      )?.long_name ?? '';

                    setFieldValue('state', state);
                    setFieldValue('zipcode', zipcode);
                    setFieldValue('address', address);
                    setFieldValue('latitude', lat);
                    setFieldValue('longitude', lng);
                  }}
                />
              </Flex>

              <Flex flexDir={responsiveFlexDir} w="100%">
                <Flex
                  flexDir="column"
                  ml={responsiveSpacing}
                  mr={responsiveSpacing}>
                  <FormikInput id="user-state" name="state" label="State" />
                </Flex>

                <Flex flexDir="column">
                  <FormikInput
                    id="user-zipcode"
                    type="number"
                    name="zipcode"
                    label="Zip Code"
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex flexDir={responsiveFlexDir}>
              <Flex
                style={{ visibility: 'hidden' }}
                flexDir="column"
                w="100%"
              />
            </Flex>
            <Flex flexDir={responsiveFlexDir} justifyContent="space-between">
              <Flex flex={1} flexDir="column">
                <FormikInput
                  id="user-password"
                  type="password"
                  name="password"
                  label="Password*"
                />
              </Flex>
              <Flex ml={responsiveSpacing} flex={1} flexDir="column">
                <FormikInput
                  id="user-confirm-password"
                  type="password"
                  name="confirmPassword"
                  label="Password Confirmation*"
                />
              </Flex>
            </Flex>
            <Flex justify="center" my={5}>
              <Flex my={4}>
                <Button
                  isDisabled={isLoading}
                  onClick={onClose}
                  w="185px"
                  variant="outline">
                  Cancel
                </Button>
                <Button isLoading={isLoading} type="submit" ml={2} w="185px">
                  Create
                </Button>
              </Flex>
            </Flex>
            <Flex justify="center">
              <Text fontSize="14px">Have account?</Text>
              <Text
                cursor="pointer"
                ml={1}
                fontSize="14px"
                fontWeight="bold"
                color="brand.500"
                onClick={onLoginClick}>
                Login
              </Text>
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};

export const LoginModal = forwardRef((_, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [formtype, setFormtype] = useState<'login' | 'signup'>('login');

  useImperativeHandle(ref, () => ({ onOpen }), []);

  useEffect(() => {
    if (!isOpen) {
      setFormtype('login');
    }
  }, [isOpen]);

  return (
    <Modal size="4xl" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0}>
          {formtype === 'login' ? (
            <LoginForm
              onClose={onClose}
              onSignUpClick={() => setFormtype('signup')}
            />
          ) : (
            <SignupForm
              onClose={onClose}
              onLoginClick={() => setFormtype('login')}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
