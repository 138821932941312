import React from 'react';
import FormikDatePicker from '../FormikDatePicker';
import { Flex, Text } from '@chakra-ui/layout';
import { Radio, RadioGroup } from '@chakra-ui/radio';
import { Select } from '@chakra-ui/select';
import { AddDayDateProps } from './AddDayDate.props';

const AddDayDateView = ({
  onChangeHour,
  onChangeMeridian,
  name = 'liveDay',
}: AddDayDateProps): JSX.Element => (
  <Flex flexDirection="column">
    <Flex
      borderColor="red"
      borderWidth="1px"
      borderRadius="5px"
      mb="10px"
      p="15px">
      <Flex w="120px" mr="15px">
        <FormikDatePicker name={name} label="Day" disablePastDates={true} />
      </Flex>
      <Flex>
        <Flex mr="15px">
          <Flex flexDirection="column" mr="10px">
            <Text fontSize="xs" fontWeight="700" mb="10px">
              Hour
            </Text>
            <Select fontSize="xs" size="md" mr="10px" onChange={onChangeHour}>
              {[...Array(12)].map((n, i) => (
                <option value={i + 1} key={`liveStartHour-${i + 1}`}>
                  {String(i + 1).length === 1 ? `0${i + 1}` : i + 1}
                </option>
              ))}
            </Select>
          </Flex>
          <RadioGroup
            mt="25px"
            display="flex"
            defaultValue="0"
            onChange={onChangeMeridian}>
            <Radio value="am" mr="20px">
              <Text fontSize="xs">AM</Text>
            </Radio>
            <Radio value="pm">
              <Text fontSize="xs">PM</Text>
            </Radio>
          </RadioGroup>
        </Flex>
      </Flex>
    </Flex>
  </Flex>
);

export default AddDayDateView;
