import React, { useEffect } from 'react';

import { useQuery } from 'react-query';

import { getUser } from 'api/auth';
import { getCMSByCompany } from 'api/cms';

import { PilotWebsiteProps } from './PilotWebsite.props';
import PilotWebsiteView from './PilotWebsite.view';

const links = ['About', 'Work', 'Packages', 'Equipment'];

const PilotWebsiteContainer = (props: PilotWebsiteProps): JSX.Element => {
  const company = props.match.params.company;

  const { data, isLoading, isError, isSuccess } = useQuery(
    `fetch-cms-company-${company}`,
    async () => {
      const cms = await getCMSByCompany(company);

      const pilot = await getUser(
        typeof cms.pilot == 'string' ? cms.pilot : '',
      );
      const response = {
        ...cms,
        pilot,
      };

      return response;
    },
    {
      onError: (err) => {
        console.log(err);
      },
    },
  );

  useEffect(() => {
    if (isSuccess === false) {
      return;
    }

    const scrollToAnchor = async () => {
      let { hash } = props.location;

      if (Boolean(hash) === false) {
        return;
      }

      if (hash.includes('#')) {
        hash = hash.slice(1);
      }

      if (links.includes(hash)) {
        const el = document.getElementById(hash);

        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    const timeoutId = setTimeout(() => {
      scrollToAnchor();
    }, 600);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isSuccess]);

  return (
    <PilotWebsiteView data={data} isError={isError} isLoading={isLoading} />
  );
};

export default PilotWebsiteContainer;
