import { LineItems } from 'types/cms';
import { AdditionalService } from 'types/mission';
import * as yup from 'yup';

export const initialValues = {
  package: '',
  missionDetails: '',
  lineItems: [] as LineItems[],
  additionalServices: [] as AdditionalService[],
  workSampleURL: '',
  totalCharge: '',
  eventStart: new Date().toISOString(),
  dueDate: new Date().toISOString(),
  clientDetails: {
    email: '',
    name: '',
    phone: '',
    industry: '',
    location: {
      lat: 0,
      lng: 0,
      address: '',
      state: '',
      zipcode: '',
    },
  },
  rush: false,
  onsite: false,
  liveEvent: false,
  liveDay: undefined as string | undefined,
  liveDay2: undefined as string | undefined,
  liveDay3: undefined as string | undefined,
  liveStartTime: '',
  liveEndTime: '',
  liveEndMeridiem: 'AM',
  liveStartMeridiem: 'AM',
  liveStartTime2: '',
  liveEndTime2: '',
  liveEndMeridiem2: 'AM',
  liveStartMeridiem2: 'AM',
  liveStartTime3: '',
  liveEndTime3: '',
  liveEndMeridiem3: 'AM',
  liveStartMeridiem3: 'AM',
  contactName: '',
  contactPhoneNumber: '',
  contactEmail: '',
};

export const validationSchema = yup.object().shape({
  package: yup.string().required('mission name is required'),
  missionDetails: yup.string().required('mission details is required'),
  lineItems: yup.array().min(1, 'At least 1 deliverable item is required'),
  additionalServices: yup.array(),
  workSampleURL: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'must be a valid url',
    ),
  eventStart: yup.string().required('event start date is required'),
  dueDate: yup.string().required('due date is required'),
  clientDetails: yup.object().shape({
    email: yup
      .string()
      .email('must be a valid email')
      .required('email is required'),
    name: yup.string().required('name is required'),
    phone: yup.string(),
    industry: yup.string().required('industry is required'),
    location: yup.object().shape({
      address: yup.string().required('address is required'),
    }),
  }),
  rush: yup.boolean(),
  onsite: yup.boolean(),
  liveEvent: yup.boolean(),
});
