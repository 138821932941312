import React from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { Course } from 'utils/educationCourses';

const CourseCard = (props: { course: Course }) => {
  const {
    course: { name, label, items, discount, image, link },
  } = props;
  return (
    <Box
      cursor="pointer"
      m={4}
      maxW="250px"
      h="400px"
      w="100%"
      borderRadius="5px"
      shadow="lg"
      bg="#fff"
      display="flex"
      flexDirection="column"
      overflow="hidden">
      <Image w="100%" h="108px" borderRadius="5px 5px 0px 0px" src={image} />
      <Box p={1} fontSize="14px" bg="#E0B236">
        <Text fontWeight="bold" color="#fff" textAlign="center">
          {discount === '0' ? 'FREE' : `${discount} OFF with Droneadair`}
        </Text>
      </Box>
      <Stack py={2} px={4} bg="#fff" flex={1}>
        <Text textAlign="center" fontWeight="bold" fontSize="18px">
          {name}
        </Text>
        <Text fontSize="12px" opacity={0.6}>
          {label}
        </Text>
        <Box maxH="110px" overflowY="auto">
          <UnorderedList>
            {items.map((point, pointIndex) => (
              <ListItem key={`${point}-${pointIndex}`} fontSize="12px">
                {point}
              </ListItem>
            ))}
          </UnorderedList>
        </Box>
        <Flex align="flex-end" justify="center" mb={4} flex={1}>
          <Button as="a" textDecoration="underline" href={link} target="_blank">
            Learn more
          </Button>
        </Flex>
      </Stack>
    </Box>
  );
};

export default CourseCard;
