import React, { memo, useRef } from 'react';

import { useToast } from '@chakra-ui/toast';
import { Box, Center, Flex, Stack, Text } from '@chakra-ui/layout';

import { MdCloudUpload } from 'react-icons/md';

import placeholder from 'res/placeholder.png';

import { useField } from 'formik';

import { useDropzone } from 'react-dropzone';

import { createPreviousWorkItem } from 'api/cms';
import { useMutation, useQueryCache } from 'react-query';

import { Progress } from './Progress';
import { ImageItem } from './ImageItem';
import { TitleInput } from './TitleInput';
import { DeleteButton } from './DeleteButton';

import {
  Bg,
  IconBg,
  PickerContainer,
  scrollBarStyles,
} from './PreviousWorkSection.style';
import { Cropper, CropperRef } from './Cropper';
import { CLOUD_FRONT_URL } from 'constants/cloudFronUrl';

const colorScheme = '#6728BB';

export const ImagePicker = memo(() => {
  const toast = useToast();

  const progressRef = useRef<any>(null);
  const cropperRef = useRef<CropperRef>(null);

  const queryCache = useQueryCache();

  const { value } = useField<any[]>('previousWork')[0];

  const { value: stylings } = useField<any>('stylings')[0];

  const color = stylings?.colorScheme || colorScheme;

  const images = value?.filter((f: any) => !f?.url);

  const [create, { isLoading }] = useMutation(
    (file: File) =>
      createPreviousWorkItem(file, undefined, undefined, (e) => {
        progressRef.current?.setProgress(
          Math.round((e.loaded / e.total) * 100),
        );
      }),
    {
      onSuccess: () => {
        toast({
          status: 'success',
          description: 'The file was successfully stored',
          duration: 2000,
          isClosable: true,
        });

        queryCache.invalidateQueries('fetch-my-cms', {
          exact: true,
        });

        queryCache.invalidateQueries(`fetch-my-cms-DRAFT`, {
          exact: true,
        });

        progressRef.current?.setProgress(0);
      },
    },
  );

  const { getInputProps, getRootProps } = useDropzone({
    accept: ['image/*'],
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        const file = acceptedFiles[0];

        const fileUrl = URL.createObjectURL(file);

        cropperRef.current?.onOpen(file, fileUrl);
      }
    },
  });

  const onSuccess = (file: File) => {
    create(file);
  };

  return (
    <>
      {images?.length > 0 ? (
        <Flex overflowX="auto" css={scrollBarStyles}>
          <Flex mb={4} mt={1}>
            {[{ id: 'dummy' }, ...images].map((item: any, index: any) => {
              const imageUrl = `${CLOUD_FRONT_URL}/users/portfolio/${item?.user}/${item?.keyName}?format=jpeg&width=200`;

              return (
                <>
                  {item.id === 'dummy' ? (
                    <Flex
                      key={`${item.id}-${index}`}
                      h="200px"
                      w="220px"
                      mr="15px"
                      align="center"
                      justify="center"
                      direction="column"
                      overflow="hidden"
                      borderRadius="10px"
                      border={`2px dashed ${color}`}
                      position="relative"
                      {...getRootProps()}>
                      <input
                        {...getInputProps({
                          disabled: isLoading,
                        })}
                      />
                      <Bg w="220px" h="200px" bg={color} />
                      <Center w="80px" h="80px" position="relative">
                        <IconBg bg={color} />
                        <MdCloudUpload
                          fill={color}
                          size="40px"
                          color="#d8d8d8"
                        />
                      </Center>
                      <Text m={2} color={color} textAlign="center">
                        Select your photos or drag and drop to upload them
                      </Text>
                      {isLoading && (
                        <Center
                          top={0}
                          left={0}
                          right={0}
                          bottom={0}
                          position="absolute"
                          borderRadius="5px">
                          <Box
                            h="100%"
                            w="100%"
                            opacity={0.6}
                            bg="#4f4f4f"
                            position="absolute"
                          />
                          <Progress ref={progressRef} />
                        </Center>
                      )}
                    </Flex>
                  ) : (
                    <Flex
                      key={`${item.id}-${index}`}
                      mr="15px"
                      h="200px"
                      w="220px"
                      overflow="hidden"
                      direction="column"
                      borderRadius="10px"
                      position="relative"
                      boxShadow={`0px 0px 5px 0px ${color}50`}>
                      <ImageItem
                        h="150px"
                        w="100%"
                        src={imageUrl}
                        objectFit="contain"
                        cursor="pointer"
                        userSelect="none"
                        fallbackSrc={placeholder}
                      />
                      <TitleInput item={item} />
                      <DeleteButton id={item?.id} />
                    </Flex>
                  )}
                </>
              );
            })}
          </Flex>
        </Flex>
      ) : (
        <Stack maxW="700px" w="100%" alignSelf="center">
          <PickerContainer {...getRootProps()} borderColor={color}>
            <input
              {...getInputProps({
                disabled: isLoading,
              })}
            />
            <Bg bg={color} />
            <Center w="80px" h="80px" position="relative">
              <IconBg bg={color} />
              <MdCloudUpload fill={color} size="40px" color="#d8d8d8" />
            </Center>
            <Text ml={2} color={color}>
              Select your photos or drag and drop to upload them
            </Text>
            {isLoading && (
              <Center
                position="absolute"
                top={0}
                bottom={0}
                left={0}
                right={0}
                borderRadius="5px">
                <Box
                  h="100%"
                  w="100%"
                  opacity={0.6}
                  bg="#4f4f4f"
                  position="absolute"
                />
                <Progress ref={progressRef} />
              </Center>
            )}
          </PickerContainer>
        </Stack>
      )}
      <Cropper ref={cropperRef} onSuccess={onSuccess} aspectRatio={220 / 150} />
    </>
  );
});
