import React from 'react';
import { useQueryCache, useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { useToast } from '@chakra-ui/toast';
import { cancelMission } from 'api/mission';

import { ClientMissionActionsProps } from './ClientMissionActions.props';
import ClientMissionActionsView from './ClientMissionActions.view';

const ClientMissionActionsContainer = (
  props: ClientMissionActionsProps,
): JSX.Element => {
  const toast = useToast();
  const history = useHistory();
  const queryCache = useQueryCache();

  // const [cancelSubmit, { isLoading: isCancelling }] = useMutation(
  //   cancelMission,
  //   {
  //     onSuccess: () => {
  //       toast({
  //         status: 'success',
  //         title: 'Success',
  //         description: 'Mission Order Cancelled',
  //         isClosable: true,
  //         duration: 9000,
  //       });

  //       // invalidate useMissions query for refetching mission list
  //       queryCache.invalidateQueries(`fetch-missions-undefined-CLIENT`);
  //       queryCache.invalidateQueries(`fetch-client-missions`);
  //       history.goBack();
  //     },
  //   },
  // );

  return (
    <ClientMissionActionsView
      // isCancelling={isCancelling}
      // onMissionCancel={() => cancelSubmit(String(props.mission.id).toString())}
      onMissionUpdate={() =>
        history.push(`/mission/update/${props.mission.id}`)
      }
      mission={props.mission}
    />
  );
};

export default ClientMissionActionsContainer;
