import React, { FC, useState } from 'react';

import { Button } from '@chakra-ui/button';
import { useToast } from '@chakra-ui/toast';
import { Spinner } from '@chakra-ui/spinner';
import { Stack } from '@chakra-ui/layout';

import { useFormikContext } from 'formik';

import { aloftCheck } from 'api/mission';

import { reverseGeocoding } from 'utils/reverseGeocoding';

import { aloftInfoPopupRef } from '../PilotWebsite/AloftInfoPopup';

import { AloftToast } from 'components/primitives/AloftToast';

import { AloftResponse } from 'types/mission';

type Props = {
  mapSelection: any;
  coordinates: {
    lat: number;
    lng: number;
  } | null;
  radius: number | null;
  company: string;
  onCloseMissionModal: () => void;
};

export const SelectLocationButton: FC<Props> = (props) => {
  const toast = useToast();

  let { radius } = props;
  const { mapSelection, coordinates, company = '' } = props;
  const { values, setFieldValue } = useFormikContext<any>();

  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [response, setResponse] = useState<null | AloftResponse>(null);

  const checkAloftData = async (payload: {
    latitude: number;
    longitude: number;
  }) => {
    try {
      setIsChecking(true);

      const _response = await aloftCheck(payload);

      setIsChecking(false);

      setResponse(_response);

      return true;
    } catch (error: any) {
      setIsChecking(false);

      toast({
        status: 'error',
        description: error?.message || 'something went wrong',
        isClosable: true,
        duration: 2000,
      });

      return false;
    }
  };

  function checkIfInside(spotCoordinates: { lat: number; lng: number }) {
    if (coordinates == null || radius == null) {
      return '';
    }

    // convert miles to km
    radius = radius * 1.6;

    const newRadius = distanceInKmBetweenEarthCoordinates(
      spotCoordinates?.lat,
      spotCoordinates?.lng,
      coordinates?.lat,
      coordinates?.lng,
    );

    if (newRadius < radius) {
      //point is inside the circle
      return 'inside';
    } else if (newRadius > radius) {
      //point is outside the circle
      return 'outside';
    } else {
      //point is on the circle
      return 'outside';
    }
  }

  function distanceInKmBetweenEarthCoordinates(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
  ) {
    const earthRadiusKm = 6371;

    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lon2 - lon1);

    lat1 = degreesToRadians(lat1);
    lat2 = degreesToRadians(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusKm * c;
  }

  function degreesToRadians(degrees: number) {
    return (degrees * Math.PI) / 180;
  }

  return (
    <Stack w="100%">
      <Button
        w="100%"
        fontSize="sm"
        leftIcon={isChecking ? <Spinner /> : undefined}
        disabled={
          isChecking
            ? isChecking
            : mapSelection === 'ADDRESS'
            ? !values.location
            : !values.latitude && !values.longitude
        }
        onClick={async () => {
          if (!values?.latitude || !values?.longitude) {
            return;
          }

          const latitude = +values?.latitude;
          const longitude = +values?.longitude;

          const status = checkIfInside({
            lat: latitude,
            lng: longitude,
          });

          if (status === 'outside') {
            aloftInfoPopupRef.current?.onOpen?.(props.onCloseMissionModal);

            return;
          }

          const isValid = await checkAloftData({
            latitude,
            longitude,
          });

          if (!isValid) {
            // setFieldValue('location', '');
            // return;
          }

          if (mapSelection === 'ADDRESS') {
            if (!values.location) {
              return;
            }

            setFieldValue('gps.coordinates', [longitude, latitude]);
          } else {
            if (!values.latitude || !values.longitude) {
              return;
            }

            const address_components = await reverseGeocoding(
              latitude,
              longitude,
            );

            setFieldValue('gps.coordinates', [longitude, latitude]);

            if (!address_components) {
              return;
            }

            const { city, state, location } = address_components;

            setFieldValue('location', location);
            setFieldValue('city', city);
            setFieldValue('state', state);
          }
        }}>
        {isChecking ? 'We are checking this airspace' : 'SELECT LOCATION'}
      </Button>
      {response !== null ? <AloftToast w="100%" {...response} /> : null}
    </Stack>
  );
};
