import React, { useEffect } from 'react';

import { NotFoundProps } from './NotFound.props';

import NotFoundView from './NotFound.view';

const NotFoundContainer = (props: NotFoundProps) => {
  useEffect(() => {
    props.history.push('/');
  }, []);

  return <NotFoundView />;
};

export default NotFoundContainer;
