import React, { FC } from 'react';

import { IconButton } from '@chakra-ui/button';
import { Box, Flex, Text } from '@chakra-ui/layout';

import { MdClose } from 'react-icons/md';

import { useHistory } from 'react-router';

import { Props } from './MobileMissionCreationTabs.props';

const getHeaderMap = (isExtraTab = false): Record<number, string> => {
  if (isExtraTab) {
    return {
      0: 'Location Details',
      1: 'Mission Details',
      2: 'Information Details',
      3: 'Checkout',
      4: 'Confirmation',
    };
  }

  return {
    0: 'Location Details',
    1: 'Mission Details',
    2: 'Checkout',
    3: 'Confirmation',
  };
};

const View: FC<Props> = (props) => {
  const { goBack } = useHistory();

  const { tabSelected, onClose, children, isExtraTab } = props;

  const headerMap = getHeaderMap(isExtraTab);

  return (
    <>
      <Box
        h="160px"
        shadow="md"
        top={0}
        left={0}
        right={0}
        zIndex={20}
        position="fixed">
        <Flex
          px={6}
          pb={4}
          h="100px"
          align="flex-end"
          bg="primaryColor"
          justify="space-between">
          <Text fontSize="20px" fontWeight="bold" color="#fff">
            Schedule your package
          </Text>
          <IconButton
            mb="2px"
            size="xs"
            color="#fff"
            variant="ghost"
            aria-label="close-tabs"
            icon={<MdClose size="22px" />}
            onClick={() => {
              if (onClose) {
                onClose();
              } else {
                goBack();
              }
            }}
          />
        </Flex>
        <Flex bg="#fff" p={6} align="center" justify="space-between">
          <Text fontSize="16px" fontWeight="bold">
            {headerMap[tabSelected || 0]}
          </Text>
          <Text fontSize="14px" color="#999999">
            Step {tabSelected + 1} of {isExtraTab ? '5' : '4'}
          </Text>
        </Flex>
      </Box>
      {children}
    </>
  );
};

export default View;
