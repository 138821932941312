import { getMission } from 'api/mission';
import { QueryResult, useQuery } from 'react-query';
import { Mission } from 'types/mission';

const useMission = (missionId: string): QueryResult<Mission, unknown> => {
  const useQueryResult: QueryResult<
    Mission,
    unknown
  > = useQuery(`fetch-mission-${missionId}`, async () => getMission(missionId));

  return useQueryResult;
};

export default useMission;
