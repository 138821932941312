import React from 'react';
import useAccountActivationStatus from 'utils/hooks/useAccountActivationStatus';
import * as session from 'effector/session';
import { InactiveAccountBlockerProps } from './InactiveAccountBlocker.props';
import InactiveAccountBlockerView from './InactiveAccountBlocker.view';

const InactiveAccountBlockerContainer = (
  props: InactiveAccountBlockerProps,
): JSX.Element => {
  const { children, history } = props;

  const isNewUser = session.selectors.useIsNewUser();

  const search = new URLSearchParams(history?.location?.search);
  const showCouponPopup = search.get('showCouponPopup') === 'true';

  const { isActivated } = useAccountActivationStatus();

  if (showCouponPopup || isNewUser) {
    return children;
  }

  return (
    <InactiveAccountBlockerView isActivated={isActivated}>
      {children}
    </InactiveAccountBlockerView>
  );
};

export default InactiveAccountBlockerContainer;
