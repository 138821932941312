import React from 'react';

import { ExampleProps } from './Example.props';
import ExampleView from './Example.view';

const ExampleContainer = (props: ExampleProps): JSX.Element => {
  return <ExampleView />;
};

export default ExampleContainer;
