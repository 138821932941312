import React, { useState } from 'react';

import { Image } from '@chakra-ui/image';
import { IconButton } from '@chakra-ui/button';
import { Center, Flex, Stack, Text } from '@chakra-ui/layout';
import { Radio, RadioGroup } from '@chakra-ui/radio';
import { Spinner } from '@chakra-ui/spinner';
import { useToast } from '@chakra-ui/toast';
import { deletePaymentMethod, getPaymentMethods } from 'api/stripe';
import { cardsMap } from 'constants/cardsMap';
import { SessionStore } from 'effector/session/store';
import { MdDelete } from 'react-icons/md';
import { useMutation, useQuery, useQueryCache } from 'react-query';

import StripeLogo from 'res/stripe_logo.png';

import { SavedCardsProps } from './SavedCards.props';
import { FormControl } from '@chakra-ui/form-control';

const DeleteCard = ({ id }: { id: string }) => {
  const toast = useToast();
  const queryCache = useQueryCache();
  const uid = SessionStore.getState()?.session?.user.id ?? '';

  const [deleteCard, { isLoading: isDeleting }] = useMutation(
    deletePaymentMethod,
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Card Removed Successfully',
          isClosable: true,
          duration: 5000,
        });

        queryCache.invalidateQueries(`fetch-payment-methods-${uid}`);
      },
    },
  );

  return (
    <IconButton
      aria-label="delete"
      variant="ghost"
      size="xs"
      icon={<MdDelete color="red" size="18px" />}
      isLoading={isDeleting}
      onClick={() => deleteCard(id)}
    />
  );
};

const SavedCardsView = ({ setSelectedCard }: SavedCardsProps): JSX.Element => {
  const [value, setValue] = useState<any>();
  const uid = SessionStore.getState()?.session?.user.id ?? '';
  const key = `fetch-payment-methods-${uid}`;

  const { data, isLoading } = useQuery(key, getPaymentMethods, {
    onError: (err) => {
      console.log(err);
    },
  });

  return (
    <Center my={data?.length ? 4 : 0} flexDirection="column">
      {isLoading ? (
        <Flex>
          loading saved cards... <Spinner size="sm" />
        </Flex>
      ) : data?.length ? (
        <Stack w="100%">
          <FormControl>
            <RadioGroup
              onChange={(e) => {
                setValue(e);
                const selectedCard = data?.[Number(e)];
                setSelectedCard(selectedCard);
              }}
              value={value}>
              {data.map((item, index) => {
                const CardIcon = cardsMap[item.card.brand.toLowerCase()];
                return (
                  <Flex
                    key={`${index}`}
                    p={4}
                    alignItems="center"
                    borderTopWidth="1px"
                    borderBottomWidth={data.length - 1 === index ? '1px' : '0'}>
                    <Radio colorScheme="brand" value={index.toString()} />
                    <Stack
                      isInline
                      alignItems="center"
                      justifyContent="space-between"
                      w="100%"
                      pl={4}
                      pr={[5, 5, 20]}>
                      <Stack isInline spacing={6} alignItems="center">
                        <CardIcon size="50px" />
                        <Text>ending in {item.card.last4}</Text>
                      </Stack>
                      <DeleteCard id={item.id} />
                    </Stack>
                  </Flex>
                );
              })}
            </RadioGroup>
          </FormControl>
        </Stack>
      ) : null}
      <Flex w="100%">
        <Image mt={6} src={StripeLogo} maxW="160px" />
      </Flex>
    </Center>
  );
};
export default SavedCardsView;
