import React, { useEffect } from 'react';

import RouteWithSubRoutes from 'components/core/RouteWithSubRoutes';
import { Flex } from '@chakra-ui/react';

import { useIsMobile } from 'utils/hooks';

import { Switch, useHistory, useLocation } from 'react-router-dom';
// IMPORT ROUTE
import Tutorials from 'routes/pilot/Tutorials';
import CreateCustomMission from 'routes/pilot/CreateCustomMission';
import CustomMission from 'routes/pilot/CustomMission';
import Resources from 'routes/pilot/Resources';
import EditWebsite from 'routes/pilot/EditWebsite';
import CreateWebsite from 'routes/pilot/CreateWebsite';
import Dashboard from 'routes/pilot/Dashboard';
import Education from 'routes/pilot/Education';
import MissionDetails from 'routes/client/MissionDetails';
import MyWebsite from 'routes/pilot/MyWebsite';
import ProMissions from 'routes/pilot/ProMissions';
import PremiumPayment from 'routes/pilot/PremiumPayment';
import Settings from 'routes/pilot/Settings';
import Support from 'routes/pilot/Support';
import WelcomePage from 'routes/pilot/WelcomePage';
import NotFound from 'routes/auth/NotFound';

import ROUTES, { routesSettings } from 'constants/routes';

import { PilotSideBar } from './PilotSideBar';
import { fetchConnectedAccEv } from 'effector/stripe';
import { fetchCbSubsEv } from 'effector/chargebee';
import InactiveAccountBlockerContainer from 'components/modules/NotActiveAccountModal';
import ActivateAccount from 'routes/auth/ActivateAccount';
import MobileHeader from 'components/modules/MobileHeader';
import MobileBottomNav from 'components/modules/MobileBottomNav';
import PlanExpirationHandler from 'components/modules/PlanExpirationHandler';
import { planExpModalRef } from 'utils/customRefs';
import { PilotContextProvider } from './context/pilot';

import CouponCodeModal from 'components/modules/CouponCodeModal';

const checkForUserEmailActivation = (Component: any) => {
  return (props: any) => (
    <InactiveAccountBlockerContainer history={props.history}>
      <Component {...props} />
    </InactiveAccountBlockerContainer>
  );
};

const routeConfig = Object.freeze({
  // PILOT ROUTE ENTRY
  [ROUTES.PILOT_TUTORIALS]: {
    component: Tutorials,
    path: routesSettings.PILOT_TUTORIALS.path,
  },
  [ROUTES.PILOT_CREATE_CUSTOM_MISSION]: {
    component: CreateCustomMission,
    path: routesSettings.PILOT_CREATE_CUSTOM_MISSION.path,
  },
  [ROUTES.PILOT_CUSTOM_MISSION]: {
    component: CustomMission,
    path: routesSettings.PILOT_CUSTOM_MISSION.path,
  },
  [ROUTES.AUTH_ACTIVATE_ACCOUNT]: {
    component: ActivateAccount,
    path: routesSettings.AUTH_ACTIVATE_ACCOUNT.path,
  },
  [ROUTES.PILOT_RESOURCES]: {
    component: Resources,
    path: routesSettings.PILOT_RESOURCES.path,
  },
  [ROUTES.PILOT_EDIT_WEBSITE]: {
    component: EditWebsite,
    path: routesSettings.PILOT_EDIT_WEBSITE.path,
  },
  [ROUTES.PILOT_CREATE_WEBSITE]: {
    component: CreateWebsite,
    path: routesSettings.PILOT_CREATE_WEBSITE.path,
  },
  [ROUTES.PILOT_PREMIUM_PAYMENT]: {
    component: PremiumPayment,
    path: routesSettings.PILOT_PREMIUM_PAYMENT.path,
  },
  [ROUTES.PILOT_SUPPORT]: {
    component: Support,
    path: routesSettings.PILOT_SUPPORT.path,
  },
  [ROUTES.PILOT_EDUCATION]: {
    component: Education,
    path: routesSettings.PILOT_EDUCATION.path,
  },
  [ROUTES.PILOT_MY_WEBSITE]: {
    component: MyWebsite,
    path: routesSettings.PILOT_MY_WEBSITE.path,
  },
  [ROUTES.PILOT_PRO_MISSIONS]: {
    component: ProMissions,
    path: routesSettings.PILOT_PRO_MISSIONS.path,
  },
  // [ROUTES.PILOT_DA_MISSIONS]: {
  //   component: checkForUserEmailActivation(DaMissions),
  //   path: routesSettings.PILOT_DA_MISSIONS.path,
  // },
  [ROUTES.PILOT_WELCOME_PAGE]: {
    component: WelcomePage,
    path: routesSettings.PILOT_WELCOME_PAGE.path,
  },
  [ROUTES.PILOT_SETTINGS]: {
    component: Settings,
    path: routesSettings.PILOT_SETTINGS.path,
  },
  [ROUTES.PILOT_DASHBOARD]: {
    component: checkForUserEmailActivation(Dashboard),
    path: routesSettings.PILOT_DASHBOARD.path,
  },
  [ROUTES.PILOT_MISSION_DETAILS]: {
    component: MissionDetails,
    path: routesSettings.PILOT_MISSION_DETAILS.path,
  },
  [ROUTES.AUTH_NOT_FOUND]: {
    component: NotFound,
    path: routesSettings.AUTH_NOT_FOUND.path,
  },
});

const PilotRoutes = () => {
  const { replace } = useHistory();
  const { pathname } = useLocation();

  const isMobile = useIsMobile();
  const isWelcomePage = pathname === routeConfig.PILOT_WELCOME_PAGE.path;
  const isDetailsPage =
    pathname.includes('details') ||
    (!pathname.includes('create') && pathname.includes('custom-mission'));

  const redirectToHome = () => {
    const isIndexPath = pathname === '/';

    if (isIndexPath) {
      replace(routeConfig.PILOT_DASHBOARD.path);
    }
  };

  useEffect(() => {
    redirectToHome();
  }, [pathname]);

  useEffect(() => {
    fetchCbSubsEv();
    fetchConnectedAccEv(0);
  }, []);

  const props = isMobile
    ? {
        flexGrow: 1,
        overflow: 'auto',
      }
    : {
        flexGrow: 1,
        flexBasis: 0,
        justifyContent: 'center',
        alignItems: 'center',
        marginX: 'auto',
        overflow: 'auto',
        bg: '#F3F3F3',
      };

  return (
    <Flex flex={1} direction={{ base: 'column', sm: 'column', md: 'row' }}>
      <PilotContextProvider>
        {isWelcomePage ? null : isMobile ? <MobileHeader /> : <PilotSideBar />}
        <Flex {...props}>
          <Switch>
            {Object.entries(routeConfig).map(([key, val]) => (
              <RouteWithSubRoutes key={key} {...val} />
            ))}
          </Switch>
        </Flex>
      </PilotContextProvider>
      <CouponCodeModal />
      <PlanExpirationHandler ref={planExpModalRef} />
    </Flex>
  );
};

export default PilotRoutes;
