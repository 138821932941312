import { fetchConnectedAcc, getStripeKey } from 'api/stripe';

import { createEffect, createEvent } from 'effector';
import {
  getStripeId,
  getIsAccountActivatedStatus,
} from 'effector/session/store';

export const fetchStripeKeyFx = createEffect('fetch-stripe-key', {
  handler: getStripeKey,
});

export const fetchConnectedAccEv = createEvent<number>();

export const fetchConnectedAccFx = createEffect(async (limit: number) => {
  const stripeId = getStripeId() || '';

  if (!stripeId) {
    return Promise.resolve();
  }

  const isAccountActivated = getIsAccountActivatedStatus();

  if (!isAccountActivated) {
    return null;
  }

  try {
    const c = await fetchConnectedAcc(stripeId).catch(() => null);

    if (limit >= 5) {
      return Promise.resolve(c);
    }
    const isEnabled = Boolean(c?.charges_enabled);
    const isConnected =
      (Boolean(c?.details_submitted) ||
        Boolean(
          c?.tos_acceptance &&
            'date' in c?.tos_acceptance &&
            c?.tos_acceptance?.date !== null,
        )) &&
      //To avoid the request to be done if the DA account is not activated yet
      isAccountActivated === true;

    if (isEnabled && isConnected) {
      return Promise.resolve(c);
    }

    if (isEnabled && !isConnected && limit == 4) {
      return Promise.resolve(c);
    }
  } catch (error) {
    console.log(error);
  }

  // sleep for 3 seconds
  await new Promise((resolve) => setTimeout(resolve, 3000));

  fetchConnectedAccEv(limit + 1);
});
