import React, { FC, useRef } from 'react';

import logo from 'res/mobile_info_char.png';

import { animationConfig, MotionFlex } from './Motion';

import {
  Box,
  Text,
  Flex,
  Image,
  Divider,
  Stack,
  Button,
  Center,
  Heading,
  Checkbox,
  SimpleGrid,
  useToast,
  CircularProgress,
} from '@chakra-ui/react';
import { MdAddCircleOutline, MdCheck } from 'react-icons/md';

import { updateCMS } from 'api/cms';
import { useMutation, useQueryCache } from 'react-query';

import { CommonProps } from '../OnboardingModal.props';

const INDUSTRIES = [
  'Real Estate (Residential and Commercial)',
  'Media Productions',
  'Utilities/Power/Solar/Telecom',
  'Construction',
  'Surveying and Mapping',
  'Retail Management',
  'Insurance',
  'Agriculture',
  'Other Industries',
] as const;

const CreatePackages: FC<CommonProps> = ({ cms, setIndex }) => {
  const queryKey = `fetch-cms-company-${cms?.username}`;

  const packages = cms?.packages || [];

  const toast = useToast();
  const queryCache = useQueryCache();

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const industriesRef = useRef<string[]>(cms.industries || []);

  const [update, { isLoading }] = useMutation(
    async (_payload: any) => {
      await updateCMS({
        companyName: cms?.about?.companyName,
        payload: {
          ..._payload,
          status: 'DRAFT',
        },
      });

      await updateCMS({
        companyName: cms?.about?.companyName,
        payload: {
          ..._payload,
          status: 'PUBLISH',
        },
      });
    },
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Business Details Updated',
          isClosable: true,
          duration: 5000,
        });

        queryCache.invalidateQueries(queryKey);
      },
    },
  );

  const handleUpdate = () => {
    update({
      industries: industriesRef.current,
      pilot: typeof cms?.pilot === 'string' ? cms?.pilot : cms?.pilot?.id,
      location: cms?.location,
      gps: {
        coordinates: cms?.gps?.coordinates,
      },
    });
  };

  return (
    <MotionFlex {...animationConfig} flex={1}>
      <Center gridGap="40px">
        <Image src={logo} maxH="180px" />
        <Flex direction="column" gridGap="20px" w="30%">
          <Heading fontSize="22px">Create Your Packages</Heading>
          <Text>
            Create your own packages, so, your clients can buy easily!
          </Text>
        </Flex>
      </Center>

      <Flex direction="column" px="50px" gridGap="20px">
        <Text fontSize="14px" fontWeight="bold">
          Select your industries
        </Text>
        <SimpleGrid columns={3} gap="20px" position="relative">
          {INDUSTRIES.map((industry, index) => (
            <Checkbox
              key={industry + index}
              colorScheme="brand"
              onChange={(e) => {
                if (e.target.checked) {
                  industriesRef.current.push(industry);
                } else {
                  industriesRef.current = industriesRef.current.filter(
                    (i) => i !== industry,
                  );
                }

                if (timeoutIdRef.current) {
                  clearTimeout(timeoutIdRef.current);

                  timeoutIdRef.current = null;
                }

                timeoutIdRef.current = setTimeout(() => {
                  handleUpdate();
                }, 1500);
              }}
              defaultIsChecked={industriesRef.current.includes(industry)}>
              <Text fontWeight="600">{industry}</Text>
            </Checkbox>
          ))}

          <>
            {isLoading && (
              <Center
                position="absolute"
                left="35%"
                right="35%"
                top="0"
                bottom="0"
                borderRadius="20px"
                bg="rgba(0,0,0,0.2)"
                zIndex={2}>
                <CircularProgress isIndeterminate color="brand.500" />
              </Center>
            )}
          </>
        </SimpleGrid>
      </Flex>

      <Flex mt="20px" direction="column" px="50px" gridGap="20px">
        <Text fontSize="14px" fontWeight="bold">
          Media Productions Packages (3 max)
        </Text>

        <Flex color="#6728BB" mb="50px" gridGap="20px" flexWrap="wrap" flex={1}>
          {packages.length > 3 ? null : (
            <Box
              p="15px"
              as="button"
              minH="240px"
              minW="200px"
              borderRadius="10px"
              display="grid"
              placeItems="center"
              boxShadow="0 0 10px 0 rgba(0,0,0,0.1)"
              onClick={() => setIndex(5)}>
              <MdAddCircleOutline size="36px" />
              <Text fontWeight="bold" textAlign="center">
                Add new
                <br />
                Package
              </Text>
            </Box>
          )}

          {packages?.map((p, index) => (
            <Box as="button" key={`${p.id}-${index}`} position="relative">
              <Box
                p={4}
                pb={1}
                h="340px"
                minW="200px"
                maxW="200px"
                display="flex"
                borderRadius="10px"
                flexDirection="column"
                boxShadow="0px 0px 14px 0px rgba(50, 50, 50, 0.15)">
                <Text textAlign="center" fontSize="14px" fontWeight="bold">
                  {p.name}
                </Text>
                <Text
                  my={2}
                  fontSize="24px"
                  fontWeight="bold"
                  textAlign="center"
                  color="brand.500">
                  {p.price ? `$${p.price}` : ''}
                </Text>
                <Text mt={2} fontSize="12px" textAlign="center">
                  {p.description || ''}
                </Text>
                <Box my={2}>
                  <Divider position="absolute" left={0} />
                </Box>
                <Stack flex={1} mt={2} spacing={1} overflow="hidden">
                  {p.lineItems.map((item, itemIndex) => (
                    <Stack isInline key={`${item.name}-${itemIndex}`}>
                      <Center
                        h="22px"
                        minW="22px"
                        borderRadius="20px"
                        position="relative">
                        <MdCheck color="brand.500" />
                        <Box
                          top={0}
                          left={0}
                          h="22px"
                          minW="22px"
                          opacity={0.1}
                          bg={'brand.500'}
                          position="absolute"
                          borderRadius="20px"
                        />
                      </Center>
                      <Box>
                        <Text fontSize="12px" mb={1}>
                          {item.count ? `(${item.count})` : ''} {item.name}
                        </Text>
                      </Box>
                    </Stack>
                  ))}
                </Stack>
              </Box>
            </Box>
          ))}
        </Flex>
      </Flex>
      <Center mb="20px" gridGap="20px">
        <Button
          w="300px"
          variant="outline"
          isDisabled={isLoading}
          onClick={() => setIndex(3)}>
          GO BACK
        </Button>
        <Button w="300px" isLoading={isLoading} onClick={() => setIndex(6)}>
          CONTINUE
        </Button>
      </Center>
    </MotionFlex>
  );
};

export default CreatePackages;
