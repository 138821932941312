import React, { useState } from 'react';

import {
  Button,
  Flex,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';

import { MissionUpdateGeneratedProps } from './MissionUpdate.props';
import BackButtonWithTitle from 'components/primitives/BackButtonWithTitle';
import MissionOverview from 'components/modules/MissionOverview';
import { Form, Formik } from 'formik';
import { Mission } from 'types/mission';
import { AiOutlinePlaySquare, AiTwotoneCalendar } from 'react-icons/ai';
import { CustomCalendar } from 'components/primitives/Calendar/Calendar.style';

import { SiGooglemaps } from 'react-icons/si';
import FormikTextAreaView from 'components/primitives/FormikTextArea';

import { MissionUpdateSchema } from './MissionUpdate.utils';

const MissionUpdateView = (props: MissionUpdateGeneratedProps): JSX.Element => {
  const [isTouched, setIsTouched] = useState(false);
  const handleBlur = () => {
    return setIsTouched(true);
  };

  return (
    <Flex flexDirection="column" flex={1} w="100%" h="100%" px="10px">
      <BackButtonWithTitle title="Mission Update" />
      <Stack
        p={2}
        isInline
        letterSpacing={0.5}
        bg="secondaryColor"
        color="white">
        <Text fontFamily="Monument Extended" fontWeight="600">
          MISSION
        </Text>
        <Text fontFamily="Monument Extended">DETAILS</Text>
      </Stack>
      {props.isLoading ? (
        <Stack bg="white" p={10} alignItems="center" justifyContent="center">
          <Spinner />
        </Stack>
      ) : props.mission ? (
        <Formik<Mission>
          enableReinitialize
          initialValues={props.mission}
          validationSchema={MissionUpdateSchema}
          onSubmit={(values) =>
            props.update({
              payload: {
                dueDate: values.dueDate,
                eventStart: values.eventStart,
                liveEvent: values.liveEvent,
                notes: values.notes,
                locationDetail: values?.locationDetail,
              },
              missionId: props.missionId,
            })
          }>
          {({ values, setFieldValue, isSubmitting, errors }) => {
            return (
              <Form>
                <MissionOverview mission={values} isLeads={true} />
                <Stack
                  mt={5}
                  p={2}
                  letterSpacing={0.5}
                  bg="secondaryColor"
                  color="white">
                  <Text fontFamily="Monument Extended" fontWeight="600">
                    EDIT DETAILS
                  </Text>
                </Stack>
                <Flex
                  mb={10}
                  p={5}
                  bg="#fff"
                  borderRadius="5px"
                  shadow="md"
                  flex={1}
                  direction="column">
                  <Flex direction={['column', 'column', 'row']}>
                    <Flex direction="column">
                      <Stack
                        fontWeight="700"
                        fontSize="md"
                        color="primaryColor"
                        isInline
                        alignItems="center">
                        <AiOutlinePlaySquare size="20px" />
                        <Text>Is this a Live Event?</Text>
                      </Stack>
                      <RadioGroup
                        mt={4}
                        ml={6}
                        display="flex"
                        defaultValue={values.onsite ? '1' : '0'}
                        defaultIsChecked={values.onsite}
                        onChange={(value) =>
                          setFieldValue('liveEvent', value == 1)
                        }>
                        <Radio value="1" mr="20px">
                          <Text fontSize="xs">Yes</Text>
                        </Radio>
                        <Radio value="0">
                          <Text fontSize="xs">No</Text>
                        </Radio>
                      </RadioGroup>
                      <Stack
                        mt={6}
                        fontWeight="700"
                        fontSize="md"
                        color="primaryColor"
                        isInline
                        alignItems="center">
                        <AiTwotoneCalendar size="20px" />
                        <Text>Date</Text>
                      </Stack>
                      <div>
                        <Flex onBlur={handleBlur} flexDirection="column" mt={4}>
                          <CustomCalendar
                            onChange={(dates: Date | Date[]) => {
                              if (!Array.isArray(dates)) {
                                dates = [dates];
                              }
                              /**
                               * If there is only one date, dueDate = eventStart
                               */
                              const [eventStart, dueDate = eventStart] = dates;

                              setFieldValue(
                                'eventStart',
                                new Date(eventStart).toISOString(),
                              );

                              setFieldValue(
                                'dueDate',
                                new Date(dueDate).toISOString(),
                              );
                              console.log(dates);
                            }}
                            allowPartialRange
                            selectRange={true}
                            returnValue="range"
                            minDate={new Date()}
                          />
                        </Flex>
                        {errors && isTouched && (
                          <Text
                            color="red.500"
                            w="100%"
                            fontSize="sm"
                            mb="10px">
                            {errors?.dueDate}
                          </Text>
                        )}
                      </div>
                    </Flex>
                    <Flex
                      direction="column"
                      flex={1}
                      ml={[0, 0, 20]}
                      mt={[10, 10, 0]}>
                      <Stack
                        fontWeight="700"
                        fontSize="md"
                        color="primaryColor"
                        isInline
                        alignItems="center">
                        <SiGooglemaps size="20px" />
                        <Text>Mission Notes</Text>
                      </Stack>
                      <FormikTextAreaView
                        mt="10px"
                        name="notes"
                        w="100%"
                        h="120px"
                        placeholder="(ie. Specific site directions, parking information, other instructions)"
                        fontSize="md"
                        defaultValue={values.notes}
                      />
                      <Stack
                        mt={4}
                        fontWeight="700"
                        fontSize="md"
                        color="primaryColor"
                        isInline
                        alignItems="center">
                        <SiGooglemaps size="20px" />
                        <Text>Location Details</Text>
                      </Stack>
                      <FormikTextAreaView
                        mt="10px"
                        name="locationDetail"
                        w="100%"
                        h="120px"
                        fontSize="md"
                        defaultValue={values.locationDetail}
                      />
                    </Flex>
                  </Flex>
                  <Button
                    size="md"
                    mt={6}
                    maxW="230px"
                    type="submit"
                    // isDisabled={equals(props.mission, values)}
                    isLoading={isSubmitting}>
                    UPDATE
                  </Button>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      ) : null}
    </Flex>
  );
};

export default MissionUpdateView;
