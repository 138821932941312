import React, { FC } from 'react';

import { Stack } from '@chakra-ui/react';

import { PilotHeaderProps } from './PilotHeader.props';
import CollapsibleMenu from 'components/primitives/CollapsibleMenu';

const PilotHeaderView: FC<PilotHeaderProps> = (props) => {
  const { name } = props;

  return (
    <Stack borderRadius="14px">
      <CollapsibleMenu />
    </Stack>
  );
};

export default PilotHeaderView;
