import { useQuery } from 'react-query';
import { getCustomMissionById } from 'api/custom-mission';

export const useCustomMission = (missionId: string) => {
  const queryKey = [`fetch-custom-mission-${missionId}`];
  const queryFn = () => getCustomMissionById(missionId);
  const options = { enabled: !!missionId };

  const query = useQuery(queryKey, queryFn, options);

  return query;
};
