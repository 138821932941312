import React, { useRef } from 'react';

import { Button } from '@chakra-ui/react';

import CancelMissionModal, { RefType } from '../CancelMissionModal';

import { MissionStatuses } from 'types/mission';
import { ClientMissionActionsGeneratedProps } from './ClientMissionActions.props';

const ClientMissionActionsView = ({
  mission,
  onMissionUpdate,
}: ClientMissionActionsGeneratedProps) => {
  const cancelMissionModalRef = useRef<RefType>(null);

  const isComplete = mission.status === MissionStatuses.COMPLETED;

  if (isComplete) {
    return null;
  }

  return (
    <>
      <Button
        size="sm"
        w="150px"
        variant="solid"
        onClick={cancelMissionModalRef.current?.onOpen}>
        CANCEL ORDER
      </Button>
      {!mission.pilot && (
        <Button size="sm" w="150px" variant="outline" onClick={onMissionUpdate}>
          UPDATE ORDER
        </Button>
      )}

      <CancelMissionModal ref={cancelMissionModalRef} missionId={mission.id} />
    </>
  );
};

export default ClientMissionActionsView;
