export const ADDITIONAL_SERVICES = [
  {
    name: '24 Hour Turnaround',
    price: 99,
  },
  {
    name: 'Twilight Shoot',
    price: 149,
  },
  {
    name: '(10) Additional Edited Photos',
    price: 99,
  },
  {
    name: '(1) Additional Edited Video',
    price: 199,
  },
  {
    name: '(1) Edited Hyperlapse',
    price: 99,
  },
];
