import React, { FC, useEffect, useState } from 'react';

import { Text, Flex } from '@chakra-ui/react';

import { MdNotifications } from 'react-icons/md';

import { selectors } from 'effector/session';
import { useHistory, useLocation } from 'react-router-dom';

import { EventRegister } from 'utils/customEvent';

import { RenderType, MobileHeaderProps } from './MobileHeader.props';

const MobileHeaderView: FC<MobileHeaderProps> = () => {
  const history = useHistory();
  const location = useLocation();

  const user = selectors.useUser();

  const [renderContent, setRenderContent] = useState<RenderType>(null);
  const [renderRightIcon, setRenderRightIcon] = useState<RenderType>(null);
  const [headerTitle, setHeaderTitle] = useState('Pilot');

  useEffect(() => {
    if (location.pathname === '/education') {
      return setHeaderTitle('Droneadair Education');
    }
    if (
      location.pathname !== ('/education' && '/create-custom-misson') &&
      user?.firstName
    ) {
      return setHeaderTitle(`Welcome ${user?.firstName}`);
    }
    if (location.pathname === '/create-custom-misson' && user?.firstName) {
      return setHeaderTitle(`Custom Mission Request`);
    }
  }, [location.pathname]);

  useEffect(() => {
    const listener = (event: any) => {
      const _renderContent = event?.detail?.renderContent;
      const _renderRightIcon = event?.detail?.renderRightIcon || null;

      setRenderContent(() => _renderContent);
      setRenderRightIcon(() => _renderRightIcon);
    };

    EventRegister.subscribe('render_header_content', listener);

    return () => {
      EventRegister.unsubscribe('render_header_content', listener);
    };
  }, []);

  useEffect(() => {
    const unlisten = history.listen(() => setRenderContent(null));

    return () => unlisten();
  }, []);

  return (
    <>
      <Flex
        left={0}
        right={0}
        top={0}
        zIndex={5}
        position="fixed"
        align="center"
        justify="space-between"
        bg="#92835C"
        pt="30px"
        p="20px"
        color="#fff">
        {renderContent ? (
          renderContent()
        ) : (
          <Text fontSize="18px" fontWeight="bold" maxW="80%" noOfLines={1}>
            {headerTitle}
          </Text>
        )}
        {renderRightIcon && renderRightIcon()}
      </Flex>

      <Flex h="60px" />
    </>
  );
};

export default MobileHeaderView;
