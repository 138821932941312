import React, { FC } from 'react';

import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';

import {
  MdWeb,
  MdPerson,
  MdDashboard,
  MdImportContacts,
  MdWorkspacesFilled,
} from 'react-icons/md';

import { selectors } from 'effector/session';

import { MobileBottomNavProps } from './MobileBottomNav.props';
import { routesSettings } from 'constants/routes';
import { useHistory, useLocation } from 'react-router-dom';

const NAV_ITEMS = [
  {
    Icon: MdDashboard,
    path: routesSettings.PILOT_DASHBOARD.path,
    ItemPosition: 0,
    name: 'Dashboard',
    color: '#92835C',
  },
  {
    Icon: MdWeb,
    path: routesSettings.PILOT_MY_WEBSITE.path,
    ItemPosition: 1,
    name: 'Website',
    color: '#92835C',
  },
  {
    Icon: MdImportContacts,
    path: routesSettings.PILOT_EDUCATION.path,
    ItemPosition: 2,
    name: 'Education',
    color: '#92835C',
  },
  {
    Icon: MdWorkspacesFilled,
    path: routesSettings.PILOT_RESOURCES.path,
    ItemPosition: 3,
    name: 'Resources',
    color: '#92835C',
  },
  {
    Icon: null,
    path: routesSettings.PILOT_SETTINGS.path,
    ItemPosition: 4,
  },
];

const MobileBottomNavView: FC<MobileBottomNavProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const userImage = selectors.useUser();

  return (
    <>
      <Flex
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        zIndex={6}
        w={'100%'}
        backgroundColor="#F5F5F5">
        {NAV_ITEMS.map((item) => {
          const isCurrentItem = location.pathname.includes(item.path);
          return (
            <Box
              justifyContent="center"
              w="100%"
              key={item.path}
              bg={isCurrentItem ? '#92835C' : '#EFEDEA'}
              onClick={() => {
                history.push(item.path);
              }}>
              <Center color="bgColor" justifyContent={'center'}>
                {item.Icon ? (
                  <Flex
                    p="10px"
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center">
                    <item.Icon
                      size="26px"
                      color={isCurrentItem ? '#FFF' : item.color}
                    />
                    <Text
                      fontFamily="Roboto"
                      fontSize="10px"
                      color={isCurrentItem ? '#FFF' : 'primaryColor'}>
                      {item.name}
                    </Text>
                  </Flex>
                ) : (
                  <Center flexDir="column" p="10px">
                    <Image
                      h="26px"
                      w="26px"
                      borderRadius="5px"
                      src={userImage?.signedUrl}
                      fallback={<MdPerson size="26px" />}
                    />
                    <Text
                      fontFamily="Roboto"
                      fontSize="10px"
                      color={isCurrentItem ? '#FFF' : 'primaryColor'}>
                      Profile
                    </Text>
                  </Center>
                )}
              </Center>
            </Box>
          );
        })}
      </Flex>

      <Flex h="50px" />
    </>
  );
};

export default MobileBottomNavView;
