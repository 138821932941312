import React from 'react';

import {
  Text,
  Flex,
  Modal,
  Image,
  Button,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Center,
} from '@chakra-ui/react';

import { WelcomePageModalProps } from './WelcomePage.props';
import { MdErrorOutline } from 'react-icons/md';

const PlanSelectionEmailAlertModal = ({
  isOpen,
  onClose,
  userEmail,
  ref,
}: WelcomePageModalProps) => {
  return (
    <Modal
      trapFocus={false}
      finalFocusRef={ref}
      size="lg"
      isOpen={isOpen}
      onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor={'white'}
        maxH="400px"
        maxW="500px"
        fontWeight="bold"
        color={'#3b3a3a'}
        mt={0}>
        <ModalBody>
          <Flex align="center" direction="column">
            <Center>
              <MdErrorOutline size={'3rem'} color={'#DBA825'} />
            </Center>
            <Text mt="2px" textAlign="center" fontSize="15px">
              Please use the same email you used for creating your DroneAdair
              account to proceed with the subscription
            </Text>
            <Text textAlign="center" fontSize="15px" fontWeight="bold">
              ({userEmail})
            </Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PlanSelectionEmailAlertModal;
