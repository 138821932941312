import { resendEmail } from 'api/auth';
import { useState } from 'react';
import * as session from 'effector/session';

const useResendEmail = () => {
  const userEmail = session.selectors.useUser()?.email;

  const [status, setStatus] = useState<{
    loading: boolean;
    isSuccess?: boolean;
    error?: any;
    data?: any;
  }>({
    loading: false,
  });

  const sendActivationEmail = async () => {
    if (Boolean(userEmail)) {
      setStatus({ loading: true });

      try {
        const res = await resendEmail(userEmail as string);
        setStatus({ loading: false, data: res.data, isSuccess: true });
      } catch (error) {
        setStatus({ loading: false, error, isSuccess: false });
      } finally {
        setStatus((prevStatus) => ({ ...prevStatus, isSuccess: false }));
      }
    }
  };

  return { ...status, sendActivationEmail };
};

export default useResendEmail;
