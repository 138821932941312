import React from 'react';
import { Button, Flex, Link, Text, Image } from '@chakra-ui/react';
import DroneAdairLogo2Icon from 'components/icons/DroneAdairLogo2Icon';
import FormikInput from 'components/primitives/FormikInput';
import { Form, Formik } from 'formik';

import { MobileLoginProps } from './MobileLogin.props';
import image from 'res/logout-img.png';
import { useHistory } from 'react-router-dom';

const labelProps = {
  color: 'primaryColor',
  fontFamily: 'Roboto',
  fontSize: '0.875rem',
};

const MobileLoginView = (props: MobileLoginProps): JSX.Element => {
  return (
    <Flex pt="100px" flex={1} direction="column" bgColor="#F5F5F5">
      <Flex
        zIndex={2}
        top={0}
        left={0}
        right={0}
        position="fixed"
        shadow="lg"
        p="20px"
        bg="#fff"
        align="flex-end"
        justify="space-between"
        alignItems="center"
        bgColor="#E0E0E0"
        borderRadius="0 0 20px 20px">
        <DroneAdairLogo2Icon />
        <Button
          onClick={() => {
            props.onSignupPress();
          }}
          fontSize="12px"
          bg="primaryColor"
          _focus={{ bg: 'primaryColor' }}
          fontFamily="roboto"
          width="6rem"
          height="1.99rem">
          SIGN UP
        </Button>
      </Flex>
      <Flex
        px="20px"
        mb="50px"
        flex={1}
        direction="column"
        gridGap="20px"
        bgColor="#F5F5F5"
        justifyContent="center">
        <Text
          textAlign="center"
          color="primaryColor"
          fontStyle="normal"
          fontSize="1.25rem"
          fontWeight="bold"
          fontFamily="Kanit">
          Login to your account
        </Text>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={props.onSubmit}>
          <Form>
            <FormikInput
              name="email"
              label="Email Address"
              labelProps={labelProps}
              background="#ffff"
            />
            <FormikInput
              type="password"
              name="password"
              label="Password*"
              labelProps={labelProps}
            />
            <Text
              mt="15px"
              fontSize="0.8125rem"
              color="#A3A3A3"
              fontFamily="Roboto">
              Forgot password? <Link href="/forgot-password">Click here</Link>
            </Text>
            <Flex
              w="17.625rem"
              h="14.125rem"
              alignItems="center"
              justifyContent="center"
              my="2rem">
              <Image src={image} />
            </Flex>
            <Button
              w="100%"
              fontSize="12px"
              type="submit"
              isDisabled={props.isLoading}
              isLoading={props.isLoading}>
              LOG IN
            </Button>
          </Form>
        </Formik>
      </Flex>
    </Flex>
  );
};

export default MobileLoginView;
