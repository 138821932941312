/* eslint-disable no-useless-escape */
import React from 'react';

import { Form, Formik } from 'formik';

import { Button, Flex, Heading, Text } from '@chakra-ui/react';

import * as Yup from 'yup';

import { ResetPasswordGeneratedProps } from './ResetPassword.props';

import FormikInputView from 'components/primitives/FormikInput';
import CenterFormContainer from 'components/layouts/ConterFormContainer';

const errorMessage = 'Confirm Password field is required';
const errorMessage2 = 'New Password field is required';

export const passwordErrorMessage =
  'Password must be at least 8 characters with a number and a letter';
// prettier-ignore
//eslint-disable-next-line
const passwordRegEx = new RegExp("^(?=.*[0-9])[A-Za-z\d](?=.{8,}$)");

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(passwordRegEx, passwordErrorMessage)
    .required(errorMessage2),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match')
    .required(errorMessage),
});

const ResetPasswordView = ({
  isLoading,
  onSubmit,
  passwordToken,
}: ResetPasswordGeneratedProps): JSX.Element => (
  <CenterFormContainer title="Welcome Back!">
    <Flex
      bg="white"
      maxW="500px"
      width="100%"
      py={10}
      borderRadius="5px"
      border="1px"
      borderColor="gray.200"
      flexDir="column"
      align="center">
      <Heading
        fontFamily="Monument Extended"
        textAlign="left"
        size="md"
        color="primaryColor">
        {passwordToken ? 'Setup Password' : 'Password Recovery'}
      </Heading>
      <Text fontFamily="Monument Extended" fontSize="md" my="20px">
        Enter {passwordToken ? '' : 'new'} Password
      </Text>
      <Flex flexDir="column" maxW="510px" w="100%" px="50px" py="30px">
        <Formik
          initialValues={{
            newPassword: '',
            confirmPassword: '',
          }}
          validationSchema={validationSchema}
          onSubmit={({ newPassword }) => onSubmit(newPassword)}>
          <Form>
            <FormikInputView
              name="newPassword"
              label="New Password"
              type="password"
            />
            <FormikInputView
              name="confirmPassword"
              label="Confirm Password"
              type="password"
            />
            <Button mt={5} isFullWidth type="submit" isLoading={isLoading}>
              SUBMIT
            </Button>
          </Form>
        </Formik>
      </Flex>
    </Flex>
  </CenterFormContainer>
);

export default ResetPasswordView;
