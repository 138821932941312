import React from 'react';

import {
  FormControl,
  FormLabel,
  Text,
  FormHelperText,
  Input,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { FormikInputProps } from '../FormikInput/FormikInput.props';

const TestomnonialInputView = ({
  name,
  label,
  helperText,
  error,
  defaultValue,
  isRequired,
  labelProps,
  isInvalid,
  containerProps = {},
  ...props
}: FormikInputProps): JSX.Element => {
  const [{ value }, meta, field] = useField(name);
  return (
    <FormControl mt={2} {...containerProps} isRequired={isRequired}>
      {label && (
        <FormLabel
          fontSize="14px"
          fontWeight="600"
          htmlFor={name}
          {...labelProps}>
          {label}
        </FormLabel>
      )}
      <Input
        {...props}
        type={name}
        id={name}
        isInvalid={Boolean(error || (meta.touched && meta.error))}
        aria-describedby={`${name}-helper-text`}
        onBlur={() => field.setTouched(true)}
        onChange={(e) => field.setValue(e.target.value)}
        bg={props.bg ? props.bg : 'inputColor'}
        borderColor="inputBorderColor"
        value={value || defaultValue}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {(error || meta.error) && (
        <Text color="red.500" fontSize="12px" fontWeight="bold">
          {error || (meta.touched && meta.error)}
        </Text>
      )}
    </FormControl>
  );
};

export default TestomnonialInputView;
