import React from 'react';
import MissionListBanner from '../MissionListBanner';
import { Center } from '@chakra-ui/react';

import { TabWithHeaderProps } from './TabWithHeader.props';

const TabWithHeaderView = (props: TabWithHeaderProps): JSX.Element => {
  return (
    <>
      <MissionListBanner
        highlightedTitle={props.highlightedTitle}
        unhighlightedTitle={props.unhighlightedTitle}
        icon={props.icon}
        bannerType={props.bannerType}
        customButton={props.customButton}
        status={props.status}
        cancelAndDelete={props.deleteButton}
        buttonOnBar={props.buttonOnBar}
        showTripleButtons={props.showTripleButtons}
      />
      <Center
        bg="#FFFFFF 0% 0% no-repeat padding-box"
        borderRadius="0px 0px 8px 8px"
        p="10px">
        {props.children}
      </Center>
    </>
  );
};

export default TabWithHeaderView;
