import React, { FC, PropsWithChildren } from 'react';

import {
  Box,
  Flex,
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionButtonProps,
} from '@chakra-ui/react';

type PanelProps = PropsWithChildren<{
  title: React.ReactNode;
  buttonProps?: AccordionButtonProps;
}>;

export const Panel: FC<PanelProps> = ({
  title,
  children,
  buttonProps = {},
}) => {
  return (
    <Accordion allowToggle>
      <AccordionItem>
        <AccordionButton
          h="50px"
          bg="#E3DAC3"
          borderRadius="10px"
          _focus={{ outline: 'none' }}
          _hover={{ bg: '#E3DAC390' }}
          _active={{ bg: '#E3DAC3' }}
          _expanded={{ borderBottomRadius: '0' }}
          {...buttonProps}>
          <Box
            flex="1"
            textAlign="left"
            color="#4F452F"
            fontSize="18px"
            fontWeight="bold">
            {title}
          </Box>
          <AccordionIcon color="#4F452F" />
        </AccordionButton>
        <AccordionPanel bg="#fff" borderBottomRadius="10px">
          <Flex p="20px" flex={1} w="100%">
            {children}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
