import api from 'api/instance';
const URL = `${process.env.REACT_APP_API_URL}`;

const reportProblem = async ({
  message,
  missionId,
}: {
  message: string;
  missionId: string;
}): Promise<any> => {
  const { data } = await api.patch(
    `${URL}/missions/${missionId}/report-problem`,
    {
      message,
    },
  );
  return data;
};

export default reportProblem;
