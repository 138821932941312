import React, { FC, useState } from 'react';

import {
  Box,
  Text,
  Flex,
  Stack,
  Center,
  chakra,
  Button,
  Checkbox,
  CircularProgress,
} from '@chakra-ui/react';
import {
  MdError,
  MdPlace,
  MdRoomService,
  MdViewCarousel,
  MdCalendarToday,
} from 'react-icons/md';
import { AiFillFire } from 'react-icons/ai';

import { useHistory } from 'react-router-dom';

import { selectors } from 'effector/session';
import { useCustomMission } from 'utils/hooks';

import { useMutation } from 'react-query';
import { clientAcceptMission } from 'api/custom-mission';

import calculateDueDate from 'utils/calculateDueDate';

import GoogleMaps from 'components/primitives/GoogleMaps';

import { TitleWithIcon } from 'components/primitives/TitleWithIcon';

import { CustomStatus } from 'types/mission';

const ChakraUl = chakra('ul');
const ChakraLi = chakra('li');

type Status = 'accept_mission' | 'request_changes' | 'reject';

type MissionInfoProps = {
  onSelect: (_status: Status) => void;
  customMissionId: string;
  onClose: () => void;
};

const sx = {
  '.chakra-checkbox__control[data-checked]': {
    background: '#6728BB',
    borderColor: '#6728BB',
    color: '#fff',
  },
  '.chakra-checkbox__control[data-focus]': {
    boxShadow: 'none',
  },
};

export const MissionInfo: FC<MissionInfoProps> = ({
  onClose,
  onSelect,
  customMissionId,
}) => {
  const history = useHistory();
  const user = selectors.useUser();
  const isClient = user?.accountType === 'CLIENT';

  const { data: mission, isLoading } = useCustomMission(customMissionId);

  const [isAck, setIsAck] = useState<[boolean, boolean]>([false, false]);

  const [accept, { isLoading: isAccepting }] = useMutation(
    () =>
      clientAcceptMission({
        client: isClient ? user?.id : undefined,
        customMissionId,
      }),
    {
      onSuccess: () => {
        onSelect('accept_mission');
      },
    },
  );

  const onAccept = () => {
    accept();
  };

  const isDisabled = isAck.some((i) => i === false);

  if (isLoading) {
    return (
      <Center flexDirection="column" flex={1} py={10}>
        <CircularProgress isIndeterminate color="brand.500" size="40px" />
        <Text mt={4} fontSize="18px" fontWeight="bold">
          Loading your custom mission
        </Text>
      </Center>
    );
  }

  if (mission === undefined) {
    return (
      <Center flexDirection="column" flex={1} py={10}>
        <Text mt={4} fontSize="18px" fontWeight="bold">
          Mission not found
        </Text>
        <Button mt={8} w="200px" variant="outline" onClick={() => onClose()}>
          CLOSE
        </Button>
      </Center>
    );
  }

  const isHidden = user?.id
    ? (mission?.client as any)?.id
      ? (mission?.client as any)?.id !== user?.id
      : false
    : false;

  if (isHidden) {
    return (
      <Center flexDirection="column" flex={1} py={10}>
        <Text mt={4} fontSize="18px" fontWeight="bold">
          You cannot access this mission
        </Text>
        <Flex mt={8}>
          <Button w="120px" variant="outline" mr={4} onClick={() => onClose()}>
            CLOSE
          </Button>
        </Flex>
      </Center>
    );
  }

  if (mission?.status === CustomStatus.PENDING_PAYMENT) {
    return (
      <Center flexDirection="column" flex={1} py={10}>
        <Text mt={4} fontSize="18px" fontWeight="bold">
          Payment is pending for this mission.
        </Text>
        <Flex mt={8}>
          <Button w="120px" variant="outline" mr={4} onClick={() => onClose()}>
            CLOSE
          </Button>
          <Button
            onClick={() => {
              onSelect('accept_mission');
            }}>
            COMPLETE PAYMENT
          </Button>
        </Flex>
      </Center>
    );
  }

  const isAccepted = ![
    CustomStatus.REQUESTED,
    CustomStatus.PILOT_ACCEPTED,
    CustomStatus.PILOT_REJECTED,
    CustomStatus.CLIENT_REJECTED,
    CustomStatus.CLIENT_CHANGE_REQUESTED,
  ].includes(mission.status);

  if (isAccepted) {
    return (
      <Center flexDirection="column" flex={1} py={10}>
        <Text mt={4} fontSize="18px" fontWeight="bold">
          This Mission is already accepted!
        </Text>
        <Flex mt={8}>
          <Button w="120px" variant="outline" mr={4} onClick={() => onClose()}>
            CLOSE
          </Button>
          {isClient && (
            <Button
              onClick={() => {
                history.replace('/missions?type=1');
              }}>
              GO TO DASHBOARD
            </Button>
          )}
        </Flex>
      </Center>
    );
  }

  const isRejected = [
    CustomStatus.PILOT_REJECTED,
    CustomStatus.CLIENT_REJECTED,
  ].includes(mission.status);

  const coordinates = {
    lat: mission?.clientDetails?.location?.lat || 0,
    lng: mission?.clientDetails?.location?.lng || 0,
  };

  const dateRange =
    mission?.eventStart === mission?.dueDate
      ? calculateDueDate(new Date(mission.dueDate))
      : `${calculateDueDate(
          new Date(mission?.eventStart),
        )} - ${calculateDueDate(new Date(mission?.dueDate))}`;

  const pilotChosenDate = mission?.pilotChosenDate
    ? calculateDueDate(new Date(mission.pilotChosenDate))
    : '';

  const additionalServicesCost: number =
    mission?.package?.additionalServices
      ?.map((i) => Number(i.price || '0'))
      ?.reduce((a, b) => a + b, 0) || 0;

  const lineItemsCost: number = mission?.package?.lineItems
    ?.map((i) => Number(i.count || '0') * Number(i.price || '0'))
    ?.reduce((a, b) => a + b, 0);

  const total = additionalServicesCost + lineItemsCost;

  const additionalLocations = mission.clientDetails.additionalLocations || [];
  const isMultiLocation = Boolean(additionalLocations.length > 1);

  return (
    <Flex p={6} flex={1} flexWrap="wrap">
      <Flex
        p={4}
        mr={{ base: '0', md: 5 }}
        minW="300px"
        flex={1.5}
        borderRadius="20px"
        border="1px solid #92835C">
        <Flex flex={1} flexWrap="wrap">
          <Stack
            mr={{ base: '0', md: 10 }}
            flex={1}
            minW="280px"
            maxW={{ base: '280px', sm: '300px' }}>
            <TitleWithIcon
              ml={0}
              TextIcon={<MdPlace size="16px" />}
              text="Location"
              fontSize="14px"
              mb={0}
              pb={0}
            />
            {isMultiLocation ? (
              <ChakraUl pl="10px">
                {additionalLocations.map((loc) => (
                  <ChakraLi
                    key={loc.address}
                    color="black"
                    fontWeight="500"
                    fontSize="14px">
                    {loc.address}
                  </ChakraLi>
                ))}
              </ChakraUl>
            ) : (
              <Text pl={2} color="black" fontWeight="500" fontSize="14px">
                {mission?.clientDetails?.location?.address}
              </Text>
            )}
            <Box
              mb={4}
              minW={{ base: '250px', md: '300px' }}
              w={['100%']}
              height="180px"
              background="#fff"
              borderColor="#ccc"
              bg="inputColor"
              borderRadius="20px"
              overflow="hidden">
              <GoogleMaps
                mapContainerStyle={{
                  width: '100%',
                  height: '180px',
                }}
                initialCenter={coordinates}
                marker={coordinates}
                zoom={13}
              />
            </Box>
            <TitleWithIcon
              ml={0}
              TextIcon={<Box />}
              text="Type of project"
              fontSize="14px"
              mb={0}
              pb={0}
            />
            <Text pl={2} fontSize="14px">
              {mission.missionIndustry || ''}
            </Text>
          </Stack>
          <Stack flex={1} minW="200px">
            <TitleWithIcon
              ml={0}
              TextIcon={<MdViewCarousel size="16px" />}
              text="Package"
              fontSize="14px"
              mb={0}
              pb={0}
            />
            <Flex pl={2} fontSize="14px" direction="column">
              {mission?.package?.lineItems?.map((l, index) => (
                <Stack mb={2} isInline align="center" key={l.name + index}>
                  <Box h="10px" w="10px" bg="#6728BB" borderRadius="20px" />
                  <Text>
                    ({l.count}) {l.name}
                  </Text>
                </Stack>
              ))}
            </Flex>
            <TitleWithIcon
              ml={0}
              TextIcon={<MdRoomService size="16px" />}
              text="Additional Services"
              fontSize="14px"
              mb={0}
              pb={0}
            />
            <Flex pl={2} fontSize="14px" direction="column">
              {mission?.package?.additionalServices?.map((a, index) => (
                <Stack mb={2} isInline align="center" key={a.name + index}>
                  <Box h="10px" w="10px" bg="#6728BB" borderRadius="20px" />
                  <Text>
                    {a.name} - ${a.price.toFixed(2)}
                  </Text>
                </Stack>
              ))}
            </Flex>
            <Flex alignItems="center">
              <TitleWithIcon
                ml={0}
                TextIcon={<MdCalendarToday size="16px" />}
                text="Due Date"
                fontSize="12px"
                mb={0}
                pb={0}
              />
              {mission.isRescheduled && (
                <Flex ml={2} alignItems="center">
                  <Text fontSize="12px" color="red.500" fontWeight="bold">
                    (Date was changed by pilot)
                  </Text>
                </Flex>
              )}
            </Flex>
            <Flex>
              <Text pl={2} fontSize="14px" mr={2}>
                {Boolean(mission.pilotChosenDate) ? pilotChosenDate : dateRange}
              </Text>
              {mission.isRescheduled && (
                <MdError size={'1.3rem'} color={'#E9243F'} />
              )}
            </Flex>
            <Stack pt={4} borderTop="1px solid #ccc">
              {mission.rush && (
                <Flex
                  w="100%"
                  pb="10px"
                  alignItems="center"
                  justifyContent="space-between">
                  <TitleWithIcon
                    m={0}
                    p={0}
                    text="Rush Mission"
                    TextIcon={
                      <AiFillFire
                        size="20px"
                        color="#E9243F"
                        style={{ marginBottom: '2px' }}
                      />
                    }
                  />
                  <Box w="50px" />
                  <Text fontSize="xs" fontWeight="bold">
                    $99.00
                  </Text>
                </Flex>
              )}
              <Text>Total amount</Text>
              <Text fontSize="24px" fontWeight="bold">
                ${total.toFixed(2)}
              </Text>
            </Stack>
          </Stack>
        </Flex>
      </Flex>
      <Flex minW="300px" flex={1} direction="column">
        <Text fontSize="20px" fontWeight="bold">
          Your custom mission is ready!
        </Text>
        <Text fontSize="14px">
          Your mission request has been processed, review and confirm details
        </Text>
        <Checkbox
          mt={4}
          fontSize="14px"
          sx={sx}
          onChange={(e) => {
            setIsAck([e.target.checked, isAck[1]]);
          }}>
          I acknowledge that inclement weather may occur (*)
        </Checkbox>
        <Checkbox
          fontSize="14px"
          sx={sx}
          onChange={(e) => {
            setIsAck([isAck[0], e.target.checked]);
          }}>
          I acknowledge that airspace restrictions may occur (*)
        </Checkbox>
        {mission.isRescheduled && (
          <Checkbox
            fontSize="14px"
            sx={sx}
            onChange={(e) => {
              setIsAck([isAck[0], e.target.checked]);
            }}>
            I acknowledge the mission has been rescheduled for a different date
            and time than I initially requested (*)
          </Checkbox>
        )}

        {isRejected ? (
          <Center flex={1}>
            <Text
              textAlign="center"
              maxW="80%"
              alignSelf="center"
              fontSize="14px"
              fontWeight="bold"
              fontStyle="italic"
              color="red.500">
              This Mission request has been rejected by{' '}
              {mission.status === CustomStatus.CLIENT_REJECTED
                ? 'You'
                : 'the Pilot'}
            </Text>
          </Center>
        ) : (
          <>
            <Button
              isLoading={isAccepting}
              mt={4}
              isDisabled={isDisabled}
              onClick={onAccept}>
              ACCEPT MISSION
            </Button>
            <Button
              mt={2}
              variant="outline"
              isDisabled={isAccepting}
              onClick={() => onSelect('request_changes')}>
              REQUEST CHANGES
            </Button>
            <Button
              mt={2}
              variant="outline"
              isDisabled={isAccepting}
              onClick={() => onSelect('reject')}>
              REJECT
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  );
};
