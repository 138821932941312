export enum SocialType {
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  LINKEDIN = 'LINKEDIN',
  YOUTUBE = 'YOUTUBE',
  VIMEO = 'VIMEO',
  TIKTOK = 'TIKTOK',
}

export enum MinimumRequirements {
  CONNECT_STRIPE = 'connected',
  COMPANY_NAME = 'about',
  LOCATION = 'location',
  PACKAGE = 'packages',
}

export enum Meridiem {
  AM = 'AM',
  PM = 'PM',
}

export enum Days {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export enum WorkItemType {
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
  THREE_D = '3D',
}

export enum Industries {
  // REAL_ESTATE_RESIDENTIAL = 'REAL_ESTATE_RESIDENTIAL',
  // REAL_ESTATE_COMMERCIAL = 'REAL_ESTATE_COMMERCIAL',
  // PROPERTY_MANAGEMENT = 'PROPERTY_MANAGEMENT',
  // CONSTRUCTION = 'CONSTRUCTION',
  OTHERS = 'OTHERS',
  // MEDIA_PRODUCTIONS = 'MEDIA_PRODUCTIONS',
  // INSURANCE = 'INSURANCE',
  // AGRICULTURE = 'AGRICULTURE',
  // UTILITIES = 'UTILITIES',
  // RETAIL_MANAGEMENT = 'RETAIL_MANAGEMENT',
  // SURVEYING_AND_MAPPING = 'SURVEYING_AND_MAPPING',
}

export enum EquipmentType {
  DRONE = 'DRONE',
  CAMERA = 'CAMERA',
  SENSOR = 'SENSOR',
}

export type LineItems = {
  name: string;
  description: string;
  count: number;
  price: number;
};

export type SocialsItem = {
  url: string;
  type: string;
};

export type Location = {
  address: string;
  city: string;
  state: string;
  zipcode: string;
};

export type TimeAvailabilityItem = {
  hour: number;
  minutes: number;
  meridiem: string;
};

export type Availability = {
  day: string;
  start: TimeAvailabilityItem;
  end: TimeAvailabilityItem;
};

export type AboutItem = {
  companyName: string;
  introduction: string;
  dayAvailability: Array<Availability>;
  alwaysAvailable?: boolean;
};

export type PreviousWorkItem = {
  createdAt?: string;
  expiredAt?: string;
  fileName: string;
  fileSize: number;
  fileType: string;
  id?: string;
  keyName?: string;
  signedUrl?: string;
  user?: string;
  title?: string;
  url?: string;
  noAsset?: boolean;
};

export type Package = {
  id: string;
  name: string;
  description: string;
  price: number;
  pilot: string;
  additionalServices: Array<LineItems>;
  lineItems: Array<LineItems>;
  disabled?: boolean;
  createdAt?: string;
  industry?: string[];
};

export type EquipmentsItem = {
  createdAt?: string;
  expiredAt?: string;
  fileName: string;
  fileSize: number;
  fileType: string;
  id?: string;
  keyName?: string;
  signedUrl?: string;
  user?: string;
  type: keyof typeof EquipmentType;
};

export type GpsType = {
  coordinates: [number, number];
};

export type Services = {
  name: string;
  price: number;
};

export type CMS = {
  id?: string;
  gps: GpsType;
  radius: number;
  disabled?: string;
  createdAt?: string;
  pilot?: any;
  location: Location;
  stylings: {
    url: string;
    colorScheme: string;
    font: string;
  };
  about: AboutItem;
  industries: Array<Industries>;
  socials: Array<SocialsItem>;
  packages: Array<Package>;
  previousWork: Array<PreviousWorkItem>;
  equipments: Array<EquipmentsItem>;
  enableTestimonials: boolean;
  testimonials: Array<Testimony>;
  additionalServices: Array<Services>;
  phone?: string;
  username?: string;
  status?: string;
  profileImage?: string;
  bannerImage?: string;
  templateType?: number;
  subscriptionEnd?: string;
  signedUrl?: string;
  bannerSignedUrl?: string;
  name?: string;
  description?: string;
  price?: number;
  lineItems?: Array<LineItems>;
  industry?: string[];
};

export type PreviousWork = {
  id?: string;
  type?: string;
  fileName: string;
  fileType: string;
  fileSize: number;
  user: string;
  signedUrl?: string;
};

export type Testimony = {
  id: string;
  imageUrl?: any;
  signedUrl?: any;
  name: string;
  position: string;
  testimony: string;
  type?: string;
  testimonyFile?: File;
  image?: string;
};

export type File = {
  path: string;
  preview: string;
};
