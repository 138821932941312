import {
  FaCcAmex,
  FaCcMastercard,
  FaCcVisa,
  FaCcDiscover,
  FaCreditCard,
  FaCcDinersClub,
  FaCcJcb,
} from 'react-icons/fa';
import { IconType } from 'react-icons/lib';
import { types } from 'credit-card-type';

export const cardsMap: { [key: string]: IconType } = {
  [types.VISA.toLowerCase()]: FaCcVisa,
  [types.MASTERCARD.toLowerCase()]: FaCcMastercard,
  [types.AMERICAN_EXPRESS.toLowerCase()]: FaCcAmex,
  [types.DINERS_CLUB.toLowerCase()]: FaCcDinersClub,
  [types.DISCOVER.toLowerCase()]: FaCcDiscover,
  [types.JCB.toLowerCase()]: FaCcJcb,
  DEFAULT: FaCreditCard,
};
