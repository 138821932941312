import * as chargebee from 'effector/chargebee';

const subscriptionPlansUtils = () => {
  const subscriptions = chargebee.selectors.useAllCbSubs();

  const isMonthlyPlan = subscriptions?.some(
    (s) =>
      s?.subscription?.subscription_items?.[0]?.item_price_id ===
      'Pro-Subscription-Annually-USD-Monthly',
  );

  const isYearlyPlan = subscriptions?.some(
    (s) =>
      s?.subscription?.subscription_items?.[0]?.item_price_id ===
      'Pro-Subscription-Annually-USD-Yearly',
  );

  const isFreePlan = subscriptions?.some(
    (s) =>
      s?.subscription?.subscription_items?.[0]?.item_price_id ===
        'Free-USD-Yearly' ||
      s?.subscription?.subscription_items?.[0]?.item_price_id ===
        'Free-Subscription-USD-Monthly',
  );

  const isTrialPeriod = subscriptions?.[0]?.subscription?.status === 'in_trial';

  const plansSelection = {
    isMonthlyPlan,
    isYearlyPlan,
    isFreePlan,
    isTrialPeriod,
  };

  return plansSelection;
};

export default subscriptionPlansUtils;
