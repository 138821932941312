import React, { useRef, useState } from 'react';

import {
  Text,
  Flex,
  Stack,
  Avatar,
  Button,
  Spinner,
  useDisclosure,
  useMediaQuery,
  Box,
  Textarea,
} from '@chakra-ui/react';

import { LocationDetails } from './LocationDetails';
import { SessionStore } from 'effector/session/store';
import { MissionAssetsUpload } from 'components/modules/MissionAssetsUpload';
import { ClientMissionActions } from 'components/modules/ClientMissionActions';
import { PilotMissionActions } from 'components/modules/PilotMissionActions';

import ReportModal from 'components/modules/ReportModal';
import TabWithHeader from 'components/modules/TabWithHeader';
import MissionAssets from 'components/modules/MissionAssets';
import SelectDayTimeModal from 'components/modules/SelectDayTimeModal';
import MobileMissionDetails from 'components/modules/MobileMissionDetails';
import BackButtonWithTitle from 'components/primitives/BackButtonWithTitle';
import MissionOverview from 'components/modules/MissionOverview/MissionOverview.view';

import { AccountTypes } from 'types/accountTypes';
import { MissionDetailsGeneratedProps } from './MissionDetails.props';
import HandleCancelMissionStatusModal from 'components/modules/HandleCancelMissionStatusModal';
import { MissionStatuses } from 'types/mission';
import DeleteMissionButton from 'components/modules/DeleteMissionButton';
import ViewReceiptButton from 'components/modules/ViewReceiptButton';
import { format } from 'date-fns';
import { RefType } from 'components/modules/CancelAndDeleteMissionModal/CancelAndDeleteMissionModal.props';
import CancelAndDeleteMissionModal from 'components/modules/CancelAndDeleteMissionModal';
import { EditIcon } from 'components/icons/edit';

const MissionDetailsView = (props: MissionDetailsGeneratedProps) => {
  const selectDateTimeModalRef = useRef<any>(null);
  const cancelAndDeleteMissionModalRef = useRef<RefType>(null);

  const sessionStore = SessionStore.getState();
  const userType = sessionStore?.session?.user?.accountType;

  const [isSmall] = useMediaQuery('(max-width: 600px)');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const formatText = (text: string) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
  if (isSmall) {
    return (
      <MobileMissionDetails
        isLoading={props.isLoading}
        mission={props.mission}
      />
    );
  }

  const getDescription = () => {
    const mission = props.mission;

    const description = `\nPackage - ${
      mission?.package?.name || ''
    }\n\nMission Notes - ${mission?.notes || '-'}\n\nLocation Details - ${
      mission?.locationDetail || ''
    }\n\nAloft Data - ${mission?.airspace?.overview?.short}.\n${
      mission?.airspace?.overview?.full
    }`;

    return description;
  };

  const renderButtonSection = () => {
    if (props.isLoading) {
      return null;
    }

    if (!props.mission) {
      return null;
    }

    return (
      <Stack
        py={6}
        px={2}
        mb="100px"
        bg="white"
        borderRadius="10px"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        minW="200px">
        {userType === AccountTypes.pilot ? (
          <PilotMissionActions mission={props.mission} />
        ) : (
          <ClientMissionActions mission={props.mission} />
        )}

        <ViewReceiptButton
          chargeId={(props?.mission as any)?.payment_id}
          pilotStripeId={(props?.mission as any)?.pilot?.stripeId}
        />
        <Button
          onClick={onOpen}
          size="sm"
          color="buttonBgColor"
          variant="ghost"
          background="transparent">
          REPORT A PROBLEM
        </Button>
      </Stack>
    );
  };

  const renderCustomButton = () => {
    const clientName =
      props?.mission && props?.mission?.client
        ? `${props?.mission?.client?.firstName || ''} ${
            props?.mission?.client?.lastName || ''
          }`
        : '';

    return (
      <>
        <Text
          w="200px"
          alignSelf="center"
          cursor="pointer"
          my={1}
          textAlign="center"
          fontWeight="bold"
          color="brand.500"
          onClick={() => {
            selectDateTimeModalRef.current?.onOpen?.();
          }}>
          Add to Calendar
        </Text>
        {props?.mission?.status &&
          [MissionStatuses.SCHEDULED, MissionStatuses.CANCELLED].includes(
            props?.mission?.status as any,
          ) && (
            <DeleteMissionButton
              isDeleting={props.isDeleting}
              onDelete={() => {
                props.onMissionDelete(props.mission?.id);
              }}
            />
          )}
        <SelectDayTimeModal
          ref={selectDateTimeModalRef}
          mission={props.mission}
          onSave={(startDate, endDate) => {
            const encodedStartDate = encodeURIComponent(
              format(new Date(startDate), "yyyyMMdd'T'HHmmss'Z'"),
            );
            const encodedEndDate = encodeURIComponent(
              format(new Date(endDate), "yyyyMMdd'T'HHmmss'Z'"),
            );
            const encodedLocation = encodeURIComponent(props.mission?.location);
            const encodedDescription = encodeURIComponent(getDescription());
            const encodedSummary = encodeURIComponent(
              `DroneAdair Mission requested by ${clientName}`,
            );

            const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodedSummary}&dates=${encodedStartDate}/${encodedEndDate}&details=${encodedDescription}&location=${encodedLocation}&sf=true&output=xml`;

            window.open(url, '_blank');
          }}
        />
      </>
    );
  };

  const renderCancelAndDelete = () => {
    if (userType === AccountTypes.client) {
      return;
    }

    return (
      <>
        <Text
          w="200px"
          cursor="pointer"
          my={1}
          textAlign="center"
          color="errorColor"
          fontFamily="Montserrat"
          fontWeight="bold"
          onClick={() => {
            cancelAndDeleteMissionModalRef.current?.onOpen?.();
          }}>
          Cancel and Delete
        </Text>
        <CancelAndDeleteMissionModal
          ref={cancelAndDeleteMissionModalRef}
          missionId={props.mission?.id}
        />
      </>
    );
  };

  return (
    <Flex flexDirection="column" width="100%" mx="15px">
      <BackButtonWithTitle title="Mission Details" />
      <>
        <TabWithHeader
          highlightedTitle="MISSION DETAILS"
          status={props?.mission?.status}
          customButton={renderCustomButton()}
          deleteButton={renderCancelAndDelete()}>
          {props.isLoading ? (
            <Stack
              bg="white"
              p={10}
              alignItems="center"
              justifyContent="center">
              <Spinner />
            </Stack>
          ) : (
            <MissionOverview
              mission={props.mission}
              isLeads={true}
              // buttonSection={}
            />
          )}
        </TabWithHeader>
        <Flex my="10px" />
        <TabWithHeader
          highlightedTitle="PILOT NOTES"
          status={props?.mission?.status}
          showTripleButtons={false}
          buttonOnBar={
            !props.isEditable ? (
              <Button width="150px" onClick={props.handleEditClick}>
                <Box as={EditIcon} marginRight="8px" />
                EDIT
              </Button>
            ) : (
              <>
                <Button
                  colorScheme="white"
                  marginRight={'10px'}
                  width="150px"
                  color={'white'}
                  borderColor={'white'}
                  onClick={props.handleCancelClick}
                  variant="outline">
                  CANCEL
                </Button>
                <Button
                  colorScheme="white"
                  width="150px"
                  color={'white'}
                  borderColor={'white'}
                  onClick={props.handleSaveClick}
                  variant="outline">
                  SAVE NOTES
                </Button>
              </>
            )
          }>
          {props.isLoading ? (
            <Stack
              bg="white"
              p={10}
              alignItems="center"
              justifyContent="center">
              <Spinner />
            </Stack>
          ) : props.isEditable ? (
            <Textarea
              width="100%"
              height="20px"
              value={props.tempNotes}
              onChange={(e) => props.changeNotes(e.target.value)}
            />
          ) : (
            <Stack spacing={4} width="100%">
              <Box width="100%">
                {' '}
                {props.mission?.pilotNotes
                  ? formatText(props.mission.pilotNotes)
                  : ''}
              </Box>
            </Stack>
          )}
        </TabWithHeader>
        <Flex my="10px" />
        <TabWithHeader highlightedTitle="AIRSPACE DETAILS">
          {props.isLoading ? (
            <Stack
              bg="white"
              p={10}
              alignItems="center"
              justifyContent="center">
              <Spinner />
            </Stack>
          ) : (
            <LocationDetails mission={props.mission} />
          )}
        </TabWithHeader>
        <Flex my="10px" />
        {userType === AccountTypes.client && (
          <>
            <TabWithHeader
              highlightedTitle={
                props.mission?.pilot ? 'ASSIGNED PILOT' : 'STATUS UPDATES'
              }>
              {props.isLoading ? (
                <Spinner />
              ) : props.mission?.pilot ? (
                <Flex flex={1} p={2}>
                  <Avatar
                    src={props.mission.pilot.profileImage ?? ''}
                    size="xl"
                  />
                  <Stack ml={10} spacing={1}>
                    <Text fontSize="18px" fontWeight="bold">
                      Pilot Name : {props.mission.pilot.firstName ?? ''}{' '}
                      {props.mission.pilot.lastName ?? ''}
                    </Text>
                  </Stack>
                </Flex>
              ) : (
                <Text>A pilot will be assigned soon</Text>
              )}
            </TabWithHeader>
            <Flex my="10px" />

            <TabWithHeader highlightedTitle="ASSETS">
              {props.isLoading ? (
                <Spinner />
              ) : (
                <MissionAssets
                  missionId={String(props.mission?.id).toString()}
                  package={props.mission?.package?.name ?? ''}
                />
              )}
            </TabWithHeader>
            <Flex my="10px" />
          </>
        )}

        {!props.isLoading && (
          <ReportModal
            onClose={onClose}
            isOpen={isOpen}
            missionId={String(props.mission?.id)}
          />
        )}

        {userType === AccountTypes.pilot && (
          <>
            <TabWithHeader highlightedTitle="CLIENT DETAILS">
              <Flex gridGap="10px" w="100%" direction="column" p="10px">
                <Text fontSize="14px" fontWeight="bold">
                  Name: {props.mission?.client?.firstName || ''}{' '}
                  {props?.mission?.client?.lastName || ''}
                </Text>
                <Text fontSize="14px" fontWeight="bold">
                  Email: {props?.mission?.client?.email || ''}
                </Text>
              </Flex>
            </TabWithHeader>
            <Flex my="10px" />
            {props.mission?.status && (
              <TabWithHeader highlightedTitle="ASSETS">
                <MissionAssetsUpload mission={props.mission} />
              </TabWithHeader>
            )}
            <Flex my="20px" />
            <TabWithHeader highlightedTitle="UPLOADED ASSETS">
              {props.isLoading ? (
                <Spinner />
              ) : (
                <MissionAssets
                  missionId={String(props.mission.id).toString()}
                  package={props.mission.package?.name ?? ''}
                />
              )}
            </TabWithHeader>
            <Flex my="10px" />
          </>
        )}
        {renderButtonSection()}
      </>

      {props.mission && (
        <HandleCancelMissionStatusModal mission={props.mission} />
      )}
    </Flex>
  );
};

export default React.memo(MissionDetailsView);
