import React, { FC } from 'react';

import { Button } from '@chakra-ui/button';
import { Box, Flex, Stack, Text, Center } from '@chakra-ui/layout';
import { MdCheck, MdChevronLeft, MdChevronRight } from 'react-icons/md';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Props } from './MobilePacksCarousel.props';

const View: FC<Props> = (props) => {
  const {
    packs,
    onPackSelect,
    fontColor = '#fff',
    brandColor = '#6728BB',
  } = props;

  return (
    <Box>
      <Carousel
        width={'90vw'}
        showStatus={false}
        showIndicators
        showThumbs={false}
        swipeable={false}
        renderIndicator={(_, isSelected) => {
          return (
            <Box
              h={3}
              w={3}
              borderRadius={10}
              bg={isSelected ? '#4f4f4f' : '#ccc'}
              mx={2}
              my={1}
              mb="-10px"
              borderWidth={isSelected ? 2 : 0}
              borderColor={isSelected ? '#000' : undefined}
            />
          );
        }}
        renderArrowPrev={(clickHandler, hasPrev) =>
          hasPrev && (
            <Button
              onClick={clickHandler}
              position="absolute"
              padding={0}
              top="50%"
              left={0}
              transform="translateY(-50%)"
              bg="rgba(0, 0, 0, 0.3)"
              color="white"
              borderRadius="full"
              w={12}
              h={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
              _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
              _active={{ bg: 'rgba(0, 0, 0, 0.6)' }}
              zIndex={2}>
              <MdChevronLeft size={24} />
            </Button>
          )
        }
        renderArrowNext={(clickHandler, hasNext) =>
          hasNext && (
            <Button
              onClick={clickHandler}
              position="absolute"
              padding={0}
              top="50%"
              right={0}
              transform="translateY(-50%)"
              bg="rgba(0, 0, 0, 0.3)"
              color="white"
              borderRadius="full"
              w={12}
              h={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
              _hover={{ bg: 'rgba(0, 0, 0, 0.3)' }}
              _active={{ bg: 'rgba(0, 0, 0, 0.6)' }}
              zIndex={2}>
              <MdChevronRight size={24} />
            </Button>
          )
        }>
        {packs.map((pack, index) => {
          return (
            <Flex
              key={`${pack.id}-${index}`}
              w="90vw"
              direction="column"
              py={5}
              px={5}>
              <Stack
                p={2}
                pt={'4rem'}
                align="center"
                position="relative"
                bg="#fff"
                minH="450px"
                boxShadow={`0px 0px 20px 0px ${brandColor}30`}
                border={`3px solid ${brandColor}`}
                borderRadius="20px">
                <Text
                  color="#222222"
                  fontSize="26px"
                  fontWeight="500"
                  textAlign="center">
                  {pack.name}
                </Text>
                <Text
                  my={2}
                  fontWeight="bold"
                  textAlign="center"
                  color={brandColor}
                  fontSize="50px">
                  ${pack.price.toLocaleString()}
                </Text>
                <Text
                  mt={2}
                  mb={1}
                  maxH="100px"
                  noOfLines={4}
                  fontSize="14px"
                  color="#838383"
                  textAlign="center">
                  {pack.description}
                </Text>
                <Box>
                  <Box
                    h="1px"
                    left={0}
                    right={0}
                    bg="#979797"
                    opacity={0.3}
                    position="absolute"
                  />
                </Box>
                <Center>
                  <Button
                    w="240px"
                    mt={'0.5rem'}
                    __css={{
                      bg: brandColor,
                      px: 5,
                      py: 3,
                      borderRadius: 30,
                      fontWeight: 'bold',
                      color: fontColor,
                    }}
                    _disabled={{
                      cursor: 'not-allowed',
                      opacity: 0.5,
                    }}
                    onClick={() => onPackSelect(pack)}>
                    START A MISSION
                  </Button>
                </Center>
                <Flex direction="column" flex={1} width={'70vw'} py={'1rem'}>
                  {pack.lineItems.map((lineItem, index) => (
                    <Stack
                      mb={2}
                      isInline
                      align="center"
                      key={`${lineItem.name}-${index}`}>
                      <Center
                        w="22px"
                        h="22px"
                        overflow="hidden"
                        borderRadius="20px"
                        position="relative">
                        <Box
                          w="100%"
                          h="100%"
                          opacity={0.1}
                          bg={brandColor}
                          position="absolute"
                        />
                        <MdCheck color={brandColor} />
                      </Center>
                      <Text color="#838383">
                        {`(${lineItem.count})`} {lineItem?.name || ''}
                      </Text>
                    </Stack>
                  ))}
                </Flex>
              </Stack>
            </Flex>
          );
        })}
      </Carousel>
    </Box>
  );
};

export default View;
