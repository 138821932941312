import React, { forwardRef, memo, useImperativeHandle } from 'react';

import {
  Text,
  Image,
  Modal,
  Stack,
  Button,
  Center,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { useHistory } from 'react-router';

import skipCharacter from 'res/skip-character.png';

const View = forwardRef((_, ref) => {
  const { push } = useHistory();
  const { isOpen, onClose, onOpen } = useDisclosure();

  useImperativeHandle(
    ref,
    () => ({
      onOpen,
    }),
    [],
  );

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Center flexDirection="column">
            <Image src={skipCharacter} h="200px" w="200px" />
            <Text
              textAlign="center"
              fontFamily="Monument Extended"
              my={4}
              maxW="90%">
              Are you sure you want to skip the Stripe set up?
            </Text>
            <Text mb={4} textAlign="center">
              If you don’t set up your account you won’t be able to access to
              missions and more features
            </Text>
            <Stack isInline mb={6}>
              <Button onClick={onClose} w="150px" variant="outline">
                GO BACK
              </Button>
              <Button
                w="150px"
                onClick={() => {
                  onClose();

                  push('/pilot/welcome');
                }}>
                SKIP ANYWAY
              </Button>
            </Stack>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

export const SkipModal = memo(View);
