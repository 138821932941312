import React, { FC } from 'react';

import {
  Stack,
  Text,
  Button,
  Modal,
  useToast,
  useDisclosure,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import * as session from 'effector/session';
import { logoutEvent } from 'effector/session/events';

import { deleteUser } from 'api/auth';
import { useMutation, useQueryCache } from 'react-query';

export const DeleteAccountButton: FC<{
  renderButton?: (onOpen: () => void) => JSX.Element;
}> = ({ renderButton }) => {
  const toast = useToast();
  const history = useHistory();
  const queryCache = useQueryCache();

  const user = session.selectors.useUser();

  const onLogout = () => {
    logoutEvent();

    queryCache.removeQueries();

    history.push('/login');

    window.location.reload();
  };

  const [deleteUserAccount, { isLoading }] = useMutation(deleteUser, {
    onSuccess: () => {
      onLogout();

      toast({
        status: 'success',
        description: 'Your Account has been deleted',
        isClosable: true,
        duration: 2000,
      });
    },
  });

  const message =
    user?.accountType === 'PILOT'
      ? 'All your account information related to Chargebee and Stripe Connect will be deleted. Are you sure?'
      : 'All your account information will be delete. Are you sure?';

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {renderButton ? (
        renderButton(onOpen)
      ) : (
        <Button
          bg="red.500"
          onClick={onOpen}
          isLoading={false}
          w="200px"
          variant="solid">
          Delete Account
        </Button>
      )}
      <Modal size="xl" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay zIndex={0} />
        <ModalContent>
          <ModalHeader
            textAlign="center"
            fontWeight="500"
            fontFamily="Monument Extended">
            Delete Account
          </ModalHeader>
          <ModalBody display="flex" flexDirection="column" alignItems="center">
            <Text textAlign="center" mb={4}>
              {message}
            </Text>
            <Stack pb={6} isInline>
              <Button
                isDisabled={isLoading}
                variant="outline"
                onClick={onClose}>
                Close
              </Button>
              <Button
                isLoading={isLoading}
                onClick={() => {
                  deleteUserAccount(user?.id || '');
                }}>
                Delete
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
