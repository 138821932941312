import React, { FC } from 'react';

import { Button } from '@chakra-ui/react';

import { useMutation } from 'react-query';
import { fetchReceiptUrl } from 'api/stripe';

import { ViewReceiptButtonProps } from './ViewReceiptButton.props';

const ViewReceiptButtonView: FC<ViewReceiptButtonProps> = ({
  chargeId,
  pilotStripeId,
}) => {
  const [handleSubmit, { isLoading }] = useMutation(
    () => fetchReceiptUrl(chargeId, pilotStripeId),
    {
      onSuccess: (receiptUrl) => {
        window.open(receiptUrl, '_blank');
      },
    },
  );

  return (
    <Button
      size="sm"
      w="150px"
      variant="outline"
      isLoading={isLoading}
      onClick={() => handleSubmit()}>
      VIEW RECEIPT
    </Button>
  );
};

export default ViewReceiptButtonView;
