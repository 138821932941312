import {
  MdAgriculture,
  MdHome,
  MdHomeWork,
  MdRecordVoiceOver,
} from 'react-icons/md';
import { IoIosConstruct } from 'react-icons/io';
import { RiBuilding4Fill } from 'react-icons/ri';
import { FaFileInvoiceDollar } from 'react-icons/fa';

import { Industries } from 'types/cms';
import { IconType } from 'react-icons/lib';
import { AiFillInsurance } from 'react-icons/ai';

export const industriesMap: { [key: string]: string } = {
  // [Industries.CONSTRUCTION]: 'Construction',
  [Industries.OTHERS]: 'Others',
  // [Industries.PROPERTY_MANAGEMENT]: 'Property Management',
  // [Industries.REAL_ESTATE_RESIDENTIAL]: 'Real Estate (Residential)',
  // [Industries.REAL_ESTATE_COMMERCIAL]: 'Real Estate (Commercial)',
  // [Industries.AGRICULTURE]: 'Agriculture',
  // [Industries.INSURANCE]: 'Insurance',
  // [Industries.MEDIA_PRODUCTIONS]: 'Media Productions',
  // [Industries.RETAIL_MANAGEMENT]: 'Retail Management',
  // [Industries.UTILITIES]: 'Utilities/Power/Solar/Telecom',
  // [Industries.SURVEYING_AND_MAPPING]: 'Surveying and Mapping',
  DEFAULT: 'Others',
};

export const industriesIconMap: { [key: string]: IconType } = {
  // [Industries.CONSTRUCTION]: IoIosConstruct,
  [Industries.OTHERS]: FaFileInvoiceDollar,
  // [Industries.PROPERTY_MANAGEMENT]: RiBuilding4Fill,
  // [Industries.REAL_ESTATE_RESIDENTIAL]: MdHome,
  // [Industries.REAL_ESTATE_COMMERCIAL]: MdHomeWork,
  // [Industries.AGRICULTURE]: MdAgriculture,
  // [Industries.INSURANCE]: AiFillInsurance,
  // [Industries.MEDIA_PRODUCTIONS]: MdRecordVoiceOver,
  // [Industries.RETAIL_MANAGEMENT]: MdRecordVoiceOver,
  // [Industries.UTILITIES]: MdRecordVoiceOver,
  // [Industries.SURVEYING_AND_MAPPING]: MdRecordVoiceOver,
  DEFAULT: MdRecordVoiceOver,
};

export const getIndustryName = (name = '') => {
  if (!name) {
    return '';
  }

  const industry = industriesMap[name];

  if (industry) {
    return industry;
  }

  const formattedIndustry = name.split(' ').join(' ');

  return formattedIndustry;
};
