import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { wrap } from 'popmotion';
import './Carousel.css';
import { Flex, Box } from '@chakra-ui/react';
import ArrowForwardInCircleView from 'components/primitives/ArrowInCircle/ArrowInCircle.view';
import { MdArrowBack, MdArrowForward, MdPlayCircle } from 'react-icons/md';
import { sanitizeFileName } from './utils';

const CarouselItem = ({ children, isSelected }: any) => {
  const scale = isSelected ? 1.05 : 0.9;

  return (
    <motion.div
      className={`item ${isSelected ? 'selected' : 'shadow'}`}
      animate={{
        scale: scale,
      }}
      transition={{
        type: 'just',
      }}>
      {children}
    </motion.div>
  );
};

const Carousel = ({ items }: any) => {
  const ELEMENTS_TO_SHOW = 5;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [direction, setDirection] = useState(0);

  let middleIndex: number;

  if (items.length === 3) {
    middleIndex = 1; // Set the middle index manually for 3 items
  } else {
    middleIndex = Math.floor(items.length / 2);
  }

  const handleNext = () => {
    setSelectedIndex(getNextIndex(-1));
    setDirection(1);
  };

  const handlePrev = () => {
    setSelectedIndex(getNextIndex(1));
    setDirection(-1);
  };

  function getNextIndex(factor: number) {
    const wrapIndex = wrap(0, items.length, selectedIndex + factor);
    return wrapIndex;
  }

  function generateItemsIndex() {
    const itemsIndex = [];
    let ELEMENTS = 0;

    if (items.length > 3) {
      ELEMENTS = ELEMENTS_TO_SHOW;
    } else {
      ELEMENTS = items.length;
    }
    for (let i = 0; i < ELEMENTS; i++) {
      itemsIndex.push(getNextIndex(i));
    }
    return itemsIndex;
  }
  const handleOnPlay = (url?: string) => {
    if (!url) {
      return;
    }

    const isHttp = url.includes('http');

    if (isHttp) {
      window.open(url, '_blank');
    } else {
      window.open(`https://${url}`, '_blank');
    }
  };
  return (
    <div className="carousel-container">
      <Flex onClick={handlePrev} className="prev-button">
        <ArrowForwardInCircleView Icon={MdArrowBack} />
      </Flex>
      <div className="carousel">
        {generateItemsIndex().map((item: any, index: any) => {
          const { imageUrl, url, title } = items[item];
          const archiveName = () => {
            if (!title) {
              return '';
            }
            return sanitizeFileName(title);
          };
          return (
            <CarouselItem
              key={`item-key-${index}`}
              isSelected={index === middleIndex}
              index={index}
              selectedIdx={selectedIndex}
              direction={direction}>
              <div className="item-container">
                {url && (middleIndex || items.length === 1) ? (
                  <Flex
                    className="icon-play-centered"
                    onClick={() => handleOnPlay(url)}>
                    <MdPlayCircle fontSize={'3rem'} />{' '}
                  </Flex>
                ) : null}
                <img src={imageUrl} alt="" />
                <span className="title-container">{archiveName()}</span>
              </div>
            </CarouselItem>
          );
        })}
      </div>
      <Flex onClick={handleNext} className="next-button">
        <ArrowForwardInCircleView Icon={MdArrowForward} />
      </Flex>
    </div>
  );
};

export default Carousel;
