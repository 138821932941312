import React, { FC, useState } from 'react';

import {
  Box,
  Flex,
  Text,
  Image,
  Stack,
  Button,
  Center,
  Radio,
  RadioGroup,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { MdClose, MdPeople, MdAddCircle, MdPlayArrow } from 'react-icons/md';

import { useCMS } from './Context';

import * as session from 'effector/session';
import { getUserEmail, getUserId } from 'effector/session/store';

import success_asset from 'res/mission-quote-asset.png';

import { addDays, set } from 'date-fns';

import { useMutation } from 'react-query';
import { createCustomMission } from 'api/custom-mission';

import { Form, Formik, useField, useFormikContext } from 'formik';

import { MultiLocationInput } from './MultiLocationInput';

import TimePicker from 'components/primitives/TimePicker';
import FormikCalendar from 'components/primitives/FormikCalendar';
import FormikInput from 'components/primitives/FormikInput/FormikInput.view';
import FormikTextArea from 'components/primitives/FormikTextArea/FormikTextArea.view';
import FormikDatePicker from 'components/primitives/FormikDatePicker/FormikDatePicker.view';

import { CustomMissionRequestValidationSchema } from './CustomMission.utils';

import { AccountTypes } from 'types/accountTypes';

const css = {
  '::placeholder': {
    fontStyle: 'italic',
    fontSize: '14px',
  },
};

const fontSize = '14px';

const labelProps = {
  fontSize: '14px',
};

const getInitialValues = () => {
  const email = getUserEmail();

  return {
    name: '',
    email,
    location: {
      address: '',
      lat: 0,
      lng: 0,
      state: '',
      zipcode: '',
    },
    additionalLocations: [],
    phone: '',
    missionDetails: '',
    industry: '',
    budget: '',
    eventStart: '',
    dueDate: '',
    rush: false,
    onsite: false,
    liveEvent: false,
    liveDay: undefined as string | undefined,
    liveDay2: undefined as string | undefined,
    liveDay3: undefined as string | undefined,
    liveStartTime: '',
    liveEndTime: '',
    liveEndMeridiem: 'AM',
    liveStartMeridiem: 'AM',
    liveStartTime2: '',
    liveEndTime2: '',
    liveEndMeridiem2: 'AM',
    liveStartMeridiem2: 'AM',
    liveStartTime3: '',
    liveEndTime3: '',
    liveEndMeridiem3: 'AM',
    liveStartMeridiem3: 'AM',
    contactName: '',
    contactPhoneNumber: '',
    contactEmail: '',
  };
};

type Values = ReturnType<typeof getInitialValues>;

const HourInput: FC<{
  type: 'Start' | 'End';
  timeName: string;
}> = ({ type, timeName }) => {
  const { brandColor } = useCMS();

  const [, meta, helpers] = useField(timeName);

  return (
    <Stack>
      <Text fontSize="14px" fontWeight="bold">
        {type} Hour
      </Text>
      <Flex>
        <TimePicker
          brandColor={brandColor}
          isInvalid={Boolean(meta.touched && meta.error)}
          onClick={() => helpers.setTouched(true)}
          onChange={(time) => helpers.setValue(time)}
        />
      </Flex>
    </Stack>
  );
};

const LiveOnsiteSection: FC = () => {
  const { brandColor } = useCMS();
  const { values, setFieldValue } = useFormikContext<Values>();

  const [days, setDays] = useState<number>(1);

  const isLive = values?.liveEvent;

  return (
    <Flex
      mt={4}
      pb={4}
      pt={0}
      direction="column"
      border="1px solid #ccc"
      borderRadius="5px">
      <Flex px={4} flexWrap="wrap">
        <Stack mt={4}>
          <Stack isInline align="center">
            <Box
              maxH="18px"
              border={`1px solid ${brandColor}`}
              borderRadius="2px">
              <MdPlayArrow color={brandColor} />
            </Box>
            <Text fontWeight="bold">Is this a live event?</Text>
          </Stack>
          <RadioGroup
            defaultValue={'0'}
            onChange={(e) => {
              setFieldValue('liveEvent', Boolean(parseInt(e as any)));
            }}
            sx={{
              '.chakra-radio__control[data-checked]': {
                background: brandColor,
                borderColor: brandColor,
              },
              '.chakra-radio__control[data-focus]': {
                boxShadow: 'none',
              },
            }}>
            <Stack direction="row">
              <Radio value="1">Yes</Radio>
              <Radio value="0">No</Radio>
            </Stack>
          </RadioGroup>
        </Stack>
        <Box w="100px" h="10px" />
        <Stack mt={4}>
          <Stack isInline align="center">
            <Box>
              <MdPeople color={brandColor} />
            </Box>
            <Text fontWeight="bold">Do you need to be on site?</Text>
          </Stack>
          <RadioGroup
            defaultValue={'0'}
            onChange={(e) => {
              setFieldValue('onsite', Boolean(parseInt(e as any)));
            }}
            sx={{
              '[data-focus]': { boxShadow: 'none' },
              '.chakra-radio__control[data-checked]': {
                background: brandColor,
                borderColor: brandColor,
              },
            }}>
            <Stack direction="row">
              <Radio value="1">Yes</Radio>
              <Radio value="0">No</Radio>
            </Stack>
          </RadioGroup>
        </Stack>
      </Flex>
      {(isLive || (!isLive && values.onsite)) && (
        <Flex mt={4} pt={4} px={4} borderTop="1px solid #ccc" flexWrap="wrap">
          <FormikDatePicker
            name="liveDay"
            label="Day"
            labelProps={{ fontSize: '14px' }}
            disablePastDates={true}
            textFieldProps={{
              css,
              w: '280px',
              fontSize: '14px',
            }}
            containerProps={{ maxW: '280px' }}
            minDate={addDays(new Date(), 1)}
          />
          <Box w="20px" h="10px" />
          <HourInput type="Start" timeName="liveStartTime" />
          <Box w="20px" h="10px" />
          <HourInput type="End" timeName="liveEndTime" />
        </Flex>
      )}
      {(isLive || (!isLive && values.onsite)) && days > 1 && (
        <Flex mt={4} pt={4} px={4} flexWrap="wrap" align="flex-end">
          <FormikDatePicker
            name="liveDay2"
            label="Day"
            labelProps={{ fontSize: '14px' }}
            disablePastDates={true}
            textFieldProps={{
              css,
              w: '280px',
              fontSize: '14px',
            }}
            containerProps={{ maxW: '280px' }}
          />
          <Box w="20px" h="10px" />
          <HourInput type="Start" timeName="liveStartTime2" />
          <Box w="20px" h="10px" />
          <HourInput type="End" timeName="liveEndTime2" />
          {days == 2 && (
            <IconButton
              ml={4}
              mb={1}
              isRound
              size="sm"
              aria-label="close-2-btn"
              icon={<MdClose />}
              borderColor={brandColor}
              bg={brandColor}
              _hover={{
                bg: `${brandColor}10`,
              }}
              _active={{
                bg: `${brandColor}30`,
              }}
              onClick={() => {
                setDays(1);
                setFieldValue('liveDay2', undefined);
              }}
            />
          )}
        </Flex>
      )}
      {(isLive || (!isLive && values.onsite)) && days > 2 && (
        <Flex mt={4} pt={4} px={4} flexWrap="wrap" align="flex-end">
          <FormikDatePicker
            name="liveDay3"
            label="Day"
            labelProps={{ fontSize: '14px' }}
            disablePastDates={true}
            textFieldProps={{
              css,
              w: '280px',
              fontSize: '14px',
            }}
            containerProps={{ maxW: '280px' }}
          />
          <Box w="20px" h="10px" />
          <HourInput type="Start" timeName="liveStartTime3" />
          <Box w="20px" h="10px" />
          <HourInput type="End" timeName="liveEndTime3" />
          <IconButton
            ml={4}
            mb={1}
            isRound
            size="sm"
            aria-label="close-2-btn"
            icon={<MdClose />}
            borderColor={brandColor}
            bg={brandColor}
            _hover={{
              bg: `${brandColor}10`,
            }}
            _active={{
              bg: `${brandColor}30`,
            }}
            onClick={() => {
              setDays(2);
              setFieldValue('liveDay3', undefined);
            }}
          />
        </Flex>
      )}
      {days <= 3 && !isLive && values.onsite ? (
        <Center mt={6}>
          <Button
            onClick={() => {
              setDays((prev) => (prev == 3 ? prev : prev + 1));
            }}
            leftIcon={<MdAddCircle size="20px" />}
            variant="outline"
            borderColor={brandColor}
            color={brandColor}
            _hover={{
              bg: `${brandColor}10`,
            }}
            _active={{
              bg: `${brandColor}30`,
            }}>
            ADD ANOTHER DAY
          </Button>
        </Center>
      ) : null}
      {values.onsite && (
        <Flex flexDirection="column" p={4}>
          <Stack isInline align="center">
            <MdPeople color={brandColor} />
            <Text fontWeight="bold">On Site Contact Information</Text>
          </Stack>
          <Flex
            my={2}
            w="100%"
            flex={1}
            align="center"
            flexWrap={{
              base: 'wrap',
              sm: 'wrap',
              md: 'wrap',
              lg: 'nowrap',
            }}>
            <FormikInput
              name="contactName"
              label="Name"
              placeholder="Your name here"
              minW="250px"
            />
            <Box w="30px" h="10px" />
            <FormikInput
              name="contactEmail"
              label="Email"
              placeholder="Your email here"
              minW="250px"
            />
            <Box w="30px" h="10px" />
            <FormikInput
              type="number"
              name="contactPhoneNumber"
              label="Phone number"
              placeholder="Your phone number here"
              minW="250px"
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export const CustomQuote: FC<{
  isPreview: boolean;
  isDefaultOpen?: boolean;
}> = ({ isPreview, isDefaultOpen }) => {
  const isUser = !!session.selectors.useUserId();

  const { cms, brandColor, fontColor } = useCMS();
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: isDefaultOpen,
  });

  const [isSuccess, setIsSuccess] = useState(false);

  const type = session.selectors.useAccountType();
  const pilotEmail = cms?.pilot?.email || '';

  const [submit, { isLoading }] = useMutation(createCustomMission, {
    onSuccess: () => {
      setIsSuccess(true);
    },
  });

  const onCustomMissionCreate = (values: Values) => {
    const userId = getUserId();

    let contact = null;
    let meetupTime: any[] = [];
    let eventStart = new Date(values?.eventStart).toISOString();
    let dueDate = new Date(values?.dueDate).toISOString();

    if (values.liveEvent) {
      eventStart = values.liveDay || '';
      dueDate = values.liveDay || '';

      const startTime = values?.liveStartTime?.split(':');
      const startHours = parseInt(startTime[0] || '0');
      const startMinutes = parseInt(startTime[1] || '0');

      const endTime = values?.liveEndTime?.split(':');
      const endHours = parseInt(endTime[0] || '0');
      const endMinutes = parseInt(endTime[1] || '0');

      eventStart = set(new Date(eventStart), {
        hours: startHours,
        minutes: startMinutes,
      }).toISOString();

      dueDate = set(new Date(dueDate), {
        hours: endHours,
        minutes: endMinutes,
      }).toISOString();

      if (values.onsite) {
        contact = {
          name: values.contactName,
          phone: values.contactPhoneNumber,
          email: values.contactEmail,
        };
      }
    }

    if (!values.liveEvent && values.onsite) {
      const startTime = values?.liveStartTime?.split(':');
      const startHours = parseInt(startTime[0] || '0');
      const startMinutes = parseInt(startTime[1] || '0');

      meetupTime = [
        set(new Date(values.liveDay || ''), {
          hours: startHours,
          minutes: startMinutes,
        }).toISOString(),
      ];

      if (!!values?.liveDay2) {
        const startTime2 = values?.liveStartTime2?.split(':');
        const startHours2 = parseInt(startTime2[0] || '0');
        const startMinutes2 = parseInt(startTime2[1] || '0');

        meetupTime = [
          ...meetupTime,
          set(new Date(values.liveDay2 || ''), {
            hours: startHours2,
            minutes: startMinutes2,
          }).toISOString(),
        ];
      }

      if (!!values.liveDay3) {
        const startTime3 = values?.liveStartTime3?.split(':');
        const startHours3 = parseInt(startTime3[0] || '0');
        const startMinutes3 = parseInt(startTime3[1] || '0');

        meetupTime = [
          ...meetupTime,
          set(new Date(values.liveDay3), {
            hours: startHours3,
            minutes: startMinutes3,
          }).toISOString(),
        ];
      }

      contact = {
        name: values.contactName,
        phone: values.contactPhoneNumber,
        email: values.contactEmail,
      };
    }

    const payload = {
      cms: cms?.id || '',
      client: userId,
      pilot: typeof cms?.pilot === 'string' ? cms?.pilot : cms?.pilot?.id,
      clientDetails: {
        name: values?.name,
        email: values?.email,
        phone: Boolean(values?.phone) ? values?.phone : 'no phone number',
        location: values?.location,
        industry: values?.industry,
        budget: Number(values?.budget),
        additionalLocations: values?.additionalLocations,
      },
      rush: values?.rush,
      onsite: values?.onsite,
      liveEvent: values?.liveEvent,
      eventStart,
      dueDate,
      missionDetails: values?.missionDetails,
      stripeId: cms?.pilot?.stripeId || '',
      meetupTime,
      contact,
    };

    submit(payload);
  };

  if (type === AccountTypes.pilot && !isDefaultOpen) {
    return null;
  }

  if (isSuccess) {
    return (
      <Center>
        <Flex
          my={4}
          p={{ base: '10px', md: '50px' }}
          flex={1}
          bg="#fff"
          maxW={{ base: '90vw', md: '60vw' }}
          borderRadius={10}
          align="center"
          justify="center"
          boxShadow="0 0 20px 0 rgba(0,0,0,0.1)"
          flexWrap="wrap">
          <Image src={success_asset} h={{ base: '200px', md: '360px' }} />
          <Box w={{ base: '0px', md: '50px' }} h="10px" />
          <Stack minW="250px" flex={1}>
            <Text fontWeight="600" fontSize="24px">
              Your request was sent!
            </Text>
            <Text mb={4} fontSize="18px">
              In the next few days you’ll have an email with all your custom
              mission details!
            </Text>
            <Button
              mb={4}
              w="260px"
              bg={brandColor}
              color={fontColor}
              _hover={{ bg: `${brandColor}90` }}
              _active={{ bg: `${brandColor}` }}
              onClick={() => {
                setIsSuccess(false);
                onOpen();
              }}>
              MAKE ANOTHER REQUEST
            </Button>
            <Text>
              For futher questions please contact{' '}
              {pilotEmail || 'hello@droneadair.com'}
            </Text>
          </Stack>
        </Flex>
      </Center>
    );
  }

  if (isOpen) {
    const isButtonDisabled = isDefaultOpen && type === AccountTypes.pilot;
    return (
      <Formik
        initialValues={getInitialValues()}
        validationSchema={CustomMissionRequestValidationSchema}
        onSubmit={onCustomMissionCreate}>
        {({ isValid }) => (
          <Form>
            <Center>
              <Flex
                my={4}
                px={6}
                py={4}
                flex={1}
                bg="#fff"
                maxW={{ base: '90vw', md: '65vw' }}
                borderRadius={10}
                direction="column"
                boxShadow="0 0 20px 0 rgba(0,0,0,0.1)">
                <Text textAlign="center" fontWeight="600" fontSize="24px">
                  Request Custom Mission
                </Text>
                <Flex
                  my={2}
                  w="100%"
                  flex={1}
                  align="center"
                  flexWrap={{
                    base: 'wrap',
                    sm: 'wrap',
                    md: 'wrap',
                    lg: 'nowrap',
                  }}>
                  <FormikInput
                    name="name"
                    label="Name"
                    placeholder="Your name here"
                    labelProps={labelProps}
                    bg="#F3F3F3"
                    size="lg"
                    css={css}
                    minW="250px"
                    fontSize={fontSize}
                  />
                  {isUser ? null : (
                    <>
                      <Box w="30px" h="10px" />
                      <FormikInput
                        name="email"
                        label="Email"
                        placeholder="Your email here"
                        labelProps={labelProps}
                        size="lg"
                        bg="#F3F3F3"
                        css={css}
                        minW="250px"
                        fontSize={fontSize}
                      />
                    </>
                  )}
                  <Box w="30px" h="10px" />
                  <FormikInput
                    type="number"
                    name="phone"
                    label="Phone number"
                    placeholder="Your phone number here"
                    labelProps={labelProps}
                    size="lg"
                    bg="#F3F3F3"
                    css={css}
                    minW="250px"
                    isOptional={true}
                    fontSize={fontSize}
                    defaultValue={'0'}
                  />
                </Flex>
                <Flex
                  w="100%"
                  flex={1}
                  gridGap="20px"
                  align="flex-start"
                  flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                  <Flex direction="column" flex={1}>
                    <MultiLocationInput />
                  </Flex>
                  <Flex mt={2}>
                    <FormikCalendar
                      css={css}
                      fontSize={fontSize}
                      fontColor={fontColor}
                      brandColor={brandColor}
                      labelProps={labelProps}
                    />
                  </Flex>
                </Flex>

                <FormikTextArea
                  name="missionDetails"
                  label="Mission Details"
                  placeholder="Mission details here, e.g., number of pictures, type of pictures and project details"
                  labelProps={labelProps}
                  size="lg"
                  bg="#F3F3F3"
                  css={css}
                  minH="150px"
                  fontSize={fontSize}
                />
                <LiveOnsiteSection />
                <Flex
                  mb={2}
                  w="100%"
                  flex={1}
                  align="center"
                  flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                  <FormikInput
                    name="industry"
                    label="Industry"
                    placeholder="The industry here"
                    labelProps={labelProps}
                    bg="#F3F3F3"
                    size="lg"
                    css={css}
                    minW="250px"
                    fontSize={fontSize}
                  />
                  <Box w="30px" h="10px" />
                  <FormikInput
                    type="number"
                    name="budget"
                    label="Budget"
                    placeholder="The budget here"
                    labelProps={labelProps}
                    size="lg"
                    bg="#F3F3F3"
                    css={css}
                    minW="250px"
                    fontSize={fontSize}
                  />
                </Flex>
                <Center my={6} flexWrap="wrap" gridGap={3}>
                  <Button
                    w="200px"
                    variant="outline"
                    borderColor={brandColor}
                    color={brandColor}
                    onClick={onClose}
                    isDisabled={isLoading || isButtonDisabled}
                    _hover={{ bg: `${brandColor}10` }}
                    _active={{ bg: `${brandColor}30` }}>
                    CANCEL
                  </Button>
                  <Button
                    w="200px"
                    type="submit"
                    bg={brandColor}
                    color={fontColor}
                    isLoading={isLoading}
                    _hover={{ bg: `${brandColor}90` }}
                    _active={{ bg: `${brandColor}` }}
                    isDisabled={!isValid || isButtonDisabled}>
                    SEND REQUEST
                  </Button>
                </Center>
              </Flex>
            </Center>
          </Form>
        )}
      </Formik>
    );
  }

  return (
    <Center
      display={'flex'}
      flexDir={'column'}
      px={6}
      py={4}
      maxW="620px"
      color="#4f4f4f"
      borderRadius={18}
      alignSelf="center"
      bg={`${brandColor}40`}
      fontSize="16px">
      Can't find a mission that fits your needs?
      <Text
        ml={2}
        id="request-custom-mission"
        as="button"
        fontWeight="bold"
        color={brandColor}
        onClick={isPreview ? undefined : onOpen}>
        Request a custom mission.
      </Text>
    </Center>
  );
};
