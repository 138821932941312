import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/modal';

import { Image } from '@chakra-ui/image';
import { Button } from '@chakra-ui/button';
import { Center, Stack } from '@chakra-ui/layout';

import { useDisclosure } from '@chakra-ui/hooks';

import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { getCroppedImg } from '../ProfilePicker/ProfilePicker.utils';

export type CropperRef = {
  onOpen: (file: File, fileUrl: string) => void;
};

export type CropperProps = {
  aspectRatio?: number;
  onSuccess: (file: File, fileUrl: string) => void;
};

export type ImageType =
  | {
      file: File;
      fileUrl: string;
    }
  | undefined;

const initialCrop: Crop = {
  unit: 'px',
  x: 25,
  y: 25,
  width: 220,
  height: 150,
};

export const Cropper = forwardRef<CropperRef, CropperProps>((props, ref) => {
  const [image, setImage] = useState<ImageType>();
  const [crop, setCrop] = useState<Crop>(initialCrop);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const imageRef = useRef<HTMLImageElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useImperativeHandle(
    ref,
    () => ({
      onOpen: (file, fileUrl) => {
        if (!file || !fileUrl) {
          return;
        }

        setImage({ file, fileUrl });
        onOpen();
      },
    }),
    [],
  );

  const onSave = async () => {
    if (!completedCrop || !imageRef?.current) {
      return;
    }

    const { file, fileUrl } = await getCroppedImg(
      imageRef.current,
      completedCrop,
      image?.file?.name,
    );

    onClose();
    setImage(undefined);
    setCrop(initialCrop);
    setCompletedCrop(undefined);

    props.onSuccess(file, fileUrl);
  };

  const onModalClose = () => {
    onClose();
    setCrop(initialCrop);
    setImage(undefined);
    setCompletedCrop(undefined);
  };

  const onImageClick = () => {
    if (crop.x === 0 || crop.y === 0 || crop.width === 0 || crop.height === 0) {
      setCrop({
        ...initialCrop,
        x: crop.x,
        y: crop.y,
      });
    }
  };

  const onChange = (c: PixelCrop) => {
    setCrop(c);
  };

  const onComplete = (c: PixelCrop) => {
    if (c.x === 0 || c.y === 0 || c.width === 0 || c.height === 0) {
      return;
    }

    setCompletedCrop(c);
  };

  return (
    <Modal
      isCentered
      size="3xl"
      isOpen={isOpen}
      scrollBehavior="inside"
      onClose={onModalClose}
      onOverlayClick={onModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody m={2}>
          <Center border="1px dashed" borderRadius="5px" p={2}>
            <ReactCrop
              aspect={props.aspectRatio}
              crop={crop}
              // maxWidth={450}
              // maxHeight={400}
              onChange={onChange}
              onComplete={onComplete}>
              <Image
                ref={imageRef}
                src={image?.fileUrl}
                onClick={onImageClick}
              />
            </ReactCrop>
          </Center>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Stack my={4}>
            <Button
              isDisabled={!completedCrop}
              size="sm"
              w="100px"
              alignSelf="center"
              onClick={onSave}>
              SAVE
            </Button>
            <Button
              size="sm"
              w="100px"
              variant="ghost"
              alignSelf="center"
              onClick={onModalClose}>
              CANCEL
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
