import React, { memo, useEffect } from 'react';

import CouponCodeModalView from './CouponCodeModal.view';

import { useMutation } from 'react-query';
import { addCouponCode } from 'api/chargebee';

import { getIsNewUser } from 'effector/session/store';

import * as chargebee from 'effector/chargebee';
import { useHistory, useLocation } from 'react-router-dom';

import { useDisclosure, useToast } from '@chakra-ui/react';

import { CouponCodeModalProps } from './CouponCodeModal.props';
import { toggleNewUser } from 'effector/session/events';
import { error } from 'console';

const CouponCodeModalContainer = memo<CouponCodeModalProps>((props) => {
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const showCouponPopup = search.get('showCouponPopup') === 'true';
  const subscriptionId = chargebee.selectors.useCbSubId();

  const [submit, { isLoading }] = useMutation(addCouponCode, {
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Your coupon has been added successfully!',
        isClosable: true,
      });

      onClose();

      chargebee.fetchCbSubsEv();
    },
    onError: (error) => {
      toast({
        status: 'error',
        title:
          'It looks like this coupon is not valid or is no longer available. Please check the code and try again.',
        isClosable: true,
      });
    },
  });

  const { isOpen, onOpen, onClose: _onClose } = useDisclosure();

  const onClose = () => {
    _onClose();

    search.delete('showCouponPopup');

    history.replace({
      pathname: location.pathname,
      search: search.toString(),
    });
  };

  const onSubmit = (couponCode: string) => {
    if (!couponCode.trim()) {
      return;
    }

    submit({
      subscriptionId,
      couponCode,
    });
  };

  useEffect(() => {
    if (subscriptionId && showCouponPopup) {
      onOpen();
    }
  }, [subscriptionId, showCouponPopup]);

  useEffect(() => {
    const isNewUser = getIsNewUser();

    if (isNewUser) {
      search.set('showCouponPopup', 'true');

      setTimeout(() => {
        history.replace({
          pathname: location.pathname,
          search: search.toString(),
        });

        toggleNewUser(false);
      }, 600);
    }
  }, []);

  return (
    <CouponCodeModalView
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
});

export default CouponCodeModalContainer;
