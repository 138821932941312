import { Flex, Box, Text } from '@chakra-ui/react';
import { missionStatusMap } from 'constants/missionStatusMap';
import React, { useMemo } from 'react';
import {
  MdCheckCircle,
  MdNewReleases,
  MdInfo,
  MdChevronRight,
} from 'react-icons/md';
import { CustomMission, Mission, MissionStatuses } from 'types/mission';
import calculateDueDate from './calculateDueDate';

import { ColumnDef } from '@tanstack/react-table';
import { selectors } from 'effector/session';
import { AccountTypes } from 'types/accountTypes';
import {
  customStatusColorsMap,
  customStatusMap,
} from 'constants/assetStatusMap';

export const columnsMissionTable = (
  data: Mission[] | CustomMission[],
  type?: string,
) => {
  const accountType = selectors.useAccountType();

  const infoByAccountType = (mission: Mission) => {
    const isPilot = accountType === AccountTypes.pilot;
    const isClient = accountType === AccountTypes.client;

    const name = isClient
      ? `${mission.pilot?.firstName} ${mission.pilot?.lastName}`
      : isPilot
      ? `${mission.client?.firstName} ${mission.client?.lastName}`
      : '';

    const email = isClient
      ? mission?.pilot?.email
      : isPilot
      ? mission?.client?.email
      : '';
    return { name, email };
  };

  const customMissionLocation = (customMission: CustomMission) => {
    const address = customMission.clientDetails?.location?.address;

    const additionalLocations =
      customMission.clientDetails.additionalLocations || [];
    const isMultiLocation = Boolean(additionalLocations.length > 1);

    const location = isMultiLocation
      ? `${address.slice(0, 22) + '... , '} +${additionalLocations.length} more`
      : address || '';

    return location;
  };

  const RenderStatusCell = (mission: any) => {
    if (!type) {
      return (
        <Flex flexDir="row" justifyContent="flex-start">
          <Box flex={1}>
            <Text fontSize="md" isTruncated>
              {mission.prop.getValue() as string}
            </Text>
          </Box>
          <Flex ml={2}>
            {mission.prop.getValue().toLocaleUpperCase() ===
            MissionStatuses.COMPLETED ? (
              <MdCheckCircle size="18px" color="#34D17D" />
            ) : [
                MissionStatuses.SCHEDULED,
                MissionStatuses.ASSETS_UPLOADED,
                MissionStatuses.FLIGHT_COMPLETED,
                MissionStatuses.ORDERED,
              ].includes(mission.prop.getValue().toLocaleUpperCase()) ? (
              <MdNewReleases size="18px" color="#018DE1" />
            ) : (
              <MdInfo size="18px" color="#E9243Fed" />
            )}
          </Flex>
        </Flex>
      );
    } else {
      return (
        <Flex flexDir="row" justifyContent="flex-start">
          <Text
            mx={1}
            py="2px"
            flex={1.5}
            fontSize="14px"
            fontWeight="bold"
            textAlign="center"
            borderRadius="5px"
            bg={`${
              customStatusColorsMap[mission.prop.getValue().toLocaleUpperCase()]
            }20`}
            color={
              customStatusColorsMap[mission.prop.getValue().toLocaleUpperCase()]
            }>
            {customStatusMap[mission?.prop.getValue()]}
          </Text>
        </Flex>
      );
    }
  };

  const columns: ColumnDef<Mission>[] = useMemo(
    () => [
      {
        accessorFn: type
          ? (customMission: any) => {
              return customMission.status;
            }
          : (mission: any) => {
              return missionStatusMap[mission?.status || 'DEFAULT'];
            },
        cell: (mission: any) => <RenderStatusCell prop={mission} />,
        header: 'Status',
        id: 'status',
      },
      {
        accessorFn: (mission: any) => calculateDueDate(mission?.dueDate),
        header: 'Due Date',
      },
      {
        accessorFn: type
          ? (customMission: CustomMission) =>
              customMission.clientDetails?.name || '-'
          : (mission: any) => infoByAccountType(mission)?.name,
        header: 'Client Name',
      },
      {
        accessorFn: type
          ? (customMission: CustomMission) =>
              customMission?.clientDetails?.email || '-'
          : (mission: any) => infoByAccountType(mission)?.email,
        header: 'Client Email',
      },
      {
        accessorFn: type
          ? (customMission: CustomMission) =>
              customMissionLocation(customMission)
          : (mission: Mission) => mission?.location,
        header: 'Location',
      },
      {
        accessorFn: type
          ? (customMission: CustomMission) =>
              customMission?.clientDetails?.industry || '-'
          : (mission: Mission) => mission?.missionIndustry,
        header: 'Industry/Service',
      },
      {
        accessorFn: (mission: Mission) => mission?.id,
        header: 'Mission ID',
        id: 'missionId',
      },
    ],
    [],
  );

  return columns;
};
