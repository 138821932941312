import React, { forwardRef, memo, useImperativeHandle } from 'react';

import {
  Modal,
  Button,
  useToast,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton,
} from '@chakra-ui/react';

import { useMutation, useQueryCache } from 'react-query';

import * as session from 'effector/session';
import * as chargebee from 'effector/chargebee';

import { toggleProEvent } from 'effector/session/events';

import { subscribeToPro } from 'api/auth';
import { reactivateSubscription } from 'api/chargebee';

import { Props, RefType } from './ReactivateProModal.props';

const ReactivateProModal = forwardRef<RefType, Props>((_, ref) => {
  const toast = useToast();
  const queryCache = useQueryCache();

  const uid = session.selectors.useUserId() || '';

  const cbSubId = chargebee.selectors.useCbSubId();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updateUser] = useMutation(() => subscribeToPro(uid), {
    onSuccess: () => {
      queryCache.invalidateQueries(`fetch-user-${uid}`, { exact: true });

      toggleProEvent(true);
    },
  });

  const [reactivate, { isLoading }] = useMutation(reactivateSubscription, {
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Subscription Reactivated',
        duration: 5000,
        isClosable: true,
      });

      updateUser();

      chargebee.fetchCbSubsEv();
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      onOpen,
    }),
    [],
  );

  const onReactivate = () => {
    reactivate(cbSubId);
  };

  return (
    <Modal size="xl" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="500" fontFamily="Monument Extended">
          Reactivate your subscription
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize="18px" textAlign="center">
          Your premium subscription already exist. you can reactivate your
          subscription
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button variant="outline" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button mr={1} onClick={onReactivate} isLoading={isLoading}>
            Reactivate
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

const Memoized = memo(ReactivateProModal);

export default Memoized;
