import { MissionStatuses } from 'types/mission';

export const missionStatusMap: { [key: string]: string } = {
  [MissionStatuses.SCHEDULED]: 'Scheduled',
  [MissionStatuses.PROPOSAL]: 'Proposal',
  [MissionStatuses.ORDERED]: 'Ordered',
  [MissionStatuses.FOR_PAYMENT]: 'For Payment',
  [MissionStatuses.FLIGHT_COMPLETED]: 'Flight Completed',
  [MissionStatuses.COMPLETED]: 'Completed',
  [MissionStatuses.ASSETS_UPLOADED]: 'Assets Uploaded',
  [MissionStatuses.CANCELLED]: 'Cancelled',
  [MissionStatuses.DECLINED]: 'Declined',
  DEFAULT: '',
};
