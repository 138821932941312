import React, { useEffect } from 'react';

import * as stripe from 'effector/stripe';

import { useHistory } from 'react-router-dom';

import { useMutation } from 'react-query';
import { createConnectAccount, updateConnectedAcc } from 'api/stripe';

import { getStripeId } from 'effector/session/store';
import { updateUserEvent } from 'effector/session/events';

import { StripeBannerProps } from './StripeBanner.props';

import StripeBannerView from './StripeBanner.view';

const PATH = '/pilot/dashboard?redirect=true';

const StripeBannerContainer = (props: StripeBannerProps) => {
  const history = useHistory();

  const isEnabled = stripe.selectors.useIsEnabled();
  const isFetching = stripe.selectors.useIsPending();
  const isConnected = stripe.selectors.useIsConnected();

  const [handleConnect, { isLoading }] = useMutation(
    async () => {
      let stripeId = getStripeId();

      if (!stripeId) {
        const stripeAccount = await createConnectAccount();

        stripeId = stripeAccount.id;

        updateUserEvent({ stripeId } as any);
      }

      return updateConnectedAcc(PATH, stripeId);
    },
    {
      onSuccess: (stripeUrl: string) => {
        window.open(stripeUrl, '_self');
      },
    },
  );

  useEffect(() => {
    const onStripeRedirect = async () => {
      history.replace({ search: '' });
    };

    const search = new URLSearchParams(history.location?.search);
    const redirect = search.get('redirect');

    if (redirect !== 'true') {
      return;
    }

    onStripeRedirect();
  }, []);

  return (
    <StripeBannerView
      isEnabled={isEnabled}
      isLoading={isLoading}
      isFetching={isFetching}
      isConnected={isConnected}
      handleConnect={handleConnect}
    />
  );
};

export default StripeBannerContainer;
