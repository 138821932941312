import React, { FC, useEffect } from 'react';

import {
  Text,
  Flex,
  Modal,
  Button,
  Center,
  useToast,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { MdErrorOutline } from 'react-icons/md';

import { useResendEmail } from 'utils/hooks';
import { isMobile } from 'react-device-detect';

const ResendEmailModal: FC = () => {
  const toast = useToast();

  const { loading, isSuccess, sendActivationEmail } = useResendEmail();

  const onSuccess = () => {
    toast({
      duration: 2000,
      title: 'Success',
      isClosable: true,
      status: 'success',
      description: 'Email sent successfully',
    });
  };

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess]);

  const responsiveStyles = {
    maxW: isMobile ? '18.5rem' : '48.938rem',
    maxH: isMobile ? '23rem' : '33.563rem',
    px: isMobile ? '3.063rem' : '10.688rem',
    py: isMobile ? '3.5rem' : '2.125rem',
    borderRadius: '8.84px',
  };

  return (
    <Modal
      size="xl"
      isCentered
      isOpen={true}
      onClose={() => null}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent borderRadius="34px" sx={responsiveStyles}>
        <Flex
          flex={1}
          display={'flex'}
          flexDirection="column"
          alignContent={'center'}
          justifyContent={'center'}>
          <Center flexDirection={'column'}>
            <Center>
              <MdErrorOutline size={'5rem'} color={'#DBA825'} />
            </Center>
            <Text
              fontFamily={'Montserrat'}
              fontSize={'1rem'}
              fontWeight={'semibold'}
              textAlign={'center'}
              py={'0.875rem'}>
              To continue you must confirm your email
            </Text>
            <Text
              fontSize={'15px'}
              fontFamily={'Roboto'}
              fontWeight={'600px'}
              pb={'2rem'}>
              Haven’t recived the email?{' '}
            </Text>

            <Button
              w="13rem"
              marginBottom={'2rem'}
              className="popup-email-button"
              onClick={sendActivationEmail}
              fontFamily={'cairo, sans-serif'}
              fontSize={'12px'}
              isLoading={loading}>
              RESEND MAIL
            </Button>
          </Center>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default ResendEmailModal;
