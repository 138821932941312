import React, { FC, useRef } from 'react';

import { Button, Flex, Heading, Stack } from '@chakra-ui/react';

import { BiMedal } from 'react-icons/bi';

import { useHistory } from 'react-router-dom';

import { routesSettings } from 'constants/routes';

import * as session from 'effector/session';
import * as chargebee from 'effector/chargebee';

import { ReactivateProModal } from '../ReactivateProModal';

import { SubscribeToProProps } from './SubscribeToPro.props';

const SubscribeToProView: FC<SubscribeToProProps> = (props) => {
  const history = useHistory();
  const modalRef = useRef<any>(null);

  const isFetching = session.selectors.useFetching();

  const cbSub = chargebee.selectors.useCbSub();
  const isCbFetching = chargebee.selectors.useIsCbFetching();

  return (
    <Flex
      p="20px"
      bg="#DDCEF250"
      my="20px"
      borderRadius="10px"
      direction={['column', 'row']}
      color="#4f4f4f"
      w="100%"
      maxW={['auto', '700px']}
      justify="space-between"
      alignSelf="center"
      alignItems={['flex-start', 'center']}
      fontWeight="bold">
      <Stack>
        <Heading fontFamily="Monument Extended" fontSize="18px">
          You are currently on our Free Version,
        </Heading>
        <Heading fontFamily="Monument Extended" fontSize="18px">
          Click the "Get Pro" button now to Upgrade!
        </Heading>
      </Stack>
      {!isCbFetching && (
        <Button
          size="sm"
          w="150px"
          borderRadius="20px"
          mt={[5, 0]}
          leftIcon={<BiMedal size="18px" />}
          fontSize="12px"
          fontWeight="bold"
          isLoading={isFetching}
          onClick={() => {
            if (
              ['cancelled', 'non_renewing', 'paused'].includes(
                cbSub?.subscription?.status || '',
              )
            ) {
              modalRef?.current?.onOpen?.();
            } else {
              history.push(routesSettings.PILOT_WELCOME_PAGE.path);
            }
          }}>
          GET PRO
        </Button>
      )}
      <ReactivateProModal ref={modalRef} />
    </Flex>
  );
};

export default SubscribeToProView;
