import React, { useRef } from 'react';
import { useMutation } from 'react-query';
import authApi from 'api/auth';
import { useToast } from '@chakra-ui/react';

import { ForgotPasswordProps } from './ForgotPassword.props';
import ForgotPasswordView from './ForgotPassword.view';
import { useHistory } from 'react-router-dom';
import { cms } from 'routes/premiumWebsite/PremiumMissionCreate/cmsStore';

const ForgotPasswordContainer = (props: ForgotPasswordProps): JSX.Element => {
  const toast = useToast();
  const history = useHistory();
  const emailRef = useRef('');

  const isCmsPath = cms.useCms();

  const [forgotPassword, { isLoading, isSuccess }] = useMutation(
    authApi.forgotPassword,
    {
      onMutate: ({ email }) => {
        if (Boolean(isCmsPath)) {
          cms.saveEmail(email);
        }

        emailRef.current = email;
      },
    },
  );

  const onBackClick = () => {
    history.goBack();
  };

  return (
    <ForgotPasswordView
      isLoading={isLoading}
      isSuccess={isSuccess}
      onSubmit={forgotPassword}
      onBackClick={onBackClick}
      email={emailRef.current}
    />
  );
};

export default ForgotPasswordContainer;
