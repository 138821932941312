import React, { FC, memo } from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { cancelMissionRequest } from 'api/mission';
import { useMutation, useQueryCache } from 'react-query';

import { MissionStatuses } from 'types/mission';
import { HandleCancelMissionStatusModalProps } from './HandleCancelMissionStatusModal.props';

import HandleCancelMissionStatusModalView from './HandleCancelMissionStatusModal.view';

const Container: FC<HandleCancelMissionStatusModalProps> = (props) => {
  const queryCache = useQueryCache();

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [submit, { isSuccess, isLoading }] = useMutation(cancelMissionRequest, {
    onSuccess: () => {
      queryCache.invalidateQueries(`fetch-mission-${props.mission.id}`);
      queryCache.invalidateQueries(`fetch-missions-undefined-CLIENT`);
      queryCache.invalidateQueries(`fetch-missions-undefined-PILOT`);
      queryCache.invalidateQueries(
        `fetch-missions-${MissionStatuses.COMPLETED}-PILOT`,
      );
      queryCache.invalidateQueries(`fetch-client-missions`);
    },
  });

  const handleSubmit = (status: 'ACCEPTED' | 'REJECTED') => {
    submit({
      missionId: props.mission.id,
      status,
    });
  };

  if (!props.mission?.cancellation) {
    return null;
  }

  return (
    <HandleCancelMissionStatusModalView
      isOpen={isOpen}
      onClose={onClose}
      isSuccess={isSuccess}
      isLoading={isLoading}
      mission={props.mission}
      handleSubmit={handleSubmit}
      cancellation={props.mission.cancellation}
    />
  );
};

const HandleCancelMissionStatusModal = memo(Container);

export default HandleCancelMissionStatusModal;
