import { chakra } from '@chakra-ui/system';

export const Container = chakra('div', {
  baseStyle: {
    mb: 4,
    pl: [5, 10],
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    position: 'relative',
  },
});

export const scrollBarStyles = {
  '&::-webkit-scrollbar': {
    height: '10px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#F6EEFF',
    borderRadius: '20px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#6728BB',
    borderRadius: '24px',
    width: '20px',
  },
};

export const Row = chakra('div', {
  baseStyle: {
    mt: 4,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
});
