import React, { memo } from 'react';

import { Props } from './TestimonialInput.props';
import TestimonialInputView from './TestimonialInput.view';

const Container = memo<Props>((props) => {
  return <TestimonialInputView {...props} />;
});

export default Container;
