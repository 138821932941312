import React, { FC } from 'react';

import {
  Box,
  Flex,
  Button,
  Center,
  Heading,
  FormLabel,
  FormControl,
  useToast,
} from '@chakra-ui/react';
import { MdArrowBack } from 'react-icons/md';

import { animationConfig, MotionFlex } from './Motion';

import { industriesMap } from 'constants/industriesMap';

import { createPackageItem, updateCMS } from 'api/cms';
import { useMutation, useQueryCache } from 'react-query';

import { Form, Formik } from 'formik';

import Multiselect from 'multiselect-react-dropdown';

import FormikInput from 'components/primitives/FormikInput';
import FormikTextArea from 'components/primitives/FormikTextArea';

import { AddItem } from 'components/modules/CreatePackageModal/CreatePackageModal.view';

import { Package } from 'types/cms';
import { CommonProps } from '../OnboardingModal.props';

const PackageForm: FC<CommonProps> = ({ cms, setIndex }) => {
  const pilotId = cms?.pilot?.id || '';
  const industries = cms?.industries || [];
  const queryKey = `fetch-cms-company-${cms?.username}`;

  const formattedIndustries = industries.map((i) =>
    industriesMap[i] ? industriesMap[i] : i,
  );

  const options = formattedIndustries.map((i) => ({ value: i }));

  const toast = useToast();
  const queryCache = useQueryCache();

  const [createPackage, { isLoading }] = useMutation(
    async (payload: Package) => {
      await createPackageItem(payload);

      await updateCMS({
        companyName: cms?.about?.companyName,
        payload: {
          status: 'PUBLISH',
          pilot: typeof cms?.pilot === 'string' ? cms?.pilot : cms?.pilot?.id,
          location: cms?.location,
          gps: {
            coordinates: cms?.gps?.coordinates,
          },
        },
      });
    },
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Package Created',
          isClosable: true,
          duration: 5000,
        });

        queryCache.refetchQueries(queryKey);
        queryCache.invalidateQueries(queryKey);
        setIndex(4);
      },
    },
  );

  return (
    <MotionFlex {...animationConfig} flex={1}>
      <Box
        as="button"
        position="absolute"
        left="20px"
        top="20px"
        zIndex={5}
        onClick={() => setIndex(4)}>
        <MdArrowBack size="20px" />
      </Box>
      <Center w="100%" position="absolute" top="15px" left="0">
        <Heading textAlign="center" fontSize="22px">
          Package Creation
        </Heading>
      </Center>

      <Formik<Partial<Package>>
        enableReinitialize
        initialValues={{
          name: '',
          industry: [],
          price: 0,
          description: '',
          lineItems: [],
        }}
        onSubmit={(values) => {
          createPackage({
            ...values,
            pilot: pilotId,
          } as Package);
        }}>
        {({ isValid, values, setFieldValue }) => {
          const selectedIndustries = values?.industry?.map((i) => ({
            value: i,
          }));

          return (
            <Form>
              <Flex direction="column" gridGap="20px" px="25%">
                <FormikInput name="name" label="Name" isRequired />

                <Flex flex={1} align="flex-start" gridGap="20px">
                  <FormControl isRequired mt="3">
                    <FormLabel fontSize="14px" fontWeight="bold">
                      Industry
                    </FormLabel>
                    <Multiselect
                      options={options}
                      selectedValues={selectedIndustries}
                      displayValue="value"
                      placeholder="Select Industry/Service"
                      showCheckbox
                      showArrow
                      onSelect={(_, selected) => {
                        setFieldValue('industry', [
                          ...(values?.industry || []),
                          selected.value,
                        ]);
                      }}
                      onRemove={(_, removed) => {
                        setFieldValue(
                          'industry',
                          values?.industry?.filter((i) => i !== removed.value),
                        );
                      }}
                      style={{
                        searchBox: {
                          overflow: 'auto',
                          background: '#FAFAFA',
                        },
                        chips: {
                          background: '#6728BB',
                        },
                      }}
                    />
                  </FormControl>

                  <FormikInput
                    type="number"
                    name="price"
                    label="Price"
                    isRequired
                  />
                </Flex>

                <FormikTextArea
                  name="description"
                  label="Description"
                  isRequired
                  labelProps={{ fontWeight: 'bold' }}
                />

                <Center
                  mt="20px"
                  minH="150px"
                  flex={1}
                  gridGap="20px"
                  flexDirection="column">
                  <Heading fontSize="18px">Deliverables</Heading>
                  <AddItem color="#6728BB" />
                </Center>

                <Center gridGap="20px">
                  <Button
                    w="300px"
                    variant="outline"
                    isDisabled={isLoading}
                    onClick={() => setIndex(4)}>
                    GO BACK
                  </Button>
                  <Button
                    w="300px"
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={!isValid}>
                    SAVE PACKAGE
                  </Button>
                </Center>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </MotionFlex>
  );
};

export default PackageForm;
