import React from 'react';

import { useMutation } from 'react-query';
import { updateConnectedAcc } from 'api/stripe';

import { selectors } from 'effector/session';

import { StripeConnectProps } from './StripeConnect.props';

import StripeConnectView from './StripeConnect.view';

const StripeConnectContainer = (_: StripeConnectProps) => {
  const user = selectors.useUser();

  const fname = user?.firstName || '';

  const [createLink, { isLoading }] = useMutation(
    () => updateConnectedAcc('/stripe-connect/success'),
    {
      onSuccess: (stripeUrl) => {
        window.open(stripeUrl, '_self');
      },
    },
  );

  const _props = {
    fname,
    isLoading,
    onCreate: createLink,
  };

  return <StripeConnectView {..._props} />;
};

export default StripeConnectContainer;
