import React, { FC } from 'react';

import {
  Box,
  Center,
  CircularProgress,
  Flex,
  Spinner,
  Text,
} from '@chakra-ui/react';

import { MdCameraAlt } from 'react-icons/md';

import TabWithHeaderView from 'components/modules/TabWithHeader';
import MissionAccordion from '../MissionAccordion';
import Pagination from 'components/primitives/Pagination';

import { UberMissionRequestsGeneratedProps } from './UberMissionRequests.props';

const tabPaddingX = [1, 1, 5];
const tabPaddingY = [1, 1, 2];

const UberMissionRequestsView: FC<UberMissionRequestsGeneratedProps> = ({
  page,
  setPage,
  missions,
  isLoading,
  totalPages,
  isMissions,
}) => {
  return (
    <Flex direction="column" flex={1} color="#4f4f4f" mt="30px" mb="100px">
      <TabWithHeaderView
        icon={<MdCameraAlt size="18px" color="secondaryColor" />}
        highlightedTitle="AVAILABLE MISSIONS"
        unhighlightedTitle="IN YOUR AREA">
        <Flex flexDirection="column" flexGrow={1}>
          {isLoading ? (
            <Center p={4}>
              <CircularProgress color="brand.500" isIndeterminate />
            </Center>
          ) : missions?.length ? (
            <>
              <Flex fontSize="14px" flex={1} fontWeight="600">
                <Text px={tabPaddingX} py={tabPaddingY} flex={0.8}>
                  ID
                </Text>
                <Text px={tabPaddingX} py={tabPaddingY} flex={2.5}>
                  Location
                </Text>
                <Text px={tabPaddingX} py={tabPaddingY} flex={2.5}>
                  Due Date
                </Text>
                <Text px={tabPaddingX} py={tabPaddingY} flex={1.5}>
                  Pack
                </Text>
                <Text px={tabPaddingX} py={tabPaddingY} flex={1}>
                  Total Pay
                </Text>
                <Box w="20px" h="10px" mr={2} />
              </Flex>
              <Flex flex={1} direction="column">
                {missions.map((mission, i) => (
                  <MissionAccordion
                    mission={mission}
                    key={`${mission.id}-${i}`}
                    callbackFunction={() => {
                      window.location.reload();
                    }}
                  />
                ))}
              </Flex>
              {isMissions && totalPages !== 1 && page <= totalPages ? (
                <Flex justify="flex-end" color="grey" mt={4} ml={2}>
                  <Pagination
                    currentPage={page}
                    totalPages={totalPages}
                    onNextClick={() => setPage(page + 1)}
                    onPreviousClick={() => setPage(page - 1)}
                    onPageNumberClick={(pageNo) => setPage(pageNo)}
                  />
                </Flex>
              ) : null}
            </>
          ) : (
            <Text p={{ base: 10, md: 20 }} textAlign="center" fontWeight="bold">
              There are no missions available in your area
            </Text>
          )}
        </Flex>
      </TabWithHeaderView>
    </Flex>
  );
};

export default UberMissionRequestsView;
