import {
  isMobile,
  isDesktop,
  isAndroid,
  isIOS,
  isMacOs,
  isWindows,
} from 'react-device-detect';

type Destination = { lat: number; lng: number };

/**
 *this function checks the user OS and accordingly opens the users native Map app
 *
 * @param destination : { lat:number; lng:number; }
 * @return void
 */

export const goToMaps = (destination: Destination): void => {
  const query = `${destination.lat},${destination.lng}`;
  const appleLink = `https://maps.apple.com/maps?q=:${query}`;
  const androidOrWindowsLink = `https://maps.google.com?q=${query}`;

  if (isMobile) {
    if (isAndroid) {
      window.open(androidOrWindowsLink);
    } else if (isIOS) {
      window.open(appleLink);
    }
  } else if (isDesktop) {
    if (isWindows) {
      window.open(androidOrWindowsLink);
    } else if (isMacOs) {
      window.open(appleLink);
    }
  } else {
    window.open(androidOrWindowsLink);
  }
};
