import React, { FC, useState } from 'react';

import {
  Box,
  Text,
  Flex,
  Center,
  Spinner,
  IconButton,
  CircularProgress,
} from '@chakra-ui/react';
import { MdMoreVert } from 'react-icons/md';
import { format } from 'date-fns';

import { useIsMobile, useSearchParamState } from 'utils/hooks';

import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getCustomMissions } from 'api/custom-mission';

import {
  customStatusMap,
  customStatusColorsMap,
} from 'constants/assetStatusMap';

import calculateDueDate from 'utils/calculateDueDate';

import Pagination from 'components/primitives/Pagination';

import { CustomStatus } from 'types/mission';
import ResizableTableView from '../ResizableTable';
import { columnsMissionTable } from 'utils/columnsMissionTable';
import ResizableTable from '../ResizableTable';
import { EmptyMissionsPage } from '../EmptyMissionsPage';

const MISSIONS_PER_PAGE = 5;

type Props = { isClient: boolean };

export const SentRequests: FC<Props> = ({ isClient }) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const [page, setPage] = useState<number>(1);
  const [missionStatus] = useSearchParamState('status');

  const queryKey = [
    `fetch-custom-missions-open`,
    isClient,
    page,
    missionStatus,
  ];
  const queryFn = () =>
    getCustomMissions({
      page,
      isClient,
      status: isClient ? 'COMPLETED' : undefined,
      limit: MISSIONS_PER_PAGE,
      statusFilter: !missionStatus ? undefined : missionStatus,
    });

  const { data, isFetching } = useQuery(queryKey, queryFn);

  const missions = data?.results || [];
  const totalPages = data?.totalPages || 0;
  const isMissions = missions && missions?.length > 0;
  const type = 'CUSTOM';
  const tableColumns = columnsMissionTable(missions, type);

  const onMissionClick = (customMissionId: string) => {
    history.push(`/custom-mission/${customMissionId}`);
  };

  if (isMobile) {
    return (
      <Flex direction="column">
        {isFetching ? (
          <Center minH="200px" flex={1}>
            <Spinner />
          </Center>
        ) : missions.length > 0 ? (
          <Flex direction="column">
            <Flex minH="400px" direction="column">
              {missions?.map((mission) => {
                const address = mission.clientDetails?.location?.address;

                const additionalLocations =
                  mission.clientDetails.additionalLocations || [];
                const isMultiLocation = Boolean(additionalLocations.length > 1);

                return (
                  <Flex
                    gridGap="10px"
                    key={mission.id}
                    p="10px 20px"
                    borderBottom="1px solid #ccc"
                    direction="column"
                    onClick={() => {
                      history.push(`/custom-mission/${mission.id}`);
                    }}>
                    <Flex justify="space-between" align="flex-end">
                      <Box>
                        <Text fontWeight="600" maxW="100%" noOfLines={1}>
                          {mission?.clientDetails?.name}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontSize="12px" noOfLines={1}>
                          {format(new Date(mission?.dueDate), 'dd MMM')}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex justify="space-between" align="flex-end">
                      <Box>
                        <Text fontSize="12px" maxW="90%" noOfLines={1}>
                          {isMultiLocation
                            ? `${address.slice(0, 10) + '...'} +${
                                additionalLocations.length
                              } more`
                            : address || ''}
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontSize="12px"
                          fontWeight="bold"
                          noOfLines={1}
                          textTransform="capitalize"
                          color={customStatusColorsMap[mission.status]}>
                          {mission.status === CustomStatus.PILOT_ACCEPTED
                            ? 'Open'
                            : mission.status?.toLocaleLowerCase()}
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
            {isMissions && totalPages !== 1 && page <= totalPages ? (
              <Flex mt={6} justify="flex-end">
                <Pagination
                  totalPages={totalPages}
                  currentPage={page}
                  onPreviousClick={() => setPage(page - 1)}
                  onNextClick={() => setPage(page + 1)}
                  onPageNumberClick={(pageNo) => setPage(pageNo)}
                />
              </Flex>
            ) : null}
          </Flex>
        ) : (
          <Text w="100%" m="30px">
            No missions available
          </Text>
        )}
      </Flex>
    );
  }

  return (
    <Flex direction="column">
      <Flex
        direction="column"
        maxWidth={isMissions ? '80vw' : undefined}
        overflow="auto">
        {isFetching ? (
          <Center p={20}>
            <CircularProgress color="brand.500" isIndeterminate />
          </Center>
        ) : isMissions ? (
          <ResizableTable
            data={missions}
            columns={tableColumns}
            onRowClick={onMissionClick}
          />
        ) : (
          <EmptyMissionsPage />
        )}
        {isMissions && totalPages !== 1 && page <= totalPages ? (
          <Flex mt={6} justify="flex-end">
            <Pagination
              totalPages={totalPages}
              currentPage={page}
              onPreviousClick={() => setPage(1)}
              onNextClick={() => setPage(1)}
              onPageNumberClick={(pageNo) => setPage(pageNo)}
            />
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
};
