/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';

import { EditWebsiteProps } from './EditWebsite.props';
import EditWebsiteView from './EditWebsite.view';
import { getMyCMS, updateCMS } from 'api/cms';
import { useToast } from '@chakra-ui/toast';

const EditWebsiteContainer = (props: EditWebsiteProps): JSX.Element => {
  const toast = useToast();
  const queryCache = useQueryCache();

  const { data, isLoading } = useQuery(`fetch-my-cms`, getMyCMS);

  const [update] = useMutation(updateCMS, {
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Data Updated Succesfully',
        isClosable: true,
        duration: 5000,
      });

      queryCache.invalidateQueries(`fetch-my-cms`, {
        exact: true,
      });

      props.history.goBack();
    },
  });

  return (
    <EditWebsiteView
      initialData={data}
      isLoading={isLoading}
      onSubmit={async (values) => {
        //await update({ values });
      }}
    />
  );
};

export default EditWebsiteContainer;
