import React from 'react';

import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { ReactQueryConfigProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes';
import theme from './theme';
import { reactQueryConfig } from 'utils/reactQueryConfig';
import { AlertHandler } from 'components/primitives/AlertHandler';
import SiteBlocker from 'components/modules/SiteBlocker';
import { GoogleOAuthProvider } from '@react-oauth/google';

const IS_DEV = process.env.REACT_APP_ENVIRONMENT === 'dev';
const API_KEY = `${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`;

function App(): JSX.Element {
  return (
    <GoogleOAuthProvider clientId={API_KEY}>
      <ReactQueryConfigProvider config={reactQueryConfig}>
        <ChakraProvider theme={theme}>
          <CSSReset />
          <BrowserRouter>
            <Routes />
            <AlertHandler />
            {IS_DEV && <SiteBlocker />}
          </BrowserRouter>
        </ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </ReactQueryConfigProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
