import React, { FC, useEffect } from 'react';

import { IoMdTime } from 'react-icons/io';

import { Button, Checkbox, Input } from '@chakra-ui/react';
import { Flex, Stack, Text } from '@chakra-ui/layout';

import { useTimeoutId } from 'utils/hooks';
import { useFormikContext } from 'formik';

import { getHours, getMinutes } from 'date-fns';

import { CMS, Days } from 'types/cms';

const DAYS = Object.keys(Days);

const labelProps = {
  fontSize: '16px',
  color: '#707070',
  fontWeight: 'bold',
};

function convertTo24HourFormat(
  hours: number | string,
  minutes: number | string,
  meridiem: string,
): string {
  if (typeof hours === 'string') {
    hours = parseInt(hours);
  }

  if (typeof minutes === 'string') {
    minutes = parseInt(minutes);
  }

  meridiem = meridiem.toUpperCase(); // Convert meridiem to uppercase for case-insensitive comparison

  if (meridiem === 'PM' && hours !== 12) {
    hours += 12;
  } else if (meridiem === 'AM' && hours === 12) {
    hours = 0; // Handle 12 AM case
  }

  // Pad hours and minutes with leading zero if needed
  const paddedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const paddedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  return `${paddedHours}:${paddedMinutes}`;
}

export const DaysSection: FC<{
  onSave?: () => void;
  hideUpdateButton: boolean;
}> = ({ onSave, hideUpdateButton }) => {
  const timeoutIdRef = useTimeoutId();

  const { values, initialValues, setFieldValue } = useFormikContext<CMS>();

  const dayAvailability = values?.about?.dayAvailability || [];
  const days = dayAvailability?.map((d) => d.day) || [];

  const alwaysAvailable = values?.about?.alwaysAvailable;

  initialValues.about.alwaysAvailable =
    (alwaysAvailable === undefined && days.length === 0) ||
    (alwaysAvailable === true && days.length === 0) ||
    (alwaysAvailable === false && days.length === 0);

  const color = values?.stylings?.colorScheme;

  const end = dayAvailability?.[0]?.end;
  const start = dayAvailability?.[0]?.start;

  const endTime = convertTo24HourFormat(
    end?.hour || 0,
    end?.minutes || 0,
    end?.meridiem || 'AM',
  );

  const startTime = convertTo24HourFormat(
    start?.hour || 0,
    start?.minutes || 0,
    start?.meridiem || 'AM',
  );
  const checkIsDisabled = () => {
    const currentDaysValue = values?.about?.dayAvailability || [];
    const initialDaysValue = initialValues?.about?.dayAvailability || [];

    if (currentDaysValue?.length === 0 && initialDaysValue?.length === 0) {
      return true;
    }

    if (currentDaysValue?.length !== initialDaysValue?.length) {
      if (!currentDaysValue?.[0]?.end || !currentDaysValue?.[0]?.start) {
        return true;
      }

      return false;
    }

    if (
      JSON.stringify(currentDaysValue?.[0]?.start || {}) !==
      JSON.stringify(initialDaysValue?.[0]?.start || {})
    ) {
      return false;
    }

    if (
      JSON.stringify(currentDaysValue?.[0]?.end || {}) !==
      JSON.stringify(initialDaysValue?.[0]?.end || {})
    ) {
      return false;
    }

    return true;
  };

  return (
    <Stack my={6} flex={1}>
      <Flex gridGap="20px" mb="10px">
        <Text {...labelProps}>Availability</Text>
        <Checkbox
          size="md"
          sx={{
            '.chakra-checkbox__control[data-checked]': {
              background: color,
              borderColor: color,
              color: '#fff',
            },
            '.chakra-checkbox__control[data-focus]': {
              boxShadow: 'none',
            },
          }}
          fontWeight="500"
          colorScheme="brand"
          borderRadius="10px"
          defaultChecked={true}
          isChecked={alwaysAvailable}
          onChange={(e) => {
            setFieldValue('about.alwaysAvailable', e.target.checked);
            if (e.target.checked) {
              setFieldValue('about.dayAvailability', []);
              timeoutIdRef.current = setTimeout(() => {
                if (onSave) onSave();
              }, 500);
            }
          }}>
          Always available
        </Checkbox>
      </Flex>
      {!!alwaysAvailable ? null : (
        <>
          <Text fontSize="14px" fontWeight="500">
            Days
          </Text>
          <Flex gridGap="15px" mb="20px">
            {DAYS.map((day) => (
              <Text
                h="30px"
                w="30px"
                key={day}
                as="button"
                type="button"
                bg={days.includes(day) ? `${color}10` : '#EDEDED'}
                color={days.includes(day) ? color : '#8D8D8D'}
                fontWeight="bold"
                fontSize="14px"
                borderRadius="50px"
                onClick={() => {
                  if (days.includes(day)) {
                    setFieldValue(
                      'about.dayAvailability',
                      dayAvailability.filter((d) => d.day !== day),
                    );
                  } else {
                    setFieldValue('about.dayAvailability', [
                      ...dayAvailability,
                      {
                        day,
                        end: {
                          hour: 12,
                          meridiem: 'AM',
                          minutes: 12,
                        },
                        start: {
                          hour: 12,
                          meridiem: 'AM',
                          minutes: 12,
                        },
                      },
                    ]);
                  }
                }}>
                {day[0]}
              </Text>
            ))}
          </Flex>

          <Text fontSize="14px" fontWeight="500">
            Time
          </Text>
          <Flex gridGap="20px" align="center" mb="20px">
            <IoMdTime size="34px" color={color} />
            <Text fontSize="14px" fontWeight="500">
              From
            </Text>
            <Input
              type="time"
              w="160px"
              value={startTime}
              onChange={(e) => {
                const date = e.target.value;

                if (!date) {
                  return;
                }

                const hour = parseInt(date.slice(0, 2));
                const minutes = parseInt(date.slice(3, 5));

                const meridiem = hour >= 12 ? 'PM' : 'AM';
                const twelveHourFormat =
                  hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;

                setFieldValue('about.dayAvailability', [
                  ...dayAvailability.map((d) => ({
                    ...d,
                    start: {
                      hour: twelveHourFormat,
                      minutes,
                      meridiem,
                    },
                  })),
                ]);
              }}
            />
            <Text fontSize="14px" fontWeight="500">
              To
            </Text>
            <Input
              type="time"
              w="160px"
              value={endTime}
              onChange={(e) => {
                const date = e.target.value;

                if (date === null) {
                  return;
                }

                const hour = parseInt(date.slice(0, 2));
                const minutes = parseInt(date.slice(3, 5));

                const meridiem = hour >= 12 ? 'PM' : 'AM';
                const twelveHourFormat =
                  hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;

                setFieldValue('about.dayAvailability', [
                  ...dayAvailability.map((d) => ({
                    ...d,
                    end: {
                      hour: twelveHourFormat,
                      minutes,
                      meridiem,
                    },
                  })),
                ]);
              }}
            />
          </Flex>

          <Button
            w="200px"
            isDisabled={checkIsDisabled()}
            hidden={hideUpdateButton}
            onClick={() => {
              if (onSave) onSave();
            }}>
            Update availability
          </Button>
        </>
      )}
    </Stack>
  );
};
