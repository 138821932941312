import React, { FC } from 'react';

import { Flex } from '@chakra-ui/layout';

import { useCMS } from './Context';

import { AeroView } from './AeroView';
import { SkyRiseView } from './SkyRiseView';

export const MyWork: FC = () => {
  const { cms } = useCMS();

  const templateType = cms?.templateType;

  return (
    <Flex
      id="Work"
      mb="50px"
      direction="column"
      overflow="hidden"
      position="relative">
      {templateType === 0 ? <SkyRiseView /> : <AeroView />}
    </Flex>
  );
};
