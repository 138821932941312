import React from 'react';
import { Button, Flex, IconButton, Stack, Text } from '@chakra-ui/react';

import * as session from 'effector/session';

import { BackButtonWithTitleProps } from './BackButtonWithTitle.props';
import { MdChevronLeft, MdNotifications } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import PilotHeader from 'components/modules/PilotHeader';

const BackButtonWithTitleView = (
  props: BackButtonWithTitleProps,
): JSX.Element => {
  const { goBack } = useHistory();
  const firstname = session.selectors.useUser()?.firstName || '';
  return (
    <Flex flexDirection="column">
      <Stack
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 20,
        }}>
        <Text
          fontFamily="Monument Extended"
          fontSize="24px"
          fontWeight="400"
          my={4}>
          {props.title}
        </Text>
        <Stack
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 20,
          }}>
          <IconButton
            mb="2px"
            mr={3}
            size="xs"
            color="primaryColor"
            variant="ghost"
            aria-label="close-tabs"
            icon={<MdNotifications size="4vh" />}
            onClick={() => console.log('notifications')}
          />
          <PilotHeader name={firstname} />
        </Stack>
      </Stack>
      <Button
        onClick={goBack}
        my={4}
        maxWidth="136px"
        size="sm"
        variant="outline"
        leftIcon={<MdChevronLeft size={20} />}>
        BACK
      </Button>
    </Flex>
  );
};

export default BackButtonWithTitleView;
