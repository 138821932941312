import { format } from 'date-fns';
import * as Yup from 'yup';
import { FormType, State } from './PremiumMissionCreate.props';

export const ValidationSchema = Yup.object<FormType>().shape({
  email: Yup.string().email('please enter a valid email').nullable(true),
  firstName: Yup.string(),
  lastName: Yup.string(),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref('email'), undefined], 'email should match')
    .nullable(true),
  eventStart: Yup.date().required('event start date is required'),
  dueDate: Yup.date().required('due date is required'),
  gps: Yup.object<FormType['gps']>()
    .shape({
      coordinates: Yup.array(Yup.number()),
    })
    .required('gps coordinates are required'),
  liveEvent: Yup.boolean(),
  location: Yup.string().required('location is required'),
  missionIndustry: Yup.string(),
  notes: Yup.string(),
  onsite: Yup.boolean(),
  package: Yup.string().required('package is required'),
  restricted: Yup.boolean(),
  rush: Yup.boolean(),
  contactName: Yup.string().when('onsite', {
    is: true,
    then: Yup.string().required('Contact Name is Required'),
  }),
  contactPhoneNumber: Yup.string().when('onsite', {
    is: true,
    then: Yup.string().required('Contact Phone Number is Required'),
  }),
  contactEmail: Yup.string().when('onsite', {
    is: true,
    then: Yup.string().email().required('Contact Email is Required'),
  }),
  acknowledgeWeather: Yup.boolean().oneOf(
    [true],
    'You must acknowledge that inclement weather may occur!',
  ),
  meetupTime: Yup.array().of(Yup.date()).nullable(true),
  city: Yup.string().required('city is required'),
  state: Yup.string().required('state is required'),
  locationDetail: Yup.string().required('Locations details are required'),
});

export const getInitialValues = (values: State): FormType => ({
  eventStart: format(new Date(), 'MM/dd/yyyy'),
  dueDate: format(new Date(), 'MM/dd/yyyy'),
  gps:
    'gps' in values
      ? values?.gps
      : {
          coordinates: [-74.006652, 40.712675],
        },
  liveEvent: false,
  location: 'location' in values ? values.location : '',
  missionIndustry: 'missionIndustry' in values ? values.missionIndustry : '',
  notes: '',
  onsite: false,
  package: 'pack' in values ? values?.pack.id : '',
  restricted: 'restricted' in values ? values.restricted : false,
  rush: false,
  latitude: 'gps' in values ? values?.gps?.coordinates?.[1] : 40.712675,
  longitude: 'gps' in values ? values?.gps?.coordinates?.[0] : -74.006652,
  addDay: false,
  liveDay: format(new Date(), 'MM/dd/yyyy'),
  liveDay2: '',
  liveDay3: '',
  liveStartTime: '',
  liveEndTime: '',
  liveEndMeridiem: 'AM',
  liveStartMeridiem: 'AM',
  liveStartTime2: '',
  liveEndTime2: '',
  liveEndMeridiem2: 'AM',
  liveStartMeridiem2: 'AM',
  liveStartTime3: '',
  liveEndTime3: '',
  liveEndMeridiem3: 'AM',
  liveStartMeridiem3: 'AM',
  contactName: '',
  contactPhoneNumber: '',
  contactEmail: '',
  acknowledgeWeather: false,
  city: 'city' in values ? values.city : '',
  state: 'state' in values ? values.state : '',
  locationDetail: (values as any)?.locationDetail ?? '',
  additionalServices: [],
});

export const loginSchema = Yup.object().shape({
  _email: Yup.string().email().required(),
  _password: Yup.string().required(),
});
