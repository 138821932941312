import React from 'react';

import { Button, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import FormikInput from 'components/primitives/FormikInput';
import { Form, Formik } from 'formik';

import { ForgotPasswordGeneratedProps } from './ForgotPassword.props';
import CenterFormContainer from 'components/layouts/ConterFormContainer';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';

const ForgotPasswordView = (
  props: ForgotPasswordGeneratedProps,
): JSX.Element => (
  <CenterFormContainer title="Welcome Back!">
    <Flex
      bg="white"
      maxW="500px"
      width="100%"
      py={10}
      borderRadius="5px"
      border="1px"
      borderColor="gray.200"
      flexDir="column"
      align="center">
      {props.isSuccess ? (
        <Flex flexDir="column" maxW="510px" w="100%" px="50px" py="30px">
          <Icon
            as={IoIosCheckmarkCircleOutline}
            w="80px"
            h="80px"
            color="paymentSuccessIconColor"
            alignSelf="center"
          />
          <Text textAlign="center">Email Sent</Text>
          <Text mt={4} textAlign="center">
            We have sent you an email with instructions for resetting your
            password.
          </Text>
          <Button mt={10} onClick={props.onBackClick}>
            GO BACK
          </Button>
        </Flex>
      ) : (
        <Flex flexDir="column" maxW="510px" w="100%" px="50px" py="30px">
          <Heading
            fontFamily="Monument Extended"
            textAlign="left"
            size="md"
            color="primaryColor">
            Password Recovery
          </Heading>
          <Text fontSize="md" my="20px">
            Enter the email associated to your account, we will send you
          </Text>
          <Formik
            initialValues={{
              email: '',
            }}
            onSubmit={props.onSubmit}>
            <Form>
              <FormikInput name="email" label="Email Address" />
              <Button
                type="submit"
                mt={10}
                isFullWidth
                isDisabled={props.isLoading}
                isLoading={props.isLoading}>
                UPDATE PASSWORD
              </Button>
              <Button
                mt="5"
                variant="outline"
                isFullWidth
                onClick={props.onBackClick}>
                BACK
              </Button>
            </Form>
          </Formik>
        </Flex>
      )}
    </Flex>
  </CenterFormContainer>
);

export default ForgotPasswordView;
