import { TabPanel } from '@chakra-ui/react';
import React from 'react';

import { TabPanelWithShadowProps } from './TabPanelWithShadow.props';

const TabPanelWithShadowView = (props: any): JSX.Element => {
  return (
    <TabPanel
      bg="#FFFFFF 0% 0% no-repeat padding-box"
      boxShadow="0px 3px 6px #00000029"
      borderRadius="0px 0px 8px 8px"
      p="20px"
      {...props}>
      {props.children}
    </TabPanel>
  );
};

export default TabPanelWithShadowView;
