import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { PaymentMethod } from 'types/stripe';

import { PremiumPaymentProps } from './PremiumPayment.props';
import PremiumPaymentView from './PremiumPayment.view';
import { subscribeToPro } from 'api/auth';
import { SessionStore } from 'effector/session/store';
import { proSubscriptionEvent } from 'effector/session/events';

const PremiumPaymentContainer = (props: PremiumPaymentProps): JSX.Element => {
  const session = SessionStore.getState();
  const [selectedCard, setSelectedCard] = useState<PaymentMethod>();

  // temp
  const [subscribe, { isLoading, isSuccess }] = useMutation(subscribeToPro, {
    onSuccess: () => {
      proSubscriptionEvent();
    },
  });

  return (
    <PremiumPaymentView
      selectedCard={selectedCard}
      setSelectedCard={setSelectedCard}
      isSuccess={isSuccess}
      isLoading={isLoading}
      payNow={async () => {
        await subscribe(session?.session?.user?.id);
      }}
    />
  );
};

export default PremiumPaymentContainer;
