import React, { useEffect, useRef, useState } from 'react';

import {
  FormControl,
  Text,
  FormHelperText,
  FormLabel,
  border,
  Flex,
} from '@chakra-ui/react';
import { useField } from 'formik';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FormikTextEditorProps } from './FormikTextEditor.props';
import convertTextFromHtml from 'utils/renderHtmlTexts';

const FormikTextEditorView = ({
  name,
  label,
  helperText,
  error,
  defaultValue,
  isRequired,
  labelProps,
  isInvalid,
  style = {},
  containerProps = {},
  ...props
}: FormikTextEditorProps): JSX.Element => {
  const [{ value = '' }, meta, field] = useField(name);
  const [isError, setIsError] = useState(false);
  const editor = useRef<any>(null);

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  ];

  const modules = {
    toolbar: toolbarOptions,
  };

  useEffect(() => {
    if (meta.error || error) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [value]);

  const renderErrorBox = () => {
    return (
      <Text color="red.500" fontSize="sm" mt={50}>
        {error || meta.error}
      </Text>
    );
  };

  return (
    <FormControl mt={2} mb={3} {...containerProps} isRequired={isRequired}>
      <FormLabel
        fontSize="12px"
        fontWeight="600"
        htmlFor={name}
        {...labelProps}>
        {label}
      </FormLabel>
      <Flex flexDir="column" position="relative">
        <ReactQuill
          ref={editor}
          modules={modules}
          defaultValue={convertTextFromHtml(value)}
          onChange={(e) => {
            field.setValue(e);
          }}
          onBlur={() => {
            props.onBlur?.();
          }}
          style={style}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {isError && renderErrorBox()}
      </Flex>
    </FormControl>
  );
};

export default FormikTextEditorView;
