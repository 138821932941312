import React, { useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useTheme,
  Button,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { ReportModalGeneratedProps } from './ReportModal.props';

const ReportModalView = (props: ReportModalGeneratedProps): JSX.Element => {
  const theme = useTheme();
  const [message, setMessage] = useState('');

  return (
    <Modal isCentered isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={theme.colors.primaryColor}>
          Report a problem
        </ModalHeader>
        <ModalCloseButton size="sm" mt={1} color={theme.colors.buttonBgColor} />
        <ModalBody>
          <Text
            mt={4}
            mb={1}
            fontSize="sm"
            fontWeight="600"
            color={theme.colors.textColor}>
            Message
          </Text>
          <Textarea
            size="sm"
            borderRadius="5px"
            type=""
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            isLoading={props.isLoading}
            size="sm"
            w="136px"
            isDisabled={!message}
            onClick={() => {
              props.onSubmit(message);
              setMessage('');
            }}>
            SEND
          </Button>
          <Button
            ml="5px"
            size="sm"
            w="136px"
            variant="outline"
            onClick={props.onClose}>
            CANCEL
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReportModalView;
