import React, { useEffect, useMemo } from 'react';

import Navbar from 'components/core/Navbar';
import RouteWithSubRoutes from 'components/core/RouteWithSubRoutes';
import { Switch, useHistory, useLocation } from 'react-router-dom';

import { useIsMobile } from 'utils/hooks';

// IMPORT ROUTE
import ActivateAccount from 'routes/auth/ActivateAccount';
import NotFound from 'routes/auth/NotFound';
import StripeConnectSuccess from 'routes/auth/StripeConnectSuccess';
import StripeConnect from 'routes/auth/StripeConnect';
import ResetPassword from 'routes/auth/ResetPassword';
import Example from 'routes/auth/Example';
import ForgotPassword from 'routes/auth/ForgotPassword';
import Home from 'routes/auth/Home';
import Login from 'routes/auth/Login';
import Register from 'routes/auth/Register';

import ROUTES, { routesSettings } from 'constants/routes';

const routeConfig = Object.freeze({
  // AUTH ROUTE ENTRY
  [ROUTES.AUTH_ACTIVATE_ACCOUNT]: {
    component: ActivateAccount,
    path: routesSettings.AUTH_ACTIVATE_ACCOUNT.path,
  },
  [ROUTES.AUTH_STRIPE_CONNECT_SUCCESS]: {
    component: StripeConnectSuccess,
    path: routesSettings.AUTH_STRIPE_CONNECT_SUCCESS.path,
  },
  [ROUTES.AUTH_STRIPE_CONNECT]: {
    component: StripeConnect,
    path: routesSettings.AUTH_STRIPE_CONNECT.path,
  },
  [ROUTES.AUTH_RESET_PASSWORD]: {
    component: ResetPassword,
    path: routesSettings.AUTH_RESET_PASSWORD.path,
  },
  [ROUTES.AUTH_REGISTER]: {
    component: Register,
    path: routesSettings.AUTH_REGISTER.path,
  },
  [ROUTES.AUTH_EXAMPLE]: {
    component: Example,
    path: routesSettings.AUTH_EXAMPLE.path,
  },
  [ROUTES.AUTH_FORGOT_PASSWORD]: {
    component: ForgotPassword,
    path: routesSettings.AUTH_FORGOT_PASSWORD.path,
  },
  [ROUTES.AUTH_LOGIN]: {
    component: Login,
    path: routesSettings.AUTH_LOGIN.path,
  },
  [ROUTES.AUTH_HOME]: {
    component: Home,
    path: routesSettings.AUTH_HOME.path,
  },
  [ROUTES.AUTH_NOT_FOUND]: {
    component: NotFound,
    path: routesSettings.AUTH_NOT_FOUND.path,
  },
});

const AuthRoutes = () => {
  const history = useHistory();
  const location = useLocation();

  const isMobile = useIsMobile();

  useEffect(() => {
    if (history.location.pathname === '/') {
      history.replace(routesSettings.AUTH_LOGIN.path);
    }
  }, []);

  const isStripe = useMemo(
    () =>
      history.location.pathname ===
        routeConfig.AUTH_STRIPE_CONNECT_SUCCESS.path ||
      history.location.pathname === routeConfig.AUTH_STRIPE_CONNECT.path,
    [history.location.pathname],
  );

  const isMobileLogin =
    location.pathname === routeConfig.AUTH_LOGIN.path && isMobile;
  const isMobileRegister =
    location.pathname === routeConfig.AUTH_REGISTER.path && isMobile;

  return (
    <>
      {isStripe || isMobileLogin || isMobileRegister ? null : <Navbar />}
      <Switch>
        {Object.entries(routeConfig).map(([key, val]) => (
          <RouteWithSubRoutes key={key} {...val} />
        ))}
      </Switch>
    </>
  );
};

export default AuthRoutes;
