import React, { FC, useState } from 'react';

import {
  Flex,
  Modal,
  Image,
  Text,
  Button,
  Checkbox,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

import { useIsMobile } from 'utils/hooks';

import ambient_screen from 'res/ambient_screen.png';

import { MobileViewModalProps } from './MobileViewModal.props';

const MobileViewModalView: FC<MobileViewModalProps> = () => {
  const isMobile = useIsMobile();

  const [defaultIsOpen] = useState(() => {
    const doNotShow = localStorage.getItem('doNotShowMobileViewModal');

    return doNotShow !== 'true';
  });

  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: isMobile && defaultIsOpen,
  });

  const [isChecked, setIsChecked] = useState(false);

  const onContinueClick = () => {
    if (isChecked) {
      localStorage.setItem('doNotShowMobileViewModal', 'true');
    }

    onClose();
  };

  return (
    <Modal
      size="xs"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent borderRadius="20px">
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" p="20px 10px" align="center">
            <Image w="90px" src={ambient_screen} />
            <Text
              my="20px"
              textAlign="center"
              fontSize="22px"
              fontWeight="bold"
              lineHeight="0.9">
              We're still fully optimizing for mobile.
            </Text>
            <Text fontSize="16px" textAlign="center" fontFamily="Roboto">
              For the best experience, we suggest you going to the desktop
              version of the website through your browser.
            </Text>
            <Button w="full" my="20px" onClick={onContinueClick}>
              Continue
            </Button>
            <Checkbox
              colorScheme="brand"
              onChange={(e) => setIsChecked(e.target.checked)}>
              Don't show this message again
            </Checkbox>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MobileViewModalView;
