import React, { FC } from 'react';

import { Tooltip } from '@chakra-ui/tooltip';
import { Box, Center, Flex, Link, Stack, Text } from '@chakra-ui/layout';

import { useCMS } from './Context';

import { socialIconsMap } from 'utils/socialItemsMap';

import { SocialType } from 'types/cms';

export const Footer: FC = () => {
  const { cms, brandColor, fontColor } = useCMS();

  const fullName = cms?.about?.companyName || '';

  const onSocialIconPress = (url: string) => {
    if (!url.includes('http') || !url.includes('https')) {
      window.open('http://' + url, '_blank');
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <Flex
      mt={10}
      flex={1}
      align="center"
      bg={brandColor}
      color={fontColor}
      direction="column"
      p={[2, 4, 6, 10]}>
      <Center w="85%">
        <Flex
          flex={1}
          justify="space-between"
          direction={{ base: 'column', md: 'row' }}
          align={{ base: 'flex-start', md: 'center' }}>
          <Text fontSize="28px" fontWeight="bold">
            {fullName}
          </Text>
          <Stack>
            <Text fontWeight="bold">Follow me</Text>
            <Stack isInline spacing={4}>
              {cms?.socials?.map((item, index) => (
                <Box
                  key={`${item.type}-${index}`}
                  onClick={() => onSocialIconPress(item?.url)}>
                  <Tooltip
                    py={2}
                    px={4}
                    hasArrow
                    borderRadius="5px"
                    label={item.url}
                    aria-label={item.url}>
                    <Box
                      maxH="35px"
                      borderRadius="5px"
                      boxShadow="0 0 10px 0 rgba(0, 0, 0, 0.15)">
                      {socialIconsMap(item.type as keyof typeof SocialType)}
                    </Box>
                  </Tooltip>
                </Box>
              ))}
            </Stack>
          </Stack>
        </Flex>
      </Center>
      <Box h="0.5px" w="90%" bg={fontColor} my={4} opacity={0.5} />
      <Center w="85%">
        <Flex flex={1} align="center" fontSize="14px" justify="space-between">
          <Text>Copyright © 2020 DroneAdair | all rights reserved</Text>
          <Stack isInline spacing={4}>
            <Link color={fontColor}>Terms Of Services</Link>
            <Link color={fontColor}>Privacy Policy</Link>
          </Stack>
        </Flex>
      </Center>
    </Flex>
  );
};
