/* eslint-disable @typescript-eslint/no-empty-function */
import MobileBottomNavView from 'components/modules/MobileBottomNav';
import React, { createContext, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useIsMobile } from 'utils/hooks';

type PilotProviderValue = {
  showMobileBottomNavBar: boolean;
  hideNavBar: () => void;
  showNavBar: () => void;
};

export const PilotContext = createContext<PilotProviderValue>({
  showMobileBottomNavBar: true,
  hideNavBar: () => {},
  showNavBar: () => {},
});

export type PilotContextProviderProps = {
  children: ReactNode;
};

export const PilotContextProvider = ({
  children,
}: PilotContextProviderProps) => {
  const [showMobileBottomNavBar, setShowMobileBottomNavBar] = useState<boolean>(
    true,
  );

  const { pathname } = useLocation();

  const isMobile = useIsMobile();
  const isDetailsPage =
    pathname.includes('details') ||
    (!pathname.includes('create') && pathname.includes('custom-mission'));

  const hideNavBar = () => {
    setShowMobileBottomNavBar(false);
  };

  const showNavBar = () => {
    setShowMobileBottomNavBar(true);
  };

  const contextValue = {
    showMobileBottomNavBar,
    hideNavBar,
    showNavBar,
  };

  const SHOW_MOBILE_NAVBAR =
    isMobile && !isDetailsPage && showMobileBottomNavBar;

  return (
    <PilotContext.Provider value={contextValue}>
      {children}
      {SHOW_MOBILE_NAVBAR && <MobileBottomNavView />}
    </PilotContext.Provider>
  );
};
