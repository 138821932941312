import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  IconButton,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';

import { MobileCreateCustomMissionGeneratedProps } from './MobileCreateCustomMission.props';
import { MdArrowBack, MdClose } from 'react-icons/md';
import { EventRegister } from 'utils/customEvent';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';

import MissionDetailsTab from './components/MissionDetailsTab';
import { ServiceTab } from './components/ServiceTab';
import {
  initialValues,
  validationSchema,
} from 'routes/pilot/CreateCustomMission/CreateCustomMission.utils';
import { SubmitButton } from 'routes/pilot/CreateCustomMission/Deliverables';
import MobileCreateMissionModal from '../MobileCreateMissionModal/MobileCreateMissionModal';
import ClientDetailsTab from './components/ClientDetailsTab';

export const fontSize = '14px';
export const brandColor = '#6728BB';

export const labelProps = {
  fontSize: '12px',
  fontFamily: 'Montserrat',
  color: '#908361',
};

export const css = {
  '::placeholder': {
    fontSize: '12px',
    fontStyle: 'italic',
  },
};

const MobileCreateCustomMissionView = (
  props: MobileCreateCustomMissionGeneratedProps,
): JSX.Element => {
  const { onCreateMission, isLoading, createMissionError } = props;
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const tabData = [
    {
      label: 'Mission Details',
      Content: MissionDetailsTab,
      position: 0,
    },
    {
      label: 'Client Details',
      Content: ClientDetailsTab,
      position: 1,
    },
    {
      label: 'Services',
      Content: ServiceTab,
      position: 2,
    },
  ];

  const numSteps = tabData.length;
  const handlePrevStep = () => {
    setActiveTab((prevStep) => Math.max(prevStep - 1, 0));
  };

  const handleNextStep = () => {
    setActiveTab((prevStep) => Math.min(prevStep + 1, numSteps - 1));
  };

  useEffect(() => {
    const renderContent = () => {
      return (
        <Flex align="center">
          <IconButton
            size="xs"
            color="#fff"
            aria-label="dash-back"
            icon={<MdClose size="28px" />}
            variant="ghost"
            onClick={() => {
              EventRegister.emit('render_header_content', {
                renderContent: null,
              });

              history.goBack();
            }}
          />
          <Text fontSize="18px" fontWeight="bold" maxW="90%" noOfLines={1}>
            Custom Mission Request
          </Text>
        </Flex>
      );
    };

    EventRegister.emit('render_header_content', {
      renderContent,
    });
  }, []);

  const onDoneClick = () => {
    setIsOpen(false);
    history.goBack();
  };

  const submitCustomMission = () => {
    setIsOpen(true);
    onCreateMission;
  };

  return (
    <>
      <Flex mt={2} bg="#F5F5F5" align="center">
        <Formik
          onSubmit={submitCustomMission}
          initialValues={initialValues}
          validationSchema={validationSchema}>
          {({ values, errors, touched, isValid }) => (
            <Form>
              {console.log(values)}
              <Tabs
                bg="#F5F5F5"
                variant="unstyled"
                index={activeTab}
                onChange={(index) => setActiveTab(index)}>
                <TabList
                  justifyContent="space-evenly"
                  borderBottomWidth="4px"
                  borderColor="#B8B8B8"
                  color="#B8B8B8">
                  {tabData.map((tab, index) => (
                    <Tab
                      _selected={{
                        color: 'primaryColor',
                        bg: '#F5F5F5',
                        borderBottomWidth: '4px',
                        borderColor:
                          index > activeTab ? '#CCBC8F' : 'primaryColor',
                        mb: '-4px',
                      }}
                      fontFamily="Roboto"
                      fontWeight="700"
                      fontSize="14px"
                      key={index}
                      isDisabled={Boolean(errors)}>
                      {tab.label}
                    </Tab>
                  ))}
                </TabList>
                <TabPanels bg="#F5F5F5" minH="450px">
                  {tabData.map((tab, index) => {
                    const { Content: CustomTab } = tab;

                    return (
                      <TabPanel key={index}>
                        <CustomTab />
                      </TabPanel>
                    );
                  })}
                </TabPanels>
              </Tabs>
              <Box mb={20} textAlign="center">
                {activeTab !== 0 && (
                  <IconButton
                    variant="outline"
                    colorScheme="teal"
                    width={20}
                    borderRadius={18}
                    icon={<MdArrowBack size={24} />}
                    aria-label="previous"
                    onClick={handlePrevStep}
                    mr={4}
                    fill="white"
                  />
                )}
                {activeTab !== numSteps - 1 ? (
                  <Button onClick={handleNextStep}>Next</Button>
                ) : (
                  <SubmitButton isLoading={isLoading} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Flex>
      <MobileCreateMissionModal
        isOpen={isOpen}
        onDoneClick={onDoneClick}
        isError={createMissionError}
      />
    </>
  );
};

export default MobileCreateCustomMissionView;
