import React from 'react';

import { ResizableTableProps } from './ResizableTable.props';

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

const ResizableTableView = (props: ResizableTableProps): JSX.Element => {
  const { data, columns, onRowClick } = props;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
  });

  return (
    <Table minW="1600px">
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Th
                key={header.id}
                fontWeight="bold"
                fontSize="16px"
                textColor="black"
                textTransform="capitalize"
                width={header.getSize()}
                p={5}
                position="relative">
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
                <div
                  {...{
                    onMouseDown: header.getResizeHandler(),
                    onTouchStart: header.getResizeHandler(),
                    className: `resizer ${
                      header.column.getIsResizing() ? 'isResizing' : ''
                    }`,
                  }}
                />
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <Tr
            key={row.id}
            cursor="pointer"
            fontSize="14px"
            onClick={() => onRowClick(row._valuesCache.missionId as string)}>
            {row.getVisibleCells().map((cell) => (
              <Td key={cell.id} {...table.getRowModel()}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default ResizableTableView;
