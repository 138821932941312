import React, { FC } from 'react';

import stripeText from 'res/stripe.png';
import logo from 'res/custom-payment.png';

import { animationConfig, MotionFlex } from './Motion';

import { Button, Center, Heading, Image, Text } from '@chakra-ui/react';

import * as stripe from 'effector/stripe';
import * as session from 'effector/session';
import { updateUserEvent } from 'effector/session/events';

import { useMutation } from 'react-query';
import { createConnectAccount, updateConnectedAcc } from 'api/stripe';

import { CommonProps } from '../OnboardingModal.props';

const ConnectStripe: FC<CommonProps> = ({ cms, setIndex }) => {
  const isPending = stripe.selectors.useIsPending();
  const isEnabled = stripe.selectors.useIsEnabled();
  const isAccConnected = stripe.selectors.useIsConnected();

  const stripeId = session.selectors.useStripeId();

  const [updateLink, { isLoading }] = useMutation(
    async () => {
      let _stripeId = stripeId;

      if (!_stripeId) {
        const stripeAccount = await createConnectAccount();

        _stripeId = stripeAccount.id;

        updateUserEvent({ stripeId: _stripeId } as any);
      }

      const path = `${window.location.pathname}?fromStripe=true`;

      return updateConnectedAcc(path, _stripeId);
    },
    {
      onSuccess: (stripeUrl: string) => {
        window.open(stripeUrl, '_self');
      },
    },
  );

  return (
    <MotionFlex {...animationConfig} flex={1}>
      <Image src={logo} maxH="200px" objectFit="contain" />
      <Heading textAlign="center" fontSize="22px">
        Almost there! you need to set up stripe
      </Heading>
      <Center>
        <Text textAlign="center" maxW="60%">
          You need to connect Stripe so we can send you payments, please click
          the button and set up Stripe.
          <br /> <br /> If you don’t connect stripe you’ll not be able to take
          missions.
        </Text>
      </Center>

      <Center h="80px">
        <>
          {isAccConnected ? (
            isEnabled ? (
              <Button
                variant="ghost"
                color="#fff"
                bg="#3AC917"
                rightIcon={<Text>CONNECTED</Text>}>
                <Image src={stripeText} h="22px" />
              </Button>
            ) : (
              <Button
                variant="ghost"
                color="#fff"
                bg="yellow.500"
                leftIcon={<Text>UPDATE</Text>}
                rightIcon={<Text>INFORMATION</Text>}
                isLoading={isLoading}
                onClick={() => updateLink()}>
                <Image src={stripeText} h="22px" />
              </Button>
            )
          ) : (
            <Button
              variant="ghost"
              color="#fff"
              bg="#2672F2"
              rightIcon={<Image src={stripeText} h="22px" />}
              isLoading={isLoading || isPending}
              onClick={() => updateLink()}>
              CONNECT
            </Button>
          )}
        </>
      </Center>
      <Center gridGap="20px">
        <Button w="200px" variant="outline" onClick={() => setIndex(2)}>
          GO BACK
        </Button>
        <Button
          w="200px"
          onClick={() => setIndex(4)}
          isDisabled={!isAccConnected || !isEnabled}>
          CONTINUE
        </Button>
      </Center>
    </MotionFlex>
  );
};

export default ConnectStripe;
