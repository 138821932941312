import { useEffect, useState } from 'react';

const useResponsive = () => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 1024 ? true : false,
  );
  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isMobile };
};

export default useResponsive;
