import React, { FC } from 'react';

import { Flex, Stack, Text } from '@chakra-ui/react';

import { CustomMission, CustomStatus } from 'types/mission';

type Props = {
  mission: CustomMission;
};

export const ChangesRequest: FC<Props> = ({ mission }) => {
  const logs = mission.logs || [];

  if (logs?.length <= 0) {
    return null;
  }

  const changes = logs.find(
    (c) => c.type === CustomStatus.CLIENT_CHANGE_REQUESTED,
  );

  if (changes === undefined) {
    return null;
  }

  return (
    <Flex
      mb={6}
      pb={6}
      px={6}
      flex={1}
      direction="column"
      borderBottom="1px solid #ccc">
      <Text color="#908361" fontSize="14px" fontWeight="bold">
        Changes requested
      </Text>
      <Text mt={2} fontSize="14px">
        {changes.reason || ''}
      </Text>
    </Flex>
  );
};
