import React from 'react';

import { Flex, Input } from '@chakra-ui/react';
import { format } from 'date-fns';
import ReactFlatpickr from 'react-flatpickr';

import { DatePickerProps } from './DatePicker.props';
import flatpickr from 'flatpickr';

import './DatePicker.css';

function isValidDate(value: any): boolean {
  const date = new Date(value);

  return !isNaN(date?.getTime());
}

const DatePickerView = ({
  textFieldProps,
  onChange,
  value,
  disablePastDates,
  minDate,
  maxDate,
  ...props
}: DatePickerProps): JSX.Element => {
  const customOptions: flatpickr.Options.Options = {
    dateFormat: 'm-d-Y',
    disableMobile: true,
  };

  if (disablePastDates) {
    customOptions.minDate = 'today';
  }

  if (minDate) {
    customOptions.allowInvalidPreload = true;
    customOptions.minDate = minDate;
  }
  customOptions.maxDate = maxDate;

  return (
    <ReactFlatpickr
      {...props}
      value={value}
      onChange={onChange}
      options={customOptions}
      render={(_, ref) => {
        const _value = isValidDate(value) ? value : new Date().toDateString();

        let text = format(new Date(_value), 'MM-dd-Y');

        if (_value) {
          if (typeof _value === 'string')
            text = format(new Date(_value), 'MM-dd-Y');
          else if (typeof _value === 'object') text = format(_value, 'MM-dd-Y');
        }

        return (
          <Flex bg="white">
            <Input
              ref={ref}
              value={text}
              fontSize="12px"
              onChange={() => console.log('test')}
              {...textFieldProps}
            />
          </Flex>
        );
      }}
    />
  );
};

export default DatePickerView;
