import React, {
  useRef,
  createRef,
  forwardRef,
  useImperativeHandle,
} from 'react';

import {
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { Button } from '@chakra-ui/button';
import { Flex, Text } from '@chakra-ui/layout';

import { useCMS } from './Context';

import success_char from 'res/payment-success.png';

type RefType = {
  onOpen: (_onCloseParentCallback: () => void) => void;
  onClose: () => void;
};

export const aloftInfoPopupRef = createRef<RefType>();

const AloftInfoPopup = forwardRef<RefType>((_, ref) => {
  const { cms } = useCMS();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onCloseParentCallbackRef = useRef<() => void>();

  useImperativeHandle(
    ref,
    () => ({
      onOpen: (_callback) => {
        onCloseParentCallbackRef.current = _callback;

        onOpen();
      },
      onClose: () => {
        onCloseParentCallbackRef.current = undefined;

        onClose();
      },
    }),
    [],
  );

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton size="sm" />
        <ModalBody>
          <Flex p={6} flex={1} direction="column">
            <Image src={success_char} maxH="200px" objectFit="contain" />
            <Text
              mt={4}
              color="#000"
              textAlign="center"
              fontSize="14px"
              whiteSpace="pre-line">
              This address appears to be outside of our normal working area at @
              {cms?.about?.companyName || ''}. However, there's no need to
              worry! You can still create a custom mission, and our team will be
              in touch with you.
            </Text>
            <Button
              mt={4}
              w="200px"
              alignSelf="center"
              onClick={async () => {
                onClose();
                onCloseParentCallbackRef.current?.();

                await new Promise((res) => setTimeout(res, 200));

                const customQuoteEl = document.getElementById(
                  'request-custom-mission',
                );

                if (customQuoteEl) {
                  customQuoteEl.scrollIntoView({
                    behavior: 'smooth',
                  });

                  customQuoteEl.click?.();
                }
              }}>
              CONTINUE
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

export default AloftInfoPopup;
