import React from 'react';

import { Flex } from '@chakra-ui/react';

import { ContentContainerProps } from './ContentContainer.props';

const ContentContainerView = ({
  children,
  ...props
}: ContentContainerProps) => {
  return (
    <Flex
      {...props}
      px={[2, 10]}
      pt="20px"
      w="100%"
      alignSelf="flex-start"
      direction="column"
      bg={'#F3F3F3'}>
      {children}
    </Flex>
  );
};

export default ContentContainerView;
