import React, { FC, useRef } from 'react';

import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { CancelAndDeleteMissionModalGeneratedProps } from './CancelAndDeleteMissionModal.props';
import { selectors } from 'effector/session';
import { AccountTypes } from 'types/accountTypes';

const CancelAndDeleteMissionModalView = (
  props: CancelAndDeleteMissionModalGeneratedProps,
): JSX.Element => {
  const accountType = selectors.useAccountType();
  const isClient = accountType === AccountTypes.client;

  const valueRef = useRef<string>('');

  return (
    <Modal size="xl" isCentered isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent py={6}>
        <ModalHeader
          fontWeight="500"
          textAlign="center"
          mx="20px"
          fontFamily="Monument Extended">
          Are you sure you want to cancel and delete this mission?
        </ModalHeader>
        <Text textAlign="center" mx={6} mt={4}>
          <strong>Please note:</strong> <br />
          1. The mission will be permanently removed from your list. <br />
          2. The client will be automatically refunded the booking amount.{' '}
          <br />
        </Text>
        <Text textAlign="center" fontWeight="600" color="#d30000" mb={4}>
          This action cannot be undone.
        </Text>
        <ModalCloseButton />
        <ModalFooter justifyContent="center">
          <Button
            variant="outline"
            mr={2}
            onClick={props.onClose}
            isDisabled={props.isLoading}>
            NO
          </Button>
          <Button
            onClick={() => props.handleSubmit('Delection')}
            isLoading={props.isLoading}>
            YES
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CancelAndDeleteMissionModalView;
