import React, { useMemo } from 'react';

import { useToast } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import qs from 'qs';
import authApi from 'api/auth';

import { useMutation } from 'react-query';

import { signupEvent } from 'effector/session/events';

import { Register } from './Register.props';

import RegisterView from './Register.view';
import { useIsMobile } from 'utils/hooks';
import { UserInfo } from 'os';
import { CreateUserEvent, Pilot } from 'types/mission';
import { Session } from 'types/session';
import { payloadWithFormattedNames } from 'utils/formatedRegistrationNameInput';

const userRegistrationHeader =
  'Create a free account, request and manage your missions and more!';
const pilotRegistrationHeader =
  'Create a pilot account, access job offers, manage your missions and more!';
const companyRegistrationHeader =
  'Create  a free account, request and manage your missions and more!';

enum USER_TYPES {
  client = 'CLIENT',
  pilot = 'PILOT',
  company = 'COMPANY',
  admin = 'ADMIN',
}

enum TABS {
  client = 0,
  pilot = 1,
  company = 2,
}

const RegisterContainer = (props: Register) => {
  const toast = useToast();
  const isMobile = useIsMobile();

  const queryString = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });

  const history = useHistory();

  const type = queryString.type || 'client';

  const [tabIndex, setTabIndex] = React.useState(0);
  const [headerMessage, setHeaderMessage] = React.useState(
    pilotRegistrationHeader,
  );

  const [uploadImage, { isLoading: isUploading }] = useMutation(
    authApi.uploadImage,
  );

  const [signup, { isLoading }] = useMutation(
    async ({ image, ..._payload }: any) => {
      const response = await authApi.signup(_payload);

      if (typeof window !== 'undefined' && !!window.fpr) {
        await window.fpr('referral', {
          email: _payload.email,
        });
      }

      await new Promise((res) => setTimeout(res, 3000));

      return response;
    },

    {
      onSuccess: async (session, payload: any) => {
        if (session?.signedUrl || session?.user?.signedUrl) {
          try {
            await uploadImage({
              image: payload.image,
              signedUrl: session?.user?.signedUrl ?? '',
            });
          } catch (e) {
            console.log(e);
          }
        }

        signupEvent(session);

        const search = new URLSearchParams(props.location.search);
        const redirect = search.get('redirect');

        if (redirect) {
          history.replace(redirect, state);

          return;
        }

        const accountType = session?.user?.accountType;

        if (accountType === USER_TYPES.pilot) {
          //history.push('/stripe-connect');

          history.push('/pilot/dashboard');
        } else {
          history.push('/dashboard');
        }

        //history.go(0);
      },
    },
  );

  const redirect = useMemo(() => {
    const search = new URLSearchParams(props.location.search);

    const redirectPath = search.get('redirect');

    return redirectPath ? `&redirect=${redirectPath}` : '';
  }, []);

  const state = useMemo(() => props.location.state, []);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);

    if (index === TABS.pilot) {
      history.push({
        pathname: '/register',
        search: `?type=pilot${redirect}`,
        state,
      });
    } else if (index === TABS.company) {
      history.push({
        pathname: '/register',
        search: `?type=company${redirect}`,
        state,
      });
    } else {
      history.push({
        pathname: '/register',
        search: `?type=client${redirect}`,
        state,
      });
    }
  };

  const errorMessage = (message: string) => {
    toast({
      status: 'error',
      title: 'Signup failed',
      description: message,
      duration: 3000,
      isClosable: true,
      position: 'top',
    });
  };

  const onCancel = () => {
    history.push('/dashboard');
  };

  const onSubmit = async (payload: any) => {
    if (payload.password !== payload.confirmPassword) {
      errorMessage('Passwords do not match');
      return;
    }

    if (type !== USER_TYPES.company) {
      console.log('payload:', payload);

      const values = Object.assign({}, payload);
      const formattedPayload = payloadWithFormattedNames(values, isMobile);
      const accountType = 'PILOT'; // type?.toString().toUpperCase();
      delete formattedPayload.companyLicenseNumber;

      const signupData = {
        ...formattedPayload,
        // value for profileImage is going to be a hash generated on BE.
        profileImage: payload?.image?.path || 'profileImageSample.jpg',

        accountType,
      };

      signup(signupData);
    }
  };

  return (
    <RegisterView
      type={type}
      tabIndex={tabIndex}
      onSubmit={onSubmit}
      onCancel={onCancel}
      headerMessage={headerMessage}
      handleTabsChange={handleTabsChange}
      isLoading={isLoading || isUploading}
    />
  );
};

export default RegisterContainer;
