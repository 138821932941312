import React, { FC, useRef } from 'react';

import { MdClose, MdMenu } from 'react-icons/md';

import { Button, IconButton } from '@chakra-ui/button';
import { Flex, Link, Box } from '@chakra-ui/layout';

import { useCMS } from './Context';
import { useDisclosure } from '@chakra-ui/hooks';
import { motion } from 'framer-motion';
import * as session from 'effector/session';
import { UserPill } from './UserPill';
import { LoginModal } from './LoginModal';
import { Text } from '@chakra-ui/react';
import { log } from 'console';

const MotionFlex = motion.custom(Flex);

type MenuProps = {
  links: string[];
  onLinkClick: (link: string) => void;
};

const Menu: FC<MenuProps> = ({ links, onLinkClick }) => {
  const { brandColor, fontColor } = useCMS();
  const { isOpen, onClose, onToggle } = useDisclosure();

  return (
    <>
      <Box
        position="absolute"
        right={2}
        zIndex={2}
        p={2}
        display={{ base: 'block', md: 'none' }}>
        <IconButton
          aria-label="menu"
          icon={isOpen ? <MdClose size="24px" /> : <MdMenu size="24px" />}
          _focus={{ outline: 'none' }}
          onClick={onToggle}
          __css={{ bg: brandColor, color: fontColor, p: 2 }}
        />
      </Box>
      {isOpen && (
        <MotionFlex
          direction="column"
          align="center"
          position="absolute"
          top="80px"
          left={0}
          right={0}
          overflow="hidden"
          bg={brandColor}
          initial={{ opacity: 1, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 1, height: 0 }}>
          {links.map((link) => (
            <Link
              key={`${link}`}
              m={6}
              color={fontColor}
              fontWeight="bold"
              onClick={() => {
                onLinkClick(link);
                onClose();
              }}>
              {link}
            </Link>
          ))}
        </MotionFlex>
      )}
    </>
  );
};

const UserSection: FC = () => {
  const { brandColor } = useCMS();
  const modalRef = useRef<any>(null);

  const isUser = session.selectors.useUserId();

  return !isUser ? (
    <>
      <Button
        onClick={() => modalRef?.current?.onOpen?.()}
        color={brandColor}
        borderColor={brandColor}
        variant="outline">
        Login
      </Button>
      <LoginModal ref={modalRef} />
    </>
  ) : (
    <UserPill />
  );
};

export const Header: FC = () => {
  const { brandColor, fontColor, cms } = useCMS();
  const links = ['About', 'Work', 'Packages', 'Equipment'];

  const onLinkClick = (link: string) => {
    const element = document.getElementById(link);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };
  console.log(cms);

  return (
    <Flex
      zIndex={1}
      px={{ base: '10px', md: '50px' }}
      h={{ base: '80px', md: '110px' }}
      shadow="md"
      align="center"
      bg={brandColor}
      width="100%"
      color={fontColor}>
      <Flex
        fontWeight="bold"
        fontSize="1.75rem"
        display={{ base: 'none', md: 'flex' }}>
        <Text> {cms?.about.companyName} </Text>
      </Flex>

      <Flex flex={1} justify="flex-end" display={{ base: 'none', md: 'flex' }}>
        {links.map((link) => (
          <Link
            key={`${link}`}
            mx={6}
            color={fontColor}
            fontWeight="bold"
            onClick={() => onLinkClick(link)}>
            {link}
          </Link>
        ))}
      </Flex>
      <Menu links={links} onLinkClick={onLinkClick} />
      <UserSection />
    </Flex>
  );
};
