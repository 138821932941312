import React, { FC } from 'react';

import {
  Tab,
  Tabs,
  Text,
  Flex,
  Input,
  Stack,
  Divider,
  TabList,
  Textarea,
  TabPanel,
  TabPanels,
  Center,
  Button,
  useToast,
} from '@chakra-ui/react';

import calculateDueDate from 'utils/calculateDueDate';

import { selectors } from 'effector/session';

import { useMissionAssets } from 'utils/hooks/useMissionAssets';

import { useMutation, useQueryCache } from 'react-query';
import { clientRejectMission, markAsComplete } from 'api/custom-mission';

import MissionAssets from '../MissionAssets';

import { MissionClientInfo } from './MissionClientInfo';
import { MissionAssetsUpload } from '../MissionAssetsUpload';

import { PayButton } from 'routes/pilot/CustomMission/MissionActions';

import { CustomMission, CustomStatus } from 'types/mission';

const color = '#6728BB';
const fontSize = '14px';
const labelProps = {
  fontSize: '14px',
  color: 'primaryColor',
  fontWeight: 'bold',
};
const tabProps = {
  fontSize: '14px',
  bg: '#f1f1f1',
  fontWeight: 'bold',
  opacity: 0.5,
  _focus: { outline: 'none' },
  _selected: {
    borderColor: 'brand.500',
    bg: '#fff',
    color: 'brand.500',
    opacity: 1,
  },
};

type Props = {
  mission: CustomMission;
  setIsEdit: (value: boolean) => void;
  setShowReject: (value: boolean) => void;
};

export const DetailsTabs: FC<Props> = (props) => {
  const { mission, setShowReject, setIsEdit } = props;
  const isClient = selectors.useAccountType() === 'CLIENT';

  const status = mission.status;

  const additionalServicesCost: number =
    mission?.package?.additionalServices
      ?.map((i) => Number(i.price || '0'))
      ?.reduce((a, b) => a + b, 0) || 0;

  const lineItemsCost: number = mission?.package?.lineItems
    ?.map((i) => Number(i.count || '0') * Number(i.price || '0'))
    ?.reduce((a, b) => a + b, 0);

  const total = additionalServicesCost + lineItemsCost;

  const renderMissionActions = (status: CustomStatus) => {
    if (status === CustomStatus.CLIENT_REJECTED) {
      return (
        <Center flex={1} py={10}>
          <Text fontSize="14px" fontWeight="bold" color="primaryColor">
            The client rejected your custom quote
          </Text>
        </Center>
      );
    }
  };

  const showFilesUpload =
    status === CustomStatus.ORDERED || status === CustomStatus.COMPLETED;

  return (
    <>
      <Tabs m={0} p={0} isFitted variant="enclosed" minW="100vw" maxW="100vw">
        <TabList p={0}>
          <Tab {...tabProps}>MISSION</Tab>
          <Tab {...tabProps}>DETAILS</Tab>
          {showFilesUpload && <Tab {...tabProps}>FILES</Tab>}
        </TabList>

        <TabPanels p={0} m={0}>
          <TabPanel p={0} m={0}>
            <MissionClientInfo mission={mission}>
              <Flex direction="column">
                {isClient ? (
                  <ClientMissionActions mission={mission} />
                ) : (
                  <PilotMissionActions
                    mission={mission}
                    setIsEdit={setIsEdit}
                    setShowReject={setShowReject}
                  />
                )}
              </Flex>
            </MissionClientInfo>
          </TabPanel>
          <TabPanel p={0} m={0}>
            <Flex p="20px" direction="column" gridGap="15px" mb="100px">
              <Stack flex={1}>
                <Text {...labelProps}>Name</Text>
                <Input
                  name="name"
                  size="lg"
                  isReadOnly
                  fontSize={fontSize}
                  value={mission?.package?.name || ''}
                />
              </Stack>
              <Stack flex={1}>
                <Text {...labelProps}>Industry</Text>
                <Input
                  size="lg"
                  isReadOnly
                  fontSize={fontSize}
                  value={mission?.missionIndustry || ''}
                />
              </Stack>
              <Stack flex={1}>
                <Text {...labelProps}>URL Work Samples</Text>
                <Input
                  size="lg"
                  isReadOnly
                  fontSize={fontSize}
                  value={mission?.workSampleURL || ''}
                />
              </Stack>
              {!!mission.pilotChosenDate && (
                <>
                  <Stack flex={1}>
                    <Text {...labelProps}>Pilot Chosen Date</Text>
                    <Input
                      size="lg"
                      isReadOnly
                      fontSize={fontSize}
                      value={
                        calculateDueDate(new Date(mission?.pilotChosenDate)) ||
                        ''
                      }
                    />
                  </Stack>
                </>
              )}

              <Stack mt="20px" flex={1}>
                <Text {...labelProps}>Description</Text>
                <Textarea
                  minH="100px"
                  isReadOnly
                  fontSize={fontSize}
                  value={mission?.package?.description || ''}
                />
              </Stack>

              <Stack align="center" isInline>
                <Text {...labelProps}>Deliverables</Text>
              </Stack>

              {mission?.package?.lineItems?.map((l, i) => (
                <Flex
                  my="10px"
                  p="10px"
                  key={`${l.name}-${i}`}
                  borderRadius="6px"
                  bg={`${color}30`}
                  direction="column"
                  border={`1px solid ${color}`}
                  overflow="hidden">
                  <Text color={color} fontWeight="bold" fontSize="16px">
                    {l.name}
                  </Text>
                  <Divider />
                  <Flex align="center" gridGap="20px" pt="10px">
                    <Flex justify="center" flex={1}>
                      <Text>{l.count}</Text>
                    </Flex>
                    <Divider
                      borderColor="brand.500"
                      orientation="vertical"
                      minH="50px"
                    />
                    <Flex justify="center" flex={1}>
                      <Text>${l.price}</Text>
                    </Flex>
                    <Divider
                      orientation="vertical"
                      minH="50px"
                      borderColor="brand.500"
                    />
                  </Flex>
                </Flex>
              ))}

              <Stack align="center" isInline>
                <Text {...labelProps}>Additional Services</Text>
              </Stack>

              {mission?.package?.additionalServices?.map((l, i) => (
                <Flex
                  key={`${l.name}-${i}`}
                  minW="200px"
                  maxW="580px"
                  borderRadius="6px"
                  bg={`${color}30`}
                  align="center"
                  border={`1px solid ${color}`}
                  overflow="hidden">
                  <Text
                    my={1}
                    mx={2}
                    w="100%"
                    color={color}
                    fontWeight="bold"
                    fontSize="16px">
                    {l.name}
                  </Text>
                  <Text color={color} mx={3}>
                    ${l.price}
                  </Text>
                </Flex>
              ))}

              <Text mt="20px" fontWeight="bold" fontSize="22px">
                Mission Total Price: ${total.toFixed(2)}
              </Text>
            </Flex>
          </TabPanel>
          {showFilesUpload && (
            <TabPanel p="0">
              {!isClient && (
                <MissionAssetsUpload
                  type={1}
                  mission={
                    {
                      id: mission.id || '',
                    } as any
                  }
                />
              )}
              <MissionAssets
                type={1}
                missionId={mission.id || ''}
                package={props.mission.package?.name ?? ''}
                containerProps={{ bg: '#fff' }}
              />

              {!isClient && <MissionCompleteAction mission={mission} />}
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>

      {renderMissionActions(mission.status)}
    </>
  );
};

const ClientMissionActions: FC<{ mission: CustomMission }> = ({ mission }) => {
  const toast = useToast();
  const queryCache = useQueryCache();

  const [onCancel, { isLoading: isCancelling }] = useMutation(
    () => clientRejectMission('Mission Cancelled', mission.id || ''),
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Mission cancelled',
          isClosable: true,
          duration: 3000,
        });

        queryCache.invalidateQueries([`fetch-custom-mission-${mission.id}`]);

        queryCache.invalidateQueries([`fetch-custom-missions-open`]);
        queryCache.invalidateQueries([`fetch-custom-missions-open`]);
      },
    },
  );

  if (mission.status !== CustomStatus.PENDING_PAYMENT) {
    return null;
  }

  return (
    <Stack p="20px" mb="50px">
      <Button
        variant="outline"
        onClick={() => onCancel()}
        isLoading={isCancelling}>
        CANCEL MISSION
      </Button>
      <PayButton mission={mission} customMissionId={mission?.id || ''} />
    </Stack>
  );
};

const PilotMissionActions: FC<{
  mission: CustomMission;
  setShowReject: (value: boolean) => void;
  setIsEdit: (value: boolean) => void;
}> = ({ mission, setIsEdit, setShowReject }) => {
  if (mission.status !== CustomStatus.CLIENT_CHANGE_REQUESTED) {
    return null;
  }

  return (
    <Stack spacing={4} mb="100px" align="center">
      <Button
        fontSize="12px"
        w="80vw"
        variant="outline"
        onClick={() => {
          setShowReject(true);
        }}>
        REJECT
      </Button>
      <Button fontSize="12px" w="80vw" onClick={() => setIsEdit(true)}>
        UPDATE CUSTOM MISSION
      </Button>
    </Stack>
  );
};

const MissionCompleteAction: FC<{ mission: CustomMission }> = ({ mission }) => {
  const toast = useToast();
  const queryCache = useQueryCache();

  const { data } = useMissionAssets(mission?.id || '');

  const [onComplete, { isLoading }] = useMutation(
    () => markAsComplete(mission.id || ''),
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Mission marked as Complete',
          isClosable: true,
          duration: 3000,
        });

        queryCache.invalidateQueries([`fetch-custom-mission-${mission?.id}`]);

        queryCache.invalidateQueries([`fetch-custom-missions-open`]);
        queryCache.invalidateQueries([`fetch-custom-missions-open`]);
      },
    },
  );

  const onMarkAsComplete = () => {
    if (data === undefined) {
      return;
    }

    const isAssets = data?.results?.length > 0;

    if (isAssets === false) {
      toast({
        status: 'error',
        title: 'Please upload assets first to mark mission as complete',
        isClosable: true,
        duration: 4000,
      });

      return;
    }

    onComplete();
  };

  if (mission.status === CustomStatus.COMPLETED) {
    return null;
  }

  return (
    <Stack p="20px" mb="50px">
      <Button onClick={onMarkAsComplete}>MISSION COMPLETE</Button>
    </Stack>
  );
};
