import React, { FC, useCallback } from 'react';

import {
  Box,
  Flex,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';

import {
  TabProps,
  TabIndex,
  OnboardingModalGeneratedProps,
} from './OnboardingModal.props';
import { CMS } from 'types/cms';

import {
  Success,
  Welcome,
  PackageForm,
  ConnectStripe,
  CreatePackages,
  BusinessDetails,
  InformationDetails,
} from './Tabs';

const TAB_LABELS = [
  null,
  'Information Details',
  'Business Details',
  'Connect Stripe',
  'Create Your Packages',
] as const;

const Tabs = [
  Welcome,
  InformationDetails,
  BusinessDetails,
  ConnectStripe,
  CreatePackages,
  PackageForm,
  Success,
] as const;

const Tab: FC<TabProps> = ({ index, label, onClick, isSelected }) => {
  return (
    <Box
      p="10px"
      w="full"
      cursor={isSelected ? 'pointer' : 'not-allowed'}
      bg={isSelected ? '#908361' : '#fff'}
      color={isSelected ? '#fff' : '#908361'}
      borderRadius="50vw"
      border="2px solid currentColor">
      <Text noOfLines={1} textAlign="center" fontWeight="bold">
        {index}. {label}
      </Text>
    </Box>
  );
};

const OnboardingModalView: FC<OnboardingModalGeneratedProps> = ({
  cms,
  index: currentIndex,
  isOpen,
  onClose,
  setIndex,
}) => {
  const isSuccess = currentIndex === 6;
  const isWelcome = currentIndex === 0;
  const isPackageForm = currentIndex === 5;

  const hideTabs = isSuccess || isWelcome || isPackageForm;

  const renderTabs = useCallback((_index: TabIndex, _cms: CMS) => {
    const TabComponent = Tabs[_index];

    return (
      <TabComponent setIndex={(newIndex) => setIndex(newIndex)} cms={_cms} />
    );
  }, []);

  return (
    <Modal
      isCentered
      size={isWelcome ? '2xl' : '6xl'}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      motionPreset="slideInBottom"
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent p="40px" borderRadius="30px">
        <ModalCloseButton />
        {hideTabs ? null : (
          <Flex gridGap="10px" align="center">
            {TAB_LABELS.map((label, _index) =>
              label === null ? null : (
                <React.Fragment key={label + _index}>
                  <Tab
                    label={label}
                    index={_index}
                    isSelected={_index === currentIndex}
                    onClick={() => setIndex(_index as TabIndex)}
                  />
                  {_index < 4 && (
                    <Box
                      h="20px"
                      minW="20px"
                      borderRadius="20px"
                      border="2px solid #908361"
                    />
                  )}
                </React.Fragment>
              ),
            )}
          </Flex>
        )}
        <ModalBody mt="20px" p="0" maxH="80vh" overflow="auto">
          <Flex flex={1} w="full" minH={isWelcome ? 'auto' : '70vh'}>
            <AnimatePresence exitBeforeEnter>
              {renderTabs(currentIndex, cms)}
            </AnimatePresence>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OnboardingModalView;
