import React, { FC, useState } from 'react';

import { useQuery } from 'react-query';
import { getMissions } from 'api/mission';

import { selectors } from 'effector/session';

import { useHistory } from 'react-router-dom';

import { ClientMissionsProps } from './ClientMissions.props';
import ClientMissionsView from './ClientMissions.view';

const MISSIONS_PER_PAGE = 5;
const TODAY = new Date().toISOString();

const ClientMissionsContainer: FC<ClientMissionsProps> = ({ type }) => {
  const history = useHistory();
  const userId = selectors.useUserId();

  const [page, setPage] = useState(1);

  const queryKey = [`fetch-client-missions`, type, page];
  const queryFn = () =>
    getMissions({
      page,
      client: userId,
      limit: MISSIONS_PER_PAGE,
      dueDate: TODAY,
      type: type === 'ALL' ? 0 : 1,
    });

  const { data, isFetching } = useQuery(queryKey, queryFn, {
    onError: (e) => {
      console.log(e);
    },
  });

  const onMissionClick = (missionId: string | number) => {
    const missionDetailsUrl = `mission/details/${missionId}`;
    history.push(missionDetailsUrl);
  };

  const missions = data?.results || [];
  const totalPages = data?.totalPages || 0;
  const totalResults = data?.totalResults || 0;

  return (
    <ClientMissionsView
      page={page}
      setPage={setPage}
      missions={missions}
      isLoading={isFetching}
      totalPages={totalPages}
      totalResults={totalResults}
      onMissionClick={onMissionClick}
    />
  );
};

export default ClientMissionsContainer;
