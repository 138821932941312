import axios from 'axios';

import { Session } from 'types/session';
import { refreshTokenEvent } from 'effector/session/events';
import {
  getAccessToken,
  getRefreshToken,
  shouldSessionPersist,
} from 'effector/session/store';

import createAuthRefreshInterceptor from 'axios-auth-refresh';

const URL = `${process.env.REACT_APP_API_URL}`;

const DEFAULT_HEADERS: Record<string, string> = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const instance = axios.create({
  baseURL: URL,
  headers: DEFAULT_HEADERS,
});

const refreshTokenLogic = async (callbackRequest: any) => {
  const isLoginRequest = callbackRequest.request.responseURL.includes(
    '/auth/login',
  );

  if (isLoginRequest) {
    return;
  }

  try {
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
      return Promise.reject({
        response: {
          data: {
            message: 'Please Log in',
          },
        },
      });
    }

    try {
      const data = await refreshAuthToken({
        refreshToken,
      });

      refreshTokenEvent(data);

      callbackRequest.response.config.headers[
        'Authorization'
      ] = `Bearer ${data.access.token}`;

      return Promise.resolve();
    } catch (error) {
      return Promise.reject({
        response: {
          type: 'clear_session',
          data: {
            message: 'Your session has expired. Please Log in again.',
          },
        },
      });
    }
  } catch (error: any) {
    throw new Error(error);
  }
};

createAuthRefreshInterceptor(instance, refreshTokenLogic, {
  statusCodes: [401],
  //To avoid multiple refresh tokens creation, since while the account is not activated yet there is some 401 errors
  pauseInstanceWhileRefreshing: true, // default: false
});

instance.interceptors.request.use((request) => {
  // check if session expiry time is passed and handle session data clearing

  const isExpired = shouldSessionPersist(true);

  if (isExpired === true) {
    return Promise.reject({
      response: {
        type: 'clear_session',
        data: {
          message: 'Your session has expired. Please Log in again.',
        },
      },
    });
  }

  const accessToken = getAccessToken();

  // send token whenever available which is when logged in
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }

  return request;
});

export default instance;

export const refreshAuthToken = async (payload: {
  refreshToken: string;
}): Promise<Session['tokens']> => {
  const { data } = await axios.post(`${URL}/auth/refresh-tokens`, payload);

  return data;
};
