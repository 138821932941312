import api from 'api/instance';
import { getStripeId } from 'effector/session/store';
import { StripeKeyStore } from 'effector/stripe';
import { PaymentMethod } from 'types/stripe';
import axios from 'axios';

const URL = `${process.env.REACT_APP_API_URL}`;

export const getPaymentMethods = async (): Promise<PaymentMethod[]> => {
  const { data } = await api.get<{ data: PaymentMethod[] }>(
    `${URL}/stripe/payment-methods`,
  );

  return data.data;
};

type SaveCardPayload = {
  card_number: string;
  exp_month: number;
  exp_year: number;
  cvc: string;
};

export const saveCard = async (
  payload: SaveCardPayload,
): Promise<PaymentMethod> => {
  const { data } = await api.post<PaymentMethod>(
    `${URL}/stripe/payment-methods`,
    payload,
  );

  return data;
};

export const createStripeIntent = async (
  userEmail?: string,
): Promise<{
  client_secret: string;
}> => {
  const { data } = await api.post<{ client_secret: string }>(
    `${URL}/stripe/setup-intent${userEmail ? '-with-email' : ''}`,
    { email: userEmail },
  );

  return data;
};

export const deletePaymentMethod = async (id: string): Promise<any> => {
  const { data } = await api.delete(`${URL}/stripe/payment-methods/${id}`);

  return data;
};

export const getStripeKey = async (): Promise<string | null> => {
  try {
    const { data } = await api.get<{ publicKey: string }>(
      `${URL}/stripe/publishable-key`,
    );

    return data.publicKey;
  } catch (error) {
    return null;
  }
};

type OnboardingResponse = {
  created: Date;
  expires_at: Date;
  object: string;
  url: string;
};

// export const createConnectedAccLink = async (
//   path = '/stripe-connect/success',
// ) => {
//   const {
//     data: { id },
//   } = await api.post<any>(`${URL}/stripe/connect`);

//   const temp_url = `${window.location.origin}${path}`;

//   const { data } = await api.post<OnboardingResponse>(
//     `${URL}/stripe/connect/${id}/account-onboarding`,
//     {
//       return_url: temp_url,
//       refresh_url: temp_url,
//       type: 'account_onboarding',
//     },
//   );

//   return data?.url;
// };

export const updateConnectedAcc = async (path: string, _stripeId?: string) => {
  const stripeId = _stripeId || getStripeId();
  const temp_url = `${window.location.origin}${path}`;

  const { data } = await api.post<OnboardingResponse>(
    `${URL}/stripe/connect/${stripeId}/account-onboarding`,
    {
      return_url: temp_url,
      refresh_url: temp_url,
      type: 'account_onboarding',
    },
  );

  return data?.url;
};

export const fetchConnectedAcc = async (stripeId: string) => {
  const { data } = await api.get(`${URL}/stripe/connect/${stripeId}`);

  return data;
};

export const transferPayment = async (missionId: string) => {
  const { data } = await api.post(`${URL}/stripe/connect/payment/transfer`, {
    missionId,
  });

  return data;
};

export const getConnectDashboardUrl = async (stripeId: string) => {
  const { data } = await api.get(`${URL}/stripe/connect/${stripeId}/dashboard`);

  return data?.url;
};

export const uploadProfileToStripe = async (
  file: File,
  stripeAccountId: string,
) => {
  const public_key = StripeKeyStore.getState().public_key;

  const url = 'https://files.stripe.com/v1/files';

  const headers = {
    Authorization: `Bearer ${public_key}`,
  };

  const formData = new FormData();
  formData.append('file', file, file.name);
  formData.append('purpose', 'business_icon');

  const fileResponse: any = await axios.post(url, formData, {
    headers,
  });

  const response = await api.post(`${URL}/stripe/connect/${stripeAccountId}`, {
    fileName: fileResponse.data.id,
  });

  return response;
};

export const fetchReceiptUrl = async (
  chargeId: string,
  pilotStripeAccountId: string,
) => {
  const { data } = await api.get(
    `${URL}/stripe/payment/get-receipt-url/${chargeId}/${pilotStripeAccountId}`,
  );

  return data?.receipt_url as string;
};

export const createConnectAccount = async () => {
  const { data } = await api.post(`${URL}/stripe/connect`);

  return data;
};
