import React from 'react';

import {
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiOutlineFacebook,
  AiOutlineYoutube,
  AiFillTwitterSquare,
} from 'react-icons/ai';
import { BsVimeo } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';

import { SocialType } from 'types/cms';

export const socialItemsMap: { [key in SocialType]: string } = {
  LINKEDIN: '#0a66c2',
  FACEBOOK: '#1877f2',
  INSTAGRAM: '#F00073',
  YOUTUBE: '#ff0000',
  TWITTER: '#1da1f2',
  VIMEO: '#19B7EA',
  TIKTOK: '#ff0050',
};

const defaultStyle = {
  borderRadius: '5px',
  padding: '5px',
  cursor: 'pointer',
};

export const socialIconsMap: (
  type?: keyof typeof SocialType,
  color?: string,
  size?: string,
) => JSX.Element = (type = SocialType['FACEBOOK'], color, size = '35px') =>
  ({
    LINKEDIN: (
      <AiOutlineLinkedin
        size={size}
        style={{
          backgroundColor: color ?? socialItemsMap['LINKEDIN'],
          ...defaultStyle,
        }}
        color="#fff"
      />
    ),
    FACEBOOK: (
      <AiOutlineFacebook
        size={size}
        style={{
          backgroundColor: color ?? socialItemsMap['FACEBOOK'],
          ...defaultStyle,
        }}
        color="#fff"
      />
    ),
    INSTAGRAM: (
      <AiOutlineInstagram
        size={size}
        style={{
          backgroundColor: color ?? socialItemsMap['INSTAGRAM'],
          ...defaultStyle,
        }}
        color="#fff"
      />
    ),
    YOUTUBE: (
      <AiOutlineYoutube
        size={size}
        style={{
          backgroundColor: color ?? socialItemsMap['YOUTUBE'],
          ...defaultStyle,
        }}
        color="#fff"
      />
    ),
    TWITTER: (
      <AiFillTwitterSquare
        size={size}
        style={{
          backgroundColor: color ?? socialItemsMap['TWITTER'],
          ...defaultStyle,
        }}
        color="#fff"
      />
    ),
    VIMEO: (
      <BsVimeo
        size={size}
        style={{
          backgroundColor: color ?? socialItemsMap['VIMEO'],
          ...defaultStyle,
        }}
        color="#fff"
      />
    ),
    TIKTOK: (
      <FaTiktok
        size={size}
        color="#fff"
        style={{
          backgroundColor: color ?? socialItemsMap['TIKTOK'],
          ...defaultStyle,
        }}
      />
    ),
  }[type]);
