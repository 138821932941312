import React from 'react';

import {
  Flex,
  Stack,
  Box,
  useDisclosure,
  ModalOverlay,
  Image,
  Link,
  Center,
  Modal,
} from '@chakra-ui/react';
import MotionLink from 'components/primitives/MotionLink';
import { AnimatePresence, motion } from 'framer-motion';
import { MdClose, MdMenu, MdReply } from 'react-icons/md';
import { useResponsive } from 'utils/hooks';

import { SideBarProps } from './SideBar.props';
import DroneAdairLogo from 'res/logos/logo1.png';
import { UserAvatar } from 'components/modules/UserAvatar';

const MotionStack = motion.custom(Stack);
const variants = {
  hidden: {
    x: '-250px',
    transition: {
      type: 'spring',
      duration: 'linear',
      stiffness: 80,
      damping: 15,
    },
  },
  visible: {
    x: '0px',
    transition: {
      type: 'spring',
      duration: 'linear',
      stiffness: 80,
      damping: 15,
    },
  },
};

const scrollBarStyles = {
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#fff',
    borderRadius: '30px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#92835C50',
    borderRadius: '30px',
  },
};

const SideBarView = (props: SideBarProps): JSX.Element => {
  const { isOpen, onToggle } = useDisclosure();
  const { isMobile } = useResponsive();

  return (
    <>
      {isMobile ? (
        <Flex>
          <Box pl="10px" pt="10px" zIndex={15} top="0" position="fixed">
            {isOpen ? (
              <MdClose size={30} onClick={onToggle} />
            ) : (
              <MdMenu size={30} onClick={onToggle} />
            )}
          </Box>
          <Modal isOpen={isOpen} onClose={onToggle}>
            <ModalOverlay zIndex={5} onClick={onToggle} />
          </Modal>
          <AnimatePresence>
            {isOpen && (
              <MotionStack
                variants={variants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                w="250px"
                position="fixed"
                h="100%"
                spacing={0}
                borderRightWidth="1px"
                borderColor="#cccc"
                direction="column"
                fontSize="xs"
                fontWeight="bold"
                bg="tertiaryColor"
                zIndex={10}
                m="0px"
                p="0px">
                <Center mb="-35px">
                  <Link href="/dashboard">
                    <Image
                      width="125px"
                      height="150px"
                      objectFit="contain"
                      src={DroneAdairLogo}
                    />
                  </Link>
                </Center>
                <Flex
                  flex={1}
                  direction="column"
                  overflowY="auto"
                  css={scrollBarStyles}>
                  <Flex flex={1} direction="column">
                    {props.middleSection(onToggle)}
                  </Flex>
                  <Flex direction="column">
                    {props.bottomSection && props.bottomSection(onToggle)}
                  </Flex>
                </Flex>
              </MotionStack>
            )}
          </AnimatePresence>
        </Flex>
      ) : (
        <Stack minW="240px" bg="bgColor">
          <Stack
            w="240px"
            position="fixed"
            bg="tertiaryColor"
            h="100%"
            spacing={0}
            borderRightWidth="1px"
            borderColor="#cccc"
            direction="column"
            fontSize="xs"
            fontWeight="bold">
            <Center>
              <Link href="/dashboard">
                <Image
                  width="125px"
                  height="150px"
                  objectFit="contain"
                  src={DroneAdairLogo}
                />
              </Link>
            </Center>
            <Flex
              flex={1}
              direction="column"
              overflowY="auto"
              css={scrollBarStyles}>
              <Flex flex={1} direction="column">
                {props.middleSection()}
              </Flex>
              <Flex direction="column">
                {props.bottomSection && props.bottomSection()}
              </Flex>
            </Flex>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default SideBarView;
