import React, { FC, useEffect, useState } from 'react';

import { Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react';

import MissionListBanner from '../MissionListBanner';
import TabPanelWithShadow from 'components/primitives/TabPanelWithShadow';

import { ClientMissionTabsGeneratedProps } from './ClientMissionTabs.props';
import ClientMissions from '../ClientMissions';

const tabSpacing = [5, 8, 8];

enum Title {
  YOUR_MISSIONS = 'YOUR MISSIONS',
  COMPLETED_MISSIONS = 'COMPLETED MISSIONS',
}

const ClientMissionTabsView: FC<ClientMissionTabsGeneratedProps> = (props) => {
  const [index, setIndex] = useState(0);
  const [title, setTitle] = useState(Title.YOUR_MISSIONS);

  useEffect(() => {
    if (index === 0) {
      setTitle(Title.YOUR_MISSIONS);
    } else {
      setTitle(Title.COMPLETED_MISSIONS);
    }
  }, [index]);

  return (
    <Tabs isLazy variant="enclosed" index={index} onChange={setIndex}>
      <TabList>
        <Tab
          _focus={{ borderColor: 'none' }}
          _selected={{ backgroundColor: 'secondaryColor', color: 'white' }}
          fontSize="md"
          fontWeight="bold"
          px={tabSpacing}
          py="3">
          CURRENT MISSIONS
        </Tab>
        <Tab
          _focus={{ borderColor: 'none' }}
          _selected={{ backgroundColor: 'secondaryColor', color: 'white' }}
          fontSize="md"
          fontWeight="bold"
          px={tabSpacing}
          py="3">
          PAST MISSIONS
        </Tab>
      </TabList>
      <MissionListBanner highlightedTitle={title} unhighlightedTitle="LIST" />
      <TabPanels bg="white" border="0.5px solid #E2E8F0">
        <TabPanelWithShadow p={0} m={0}>
          <ClientMissions type="ALL" />
        </TabPanelWithShadow>
        <TabPanelWithShadow p={0} m={0} bg="white">
          <ClientMissions type="COMPLETED" />
        </TabPanelWithShadow>
      </TabPanels>
    </Tabs>
  );
};

export default ClientMissionTabsView;
