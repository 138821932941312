import * as Yup from 'yup';

import { phoneRegExp } from 'constants/registration';

const regZipCode = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/;
const regName = /^[a-zA-Z]+ [a-zA-Z]+$/;

export const PilotRegistrationSchema = Yup.object().shape({
  fullName: Yup.string().matches(regName, 'Please provide your last name'),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
  confirmPassword: Yup.string().required('Required'),
  phone: Yup.string().trim().matches(phoneRegExp, 'Phone number is invalid'),
  address: Yup.string(),
  state: Yup.string(),
  isNotPilotCertNumber: Yup.boolean(),
  zipcode: Yup.string()
    .matches(regZipCode, 'Please enter a valid zip code')
    .required('Please enter the zip code'),
  pilotCertNumber: Yup.string().when('isNotPilotCertNumber', {
    is: false,
    then: Yup.string()
      .trim()
      .required('Required')
      .length(7, 'should be 7 digits'),
    otherwise: Yup.string().notRequired(),
  }),
  pilotCertExpire: Yup.string().when('isNotPilotCertNumber', {
    is: false,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  }),
  companyName: Yup.string()
    .trim()
    .min(2, 'Company Name should be more then 2 characters')
    .matches(/^[A-Za-z0-9 ]+$/, 'Company Name cannot be symbols')
    .required('Company Name is required'),
});

export const initialValues = {
  fullName: '',
  email: '',
  password: '',
  confirmPassword: '',
  phone: '',
  address: '',
  state: '',
  zipcode: undefined,
  pilotCertNumber: '',
  pilotCertExpire: new Date().toISOString(),
  isNotPilotCertNumber: false,
  companyName: '',
};
