import React, { FC, useRef } from 'react';

import { IconButton } from '@chakra-ui/button';
import { Box, Center, Divider, Flex, Stack, Text } from '@chakra-ui/layout';

import { MdAddCircleOutline, MdCheck, MdDelete } from 'react-icons/md';

import { useToast } from '@chakra-ui/toast';
import { useFormikContext } from 'formik';
import { useMutation, useQueryCache } from 'react-query';

import { selectors } from 'effector/stripe';

import { deletePackageItem } from 'api/cms';

import { CreatePackageModal } from '../CreatePackageModal';

import { NormalText } from 'components/primitives/NormalText';

import { CMS, Package } from 'types/cms';

const colorScheme = '#6728BB';

type ModalRef = { onOpen: (values?: any) => void };

const DeletPackageButton: FC<{
  id: string;
  onRemove: () => void;
}> = ({ id, onRemove }) => {
  const formikRef = useFormikContext<CMS>();

  const color = formikRef?.values?.stylings?.colorScheme || colorScheme;

  const toast = useToast();
  const queryCache = useQueryCache();

  const [onDelete, { isLoading }] = useMutation(deletePackageItem, {
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'File Deleted',
        duration: 2000,
        isClosable: true,
      });

      queryCache.invalidateQueries('fetch-my-cms', {
        exact: true,
      });
      queryCache.invalidateQueries(`fetch-my-cms-DRAFT`, {
        exact: true,
      });
    },
  });

  return (
    <IconButton
      bg={color}
      top={0}
      right={0}
      zIndex={2}
      size="sm"
      aria-label="delete-button"
      icon={<MdDelete size="16px" />}
      borderRadius="0 10px 0 10px"
      position="absolute"
      isLoading={isLoading}
      onClick={() => (id ? onDelete(id) : onRemove())}
    />
  );
};

export const PackagesSection: FC<{
  isNewCMS: boolean;
}> = ({ isNewCMS }) => {
  const modalRef = useRef<ModalRef>(null);
  const formikRef = useFormikContext<CMS>();
  const packages = formikRef.values.packages;
  const isAccConnected = selectors.useIsConnected();

  const isIndustriesSelected = formikRef.values?.industries?.length > 0;

  const color = formikRef?.values?.stylings?.colorScheme || colorScheme;

  const toast = useToast();
  /**
   * It's necessary a toast to warning user that there is unsaved changes,
   * this avoid lost information
   */
  const preventUnsavedChangesToast = () => {
    toast({
      status: 'error',
      title:
        'You need to create an Industry before being able to create a Package',
      duration: 5000,
      isClosable: false,
    });
  };

  const handleNewPackageClick = () => {
    if (!isAccConnected) {
      toast({
        status: 'warning',
        title:
          'Please Connect your Stripe account in order to create a Package',
        isClosable: true,
      });

      return;
    }

    /**
     *To permit the modal opening only if there is saved changes.
     */
    return isIndustriesSelected
      ? modalRef.current?.onOpen()
      : preventUnsavedChangesToast();
  };

  const handleUpdatePackageClick = (p: Package) => {
    return isIndustriesSelected
      ? modalRef.current?.onOpen(p)
      : preventUnsavedChangesToast();
  };
  return (
    <Stack spacing={0} id="packages" mb={4} px={[5, 10]}>
      <Flex justifyContent="space-between">
        <Box>
          <NormalText fontSize="14px" color="#707070">
            Media Productions Packages
          </NormalText>
        </Box>
      </Flex>
      <Flex
        p={4}
        flex={1}
        gridGap="20px"
        overflowX="auto"
        flexWrap="nowrap"
        maxW={['350px', '1000px']}>
        <Box
          h="340px"
          minW="200px"
          display="flex"
          cursor="pointer"
          borderRadius="10px"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          onClick={(event) => handleNewPackageClick()}
          boxShadow="0px 0px 14px 0px rgba(50, 50, 50, 0.15)">
          <MdAddCircleOutline size="50px" fill={color} />
          <Text
            mt={2}
            fontWeight="bold"
            color={color}
            textAlign="center"
            whiteSpace="pre-line">
            Add new{'\n'}Package
          </Text>
        </Box>
        {packages?.map((p, index) => (
          <Box key={`${p.id}-${index}`} position="relative">
            <Box
              p={4}
              pb={1}
              minH="340px"
              minW="200px"
              maxW="200px"
              display="flex"
              cursor="pointer"
              borderRadius="10px"
              flexDirection="column"
              boxShadow="0px 0px 14px 0px rgba(50, 50, 50, 0.15)"
              onClick={() => handleUpdatePackageClick(p)}>
              <Text textAlign="center" fontSize="14px" fontWeight="bold">
                {p.name}
              </Text>
              <Text
                my={2}
                fontSize="24px"
                fontWeight="bold"
                textAlign="center"
                color={color}>
                {p.price ? `$${p.price}` : ''}
              </Text>
              <Text mt={2} fontSize="12px" textAlign="center">
                {p.description || ''}
              </Text>
              <Box my={2}>
                <Divider position="absolute" left={0} />
              </Box>
              <Stack flex={1} mt={2} spacing={1}>
                {p.lineItems.map((item, itemIndex) => (
                  <Stack isInline key={`${item.name}-${itemIndex}`}>
                    <Center
                      h="22px"
                      minW="22px"
                      borderRadius="20px"
                      position="relative">
                      <MdCheck color={color} />
                      <Box
                        top={0}
                        left={0}
                        h="22px"
                        minW="22px"
                        opacity={0.1}
                        bg={color}
                        position="absolute"
                        borderRadius="20px"
                      />
                    </Center>
                    <Box>
                      <Text fontSize="12px" mb={1}>
                        {item.count ? `(${item.count})` : ''} {item.name}
                      </Text>
                    </Box>
                  </Stack>
                ))}
              </Stack>
            </Box>
            <DeletPackageButton
              id={p.id || ''}
              onRemove={() => {
                formikRef.setFieldValue(
                  'packages',
                  packages.filter((i) => i.name !== p.name),
                );
              }}
            />
          </Box>
        ))}
      </Flex>
      <CreatePackageModal ref={modalRef} isEdit={!isNewCMS} />
    </Stack>
  );
};
