import React, { FC, useState } from 'react';

import { Button, IconButton } from '@chakra-ui/button';
import { Center, Flex, Stack, Text } from '@chakra-ui/layout';

import { MdAddCircle, MdEdit } from 'react-icons/md';

import * as yup from 'yup';

import { Formik, useField } from 'formik';
import { useDisclosure } from '@chakra-ui/hooks';

import { NormalText } from 'components/primitives/NormalText';

import FormikInputView from 'components/primitives/FormikInput';

import { Services } from 'types/cms';

const colorScheme = '#6728BB';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .max(25, 'Package name should be shorter than 25 characters'),
  price: yup.string().required('Price is required'),
});

type Selected = {
  value: {
    name: string;
    price: string;
  };
  index: number;
} | null;

export const AdditionalServices: FC<{ onSave: () => void }> = ({ onSave }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selected, setSelected] = useState<Selected>(null);
  const [field, , helpers] = useField<Services[]>('additionalServices');
  const { value: stylings } = useField<any>('stylings')[0];

  const color = stylings?.colorScheme || colorScheme;

  const { value } = field;
  const { setValue } = helpers;

  return (
    <Flex my={4} px={[2, 4, 6, 10]} direction="column">
      <NormalText fontSize="14px">Add your additional services</NormalText>
      {isOpen ? (
        <Formik
          enableReinitialize
          initialValues={selected ? selected.value : { name: '', price: '' }}
          onSubmit={(values) => {
            if (selected) {
              const newValue = [...value];

              newValue[selected.index] = {
                name: values.name,
                price: parseInt(values.price || '0'),
              };

              setValue(newValue);
            } else {
              setValue([
                ...value,
                {
                  name: values.name,
                  price: parseInt(values.price || '0'),
                },
              ]);
            }

            onClose();
            setSelected(null);

            setTimeout(() => {
              onSave();
            }, 500);
          }}
          validationSchema={validationSchema}>
          {({ isValid, submitForm, values }) => (
            <Center>
              <Stack maxW="400px" w="100%">
                <FormikInputView
                  name="name"
                  label="Name"
                  value={values.name}
                  labelProps={{ color: '#707070' }}
                />
                <FormikInputView
                  type="number"
                  name="price"
                  label="Price"
                  value={values.price}
                  labelProps={{ color: '#707070' }}
                />
                <Center py={4} flexDirection="column">
                  <Button
                    bg={color}
                    w="100px"
                    size="sm"
                    onClick={submitForm}
                    isDisabled={!isValid || !values.name || !values.price}>
                    {selected ? `UPDATE` : 'ADD'}
                  </Button>
                  <Button
                    mt={2}
                    w="100px"
                    size="sm"
                    variant="outline"
                    borderColor={color}
                    color={color}
                    onClick={() => {
                      onClose();
                      setSelected(null);
                    }}>
                    CANCEL
                  </Button>
                </Center>
              </Stack>
            </Center>
          )}
        </Formik>
      ) : (
        <Flex flexWrap="wrap" py={2} maxW="800px">
          {value.map((service, index) => {
            return (
              <Flex
                m={2}
                key={`${service.name}-${index}`}
                border={`1px solid ${color}`}
                borderRadius="5px"
                bg={`${color}30`}
                color={color}
                overflow="hidden"
                fontWeight="bold">
                <Text px={4} py={1}>
                  {index + 1} - {service.name} - ${service.price}
                </Text>
                <IconButton
                  aria-label="edit-service"
                  icon={<MdEdit />}
                  size="sm"
                  color={color}
                  variant="ghost"
                  border="none"
                  borderRadius="0px"
                  borderLeft={`1px solid ${color}`}
                  _focus={{ border: 'none', borderLeft: `1px solid ${color}` }}
                  onClick={() => {
                    setSelected({
                      value: {
                        name: service.name,
                        price: service.price.toString(),
                      },
                      index,
                    });

                    onOpen();
                  }}
                />
              </Flex>
            );
          })}
        </Flex>
      )}
      {!isOpen && (
        <Button
          mt={4}
          w="160px"
          variant="ghost"
          onClick={onOpen}
          leftIcon={<MdAddCircle size="20px" />}
          color={color}>
          Add service
        </Button>
      )}
    </Flex>
  );
};
