import Working from 'res/tutorial/working.webp';
import Dashboard from 'res/tutorial/dashboard.webp';
import Website from 'res/tutorial/website.webp';
import EducationResources from 'res/tutorial/education_resources.webp';
import Rocket from 'res/tutorial/rocket.webp';

export type IntroProps = {
  title: string;
  text?: string;
  img_src: string;
  buttonLabel: string;
};

const firstIntro = {
  title: 'Welcome to DroneAdair!',
  text: `We're so excited that you've signed up, please take a minute to scroll through these 3 quick setup tips!`,
  img_src: Working,
  buttonLabel: 'CONTINUE',
};

const secondIntro = {
  title: 'Dashboard',
  text: `Go here to access all current/past missions, create new custom missions, upload/view files and more!`,
  img_src: Dashboard,
  buttonLabel: 'CONTINUE',
};

const thirdIntro = {
  title: 'My website',
  text: `Setup all of your information here to start booking jobs and automating your business.`,
  img_src: Website,
  buttonLabel: 'CONTINUE',
};

const fourthIntro = {
  title: 'Education & Resources',
  text: `Access continued education courses and important industry info all in one place.`,
  img_src: EducationResources,
  buttonLabel: 'CONTINUE',
};

const fifthIntro = {
  title: 'Start setting up your page now!',
  img_src: Rocket,
  buttonLabel: 'GET STARTED',
};

export const INTRODUCTIONS_CONTENT_MAP: IntroProps[] = [
  firstIntro,
  secondIntro,
  thirdIntro,
  fourthIntro,
  fifthIntro,
];
