import React from 'react';

import { SupportProps } from './Support.props';
import SupportView from './Support.view';

const SupportContainer = (props: SupportProps): JSX.Element => {
  return <SupportView />;
};

export default SupportContainer;
