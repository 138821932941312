import React, { FC, useRef } from 'react';

import {
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';

import { DeleteMissionButtonProps } from './DeleteMissionButton.props';

const DeleteMissionButtonView: FC<DeleteMissionButtonProps> = ({
  onDelete,
  isDeleting,
}) => {
  const cancelRef = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        ml="2"
        variant="outline"
        color="red.500"
        borderColor="red.500"
        isLoading={isDeleting}
        onClick={onOpen}>
        Delete Mission
      </Button>

      <AlertDialog
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              textAlign="center">
              Delete Mission
            </AlertDialogHeader>

            <AlertDialogBody textAlign="center">
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} variant="outline">
                Cancel
              </Button>
              <Button
                bg="red.500"
                onClick={() => {
                  onDelete();
                  onClose();
                }}
                ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DeleteMissionButtonView;
