import React, { FC } from 'react';

import { Flex, Text } from '@chakra-ui/react';

import { CustomMission, CustomStatus } from 'types/mission';

type Props = {
  mission: CustomMission;
  children?: React.ReactNode;
};

export const ChangesRequest: FC<Props> = ({ mission, children }) => {
  const logs = mission.logs || [];

  if (logs?.length <= 0) {
    return null;
  }

  const changes = logs.find(
    (c) => c.type === CustomStatus.CLIENT_CHANGE_REQUESTED,
  );

  if (changes === undefined) {
    return null;
  }

  return (
    <Flex bg="#FFFBF6" p="20px" flex={1} direction="column">
      {children}
      <Text mt={2} fontSize="16px">
        {changes.reason || ''}
      </Text>
    </Flex>
  );
};
