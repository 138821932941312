import { useDisclosure, useToast } from '@chakra-ui/react';
import { deleteCustomMission } from 'api/custom-mission';
import { cancelMissionRequest, deleteMission } from 'api/mission';
import { selectors } from 'effector/session';
import React, { forwardRef, memo, useImperativeHandle } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { MissionStatuses } from 'types/mission';

import {
  CancelAndDeleteMissionModalProps,
  RefType,
} from './CancelAndDeleteMissionModal.props';
import CancelAndDeleteMissionModalView from './CancelAndDeleteMissionModal.view';

const Container = forwardRef<RefType, CancelAndDeleteMissionModalProps>(
  (props, ref) => {
    const toast = useToast();
    const queryCache = useQueryCache();
    const params = useParams<{ missionId: string }>();
    const { push, goBack } = useHistory();
    const accountType = selectors.useAccountType();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [
      submitCustomMission,
      { isLoading: isCustomMissionLoading },
    ] = useMutation(deleteCustomMission, {
      onSuccess: () => {
        onClose();

        toast({
          status: 'success',
          title: 'Cancellation request sent',
          isClosable: true,
          duration: 3000,
        });

        queryCache.invalidateQueries(`fetch-mission-${props.missionId}`);
        queryCache.invalidateQueries(`fetch-missions-undefined-CLIENT`);
        queryCache.invalidateQueries(`fetch-missions-undefined-PILOT`);
        queryCache.invalidateQueries(
          `fetch-missions-${MissionStatuses.COMPLETED}-PILOT`,
        );
        queryCache.invalidateQueries(`fetch-client-missions`);

        goBack();
      },
    });

    const [submit, { isLoading }] = useMutation(cancelMissionRequest, {
      onSuccess: () => {
        onClose();

        toast({
          status: 'success',
          title: 'Cancellation request sent',
          isClosable: true,
          duration: 3000,
        });

        queryCache.invalidateQueries(`fetch-mission-${props.missionId}`);
        queryCache.invalidateQueries(`fetch-missions-undefined-CLIENT`);
        queryCache.invalidateQueries(`fetch-missions-undefined-PILOT`);
        queryCache.invalidateQueries(
          `fetch-missions-${MissionStatuses.COMPLETED}-PILOT`,
        );
        queryCache.invalidateQueries(`fetch-client-missions`);

        onMissionDelete(props.missionId);
      },
    });

    const [onMissionDelete, { isLoading: isDeleting }] = useMutation(
      deleteMission,
      {
        onSuccess: () => {
          toast({ title: 'Mission Deleted' });

          push('/missions');

          queryCache.invalidateQueries(`fetch-mission-${params?.missionId}`);
          queryCache.invalidateQueries(`fetch-missions-undefined-CLIENT`);
          queryCache.invalidateQueries(`fetch-missions-undefined-PILOT`);
          queryCache.invalidateQueries(
            `fetch-missions-${MissionStatuses.COMPLETED}-PILOT`,
          );
          queryCache.invalidateQueries(`fetch-client-missions`);
        },
      },
    );

    useImperativeHandle(
      ref,
      () => ({
        onOpen,
      }),
      [],
    );

    const handleSubmit = async (reason: string) => {
      if (props.isCustomMission) {
        await submitCustomMission(props.missionId);
      } else {
        await submit({
          missionId: props.missionId,
          reason,
          requestedBy: accountType as any,
          status: 'REJECTED',
        });
      }
    };

    return (
      <CancelAndDeleteMissionModalView
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isLoading || isCustomMissionLoading || isDeleting}
        handleSubmit={handleSubmit}
      />
    );
  },
);

const CancelAndDeleteMissionModal = memo(Container);

export default CancelAndDeleteMissionModal;
