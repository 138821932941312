import React, { FC, useEffect, useRef, useState } from 'react';

import {
  Text,
  Flex,
  Image,
  Link,
  Stack,
  useToast,
  Button,
  Center,
  Checkbox,
  CircularProgress,
} from '@chakra-ui/react';

import { useCMS } from './Context';

import * as session from 'effector/session';

import * as Yup from 'yup';

import customLoginAsset from 'res/custom-login.png';
import customSignupAsset from 'res/custom-signup.png';
import payment_asset from 'res/custom-payment.png';
import payment_success from 'res/payment-success.png';

import { loadStripe } from '@stripe/stripe-js';

import {
  customLogin,
  customSignup,
  updateForPayment,
  generateClientSecret,
} from 'api/custom-mission';
import { resendEmail } from 'api/auth';
import { useMutation, useQuery } from 'react-query';

import { useHistory } from 'react-router-dom';

import { signupEvent } from 'effector/session/events';

import {
  Elements,
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { LoginForm } from './LoginModal';
import { SaveACard } from 'components/primitives/SaveACard';

import { Form, Formik } from 'formik';
import FormikInput from 'components/primitives/FormikInput/FormikInput.view';

const sx = {
  '.chakra-checkbox__control[data-checked]': {
    background: '#6728BB',
    borderColor: '#6728BB',
    color: '#fff',
  },
  '.chakra-checkbox__control[data-focus]': {
    boxShadow: 'none',
  },
};

type Status = 'accept_mission' | 'request_changes' | 'reject';

type Props = {
  onClose: () => void;
  customMissionId: string;
  onSelect: (_status: Status) => void;
};

// temp walkaround to force component render after delay inorder for stripe elements to mount
function useForceUpdate() {
  const [value, setValue] = useState(false);

  useEffect(() => {
    const forceUpdate = async () => {
      await new Promise((resolve: (value: null) => void) =>
        setTimeout(() => {
          setValue(true);
          resolve(null);
        }, 3000),
      );
    };

    forceUpdate();
  }, []);

  return value;
}

const PayButton: FC<any> = (props) => {
  const elements = useElements();

  const [isExpDisabled, setIsExpDisabled] = useState(true);
  const [isCvcDisabled, setIsCvcDisabled] = useState(true);
  const [isNumberDisabled, setIsNumberDisabled] = useState(true);

  const isUpdated = useForceUpdate();

  const { isLoading, payNow, isChecked } = props;

  const cardCvcEl = elements?.getElement(CardCvcElement);
  const cardExpEl = elements?.getElement(CardExpiryElement);
  const cardNumberEl = elements?.getElement(CardNumberElement);

  useEffect(() => {
    const unsubscribe = cardCvcEl?.on('change', (e) => {
      if (e.empty) {
        setIsCvcDisabled(true);
      } else if (e.error) {
        setIsCvcDisabled(true);
      } else if (!e.complete) {
        setIsCvcDisabled(true);
      } else if (e.complete) {
        setIsCvcDisabled(false);
      }
    });

    return () => {
      unsubscribe?.clear();
    };
  }, [cardCvcEl, isUpdated]);

  useEffect(() => {
    const unsubscribe = cardExpEl?.on('change', (e) => {
      if (e.empty) {
        setIsExpDisabled(true);
      } else if (e.error) {
        setIsExpDisabled(true);
      } else if (!e.complete) {
        setIsExpDisabled(true);
      } else if (e.complete) {
        setIsExpDisabled(false);
      }
    });

    return () => {
      unsubscribe?.clear();
    };
  }, [cardExpEl, isUpdated]);

  useEffect(() => {
    const unsubscribe = cardNumberEl?.on('change', (e) => {
      if (e.empty) {
        setIsNumberDisabled(true);
      } else if (e.error) {
        setIsNumberDisabled(true);
      } else if (!e.complete) {
        setIsNumberDisabled(true);
      } else if (e.complete) {
        setIsNumberDisabled(false);
      }
    });

    return () => {
      unsubscribe?.clear();
    };
  }, [cardNumberEl, isUpdated]);

  return (
    <Button
      fontSize="xs"
      w={['100px', '200px']}
      isLoading={isLoading}
      onClick={() => payNow()}
      isDisabled={
        !isChecked || isNumberDisabled || isCvcDisabled || isExpDisabled
      }>
      PAY NOW
    </Button>
  );
};

type PaymentFormProps = Props & {
  clientSecret: string;
};

const PaymentForm: FC<PaymentFormProps> = ({
  onClose,
  onSelect,
  clientSecret,
  customMissionId,
}) => {
  const toast = useToast();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const [isChecked, setIsChecked] = useState(false);

  // const [
  //   resend,
  //   { isLoading: isResending, isSuccess: isEmailSent },
  // ] = useMutation(resendEmail, {
  //   onSuccess: () => {
  //     toast({
  //       status: 'success',
  //       title: 'Email has been sent again',
  //       isClosable: true,
  //       duration: 3000,
  //     });
  //   },
  // });

  const [payNow, { isLoading: isPaying, isSuccess }] = useMutation(
    async () => {
      if (!stripe || !elements) {
        throw 'Stripe not loaded';
      }

      const card = elements?.getElement(CardNumberElement);

      if (!card) {
        throw 'Stripe card not loaded';
      }

      const stripeResponse = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card },
      });

      if (stripeResponse.error) {
        throw stripeResponse.error;
      }

      await updateForPayment(customMissionId);

      return stripeResponse;
    },
    {
      onError: (err: any) => {
        toast({
          status: 'error',
          title: 'Payment Error',
          description: err?.message ?? '',
          isClosable: true,
          duration: 5000,
        });
      },
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Payment Confirmed',
          isClosable: true,
          duration: 5000,
        });
      },
    },
  );

  if (isSuccess) {
    return (
      <Flex p={6} flex={1} flexWrap="wrap">
        <Flex flex={0.5}>
          <Image
            mr={6}
            w="300px"
            h="240px"
            src={payment_success}
            objectFit="cover"
            alignSelf="center"
          />
        </Flex>
        <Stack spacing={4} minW="300px" flex={1}>
          <Text fontSize="34px" fontWeight="bold">
            Mission Created
          </Text>
          <Text>
            Your Mission was successfully created. Click the button below to
            access your Mission details.
          </Text>
          <Flex>
            <Button
              w="120px"
              variant="outline"
              mr={4}
              onClick={() => onClose()}>
              CLOSE
            </Button>
            <Button
              px={3}
              onClick={() => {
                history.replace(`/custom-mission/${customMissionId}`);
              }}>
              CHECK MISSION DETAILS
            </Button>
          </Flex>
        </Stack>
      </Flex>
    );
  }

  return (
    <Flex p={6} flex={1} flexWrap="wrap">
      <Flex flex={0.5}>
        <Image
          mr={6}
          w="300px"
          h="240px"
          src={payment_asset}
          objectFit="cover"
          alignSelf="center"
        />
      </Flex>
      <Stack spacing={4} minW="300px" flex={1}>
        <Text fontSize="18px" fontWeight="bold">
          Your payment is just a click away
        </Text>
        <SaveACard
          isPilotStripeAcc
          titleProps={{ ml: 0, mt: 2, fontSize: '15px' }}
        />
        <Checkbox
          w="100%"
          my="20px"
          size="md"
          sx={sx}
          display="flex"
          alignItems="flex-start"
          isChecked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}>
          <Text fontSize="xs">
            I acknowledge that I have read and agree the{' '}
            <Link textDecoration="underline" color="buttonBgColor">
              terms and confitions
            </Link>
          </Text>
        </Checkbox>
        <Flex direction={['column', 'column', 'row']}>
          <Button
            w="200px"
            mr="10px"
            fontSize="xs"
            variant="outline"
            onClick={() => {
              onSelect(undefined as any);
            }}>
            CANCEL
          </Button>
          <PayButton
            payNow={payNow}
            isChecked={isChecked}
            isLoading={isPaying}
          />
        </Flex>
      </Stack>
    </Flex>
  );
};

type LoginProps = {
  onSignUpClick: () => void;
  onSelect: Props['onSelect'];
  customMissionId: string;
};

const Login: FC<LoginProps> = ({
  onSelect,
  onSignUpClick,
  customMissionId,
}) => {
  return (
    <Flex p={6} flex={1} direction="column">
      <LoginForm
        isSignUp
        onClose={() => {
          onSelect(undefined as any);
        }}
        onSignUpClick={onSignUpClick}
        customAsset={customLoginAsset}
        assetProps={{ objectFit: 'contain' }}
        loginApi={({ email, password }: any) =>
          customLogin(email, password, customMissionId)
        }
      />
    </Flex>
  );
};

type SignUpProps = Props & {
  onLoginClick: () => void;
  // setEmail: (_email: string) => void;
};

const SignUp: FC<SignUpProps> = ({
  onSelect,
  // setEmail,
  onLoginClick,
  customMissionId,
}) => {
  const [signup, { isLoading }] = useMutation(
    ({ email, password }: { email: string; password: string }) =>
      customSignup(email, password, customMissionId),
    {
      onSuccess: async (session) => {
        signupEvent(session);
      },
    },
  );

  return (
    <Flex p={6} flex={1} direction="column">
      <Flex>
        <Image
          src={customSignupAsset}
          h="450px"
          w={{ base: '0px', md: '400px' }}
          objectFit="contain"
        />
        <Flex flex={1} direction="column" justify="center" px={2} maxW="400px">
          <Text fontSize="24px" fontWeight="bold">
            Create your account
          </Text>
          <Text>
            Fill the info and we’ll sent all the details and instructions
          </Text>
          <Formik
            initialValues={{
              email: '',
              password: '',
              confirmPassword: '',
            }}
            onSubmit={({ email, password }) => {
              signup({
                email,
                password,
              });
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('please enter a valid email')
                .nullable(true)
                .required(),
              password: Yup.string().required(
                'Password must be at least 8 characters with a number and a letter',
              ),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
                .required(
                  'Password must be at least 8 characters with a number and a letter',
                ),
            })}>
            {({ isValid }) => (
              <Form>
                <FormikInput name="email" label="Email" />
                <FormikInput name="password" label="Password" type="password" />
                <FormikInput
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                />
                <Flex my={4}>
                  <Button
                    isDisabled={isLoading}
                    onClick={() => {
                      onSelect(undefined as any);
                    }}
                    w="185px"
                    variant="outline">
                    Cancel
                  </Button>
                  <Button
                    isDisabled={!isValid}
                    isLoading={isLoading}
                    type="submit"
                    ml={2}
                    w="185px">
                    Create
                  </Button>
                </Flex>
                <Flex justify="center" mt={2}>
                  <Text fontSize="14px">Have account?</Text>
                  <Text
                    cursor="pointer"
                    ml={1}
                    fontSize="14px"
                    fontWeight="bold"
                    color="brand.500"
                    onClick={onLoginClick}>
                    Login
                  </Text>
                </Flex>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const AcceptMission: FC<Props> = ({
  onClose,
  onSelect,
  customMissionId,
}) => {
  const { cms } = useCMS();

  const user = session.selectors.useUser();

  const stripeId = cms?.pilot?.stripeId;
  const isClient = user?.accountType === 'CLIENT';

  const queryKey = [`fetch-client-secret`, customMissionId];
  const queryFn = () =>
    generateClientSecret({
      stripeId: stripeId || '',
      customMissionId,
      userId: user?.id || '',
      userEmail: user?.email || '',
    });

  const { data, isLoading } = useQuery(queryKey, queryFn, {
    enabled: !!stripeId && !!user?.id,
  });

  const clientSecret = data?.clientSecret || '';
  const publishableKey = data?.publishableKey || '';

  const [type, setType] = useState<'login' | 'signup'>('login');

  if (isLoading) {
    return (
      <Center p={10}>
        <CircularProgress isIndeterminate color="brand.500" size="40px" />
      </Center>
    );
  }

  if (isClient) {
    if (!stripeId || !publishableKey) {
      return null;
    }

    const options = {
      stripeAccount: stripeId,
    };

    return (
      <Elements stripe={loadStripe(publishableKey || '', options)}>
        <PaymentForm
          onClose={onClose}
          onSelect={onSelect}
          clientSecret={clientSecret}
          customMissionId={customMissionId || ''}
        />
      </Elements>
    );
  }

  if (type === 'signup') {
    return (
      <SignUp
        onClose={onClose}
        onSelect={onSelect}
        onLoginClick={() => setType('login')}
        customMissionId={customMissionId || ''}
        // setEmail={(_email) => {
        //   emailRef.current = _email;
        // }}
      />
    );
  }

  return (
    <Login
      onSelect={onSelect}
      customMissionId={customMissionId}
      onSignUpClick={() => setType('signup')}
    />
  );
};
