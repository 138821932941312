import React, { useRef } from 'react';

import {
  Flex,
  Text,
  Tabs,
  Heading,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/media-query';

import { useStore } from 'effector-react';

import { MissionDetailsPanel } from 'components/modules/MissionDetailsPanel';
import { MissionLocationPanel } from 'components/modules/MissionLocationPanel';
import { MissionCheckoutPanel } from 'components/modules/MissionCheckoutPanel';
import { MissionConfirmationPanel } from 'components/modules/MissionConfirmationPanel';
import { MobileMissionCreationTabs } from 'components/modules/MobileMissionCreationTabs';

import ContentContainer from 'components/layouts/ContentContainer';
import PilotDashboardSubscriptionBox from 'components/modules/PilotHeader';

import {
  Spacer,
  SpacerFill,
  ResponsiveTab,
  ResponsiveTabList,
} from './Dashboard.style';

import {
  tabs,
  Tabselected,
  TabFormFilled,
  setTabSelected,
} from './Dashboard.utils';

import { DashboardGeneratedProps, CustomTabProps } from './Dashboard.props';

const CustomTab = ({
  tabSelected,
  isSelected,
  selected,
  ...props
}: CustomTabProps) => {
  const tabsLength = tabs.length;
  return (
    <>
      <ResponsiveTab
        background={
          isSelected ? 'white' : selected <= tabSelected ? 'white' : '#f7f7f7'
        }
        color={
          isSelected ? '#000' : selected <= tabSelected ? '#000' : '#707070'
        }
        border="1px"
        borderColor="#ccc"
        fontSize="xs"
        _selected={{ color: '#000', bg: 'white' }}
        {...props}>
        {props.children}
      </ResponsiveTab>
      {tabsLength - 1 === selected ? (
        ''
      ) : selected < tabSelected ? (
        <SpacerFill />
      ) : (
        <Spacer />
      )}
    </>
  );
};

const DashboardView = (props: DashboardGeneratedProps): JSX.Element => {
  const tabSelected = useStore(Tabselected);
  const tabsFilled = useStore(TabFormFilled);
  const clientSecretRef = useRef('');
  const [missionObj, setMissionObj] = React.useState<Object>({});

  const [isSmall] = useMediaQuery('(max-width: 600px)');

  const missionCheckout = (obj: Object) => {
    setMissionObj(obj);
  };

  return (
    <ContentContainer pb="100px">
      <>
        <PilotDashboardSubscriptionBox name={props.user.firstName} />
        <Heading
          fontFamily="Monument Extended"
          textAlign="left"
          fontSize={{ base: 'sm', md: 'xl' }}
          color="primaryColor"
          letterSpacing="wider">
          Welcome {props.user.firstName}!
        </Heading>
        <Text
          textAlign="left"
          fontSize={{ base: 'sm', md: 'xl' }}
          fontFamily="Monument Extended"
          color="#343835"
          letterSpacing="0.48px">
          Book your professional drone service, quick and easy
        </Text>
        <Flex pb="120px">
          <Tabs
            isLazy
            variant="soft-rounded"
            isFitted
            w="100%"
            my="20px"
            index={tabSelected}
            onChange={(index: number) => {
              if (tabSelected === 3) return;
              setTabSelected(index);
            }}>
            <>
              {isSmall ? (
                <MobileMissionCreationTabs tabSelected={tabSelected} />
              ) : (
                <ResponsiveTabList
                  mb="20px"
                  display="flex"
                  justifyContent="space-around"
                  w="auto">
                  {tabs.map((text: string, index: number) => {
                    return (
                      <CustomTab
                        key={index}
                        selected={index}
                        tabSelected={tabSelected}
                        onClick={() => {
                          if (tabSelected === 3) return;
                          setTabSelected(index);
                        }}
                        isDisabled={!tabsFilled[index]}
                        cursor={
                          !tabsFilled[index] || tabSelected === 3
                            ? 'not-allowed'
                            : 'pointer'
                        }
                        _focus={{
                          outline: 'none',
                        }}
                        _selected={{
                          bg: 'primaryColor',
                          color: 'white',
                        }}>
                        {text}
                      </CustomTab>
                    );
                  })}
                </ResponsiveTabList>
              )}
            </>
            <TabPanels>
              <TabPanel p={0}>
                <MissionLocationPanel packages={props.packages} />
              </TabPanel>
              <TabPanel w="100%" p={0}>
                <MissionDetailsPanel
                  packages={props.packages}
                  clientSecretRef={clientSecretRef}
                  mission={missionObj}
                  missionCheckoutHandler={missionCheckout}
                />
              </TabPanel>
              <TabPanel p={0}>
                <MissionCheckoutPanel
                  packages={props.packages}
                  clientSecretRef={clientSecretRef}
                  mission={missionObj}
                />
              </TabPanel>
              <TabPanel p={0}>
                <MissionConfirmationPanel packages={props.packages} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </>
    </ContentContainer>
  );
};

export default DashboardView;
