import React from 'react';

import { Flex } from '@chakra-ui/react';

import { ExampleGeneratedProps } from './Example.props';

const ExampleView = (props: ExampleGeneratedProps): JSX.Element => (
  <Flex>Example</Flex>
);

export default ExampleView;
