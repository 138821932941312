import React, { FC } from 'react';

import { Avatar } from '@chakra-ui/avatar';
import { Tooltip } from '@chakra-ui/tooltip';
import {
  Box,
  Center,
  Divider,
  Flex,
  Link,
  Stack,
  Text,
} from '@chakra-ui/layout';

import { MdPhone, MdRoom } from 'react-icons/md';

import { useCMS } from './Context';

import { daysMap } from 'constants/common';
import { socialIconsMap } from 'utils/socialItemsMap';

import { SocialType, Days } from 'types/cms';

import convertTextFromHtml from 'utils/renderHtmlTexts';
import { normalize } from 'path';
import { lightFormat } from 'date-fns';

const days = Object.values(Days).map((i) => i);

const sortDays = (a: any, b: any) => {
  return days.indexOf(a.day) - days.indexOf(b.day);
};

export const UserInfo: FC<{
  showBasicInfo?: boolean;
}> = ({ showBasicInfo }) => {
  const { cms, brandColor } = useCMS();

  const templateType = cms?.templateType;
  const fullName = cms?.about?.companyName || '';

  const profilePic = cms?.signedUrl || '';
  const bannerImage = cms?.bannerSignedUrl;

  const onSocialIconPress = (url: string) => {
    /* Had to do this because if the link doesn't contain an http:, it gets appended rather than replaced */
    if (!url.includes('http') || !url.includes('https')) {
      window.open('http://' + url, '_blank');
    } else {
      window.open(url, '_blank');
    }
  };

  const alwaysAvailable = cms?.about?.alwaysAvailable;
  const availability = cms?.about?.dayAvailability?.sort(sortDays);

  if (templateType === 1) {
    return (
      <Flex
        py="80px"
        id="About"
        align="center"
        justify="center"
        direction="column"
        color="#fff"
        backgroundImage={`url(${bannerImage})`}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        position="relative"
        fontSize={15}
        zIndex={0}
        _before={{
          content: `""`,
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage:
            'linear-gradient(transparent, rgba(0,0,0,0.4), rgba(0,0,0,0.6))',
          zIndex: -1,
        }}>
        <Box
          h="162px"
          w="162px"
          bg="#fff"
          borderRadius="100px"
          border={`8px solid ${brandColor}`}>
          <Avatar w="100%" h="100%" src={profilePic} />
        </Box>
        <Text
          mt="10px"
          mb="10px"
          fontSize="28px"
          fontWeight="bold"
          textShadow="0px 0px 5px #000">
          {fullName}
        </Text>
        <Text
          fontWeight={400}
          maxW={900}
          textAlign="center"
          textShadow="0px 0px 5px #000"
          dangerouslySetInnerHTML={{
            __html: convertTextFromHtml(cms?.about?.introduction || ''),
          }}
        />
        <Stack mt="20px" isInline spacing={6}>
          {cms?.socials?.map((item, index) => (
            <Box
              key={`${item.type}-${index}`}
              onClick={() => onSocialIconPress(item?.url)}>
              <Tooltip
                py={2}
                px={4}
                borderRadius="5px"
                hasArrow
                aria-label={item.url}
                label={item.url}>
                <Box maxH="35px">
                  {socialIconsMap(item.type as keyof typeof SocialType)}
                </Box>
              </Tooltip>
            </Box>
          ))}
        </Stack>
      </Flex>
    );
  }

  return (
    <Center p={{ base: 4, md: 5 }} mt={5} id="About">
      <Flex
        p={10}
        mx={5}
        flex={1}
        gridGap={4}
        w="100%"
        bg="#fff"
        maxW="1000px"
        align="center"
        borderRadius={12}
        position="relative"
        direction={{ base: 'column', md: 'row' }}
        boxShadow="0 0 20px 0 rgba(0, 0, 0, 0.08)">
        {showBasicInfo ? (
          <Center w="100%">
            <Stack align="center" maxW="320px" w="100%" alignSelf="center">
              {/* Template 0, profile pic */}
              <Box
                h="162px"
                w="162px"
                top={-16}
                bg="#fff"
                borderRadius="100px"
                border={`8px solid ${brandColor}`}>
                <Avatar w="100%" h="100%" src={profilePic} />
              </Box>

              <Text
                color="#3C3D4B"
                fontSize="25px"
                fontWeight="bold"
                textAlign={'center'}>
                {fullName}
              </Text>
              <Stack isInline align="center" position="relative">
                <Box position="absolute" left="-16px">
                  <MdRoom size="20px" color="#F14F4F" />
                </Box>
                <Text fontWeight="700" color="#71738A">{`${
                  cms?.location?.city ? `${cms?.location?.city},` : ''
                } ${cms?.location?.state || ''}`}</Text>
              </Stack>
            </Stack>
          </Center>
        ) : (
          <>
            <Stack align="center" maxW="320px" w="100%">
              {/* Template 0, profile pic */}
              <Box
                h="162px"
                w="162px"
                top={-16}
                bg="#fff"
                borderRadius="100px"
                border={`8px solid ${brandColor}`}>
                <Avatar w="100%" h="100%" src={profilePic} />
              </Box>

              <Text color="#3C3D4B" fontSize="25px" fontWeight="bold">
                {fullName}
              </Text>
              <Stack isInline align="center">
                <MdRoom size="20px" color="#F14F4F" />
                <Text fontWeight="700" color="#71738A">{`${
                  cms?.location?.city ? `${cms?.location?.city},` : ''
                } ${cms?.location?.state || ''}`}</Text>
              </Stack>
              {cms?.phone ? (
                <Stack isInline align="center">
                  <MdPhone size="20px" color="#F14F4F" />
                  <Text as={Link as any} fontWeight="700" color="#71738A">
                    {cms?.phone || ''}
                  </Text>
                </Stack>
              ) : null}
              <Stack isInline>
                {cms?.socials?.map((item, index) => (
                  <Box
                    key={`${item.type}-${index}`}
                    onClick={() => onSocialIconPress(item?.url)}>
                    <Tooltip
                      py={2}
                      px={4}
                      borderRadius="5px"
                      hasArrow
                      aria-label={item.url}
                      label={item.url}>
                      <Box maxH="35px">
                        {socialIconsMap(item.type as keyof typeof SocialType)}
                      </Box>
                    </Tooltip>
                  </Box>
                ))}
              </Stack>
            </Stack>
            <Stack
              flex={1}
              w="100%"
              noOfLines={6}
              pl={{ base: 0, md: 10 }}
              mt={{ base: 4, md: 0 }}>
              <Text
                dangerouslySetInnerHTML={{
                  __html: convertTextFromHtml(cms?.about?.introduction || ''),
                }}
                fontSize={15}
                mb={4}></Text>
              <Text fontWeight={'bold'} fontSize="18px">
                Availability
              </Text>
              <Divider />
              <Stack
                flex={1}
                justifyContent="space-between"
                flexDir="row"
                w="100%"
                maxW="500px"
                maxH="250px"
                flexWrap="wrap"
                gridGap={3}>
                {alwaysAvailable ? (
                  <Text
                    px={2}
                    py={1}
                    textAlign="center"
                    borderRadius={10}
                    bg={`${brandColor}30`}
                    color={brandColor}
                    fontWeight="bold">
                    Always available
                  </Text>
                ) : (
                  availability?.map((d, i) => {
                    return (
                      <Flex
                        maxW="250px"
                        minW="230px"
                        flex={1}
                        key={`${d.day}-${i}`}
                        fontSize="13px"
                        fontWeight="600px"
                        align="center"
                        justifyContent="space-between"
                        color="#8d8d8d"
                        flexWrap="nowrap">
                        <Text
                          fontWeight="bold"
                          px={2}
                          py={2}
                          textAlign="center"
                          borderRadius={30}
                          bg={`${brandColor}30`}
                          color={brandColor}>
                          {daysMap[d.day]}
                        </Text>
                        <Text>
                          {d.start?.hour || '-'} : {d.start?.minutes || '00'}{' '}
                          {d.start.meridiem || '-'} to {d.end?.hour || '-'} :{' '}
                          {d.end?.minutes || '00'} {d.end.meridiem || '-'}
                        </Text>
                      </Flex>
                    );
                  })
                )}
              </Stack>
            </Stack>
          </>
        )}
      </Flex>
    </Center>
  );
};
