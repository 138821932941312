import React, { useEffect } from 'react';

import { useQuery } from 'react-query';

import { checkCMS, getMyCMS } from 'api/cms';
import { DashboardProps } from './Dashboard.props';

import DashboardView from './Dashboard.view';

import * as chargebee from 'effector/chargebee';

const DashboardContainer = (props: DashboardProps) => {
  const subscription = chargebee.selectors.useCbSub();
  const { data: publishedCMS } = useQuery(`fetch-check-cms`, () => checkCMS(), {
    retry: 0,
    onError: () => {
      console.log('error');
    },
  });

  const isPublished = publishedCMS?.isExist;
  const status = isPublished ? 'PUBLISH' : 'DRAFT';

  const { data, isLoading } = useQuery(`fetch-my-cms`, () => getMyCMS(status), {
    retry: 0,
    onError: () => {
      console.log('error');
    },
  });

  useEffect(() => {
    const onStripeRedirect = async () => {
      props.history.replace({ search: '' });
    };

    const search = new URLSearchParams(props.location?.search);
    const redirect = search.get('redirect');

    if (redirect !== 'true') {
      return;
    }

    onStripeRedirect();
  }, []);

  return (
    <DashboardView
      isLoading={isLoading}
      subscription={subscription}
      hasPremiumWebsite={Boolean(data?.about?.companyName)}
      isSitePublished={isPublished}
    />
  );
};

export default DashboardContainer;
