import React, { memo } from 'react';

import {
  Text,
  Flex,
  Modal,
  Image,
  Button,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

import success_char from 'res/success-characters.png';

import { useHistory } from 'react-router-dom';
import { routesSettings } from 'constants/routes';

import { closeEv, useAlert } from 'effector/alert';

import { AlertHandlerProps } from './AlertHandler.props';

const AlertHandler = memo<AlertHandlerProps>(() => {
  const { push } = useHistory();
  const {
    isOpen,
    img = success_char,
    title,
    message,
    btnText,
    goToDashboard,
  } = useAlert();

  return (
    <Modal
      size="2xl"
      isCentered
      isOpen={isOpen}
      onClose={() => {
        if (goToDashboard) {
          push(routesSettings.PILOT_DASHBOARD.path);
        }

        closeEv();
      }}
      motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          textAlign="center"
          fontWeight="500"
          fontFamily="Monument Extended">
          {title}
        </ModalHeader>
        <ModalBody>
          <Flex p={6} flex={1} direction="column">
            <Image src={img} maxH="200px" objectFit="contain" />
            <Text
              mt={4}
              color="#000"
              textAlign="center"
              fontFamily="Monument Extended"
              fontSize="20px"
              whiteSpace="pre-line">
              {message}
            </Text>
            {btnText && (
              <Button
                mt={4}
                w="200px"
                alignSelf="center"
                onClick={() => {
                  closeEv();

                  if (goToDashboard) {
                    push(routesSettings.PILOT_DASHBOARD.path);
                  } else {
                    push(routesSettings.PILOT_MY_WEBSITE.path);
                  }
                }}>
                {btnText}
              </Button>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

export default AlertHandler;
