import api from 'api/instance';
import { CustomMission, CustomStatus } from 'types/mission';

const URL = process.env.REACT_APP_API_URL;

export const createCustomMission = async (payload: any) => {
  const { data } = await api.post(`${URL}/custom-missions`, payload);

  return data;
};

export const requestChanges = async (
  message: string,
  customMissionId: string,
  client?: string,
) => {
  const { data } = await api.patch(
    `${URL}/custom-missions/client-change-request-mission/${customMissionId}`,
    {
      message,
      client,
    },
  );

  return data;
};

export const clientRejectMission = async (
  message: string,
  customMissionId: string,
  client?: string,
) => {
  const { data } = await api.patch(
    `${URL}/custom-missions/client-reject-mission/${customMissionId}`,
    {
      message,
      client,
    },
  );

  return data;
};

export const pilotRejectMission = async (
  message: string,
  customMissionId: string,
) => {
  const { data } = await api.patch(
    `${URL}/custom-missions/pilot-reject-mission/${customMissionId}`,
    {
      message,
    },
  );

  return data;
};

type ListResponse = {
  results: Array<CustomMission>;
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
};

export const getCustomMissions = async ({
  page,
  status,
  limit,
  isClient,
  search,
  statusFilter,
}: {
  search?: string;
  limit?: number;
  page?: number;
  isClient?: boolean;
  statusFilter?: string;
  status?: 'REQUESTED' | 'PILOT_ACCEPTED' | 'COMPLETED';
}) => {
  let path = 'pilot-custom-missions';

  if (isClient) {
    path = 'client-custom-missions';
  }

  const { data } = await api.get<ListResponse>(
    `${URL}/custom-missions/${path}`,
    {
      params: {
        limit,
        page,
        search,
        status: isClient
          ? Boolean(status)
            ? [CustomStatus.COMPLETED]
            : [
                CustomStatus.REQUESTED,
                CustomStatus.CLIENT_CHANGE_REQUESTED,
                CustomStatus.ASSETS_UPLOADED,
                CustomStatus.PENDING_PAYMENT,
                CustomStatus.CLIENT_REJECTED,
                CustomStatus.FLIGHT_COMPLETED,
                CustomStatus.FOR_PAYMENT,
                CustomStatus.ORDERED,
                CustomStatus.PILOT_ACCEPTED,
                CustomStatus.PILOT_REJECTED,
              ]
          : Boolean(statusFilter)
          ? statusFilter
          : Boolean(status)
          ? [
              CustomStatus.REQUESTED,
              CustomStatus.CLIENT_CHANGE_REQUESTED,
              CustomStatus.ORDERED,
            ]
          : [
              CustomStatus.ASSETS_UPLOADED,
              CustomStatus.PENDING_PAYMENT,
              CustomStatus.CLIENT_REJECTED,
              CustomStatus.COMPLETED,
              CustomStatus.FLIGHT_COMPLETED,
              CustomStatus.FOR_PAYMENT,
              CustomStatus.PILOT_ACCEPTED,
              CustomStatus.PILOT_REJECTED,
            ],
        sortBy: 'createdAt:desc',
      },
    },
  );

  return data;
};

export const getCustomMissionById = async (customMissionId: string) => {
  const { data } = await api.get<CustomMission>(
    `${URL}/custom-missions/details/${customMissionId}`,
  );

  return data;
};

export const pilotAcceptMission = async (
  payload: any,
  customMissionId: string,
) => {
  const { data } = await api.patch(
    `${URL}/custom-missions/pilot-accept-mission/${customMissionId}`,
    payload,
  );

  return data;
};

export const clientAcceptMission = async ({
  client,
  customMissionId,
}: {
  customMissionId: string;
  client?: string;
}) => {
  const { data } = await api.patch(
    `${URL}/custom-missions/client-accept-mission/${customMissionId}`,
    {
      client,
    },
  );

  return data;
};

export const generateClientSecret = async ({
  stripeId,
  customMissionId,
  userEmail,
  userId,
}: {
  customMissionId: string;
  stripeId: string;
  userId: string;
  userEmail: string;
}) => {
  const { data } = await api.post<{
    data: {
      publishableKey: string;
      clientSecret: string;
    };
  }>(`${URL}/custom-missions/generate-client-secret/${customMissionId}`, {
    stripeId,
    userEmail,
    userId,
  });

  return data?.data;
};

export const updateForPayment = async (customMissionId: string) => {
  try {
    const { data } = await api.patch(
      `${URL}/custom-missions/update-for-payment/${customMissionId}`,
    );

    return data;
  } catch (error) {
    return '';
  }
};

export const customSignup = async (
  email: string,
  password: string,
  customMissionId: string,
) => {
  const { data } = await api.post(
    `${URL}/custom-missions/create-user/${customMissionId}`,
    {
      email,
      password,
    },
  );

  return data;
};

export const customLogin = async (
  email: string,
  password: string,
  customMissionId: string,
) => {
  const { data } = await api.post(
    `${URL}/custom-missions/login/${customMissionId}`,
    {
      email,
      password,
    },
  );

  return data;
};

export const markAsComplete = async (customMissionId: string) => {
  const { data } = await api.patch(
    `${URL}/custom-missions/mark-as-complete/${customMissionId}`,
  );

  return data;
};

export const reportMission = async (
  customMissionId: string,
  message: string,
) => {
  const { data } = await api.patch(
    `${URL}/custom-missions/report-problem/${customMissionId}`,
    {
      message,
    },
  );

  return data;
};

export const pilotCreateMission = async (payload: any) => {
  const { data } = await api.post<{ id: string }>(
    `${URL}/custom-missions/pilot/create`,
    payload,
  );

  return data;
};

export const deleteCustomMission = async (missionId: string) => {
  const { data } = await api.delete(
    `${URL}/custom-missions/delete/${missionId}`,
  );

  return data;
};
