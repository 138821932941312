import React, { useCallback } from 'react';

import { useMutation, useQueryCache } from 'react-query';

import {
  cancelSubscription,
  deleteSubscription,
  reactivateSubscription,
} from 'api/chargebee';

import { useToast } from '@chakra-ui/toast';

import * as session from 'effector/session';
import * as chargebee from 'effector/chargebee';

import { subscribeToPro, unsubscribeToPro } from 'api/auth';
import { toggleProEvent } from 'effector/session/events';

import { SettingsProps } from './Settings.props';

import SettingsView from './Settings.view';

const SettingsContainer = (_: SettingsProps) => {
  const toast = useToast();
  const queryCache = useQueryCache();

  const uid = session.selectors.useUserId() || '';
  const user = session.selectors.useUser();

  const cbSubId = chargebee.selectors.useCbSubId();
  const subscription = chargebee.selectors.useCbSub();
  const isCbFetching = chargebee.selectors.useIsCbFetching();

  const queryKey = `fetch-user-${uid}`;

  const [togglePro] = useMutation(
    (isCancelling: boolean) =>
      isCancelling ? unsubscribeToPro(uid) : subscribeToPro(uid),
    {
      onSuccess: (_, isCancelling) => {
        queryCache.invalidateQueries(queryKey, { exact: true });

        toggleProEvent(!isCancelling);
      },
    },
  );

  const [cancel, { isLoading: isCancelling }] = useMutation(
    cancelSubscription,
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Subscription Cancelled',
          duration: 5000,
          isClosable: true,
        });

        chargebee.fetchCbSubsEv();

        if (subscription?.subscription?.status === 'in_trial') {
          return;
        }

        togglePro(true);
      },
    },
  );

  const [reactivate, { isLoading: isReactivating }] = useMutation(
    reactivateSubscription,
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Subscription Reactivated',
          duration: 5000,
          isClosable: true,
        });

        chargebee.fetchCbSubsEv();

        togglePro(false);
      },
    },
  );

  const [deleteSub, { isLoading: isDeleting }] = useMutation(
    async (subId: string) => {
      await deleteSubscription(subId);

      // sleep for 10 seconds since delete subscription takes a while to process
      await new Promise((resolve) => setTimeout(resolve, 10000));
    },
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Subscription Deleted Successfully',
          isClosable: true,
          duration: 2000,
        });

        chargebee.fetchCbSubsEv();

        togglePro(true);
      },
    },
  );

  const onCancelPress = useCallback(() => {
    cancel(cbSubId);
  }, [cbSubId]);

  const onReactivatePress = useCallback(() => {
    reactivate(cbSubId);
  }, [cbSubId]);

  const onDeleteSubscription = useCallback(() => {
    deleteSub(cbSubId);
  }, [cbSubId]);

  return (
    <SettingsView
      data={subscription}
      user={user}
      isLoading={isCbFetching}
      isCancelling={isCancelling}
      isDeleting={isDeleting}
      onCancelPress={onCancelPress}
      isReactivating={isReactivating}
      onReactivatePress={onReactivatePress}
      onDeleteSubscription={onDeleteSubscription}
    />
  );
};

export default SettingsContainer;
