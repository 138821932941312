enum ROUTES {
  ERROR = 'ERROR',
  // AUTH ROUTE ENTRY
  AUTH_ENHANCED_DASHBOARD = 'AUTH_ENHANCED_DASHBOARD',
  AUTH_ACTIVATE_ACCOUNT = 'AUTH_ACTIVATE_ACCOUNT',
  AUTH_NOT_FOUND = 'AUTH_NOT_FOUND',
  AUTH_STRIPE_CONNECT_SUCCESS = 'AUTH_STRIPE_CONNECT_SUCCESS',
  AUTH_STRIPE_CONNECT = 'AUTH_STRIPE_CONNECT',
  AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD',
  PILOT_WEBSITE = 'PILOT_WEBSITE',
  AUTH_HOME = 'AUTH_HOME',
  AUTH_REGISTER = 'AUTH_REGISTER',
  AUTH_EXAMPLE = 'AUTH_EXAMPLE',
  AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD',
  AUTH_LOGIN = 'AUTH_LOGIN',
  // CLIENT ROUTE ENTRY
  CLIENT_PREMIUM_MISSION_CREATE = 'CLIENT_PREMIUM_MISSION_CREATE',
  CLIENT_MISSION_UPDATE = 'CLIENT_MISSION_UPDATE',
  CLIENT_MISSION_DETAILS = 'CLIENT_MISSION_DETAILS',
  CLIENT_SETTINGS = 'CLIENT_SETTINGS',
  CLIENT_SUPPORT = 'CLIENT_SUPPORT',
  CLIENT_MISSIONS = 'CLIENT_MISSIONS',
  CLIENT_DASHBOARD = 'CLIENT_DASHBOARD',
  // PILOT ROUTE ENTRY
  PILOT_TUTORIALS = 'PILOT_TUTORIALS',
  PILOT_PILOT_WEBSITE_PREVIEW = 'PILOT_PILOT_WEBSITE_PREVIEW',
  PILOT_MOBILE_CREATE_CUSTOM_MISSION = 'PILOT_MOBILE_CREATE_CUSTOM_MISSION',
  PILOT_CREATE_CUSTOM_MISSION = 'PILOT_CREATE_CUSTOM_MISSION',
  PILOT_CUSTOM_MISSION = 'PILOT_CUSTOM_MISSION',
  PILOT_RESOURCES = 'PILOT_RESOURCES',
  PILOT_EDIT_WEBSITE = 'PILOT_EDIT_WEBSITE',
  PILOT_CREATE_WEBSITE = 'PILOT_CREATE_WEBSITE',
  PILOT_PREMIUM_PAYMENT = 'PILOT_PREMIUM_PAYMENT',
  PILOT_MISSION_DETAILS = 'PILOT_MISSION_DETAILS',
  PILOT_SUPPORT = 'PILOT_SUPPORT',
  PILOT_EDUCATION = 'PILOT_EDUCATION',
  PILOT_MY_WEBSITE = 'PILOT_MY_WEBSITE',
  PILOT_PRO_MISSIONS = 'PILOT_PRO_MISSIONS',
  PILOT_DA_MISSIONS = 'PILOT_DA_MISSIONS',
  PILOT_SETTINGS = 'PILOT_SETTINGS',
  PILOT_DASHBOARD = 'PILOT_DASHBOARD',
  PILOT_WELCOME_PAGE = 'PILOT_WELCOME_PAGE',
}

export const routesSettings = Object.freeze({
  // AUTH ROUTE SETTINGS ENTRY
  [ROUTES.AUTH_ACTIVATE_ACCOUNT]: {
    name: 'ActivateAccount',
    path: '/activate-account',
    getPath: () => `/activate-account*`,
  },
  [ROUTES.AUTH_NOT_FOUND]: {
    name: 'NotFound',
    path: '/*',
    getPath: () => `/*`,
  },
  [ROUTES.AUTH_STRIPE_CONNECT_SUCCESS]: {
    name: 'StripeConnectSuccess',
    path: '/stripe-connect/success',
    getPath: () => `/stripe-connect/success`,
  },
  [ROUTES.AUTH_STRIPE_CONNECT]: {
    name: 'StripeConnect',
    path: '/stripe-connect',
    getPath: () => `/stripe-connect`,
  },
  [ROUTES.AUTH_RESET_PASSWORD]: {
    name: 'ResetPassword',
    path: '/reset-password/:token/:email?/:missionId?',
    getPath: () => `/reset-password/:token/:email?/:missionId?`,
  },
  [ROUTES.PILOT_WEBSITE]: {
    name: 'PilotWebsite',
    path: '/cms/:company',
    getPath: () => `/cms/:company`,
  },
  [ROUTES.AUTH_HOME]: {
    name: 'Home',
    path: '/home',
    getPath: () => `/home`,
  },
  [ROUTES.AUTH_REGISTER]: {
    name: 'Register',
    path: '/register',
    getPath: () => `/register`,
  },
  [ROUTES.AUTH_EXAMPLE]: {
    name: 'Example',
    path: '/example',
    getPath: () => `/example`,
  },
  [ROUTES.AUTH_FORGOT_PASSWORD]: {
    name: 'Forgot Password',
    path: '/forgot-password',
    getPath: () => `/forgot-password`,
  },
  [ROUTES.AUTH_LOGIN]: {
    name: 'Login',
    path: '/login',
    getPath: () => `/login`,
  },
  // CLIENT ROUTE SETTINGS ENTRY
  [ROUTES.CLIENT_PREMIUM_MISSION_CREATE]: {
    name: 'PremiumMissionCreate',
    path: '/create-mission',
    getPath: () => `/create-mission`,
  },
  [ROUTES.CLIENT_MISSION_UPDATE]: {
    name: 'MissionUpdate',
    path: '/mission/update/:missionId',
    getPath: () => `/mission/update/:missionId`,
  },
  [ROUTES.CLIENT_MISSION_DETAILS]: {
    name: 'MissionDetails',
    path: '/mission/details/:missionId',
    getPath: () => `/mission/details/:missionId`,
  },
  [ROUTES.CLIENT_SETTINGS]: {
    name: 'Settings',
    path: '/settings',
    getPath: () => `/settings`,
  },
  [ROUTES.CLIENT_SUPPORT]: {
    name: 'Support',
    path: '/support',
    getPath: () => `/support`,
  },
  [ROUTES.CLIENT_MISSIONS]: {
    name: 'Missions',
    path: '/missions',
    getPath: () => `/missions`,
  },
  [ROUTES.CLIENT_DASHBOARD]: {
    name: 'Dashboard',
    path: '/dashboard',
    getPath: () => `/dashboard`,
  },
  // PILOT ROUTE SETTINGS ENTRY
  [ROUTES.PILOT_TUTORIALS]: {
    name: 'Tutorials',
    path: '/pilot/tutorials',
    getPath: () => `/pilot/tutorials`,
  },
  [ROUTES.PILOT_PILOT_WEBSITE_PREVIEW]: {
    name: 'PilotWebsitePreview',
    path: '/cms/preview/:company',
    getPath: () => `/cms/preview/:company`,
  },
  [ROUTES.PILOT_CREATE_CUSTOM_MISSION]: {
    name: 'CreateCustomMission',
    path: '/custom-mission/create',
    getPath: () => `/custom-mission/create`,
  },
  [ROUTES.PILOT_CUSTOM_MISSION]: {
    name: 'CustomMission',
    path: '/custom-mission/:id',
    getPath: () => `/custom-mission/:id`,
  },
  [ROUTES.PILOT_RESOURCES]: {
    name: 'Resources',
    path: '/resources',
    getPath: () => `/resources`,
  },
  [ROUTES.PILOT_EDIT_WEBSITE]: {
    name: 'EditWebsite',
    path: '/my-website/edit',
    getPath: () => `/my-website/edit`,
  },
  [ROUTES.PILOT_CREATE_WEBSITE]: {
    name: 'CreateWebsite',
    path: '/my-website/create',
    getPath: () => `/my-website/create`,
  },
  [ROUTES.PILOT_PREMIUM_PAYMENT]: {
    name: 'PremiumPayment',
    path: '/pilot/premium-payment',
    getPath: () => `/pilot/premium-payment`,
  },
  [ROUTES.PILOT_MISSION_DETAILS]: {
    name: 'MissionDetails',
    path: '/pilot/mission/details/:missionId',
    getPath: () => `/pilot/mission/details/:missionId`,
  },
  [ROUTES.PILOT_SUPPORT]: {
    name: 'Support',
    path: '/support',
    getPath: () => `/support`,
  },
  [ROUTES.PILOT_EDUCATION]: {
    name: 'Education',
    path: '/education',
    getPath: () => `/education`,
  },
  [ROUTES.PILOT_MY_WEBSITE]: {
    name: 'MyWebsite',
    path: '/my-website',
    getPath: () => `/my-website`,
  },
  [ROUTES.PILOT_PRO_MISSIONS]: {
    name: 'PremiumMissions',
    path: '/pilot/pro-missions',
    getPath: () => `/pilot/pro-missions`,
  },
  [ROUTES.PILOT_DA_MISSIONS]: {
    name: 'MyLeads',
    path: '/pilot/da-missions',
    getPath: () => `/pilot/da-missions`,
  },
  [ROUTES.PILOT_WELCOME_PAGE]: {
    name: 'WelcomePage',
    path: '/pilot/welcome',
    getPath: () => `/pilot/welcome`,
  },
  [ROUTES.PILOT_SETTINGS]: {
    name: 'Settings',
    path: '/settings',
    getPath: () => `/settings`,
  },
  [ROUTES.PILOT_DASHBOARD]: {
    name: 'Dashboard',
    path: '/pilot/dashboard',
    getPath: () => `/pilot/dashboard`,
  },
});

export default ROUTES;
