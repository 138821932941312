import React from 'react';

import { MobileMissionDetailsProps } from './MobileMissionDetails.props';

import MobileMissionDetailsView from './MobileMissionDetails.view';

const MobileMissionDetailsContainer = (props: MobileMissionDetailsProps) => {
  return <MobileMissionDetailsView {...props} />;
};

export default MobileMissionDetailsContainer;
