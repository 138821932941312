/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createStandaloneToast } from '@chakra-ui/toast';
import { logoutEvent } from 'effector/session/events';
import { ReactQueryConfig } from 'react-query';

import theme from '../theme/index';

const toast = createStandaloneToast({ theme });

function clearSession() {
  logoutEvent();

  window.location.href = '/';
}

export const reactQueryConfig: ReactQueryConfig = {
  queries: {
    retry: 0,
    refetchOnWindowFocus: false,
    onError: (err: any) => {
      const errorType = String(err?.response?.type || '');

      const error =
        typeof err?.response?.data?.message === 'string'
          ? err?.response?.data?.message
          : 'Something went wrong with the request';

      // TODO: Put toast in view layer
      if (error === 'This account is not yet activated') {
        return;
      }

      toast({
        status: 'error',
        title: 'Error',
        description: error,
        isClosable: true,
        duration: 5000,
      });

      if (errorType === 'clear_session') {
        setTimeout(() => {
          clearSession();
        }, 1000);
      }
    },
  },
  mutations: {
    onError: (err: any) => {
      const errorType = String(err?.response?.type || '');
      const error =
        typeof err?.response?.data?.message === 'string'
          ? err?.response?.data?.message
          : 'Something went wrong with the request';

      // TODO: Put toast in view layer
      toast({
        status: 'error',
        title: 'Error',
        description: error,
        isClosable: true,
        duration: 5000,
      });

      if (errorType === 'clear_session') {
        setTimeout(() => {
          clearSession();
        }, 1000);
      }
    },
  },
};
