import { Box, Center, Flex, Spinner, Text } from '@chakra-ui/react';
import Pagination from 'components/primitives/Pagination';
import { missionStatusColorsMap } from 'constants/assetStatusMap';
import { format } from 'date-fns';
import React from 'react';
import { MissionStatuses } from 'types/mission';

import { MobilePremiumMissionListProps } from './MobilePremiumMissionList.props';

const MobilePremiumMissionListView = (
  props: MobilePremiumMissionListProps,
): JSX.Element => {
  const {
    missions,
    isLoading,
    totalPages,
    page,
    isMissions,
    setPage,
    onMissionClick,
  } = props;
  return (
    <Flex direction="column">
      {isLoading ? (
        <Center minH="200px" flex={1}>
          <Spinner />
        </Center>
      ) : missions.length > 0 ? (
        <Flex direction="column">
          <Flex direction="column" minH="400px">
            {missions?.map((mission) => (
              <Flex
                gridGap="10px"
                key={mission.id}
                p="10px 20px"
                borderBottom="1px solid #ccc"
                direction="column"
                onClick={() => {
                  onMissionClick(mission.id);
                }}>
                <Flex justify="space-between" align="flex-end">
                  <Box>
                    <Text fontWeight="600" maxW="100%" noOfLines={1}>
                      {mission?.client?.firstName || ''}{' '}
                      {mission?.client?.lastName || ''}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="12px" noOfLines={1}>
                      {format(new Date(mission?.dueDate), 'dd MMM')}
                    </Text>
                  </Box>
                </Flex>
                <Flex justify="space-between" align="flex-end">
                  <Box>
                    <Text fontSize="12px" maxW="80%" noOfLines={1}>
                      {mission?.location || ''}
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontSize="12px"
                      fontWeight="bold"
                      noOfLines={1}
                      textTransform="capitalize"
                      color={
                        mission?.status
                          ? missionStatusColorsMap[
                              mission?.status as MissionStatuses
                            ]
                          : '#000'
                      }>
                      {mission.status?.toLocaleLowerCase()}
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            ))}
          </Flex>
          {isMissions && totalPages !== 1 && page <= totalPages ? (
            <Flex mt={6} justify="flex-end">
              <Pagination
                currentPage={page}
                totalPages={totalPages}
                onNextClick={() => setPage(page + 1)}
                onPreviousClick={() => setPage(page - 1)}
                onPageNumberClick={(pageNo) => setPage(pageNo)}
              />
            </Flex>
          ) : null}
        </Flex>
      ) : (
        <Text w="100%" m="30px">
          No missions available
        </Text>
      )}
    </Flex>
  );
};

export default MobilePremiumMissionListView;
