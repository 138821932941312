import React, { forwardRef, useImperativeHandle } from 'react';
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Image,
  Text,
  Button,
  ModalCloseButton,
  Box,
  useDisclosure,
} from '@chakra-ui/react';

import LogoutImg from 'res/logout-img.png';
import { LogoutModalProps, RefType } from './LogoutModalProps';

const LogoutModal = forwardRef<RefType, LogoutModalProps>((props, ref) => {
  const { onLogout } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  useImperativeHandle(
    ref,
    () => ({
      onOpen,
    }),
    [],
  );

  return (
    <Flex
      minW="80vw"
      direction="column"
      alignSelf="flex-start"
      flex={1}
      h="100%">
      <Modal size="xs" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay zIndex={0} />
        <ModalContent>
          <ModalBody display="flex" flexDirection="column" alignItems="center">
            <ModalCloseButton />
            <Text
              mt={6}
              textAlign="center"
              fontFamily="Monument Extended"
              fontWeight="200"
              fontSize="20px"
              color="#373737"
              mb="15px">
              See you soon!
            </Text>
            <Image src={LogoutImg} mb="20px" />
            <Button
              ml="5px"
              size="sm"
              w="136px"
              variant="outline"
              mb="15px"
              onClick={onClose}>
              CANCEL
            </Button>
            <Button
              ml="5px"
              size="sm"
              bg="red.500"
              w="136px"
              variant="solid"
              onClick={onLogout}
              mb="15px">
              LOG OUT
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
});

export default LogoutModal;
