import React, { FC, useCallback } from 'react';

import SideBar from 'components/core/SideBar';
import MotionLink from 'components/primitives/MotionLink';

import {
  MdWeb,
  MdStars,
  MdSettings,
  MdDashboard,
  MdCameraAlt,
  MdHeadsetMic,
  MdImportContacts,
  MdWorkspacesFilled,
} from 'react-icons/md';

import { useQueryCache } from 'react-query';

import { useHistory } from 'react-router-dom';
import { routesSettings } from 'constants/routes';

import { selectors } from 'effector/session';
import { logoutEvent } from 'effector/session/events';

import { NotificationsIcon } from 'components/modules/NotificationsIcon';
import { navItemsPilot } from 'utils/navItems';

export const PilotSideBar: FC = () => {
  const history = useHistory();
  const queryCache = useQueryCache();

  const isPro = selectors.useIsPro();
  const isFetching = selectors.useFetching();

  const onLogout = () => {
    logoutEvent();

    queryCache.removeQueries();

    history.push('/login');

    localStorage.removeItem('doNotShowMobileViewModal');
  };

  const checkItem = useCallback(
    (name: string): boolean => {
      if (['PRO MISSIONS', 'MY WEBSITE', 'EDUCATION'].includes(name)) {
        return !isPro;
      } else {
        return false;
      }
    },
    [isPro],
  );

  const checkIsFetching = useCallback(
    (name: string): boolean => {
      if (['PRO MISSIONS', 'MY WEBSITE', 'EDUCATION'].includes(name)) {
        return isFetching;
      } else {
        return false;
      }
    },
    [isFetching],
  );

  return (
    <SideBar
      middleSection={(onToggle) =>
        navItemsPilot.slice(0, 7).map((item, index) => {
          const isDashboard = item.name === 'DASHBOARD';

          return (
            <MotionLink
              key={`${item.name}-${index}`}
              name={item.name}
              link={item.link}
              icon={<item.icon size={16} />}
              isDisabled={checkItem(item.name)}
              isLoading={checkIsFetching(item.name)}
              onToggle={onToggle}
              renderBellIcon={
                isDashboard
                  ? () => <NotificationsIcon type={undefined} />
                  : undefined
              }
            />
          );
        })
      }
      bottomSection={(onToggle) =>
        navItemsPilot
          .slice(7, 8)
          .map((item, index) => (
            <MotionLink
              key={`${item.name}-${index}`}
              name={item.name}
              link={item.link}
              icon={<item.icon size={16} />}
              onToggle={onToggle}
            />
          ))
      }
      onLogout={onLogout}
    />
  );
};
