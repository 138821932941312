import { Session } from 'types/session';

export const payloadWithFormattedNames = (
  payload: Session['user'],
  isMobile: boolean,
) => {
  let firstName = '';
  let lastName = '';

  if (isMobile && payload.fullName) {
    firstName = payload.fullName.split(' ').slice(0, -1).join(' ');
    lastName = payload.fullName.split(' ').slice(-1).join(' ');

    delete payload.fullName;
  }
  const middleName = '';

  delete payload.confirmPassword;
  delete payload.isNotPilotCertNumber;

  if (!payload.companyName) {
    payload.companyName = `${firstName || ''} ${lastName || ''}`;
  }

  const sanitizedPayload = !isMobile
    ? {
        ...payload,
      }
    : {
        ...payload,
        firstName,
        middleName,
        lastName,
      };

  return sanitizedPayload;
};
