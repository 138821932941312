import React from 'react';

import {
  Avatar,
  Divider,
  Flex,
  Image,
  Link,
  Stack,
  useTheme,
} from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import logo from 'res/logos/logo1.png';

const NavbarView = (): JSX.Element => {
  const theme = useTheme();
  const { push } = useHistory();
  const { state } = useLocation();

  return (
    <Flex
      height="60px"
      shadow="md"
      justifyContent="center"
      zIndex={theme.zIndices.sticky}
      bg={theme.colors.tertiaryColor}
      color={theme.colors.primaryColor}>
      <Flex
        px={5}
        flexDir="row"
        alignItems="center"
        width="100%"
        maxWidth="1200px"
        mx="auto">
        <Link href="/">
          <Image width={['100px', '150px']} objectFit="contain" src={logo} />
        </Link>
        <Stack
          isInline
          flex="1"
          align="center"
          justifyContent="flex-end"
          flexDir="row"
          spacing={[2, 6]}
          fontSize="14px"
          fontWeight="bold">
          <Divider
            orientation="vertical"
            h="30px"
            borderColor={theme.colors.primaryColor}
          />
          <Link
            onClick={() =>
              push({
                pathname: `/login`,
                //search: search.includes('/create-mission') ? search : undefined,
                state,
              })
            }>
            <Flex alignItems="center">
              <Avatar
                bg={theme.colors.primaryColor}
                size="sm"
                h="20px"
                w="20px"
                mr="2"
              />
              Login
            </Flex>
          </Link>
          <Divider
            orientation="vertical"
            h="30px"
            borderColor={theme.colors.primaryColor}
          />
          <Link
            onClick={() =>
              push({
                pathname: `/register`,
                //search: search.includes('/create-mission') ? search : undefined,
                state,
              })
            }>
            Register
          </Link>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default NavbarView;
