import React, {
  FC,
  memo,
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';

import {
  Text,
  Flex,
  Modal,
  Progress,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { MissionAsset } from 'types/mission';

import { EventRegister } from 'utils/customEvent';

import { formatElapsedTime } from 'utils/formatEllapsedTime';

type Props = {
  assets: MissionAsset[];
};

const ProgressModal = forwardRef<any, Props>((props, ref) => {
  const { assets } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  useImperativeHandle(ref, () => ({ onOpen, onClose }), []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="md"
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent bg="#f1f1f1">
        <ModalBody>
          <Flex direction="column">
            <Text textAlign="center" fontWeight="bold" fontSize="22px">
              Downloading Files
            </Text>

            <Flex
              mt="10px"
              direction="column"
              gridGap="10px"
              maxH="400px"
              overflowY="auto">
              {assets.map((asset) => (
                <ProgressItem key={asset.signedUrl} asset={asset} />
              ))}
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

export default memo(ProgressModal);

const ProgressItem: FC<{ asset: MissionAsset }> = ({ asset }) => {
  const startTimeRef = useRef(0);

  const [progress, setProgress] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(0);

  useEffect(() => {
    const handler = (event: any) => {
      if (startTimeRef.current === 0) {
        startTimeRef.current = new Date().getTime();
      }

      const progressEvent = event?.detail?.progress;

      const progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100,
      );

      const seconds_elapsed =
        (new Date().getTime() - startTimeRef.current) / 1000;
      const bytes_per_second = seconds_elapsed
        ? progressEvent.loaded / seconds_elapsed
        : 0;
      const remaining_bytes = progressEvent.total - progressEvent.loaded;
      const seconds_remaining = Number(remaining_bytes / bytes_per_second);

      setProgress(progress);
      setTimeRemaining(seconds_remaining);
    };

    EventRegister.subscribe(`progress-${asset.id}`, handler);

    return () => {
      EventRegister.unsubscribe(`progress-${asset.id}`, handler);
    };
  }, []);

  return (
    <Flex p="5px 15px" bg="#fff" shadow="md" direction="column">
      <Flex flex={1} justify="space-between">
        <Text noOfLines={1} flex={1} fontSize="14px">
          {asset?.fileName}
        </Text>
        <Text fontSize="14px">
          {timeRemaining === Infinity ? '0s' : formatElapsedTime(timeRemaining)}
        </Text>
      </Flex>
      <Flex flex={1} mt="5px" align="center" gridGap="10px">
        <Progress
          w="100%"
          size="sm"
          hasStripe
          value={progress}
          borderRadius="20px"
          colorScheme="brand"
        />
        <Text fontSize="14px">{progress}%</Text>
      </Flex>
    </Flex>
  );
};
