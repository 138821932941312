import React, { FC, useRef, useState } from 'react';

import {
  Text,
  Flex,
  Radio,
  Stack,
  Button,
  Center,
  Textarea,
  RadioGroup,
} from '@chakra-ui/react';

import { pilotRejectMission } from 'api/custom-mission';
import { useMutation, useQueryCache } from 'react-query';

const options = [
  'Budget Concern',
  "Timeframe Doens't Work",
  'Safety Concern',
  'Not A Service We Provide',
  'Others',
];

export const RejectMission: FC<{ missionId: string; onClose: () => void }> = ({
  missionId,
  onClose,
}) => {
  const queryCache = useQueryCache();

  const [index, setIndex] = useState<number>(0);

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [submit, { isLoading, isSuccess }] = useMutation(
    (_message: string) => pilotRejectMission(_message, missionId),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(`fetch-custom-mission-${missionId}`);

        onClose();
      },
    },
  );

  const onSend = () => {
    let message = options[index];

    if (index === 4) {
      const inputValue = inputRef.current?.value || '';

      if (inputValue.trim()?.length <= 0) {
        inputRef.current?.focus?.();

        return;
      } else {
        message = inputValue;
      }
    }

    submit(message);
  };

  return (
    <Flex px={6} flex={1} direction="column">
      <Flex flex={1}>
        <Text mt="50px" fontSize="16px" textAlign="left">
          Please select one of the reasons below to send to client. If none are
          relevant put your rejection or change request in the "Other" field.
          Your client will get a notification of your response.
        </Text>
      </Flex>
      <Flex direction="column" flex={1.5}>
        <RadioGroup
          my={4}
          sx={{
            '.chakra-radio__control[data-checked]': {
              background: '#6728BB',
              borderColor: '#6728BB',
            },
            '.chakra-radio__control[data-focus]': {
              boxShadow: 'none',
            },
          }}
          value={index.toString()}
          onChange={(e) => {
            setIndex(Number(e));
          }}>
          <Stack spacing={4}>
            {options.map((option, index) => (
              <Radio key={option} value={index.toString()}>
                <Text fontWeight="bold">{option}</Text>
              </Radio>
            ))}
            {index === 4 && (
              <Textarea
                ref={inputRef}
                autoFocus
                placeholder="Put your request change here"
              />
            )}
          </Stack>
        </RadioGroup>
        <Center my={4} flexWrap="wrap" gridGap="10px">
          <Button
            isDisabled={isLoading}
            onClick={onClose}
            variant="outline"
            w="80vw">
            CANCEL
          </Button>
          <Button isLoading={isLoading} w="80vw" onClick={onSend}>
            SEND
          </Button>
        </Center>
      </Flex>
    </Flex>
  );
};
