import React, { memo, useRef, forwardRef, useImperativeHandle } from 'react';

import {
  Text,
  Modal,
  Image,
  Stack,
  Center,
  Button,
  ModalBody,
  ModalContent,
  ModalOverlay,
  CircularProgress,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { useDisclosure } from '@chakra-ui/hooks';

import { declineMission, updateMission } from 'api/mission';
import { updateConnectedAcc } from 'api/stripe';

import { useMutation, useQueryCache } from 'react-query';

import * as stripe from 'effector/stripe';
import * as session from 'effector/session';

import stripeText from 'res/stripe.png';
import skipCharacter from 'res/skip-character.png';

import { MissionStatuses } from 'types/mission';
import { MissionAccordionProps } from './MissionAccordion.props';

import MissionAccordionView from './MissionAccordion.view';

const MissionAccordionContainer = (props: MissionAccordionProps) => {
  const toast = useToast();
  const queryCache = useQueryCache();

  const modalRef = useRef<any>(null);

  const pilotID = session.selectors.useUserId();
  const isEnabled = stripe.selectors.useIsEnabled();
  const isConnected = stripe.selectors.useIsConnected();

  const [declineSubmit, { isLoading: isDeclining }] = useMutation(
    async () => {
      const payload = {
        pilot: pilotID,
      };

      return await declineMission(payload, props.mission.id);
    },
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Mission Declined',
          isClosable: true,
          duration: 5000,
        });

        queryCache.invalidateQueries('fetch-pilot-missions');
      },
    },
  );

  const [acceptSubmit, { isLoading: isAccepting }] = useMutation(
    async (missionId: string = props.mission.id) => {
      const payload = {
        status: MissionStatuses.SCHEDULED,
        pilot: pilotID,
      };

      return await updateMission(payload, missionId);
    },
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Mission Accepted',
          isClosable: true,
          duration: 9000,
        });

        queryCache.invalidateQueries('fetch-pilot-missions');
        queryCache.invalidateQueries(['fetch missions accepted']);
      },
    },
  );

  const onAcceptClick = () => {
    if (isConnected && isEnabled) {
      acceptSubmit();
    } else {
      modalRef.current?.onOpen?.();
    }
  };

  return (
    <>
      <MissionAccordionView
        mission={props.mission}
        isAccepting={isAccepting}
        isDeclining={isDeclining}
        acceptSubmit={onAcceptClick}
        declineSubmit={declineSubmit}
        callbackFunction={props.callbackFunction}
      />
      <ConnectStripeModal ref={modalRef} missionId={props.mission.id} />
    </>
  );
};

export default MissionAccordionContainer;

const ConnectStripeModal = memo(
  forwardRef<any, { missionId: string }>((props, ref) => {
    const PATH = `/pilot/dashboard?redirect=true&id=${props.missionId}`;

    const { isOpen, onOpen, onClose } = useDisclosure();

    const isPending = stripe.selectors.useIsPending();
    const isAccConnected = stripe.selectors.useIsConnected();

    useImperativeHandle(ref, () => ({ onOpen }), []);

    const options = {
      onSuccess: (stripeUrl: string) => {
        window.open(stripeUrl, '_self');
      },
    };

    const [updateLink, { isLoading }] = useMutation(
      () => updateConnectedAcc(PATH),
      options,
    );

    const onButtonClick = () => {
      updateLink();
    };

    return (
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            {isPending ? (
              <Center flexDirection="column">
                <CircularProgress color="brand.500" isIndeterminate />
                <Text mt={4} textAlign="center">
                  Please wait, fetching your stripe account details. this might
                  take few moments
                </Text>
              </Center>
            ) : (
              <Center flexDirection="column">
                <Image src={skipCharacter} h="200px" w="200px" />
                <Text my={4} textAlign="center" fontSize="18px">
                  You need to connect your account with Stripe before accepting
                  missions
                </Text>
                <Stack isInline mb={6}>
                  <Button onClick={onClose} w="150px" variant="outline">
                    Cancel
                  </Button>
                  <Button
                    w="240px"
                    variant="ghost"
                    color="#fff"
                    bg="#2672F2"
                    rightIcon={<Image src={stripeText} h="22px" />}
                    isLoading={isLoading}
                    onClick={onButtonClick}>
                    Connect with
                  </Button>
                </Stack>
              </Center>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }),
);
