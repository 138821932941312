import React, { useEffect } from 'react';

import { useMutation } from 'react-query';
import authApi from 'api/auth';
import { useToast } from '@chakra-ui/toast';
import { loginEvent } from 'effector/session/events';
import { ResetPasswordProps } from './ResetPassword.props';
import ResetPasswordView from './ResetPassword.view';
import { cms } from 'routes/premiumWebsite/PremiumMissionCreate/cmsStore';
import useActivateAccount from 'utils/hooks/useActivateAccount';
import { useHistory, useParams } from 'react-router-dom';
import ROUTES, { routesSettings } from 'constants/routes';
import * as session from 'effector/session';
import { getRememberMe } from 'effector/session/store';

const ResetPasswordContainer = (props: ResetPasswordProps): JSX.Element => {
  const toast = useToast();
  const history = useHistory();
  const userId = session.selectors.useUser()?.id;

  const type = new URLSearchParams(history.location.search).get('type');

  const {
    activateUserAccountSettingPassword,
    error,
    isDispatched,
    isSuccess,
    loading,
  } = useActivateAccount(userId);

  const token = props.match.params.token;
  const missionFromUrl = props.match.params?.missionId;
  const emailFromUrl = props.match.params?.email;
  const cmsPath = cms.useCms();
  const savedEmail = cms.useSavedEmail();
  const location = props.location.pathname;

  let email = props.location.state?.email;
  let missionId = props.location.state?.missionId;
  let passwordToken = props.location.state?.passwordToken;

  if (!passwordToken && !!token) {
    passwordToken = token;
  }
  if (!missionId && !!missionFromUrl) {
    missionId = missionFromUrl;
  }

  if (!email && !!emailFromUrl) {
    email = emailFromUrl;
  }

  const [login, { isLoading: isLoggingIn }] = useMutation(authApi.login, {
    onSuccess: (data) => {
      toast({
        status: 'success',
        description: 'Account creation successful',
        isClosable: true,
        duration: 2000,
      });

      setTimeout(() => {
        loginEvent({
          session: data,
          rememberMe: getRememberMe(),
        });

        if (cmsPath) {
          props.history.replace(cmsPath);

          cms.resetEvent();
        } else {
          props.history.replace(`/mission/details/${missionId}`);
        }

        props.history.go(0);
      }, 500);
    },
  });

  const [reset, { isLoading }] = useMutation(authApi.resetPassword, {
    onSuccess: (_, { password }) => {
      if (savedEmail && cmsPath) {
        login({
          email: savedEmail,
          password,
        });

        return;
      }

      if (passwordToken && email) {
        login({
          email,
          password,
        });

        return;
      }

      toast({
        status: 'success',
        title: `Password Reset Successful`,
        description: 'Please login with new password',
        isClosable: true,
        duration: 2000,
      });

      setTimeout(() => {
        props.history.replace('/login');
      }, 1000);
    },
  });

  const isActivationSetPassword = () => {
    if (Boolean(type) && Boolean(token)) {
      return true;
    }

    return (
      Boolean(token) &&
      !Boolean(missionFromUrl) &&
      !Boolean(emailFromUrl) &&
      !Boolean(email) &&
      !Boolean(missionId) &&
      !Boolean(location.includes('reset-password'))
    );
  };

  const onSuccess = () => {
    toast({
      status: 'success',
      title: 'Success',
      description: 'Password updated successfully!',
      duration: 5000,
      isClosable: true,
    });
  };

  const onFailure = () => {
    toast({
      status: 'error',
      title: 'Error',
      description: 'Password already updated. Please, log in',
      duration: 10000,
      isClosable: true,
    });
  };

  /**
   * COMENTAR POR QUE ESTO!
   */
  useEffect(() => {
    if (isDispatched && !loading) {
      isSuccess ? onSuccess() : onFailure();
      history.push(`${routesSettings[ROUTES.AUTH_LOGIN].path}?denySignUp=true`);
    }
  }, [isDispatched, isSuccess, loading]);

  const onSubmit = (password: string) => {
    if (isActivationSetPassword()) {
      activateUserAccountSettingPassword({ token, password });
      return;
    }

    reset({
      password,
      token: !!passwordToken ? passwordToken : token,
    });
  };

  return (
    <ResetPasswordView
      isLoading={isLoading || isLoggingIn}
      passwordToken={passwordToken}
      onSubmit={onSubmit}
    />
  );
};

export default ResetPasswordContainer;
