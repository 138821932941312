import React from 'react';

import { Center, Flex, Spinner } from '@chakra-ui/react';

import { EditWebsiteGeneratedProps } from './EditWebsite.props';
import { SessionStore } from 'effector/session/store';
import PilotHeader from 'components/modules/PilotHeader';
//import { WebsiteForm } from 'components/modules/WebsiteForm';

const EditWebsiteView = ({
  onSubmit,
  initialData,
  isLoading,
}: EditWebsiteGeneratedProps): JSX.Element => {
  const name = SessionStore.getState()?.session?.user?.firstName ?? '';

  return (
    <Flex flex={1} direction="column" pt={[10, 20]} alignSelf="flex-start">
      <PilotHeader name={name} />
      {isLoading ? (
        <Center h="80vh">
          <Spinner
            size="xl"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="brand.500"
          />
        </Center>
      ) : (
        // <WebsiteForm
        //   isEdit
        //   initialValues={initialData}
        //   onSubmit={onSubmit}
        //   name={name}
        // />
        <div />
      )}
    </Flex>
  );
};

export default EditWebsiteView;
