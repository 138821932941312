import React, { useEffect, useMemo } from 'react';

import { Flex } from '@chakra-ui/react';
import RouteWithSubRoutes from 'components/core/RouteWithSubRoutes';
import SideBar from 'components/core/SideBar';
import MotionLink from 'components/primitives/MotionLink';
import {
  MdCameraAlt,
  MdHeadsetMic,
  MdSearch,
  MdSettings,
} from 'react-icons/md';
import { Switch, useHistory, useLocation } from 'react-router-dom';
// IMPORT ROUTE
import MissionUpdate from 'routes/client/MissionUpdate';
import Dashboard from 'routes/client/Dashboard';
import MissionDetails from 'routes/client/MissionDetails';
import Missions from 'routes/client/Missions';
import Settings from 'routes/client/Settings';
import Support from 'routes/client/Support';
import NotFound from 'routes/auth/NotFound';

import { useQueryCache } from 'react-query';

import ROUTES, { routesSettings } from 'constants/routes';
import { logoutEvent } from 'effector/session/events';
import InactiveAccountBlockerContainer from 'components/modules/NotActiveAccountModal/InactiveAccountBlocker.container';
import ActivateAccount from 'routes/auth/ActivateAccount';
import CustomMission from 'routes/pilot/CustomMission';
import { useCustomMissions, usePilotCollaborations } from 'utils/hooks';
import { navItemsClient } from 'utils/navItems';
import { googleLogout } from '@react-oauth/google';

const ClientSideBar = () => {
  const history = useHistory();
  const queryCache = useQueryCache();

  const { data } = usePilotCollaborations('1');
  const { data: customMissions } = useCustomMissions();

  const onLogout = () => {
    logoutEvent();

    queryCache.removeQueries();

    history.push('/login');

    try {
      googleLogout();
    } catch (error) {
      console.log;
    }
  };

  const onNewMissionClick = () => {
    // use first cms from the array
    const cms = data?.[0]?.cms;

    if (cms) {
      const url = `${process.env.REACT_APP_URL}/cms/${cms?.username}#Packages`;

      window.open(url, '_blank');
    } else {
      const customMissionCms = customMissions?.results?.[0]?.cms;

      if (customMissionCms && typeof customMissionCms === 'object') {
        const url = `${process.env.REACT_APP_URL}/cms/${
          (customMissionCms as any)?.username
        }#Packages`;

        window.open(url, '_blank');
      }
    }
  };

  return (
    <>
      <SideBar
        middleSection={(onToggle) =>
          navItemsClient.map((item, index) => (
            <MotionLink
              key={`${item.name}-${index}`}
              name={item.name}
              link={item.link}
              icon={<item.icon size={16} />}
              onToggle={onToggle}
              onClick={
                Boolean(item.redirect) === true ? onNewMissionClick : undefined
              }
            />
          ))
        }
        onLogout={onLogout}
      />
    </>
  );
};

const checkForUserEmailActivation = (Component: any) => {
  return (props: any) => (
    <InactiveAccountBlockerContainer>
      <Component {...props} />
    </InactiveAccountBlockerContainer>
  );
};

const routeConfig = Object.freeze({
  // CLIENT ROUTE ENTRY
  [ROUTES.PILOT_CUSTOM_MISSION]: {
    component: CustomMission,
    path: routesSettings.PILOT_CUSTOM_MISSION.path,
  },
  [ROUTES.AUTH_ACTIVATE_ACCOUNT]: {
    component: ActivateAccount,
    path: routesSettings.AUTH_ACTIVATE_ACCOUNT.path,
  },
  [ROUTES.CLIENT_MISSION_UPDATE]: {
    component: MissionUpdate,
    path: routesSettings.CLIENT_MISSION_UPDATE.path,
  },
  [ROUTES.CLIENT_MISSION_DETAILS]: {
    component: MissionDetails,
    path: routesSettings.CLIENT_MISSION_DETAILS.path,
  },
  [ROUTES.CLIENT_SETTINGS]: {
    component: Settings,
    path: routesSettings.CLIENT_SETTINGS.path,
  },
  // [ROUTES.CLIENT_SUPPORT]: {
  //   component: Support,
  //   path: routesSettings.CLIENT_SUPPORT.path,
  // },
  [ROUTES.CLIENT_MISSIONS]: {
    component: checkForUserEmailActivation(Missions),
    path: routesSettings.CLIENT_MISSIONS.path,
  },
  [ROUTES.CLIENT_DASHBOARD]: {
    component: checkForUserEmailActivation(Dashboard),
    path: routesSettings.CLIENT_DASHBOARD.path,
  },
  [ROUTES.AUTH_NOT_FOUND]: {
    component: NotFound,
    path: routesSettings.AUTH_NOT_FOUND.path,
  },
});

const ClientRoutes = (): JSX.Element => {
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/') {
      history.replace(routeConfig.CLIENT_MISSIONS.path);
    }
  }, [pathname]);

  return (
    <Flex flex={1} bg="#F3F3F3">
      <ClientSideBar />
      <Flex
        flexGrow={1}
        flexBasis={0}
        overflow="auto"
        justifyContent="center"
        alignItems="center"
        marginX="auto">
        <Switch>
          {Object.entries(routeConfig).map(([key, val]) => (
            <RouteWithSubRoutes key={key} {...val} />
          ))}
        </Switch>
      </Flex>
    </Flex>
  );
};

export default ClientRoutes;
