import React from 'react';

import { TutorialsProps } from './Tutorials.props';
import TutorialsView from './Tutorials.view';
import { useQuery } from 'react-query';
import tutorials, { getTutorials } from 'api/tutorials';

const TutorialsContainer = (props: TutorialsProps): JSX.Element => {
  const { data: Tutorials, isLoading, isError } = useQuery(
    `fetch-tutorials`,
    () => getTutorials(),
    {
      retry: 0,
      onError: () => {
        console.log('error');
      },
    },
  );

  // Render the TutorialsView component with the fetched tutorials
  console.log('tutorials->-- ', Tutorials);
  return <TutorialsView isLoading={isLoading} tutorials={Tutorials} />;
};

export default TutorialsContainer;
