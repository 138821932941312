import React, { FC, memo, useState } from 'react';

import { useQuery } from 'react-query';

import { useClipboard } from '@chakra-ui/react';

import EmptyMissionsPageView from './EmptyMissionsPage.view';

import { checkCMS, getMyCMS } from 'api/cms';

const EmptyMissionsPageContainer: FC = () => {
  const [valueToCopy, setValueToCopy] = useState('');

  const { data: publishedCMS } = useQuery(`fetch-check-cms`, () => checkCMS(), {
    retry: 0,
    onError: (res: Error) => {
      console.log(res.stack);

      console.log('error');
    },
  });

  const isPublished = publishedCMS?.isExist;
  const status = isPublished ? 'PUBLISH' : 'DRAFT';

  const { data } = useQuery(`fetch-my-cms`, () => getMyCMS(status), {
    retry: 0,
    onSuccess: () => {
      setValueToCopy(`${process.env.REACT_APP_URL}/cms/${data?.username}`);
    },
    onError: () => {
      console.log('error');
    },
  });

  const { onCopy, hasCopied } = useClipboard(valueToCopy);

  return (
    <EmptyMissionsPageView
      onCopy={onCopy}
      hasCopied={hasCopied}
      isPublished={isPublished}
    />
  );
};

export default memo(EmptyMissionsPageContainer);
