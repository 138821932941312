import React, { FC, useMemo, useRef } from 'react';

import { isMobile } from 'react-device-detect';

import { Flex, Spinner, Box, Text } from '@chakra-ui/react';

import hexTorgb from 'hex-rgb';

import { useLocation } from 'react-router-dom';

import { BgSvg } from './BgSvg';
import { Header } from './Header';
import { Footer } from './Footer';
import { MyWork } from './MyWork';
import { MyPacks } from './MyPacks';
import { NotFound } from './NotFound';
import { UserInfo } from './UserInfo';
import { CMSContext } from './Context';
import { Equipment } from './Equipment';
import { Industries } from './Industries';
import { CustomQuote } from './CustomQuote';
import { CustomQuoteAccept } from './CustomQuoteAccept';
import AloftInfoPopup, { aloftInfoPopupRef } from './AloftInfoPopup';

import { selectors } from 'effector/session';

import { PilotWebsiteGeneratedProps } from './PilotWebsite.props';
import TestimonialsSection from './TestimonialsSection';
import Banner from './Banner';
import OnboardingModal, {
  ModalRefType,
} from 'components/modules/OnboardingModal';

const PilotWebsiteView: FC<PilotWebsiteGeneratedProps> = ({
  data,
  isError,
  isLoading,
}) => {
  const { pathname } = useLocation();
  const isPreview = useMemo(() => pathname.includes('/preview/'), [pathname]);

  const onboardingModalRef = useRef<ModalRefType>(null);

  const isStripeConnected = !!data?.pilot?.stripeId;
  const userId = selectors.useUserId();
  const isCurrentPilot = userId === data?.pilot?.id;

  const shouldShowOnboardingBanner =
    isCurrentPilot &&
    !isPreview &&
    !isStripeConnected &&
    !data?.packages?.length;

  const value = useMemo(() => {
    let fontColor = '#ffff';
    const brandColor = data?.stylings?.colorScheme || '#6728BB';

    const { red, blue, green } = hexTorgb(brandColor);

    if (red * 0.299 + green * 0.587 + blue * 0.114 > 186) {
      fontColor = '#000';
    }

    return {
      cms: data,
      fontColor,
      brandColor,
    };
  }, [data]);
  const status = data?.status || '';
  const shouldShowContent =
    status === 'PUBLISH' || (status === 'DRAFT' && isPreview);
  if (isLoading)
    return (
      <Spinner
        size="xl"
        speed="0.85s"
        thickness="4px"
        color="brand.500"
        emptyColor="gray.200"
      />
    );

  let subscriptionEnd = data?.pilot?.subscriptionEnd;
  if (subscriptionEnd) {
    subscriptionEnd = new Date(subscriptionEnd).getTime();
  } else {
    // subscriptionEnd sets to 30days from createdAt as it is a free subscription for 30days
    subscriptionEnd =
      new Date(data?.pilot?.createdAt).getTime() + 30 * 24 * 60 * 60 * 1000;
  }
  return (
    <CMSContext.Provider value={value}>
      {data && subscriptionEnd >= new Date().getTime() ? (
        <>
          {shouldShowContent ? (
            <Flex w="100%" flex={1} direction="column" fontFamily="Poppins">
              {isPreview ? (
                <>
                  <Flex
                    position="fixed"
                    top={0}
                    left={0}
                    right={0}
                    zIndex={2}
                    height={'50px'}
                    justifyContent="center"
                    marginBottom="50px"
                    alignItems="center">
                    <Banner />
                  </Flex>
                  <Flex marginTop="50px" w="100%">
                    <Header />
                  </Flex>
                </>
              ) : (
                <Header />
              )}

              <BgSvg />
              {shouldShowOnboardingBanner ? (
                <Flex justifyContent="center" mt={5} alignItems="center">
                  <Box
                    backgroundColor="#E5E8ED"
                    borderRadius="0.938rem"
                    w="100%"
                    maxW="1000px"
                    h={isMobile ? 24 : '2rem'}
                    justifyContent="center"
                    alignItems="center"
                    shadow="md"
                    display="flex"
                    flexDir={isMobile ? 'column' : 'row'}>
                    <Text
                      fontFamily="Poppins"
                      fontSize="1rem"
                      color="#708696"
                      fontWeight="bold"
                      mr="0.3rem"
                      textAlign={'center'}>
                      {!isMobile
                        ? 'Looks like some information is missing.'
                        : 'Looks like some information is missing. Please complete your profile details using a computer.'}
                    </Text>

                    {!isMobile && (
                      <Text
                        mr="0.3rem"
                        color="#3B9AF2"
                        fontSize="1rem"
                        fontWeight="bold"
                        cursor="pointer"
                        onClick={() => {
                          onboardingModalRef.current?.onOpen?.();
                        }}>
                        Complete profile.
                      </Text>
                    )}

                    <Text fontWeight="normal" mr="0.3rem" color="#708696">
                      (This is visible just for you)
                    </Text>
                  </Box>
                </Flex>
              ) : (
                <></>
              )}
              {isStripeConnected ? (
                <>
                  <UserInfo />
                  <MyWork />
                  <Industries />
                  <CustomQuote isPreview={isPreview} />
                  {!isPreview && <CustomQuoteAccept />}
                  <Equipment />
                  <TestimonialsSection color={value.brandColor} />
                  <Footer />

                  {!isPreview && <AloftInfoPopup ref={aloftInfoPopupRef} />}
                </>
              ) : (
                <Flex direction="column" flex={1} mb="100px" gridGap="20px">
                  <UserInfo showBasicInfo />
                  <CustomQuote isPreview={isPreview} isDefaultOpen />
                </Flex>
              )}

              {isCurrentPilot && !isPreview && (
                <OnboardingModal
                  cms={data}
                  ref={onboardingModalRef}
                  isStripeConnected={isStripeConnected}
                />
              )}
            </Flex>
          ) : (
            <NotFound />
          )}
        </>
      ) : (
        <NotFound />
      )}
    </CMSContext.Provider>
  );
};

export default PilotWebsiteView;
