import React, { FC } from 'react';

import stripeText from 'res/stripe.png';

import { Button, chakra, Image, Text } from '@chakra-ui/react';

import { StripeBannerGeneratedProps } from './StripeBanner.props';

const Header = chakra('header');

const StripeBannerView: FC<StripeBannerGeneratedProps> = ({
  isEnabled,
  isLoading,
  isFetching,
  isConnected,
  handleConnect,
}) => {
  if (isFetching) {
    return null;
  }

  if (isEnabled) {
    return null;
  }

  return (
    <Header
      gridGap="20px"
      px="20px"
      py="10px"
      shadow="lg"
      bg="#5433FF"
      color="#fff"
      display="flex"
      fontWeight="bold"
      alignItems="center"
      justifyContent="center">
      {isConnected ? (
        <>
          <Text>Complete Your Stripe Setup to Get Paid!</Text>
          <Button
            variant="ghost"
            color="#fff"
            bg="yellow.500"
            leftIcon={<Text>Update</Text>}
            rightIcon={<Text>Information</Text>}
            isLoading={isLoading}
            onClick={() => handleConnect()}>
            <Image src={stripeText} h="22px" />
          </Button>
        </>
      ) : (
        <>
          <Text>Connect to Stripe and Start Earning!</Text>
          <Button
            variant="ghost"
            color="#fff"
            bg="#2672F2"
            rightIcon={<Image src={stripeText} h="22px" />}
            isLoading={isLoading}
            onClick={() => handleConnect()}>
            Connect
          </Button>
        </>
      )}
    </Header>
  );
};

export default StripeBannerView;
