import React, { useRef } from 'react';
import { Box, Text, Stack, Flex } from '@chakra-ui/react';
import { MissionListBannerProps } from './MissionListBanner.props';

import { MissionStatuses } from 'types/mission';
import TreeDotsMenu from './TreeDotsMenu';
import { props } from 'ramda';

const MissionListBannerView = ({
  showTripleButtons = true,
  ...props
}: MissionListBannerProps): JSX.Element => {
  let backgroundColor = '#373737';
  const inputRef = useRef<HTMLInputElement>(null);

  switch (props.bannerType) {
    case 'premium': {
      backgroundColor = 'primaryColor';
      break;
    }

    case 'accepted': {
      backgroundColor = 'complementaryColor';

      break;
    }

    default: {
      if (props.highlightedTitle !== 'Missions Website') {
        backgroundColor = 'secondaryColor';
      } else {
        backgroundColor = 'primaryColor';
      }
    }
  }

  const options = [props.customButton, props.cancelAndDelete].filter(
    (o) => !!o,
  );

  return (
    <Stack
      isInline
      padding="10px"
      paddingLeft={props.icon ? '20px' : '30px'}
      paddingRight="20px"
      alignItems="center"
      fontSize="14px"
      fontWeight="400"
      backgroundColor={backgroundColor}
      letterSpacing={1}
      justifyContent="space-between">
      {props.icon && (
        <Box borderRadius="30px" borderWidth={1} p="1" bg="white">
          {props.icon}
        </Box>
      )}
      <Text
        color="white"
        fontSize="lg"
        fontFamily="Monument Extended"
        fontWeight="700">
        {props.highlightedTitle}
      </Text>
      <Text color="white" fontSize="lg" fontFamily="Monument Extended">
        {props.unhighlightedTitle}
      </Text>
      {showTripleButtons && props?.status === MissionStatuses.ORDERED ? (
        <TreeDotsMenu options={options} />
      ) : (
        <Flex ml="auto !important">{props.customButton}</Flex>
      )}
      {props.buttonOnBar && <Flex ml=""> {props.buttonOnBar}</Flex>}
    </Stack>
  );
};

export default MissionListBannerView;
