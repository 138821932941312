import * as Yup from 'yup';

const CustomMissionRequestValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required field'),
  email: Yup.string().email().trim().required('Required field'),
  missionDetails: Yup.string().required('Required field'),
  industry: Yup.string().required('Required field'),
  budget: Yup.number().required('Required field'),
  eventStart: Yup.string().required('Required field'),
  dueDate: Yup.string(),
  location: Yup.object().shape({
    address: Yup.string().required('Required field'),
  }),
});

export { CustomMissionRequestValidationSchema };
