import { activateAccount, getUser, setPasswordActivation } from 'api/auth';
import { updateUserEvent } from 'effector/session/events';
import { useState } from 'react';
import { AccountTypes } from 'types/accountTypes';
import { getUserId, selectors } from 'effector/session/store';

const useActivateAccount = (userId: string | null | undefined) => {
  const sessionUser = selectors.useUser();

  const isUserLogged = Boolean(sessionUser);
  const isPilot = sessionUser?.accountType === AccountTypes.pilot;

  const [status, setStatus] = useState<{
    loading: boolean;
    isDispatched: boolean;
    error?: any;
    data?: any;
    isSuccess?: boolean;
  }>({
    loading: false,
    isDispatched: false,
    isSuccess: false,
    error: null,
  });

  const fetchUserData = async () => {
    const saveUserId = getUserId();

    if (!saveUserId) {
      return;
    }

    const data = await getUser(saveUserId).catch(() => null);

    if (data) {
      updateUserEvent(data);
    }
  };

  const activateUserAccount = async () => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      loading: true,
      isDispatched: true,
    }));

    try {
      const responseUser = await activateAccount(userId);

      if (Boolean(responseUser)) {
        if (isUserLogged) {
          await fetchUserData();
        }
      }

      setStatus((prevStatus) => ({
        ...prevStatus,
        loading: false,
        data: responseUser,
        isSuccess: true,
      }));
    } catch (error: any) {
      const errorData = error?.response?.data;
      let isSuccess = false;

      if (
        errorData?.code === 422 &&
        errorData?.message === 'User is activated already.'
      ) {
        if (isUserLogged) {
          await fetchUserData();
        }

        isSuccess = true;
      }

      setStatus((prevStatus) => ({
        ...prevStatus,
        loading: false,
        error: errorData ?? error,
        isSuccess,
      }));
    }
  };

  const activateUserAccountSettingPassword = async ({
    token,
    password,
  }: {
    token: string;
    password: string;
  }) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      loading: true,
      isDispatched: true,
    }));

    try {
      const user = await setPasswordActivation(token, password);
      setStatus((prevStatus) => ({
        ...prevStatus,
        loading: false,
        data: user,
        isSuccess: true,
      }));
    } catch (error: any) {
      const errorData = error?.response?.data;
      let isSuccess = false;

      if (
        errorData?.code === 422 &&
        errorData?.message === 'User is activated already.'
      ) {
        isSuccess = true;
      }

      setStatus((prevStatus) => ({
        ...prevStatus,
        loading: false,
        error: errorData ?? error,
        isSuccess,
      }));
    }
  };

  return {
    ...status,
    isPilot,
    isUserLogged,
    activateUserAccount,
    activateUserAccountSettingPassword,
  };
};

export default useActivateAccount;
