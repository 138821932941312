import api from 'api/instance';
import { AxiosPromise } from 'axios';
import { Session } from 'types/session';

const URL = `${process.env.REACT_APP_API_URL}`;

type UserData = Session['user'];

export type LoginPayload = {
  email: string;
  password: string;
};

export type ForgotPasswordPayload = {
  email: string;
};

export type LoginResponse = Session;

const login = async (payload: LoginPayload): Promise<LoginResponse> => {
  const { data } = await api.post<LoginResponse>(`${URL}/auth/login`, payload);
  return data;
};

const forgotPassword = async (
  payload: ForgotPasswordPayload,
): Promise<LoginResponse> => {
  const { data } = await api.post<LoginResponse>(
    `${URL}/auth/forgot-password`,
    payload,
  );
  return data;
};

const logout = (): AxiosPromise => api.post(`${URL}/logout`);

export type SignupPayload = {
  fullName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  phone?: string;
  address?: string;
  state?: string;
  zipcode?: string;
  pilotCertificateNumber?: string;
  companyLicenseNumber?: string;
  companyName?: string;
  companyRegistrationNumber?: string;
};

const signup = async (payload: SignupPayload): Promise<Session> => {
  const { data } = await api.post<Session>(`${URL}/auth/register`, payload);
  return data;
};

const uploadImage = async ({
  image,
  signedUrl,
}: {
  image: File;
  signedUrl: string;
}): Promise<any> => {
  if (!signedUrl) {
    return;
  }

  const data = await fetch(signedUrl, {
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data' },
    body: image,
  });

  return data;
};

export const updateProfilePicture = async (
  file: File,
  uid: string,
  onProgress?: (a: ProgressEvent) => void,
): Promise<any> => {
  const { data } = await api.patch(`${URL}/users/${uid}`, {
    profileImage: file.name,
  });

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', data.signedUrl, true);
    xhr.setRequestHeader('Content-Type', 'multipart/form-data');
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    if (onProgress) {
      xhr.upload.onprogress = onProgress;
    }
    xhr.onload = () => {
      resolve(data);
    };
    xhr.onerror = () => {
      if (xhr.status !== 200) {
        reject(
          new Error(
            `Request failed. Status: ${xhr.status}. Content: ${xhr.responseText}`,
          ),
        );
      }
    };
    xhr.send(file);
  });
};

export const getUser = async (id: string): Promise<UserData> => {
  const { data } = await api.get<UserData>(`${URL}/users/${id}`);

  return data;
};

// temp
export const subscribeToPro = async (uid: string): Promise<Session['user']> => {
  const { data } = await api.patch<Session['user']>(`${URL}/users/${uid}`, {
    pro: true,
  });

  return data;
};

export const unsubscribeToPro = async (uid: string) => {
  const { data } = await api.patch(`${URL}/users/${uid}`, {
    pro: false,
  });

  return data;
};

export const resetPassword = async ({
  token,
  password,
}: {
  token: string;
  password: string;
}): Promise<any> => {
  const { data } = await api.post(`${URL}/auth/reset-password?token=${token}`, {
    password,
  });

  return data;
};

export const checkIfEmailExist = async (email: string) => {
  const { data } = await api.get<{ isEmailExist: boolean }>(
    `${URL}/users/check-user-email/${email}`,
  );

  return data?.isEmailExist;
};
export const resendEmail = async (email: string) => {
  const { data } = await api.post(`${URL}/users/resend-activation-email`, {
    email,
  });

  return data;
};

export const updateUserData = async ({ id, ...payload }: any) => {
  const { data } = await api.patch<Session['user']>(
    `${URL}/users/${id}`,
    payload,
  );

  return data;
};

export const deleteUser = async (userId: string) => {
  const { data } = await api.delete(`${URL}/users/${userId}`);

  return data;
};
type AccountActivation = {
  message: string;
};

export const activateAccount = async (
  userId: string | undefined | null,
): Promise<AccountActivation> => {
  if (!Boolean(userId)) {
    throw Error('User id cannot be empty');
  }

  const { data } = await api.post(
    `${URL}/users/activate-account/${userId}`,
    {},
  );

  return data;
};

type setPasswordActivationResponse = {
  message: string;
};
//TODO: Apply the correct endpoint
export const setPasswordActivation = async (
  token: string | undefined | null,
  password: string | undefined | null,
): Promise<setPasswordActivationResponse> => {
  if (!Boolean(token)) throw Error('Token cannot be empty');
  if (!Boolean(password)) throw Error('Password cannot be empty');

  const { data } = await api.post(
    `${URL}/auth/set-password-activation?token=${token}`,
    {
      password,
    },
  );

  return data;
};

export const googleSignUp = async (token: string) => {
  const { data } = await api.post(`${URL}/auth/google-register`, {
    token,
  });

  return data;
};

export const googleLogin = async (token: string) => {
  const { data } = await api.post(`${URL}/auth/google-login`, {
    token,
  });

  return data;
};

export default {
  login,
  logout,
  signup,
  uploadImage,
  forgotPassword,
  resetPassword,
};
