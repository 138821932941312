import React from 'react';
import { Stack } from '@chakra-ui/react';

import { ClientHeaderProps } from './ClientHeader.props';
import CollapsibleMenu from 'components/primitives/CollapsibleMenu';

const ClientHeaderView = (props: ClientHeaderProps): JSX.Element => {
  return (
    <Stack flexDirection={'row-reverse'} borderRadius="14px">
      <CollapsibleMenu />
    </Stack>
  );
};

export default ClientHeaderView;
