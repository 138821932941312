import React, { useCallback, useState } from 'react';

import { useToast } from '@chakra-ui/react';

import {
  upgradePlan,
  getAllPlans,
  chargebeeCheckout,
  checkoutAcknowledge,
  reactivateSubscription,
  createProMonthlySubscription,
} from 'api/chargebee';
import { useMutation, useQuery, useQueryCache } from 'react-query';

import { useChargebee } from 'utils/hooks/useChargebee';

import { getUser, subscribeToPro } from 'api/auth';

import { showEv } from 'effector/alert';
import { getUserId } from 'effector/session/store';
import { fetchUser, toggleProEvent } from 'effector/session/events';

import * as session from 'effector/session';
import * as chargebee from 'effector/chargebee';

import { useHistory } from 'react-router-dom';

import { routesSettings } from 'constants/routes';

import { WelcomePageProps } from './WelcomePage.props';

import WelcomePageView from './WelcomePage.view';
import subscriptionPlansUtils from 'utils/chargebeeSubscriptionsUtils';

const plansMap: Record<string, string> = {
  'Pro-Monthy': 'Pro-Subscription-Annually-USD-Monthly',
  'Pro-Annually': 'Pro-Subscription-Annually-USD-Yearly',
  //Free: 'Free-USD-Yearly',
};

const WelcomePageContainer = (props: WelcomePageProps): JSX.Element => {
  const toast = useToast();
  const cbInstance = useChargebee();
  const queryCache = useQueryCache();

  const history = useHistory();

  const uid = session.selectors.useUserId();

  const cbSubId = chargebee.selectors.useCbSubId();
  const subscriptions = chargebee.selectors.useAllCbSubs();

  const isSubExist = subscriptions?.length > 0;

  const [isPending, setIsPending] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(1);

  const { data, isLoading } = useQuery(`fetch-all-plans`, getAllPlans);

  const cardStatus = subscriptions?.[0]?.card?.status || '';
  const isValidCard = cardStatus === 'valid';

  const {
    isMonthlyPlan,
    isYearlyPlan,
    isFreePlan,
    isTrialPeriod,
  } = subscriptionPlansUtils();

  const onSuccess = async () => {
    const uid = getUserId();
    const queryKey = `fetch-user-${uid}`;

    setIsPending(true);

    /**
     * @author: Munish
     * in some cases, Chargebee takes a few seconds to send the webhook request
     * that updates the user entity
     * we might need to fetch the user details multiple time,
     * just to make sure if user is been updated or not
     */

    // sleep for 3 seconds
    await new Promise((resolve) => setTimeout(resolve, 3000));

    const user = await getUser(uid || '').catch(() => null);

    if (user?.pro) {
      if (!user?.isActivated) {
        showEv({
          isOpen: true,
          title: 'Congratulations!!',
          message: `You can now explore the "Pro" features. As the first step, we recommend you to activate your account by verifying your email address.`,
          btnText: 'GO TO DASHBOARD',
          img: undefined,
          goToDashboard: true,
        });
      } else {
        showEv({
          isOpen: true,
          title: 'Congratulations!!',
          message: `You can now explore the "Pro" features. As the first step, we recommend you start setting your new site.`,
          btnText: 'SET UP WEBSITE',
          img: undefined,
          goToDashboard: false,
        });
      }

      toggleProEvent(true);
    } else {
      // this is an event which triggers the getUser
      // api call recursively until `pro=true` is returned
      fetchUser();
    }

    chargebee.fetchCbSubsEv();

    setIsPending(false);

    queryCache.invalidateQueries(queryKey, { exact: true });
  };

  const [acknowledge, { isLoading: isAcknowledging }] = useMutation(
    checkoutAcknowledge,
    {
      onSuccess,
    },
  );

  const [createFreeSub, { isLoading: isCreating }] = useMutation(
    createProMonthlySubscription,
    {
      onSuccess,
    },
  );

  const [upgrade, { isLoading: isUpgrading }] = useMutation(
    (payload: any) => {
      const trialEnd = subscriptions?.[0]?.subscription?.trial_end;

      return upgradePlan({ ...payload, trialEnd });
    },
    {
      onSuccess: (options) => {
        cbInstance.openCheckout({
          hostedPage: async () => options,
          close: () => {
            cbInstance.closeAll();
          },
          success: (hostedPageId: string) => {
            acknowledge(hostedPageId);
            cbInstance.closeAll();
          },
        });
      },
    },
  );

  const [submit, { isLoading: isSubmitting }] = useMutation(
    async (planId: string) => {
      return chargebeeCheckout(planId);
    },
    {
      onSuccess: (options) => {
        cbInstance.openCheckout({
          hostedPage: async () => options,
          close: () => {
            cbInstance.closeAll();
          },
          success: (hostedPageId: string) => {
            acknowledge(hostedPageId);
            cbInstance.closeAll();
          },
        });
      },
    },
  );

  const [updateUser, { isLoading: isUpdatingUser }] = useMutation(
    () => subscribeToPro(uid || ''),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(`fetch-user-${uid}`, { exact: true });

        toggleProEvent(true);

        history.replace(routesSettings.PILOT_DASHBOARD.path);
      },
    },
  );

  const [reactivate, { isLoading: isReactivating }] = useMutation(
    reactivateSubscription,
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Subscription Reactivated',
          duration: 5000,
          isClosable: true,
        });

        chargebee.fetchCbSubsEv();

        updateUser();
      },
    },
  );

  const onPlanSelect = useCallback(
    (type: 'Pro-Annually') => {
      if (!data) {
        return;
      }

      const plan = plansMap[type];

      submit(plan);
    },
    [data],
  );

  const onPlanUpgrade = (type: 'Pro-Annually') => {
    const planId = plansMap[type];

    upgrade({
      planId,
      subscriptionId: cbSubId,
    });
  };

  const onReactivate = () => {
    reactivate(cbSubId);
  };

  return (
    <WelcomePageView
      isFreePlan={isFreePlan}
      isBtnDisabled={!data}
      isLoading={isLoading}
      onPlanSelect={onPlanSelect}
      isSubmitting={
        isSubmitting ||
        isAcknowledging ||
        isReactivating ||
        isUpdatingUser ||
        isUpgrading ||
        isCreating
      }
      isSubExist={isSubExist}
      isPending={isPending}
      isMonthlyPlan={isMonthlyPlan}
      isYearlyPlan={isYearlyPlan}
      onReactivate={onReactivate}
      onPlanUpgrade={onPlanUpgrade}
      isTrialPeriod={isTrialPeriod}
      selectedPlan={selectedPlan}
      setSelectedPlan={setSelectedPlan}
      subscription={subscriptions?.[0]}
      createFreeSub={() => createFreeSub()}
      isValidCard={isValidCard}
    />
  );
};

export default WelcomePageContainer;
