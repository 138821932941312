import { METERS } from 'constants/common';
import { CMS, MinimumRequirements } from 'types/cms';
import * as Yup from 'yup';

export const initialValues: CMS = {
  phone: '',
  gps: {
    coordinates: [-74.006652, 40.712675],
  },
  radius: 50 * METERS,
  location: {
    address: '',
    city: '',
    state: '',
    zipcode: '',
  },
  stylings: {
    colorScheme: '',
    url: '',
    font: 'Roboto',
  },
  about: {
    companyName: '',
    dayAvailability: [],
    introduction: '',
  },
  equipments: [],
  enableTestimonials: false,
  testimonials: [],
  industries: [],
  packages: [],
  previousWork: [],
  socials: [],
  subscriptionEnd: '',
  additionalServices: [],
  templateType: 0,
  bannerImage: '',
  name: '',
  description: '',
  price: 0,
  lineItems: [],
  industry: [],
};

export const hours: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const minutes: Array<number> = [0, 15, 30, 45];

export const fonts = [
  'Roboto',
  'Arial',
  'Verdana',
  'Monument Extended',
  'Poppins',
  'Open Sans',
  'Montserrat',
  'Ubuntu',
  'Lora',
];

const checkObjectProps = (obj: any) => {
  let count = 0;

  Object.keys(obj).forEach((field: string) => {
    if (field.includes(MinimumRequirements.LOCATION)) {
      if (Boolean(obj[field])) count++;
    }
    if (field.includes(MinimumRequirements.COMPANY_NAME)) {
      if (Boolean(obj[field].companyName)) count++;
    }
    if (field.includes(MinimumRequirements.PACKAGE)) {
      if (Boolean(obj[field].length)) count++;
    }
  });

  return count;
};

export const getProgress = (values: CMS, isAccConnected?: boolean): number => {
  const totalFields = Object.keys(MinimumRequirements).length;

  if (('id' && 'disabled') in values) {
    const { id, disabled, ...restValues } = values;
    let totalCount = checkObjectProps(restValues);

    if (isAccConnected) {
      totalCount = checkObjectProps(restValues) + 1;
    }

    const progress = Math.ceil((totalCount / totalFields) * 100);

    return progress >= 100 ? 100 : progress;
  } else {
    const totalCount = checkObjectProps(values);

    const progress = Math.ceil((totalCount / totalFields) * 100);

    return progress >= 100 ? 100 : progress;
  }
};

export const businessDetailsSchema = Yup.object().shape({
  gps: Yup.object().shape({
    coordinates: Yup.array().of(Yup.number()),
  }),
  radius: Yup.number(),
  location: Yup.object().shape({
    address: Yup.string().required('Address is a required field'),
    city: Yup.string(),
    state: Yup.string().required('State is a required field'),
    zipcode: Yup.string().required('Zip code is a required field'),
  }),
  about: Yup.object().shape({
    introduction: Yup.string().max(1000, 'Number of characters exceeded'),
  }),
});

export const checkFormFilled = (
  cms: CMS,
  section: 0 | 1 | 2 | 3 | 4 | 5 | 6,
) => {
  switch (section) {
    case 0: {
      const isValid = Boolean(
        !!cms?.profileImage &&
          !!cms?.bannerImage &&
          !!cms?.stylings?.colorScheme &&
          typeof cms?.templateType === 'number',
      );

      return isValid;
    }

    case 1: {
      const isValid = Boolean(
        !!cms?.about?.companyName &&
          !!cms?.about?.introduction &&
          !!cms?.location?.address &&
          (cms?.about?.dayAvailability?.length > 0 ||
            !!cms?.about?.alwaysAvailable),
      );

      return isValid;
    }

    case 2: {
      const isValid = Boolean(cms?.previousWork?.length > 0);

      return isValid;
    }

    case 3: {
      const isValid = Boolean(
        cms?.industries?.length > 0 && cms?.packages?.length > 0,
      );

      return isValid;
    }

    case 4: {
      const isValid = Boolean(cms?.equipments?.length > 0);

      return isValid;
    }
    //validation for the website form from the pilot web site
    case 5: {
      const isValid = Boolean(!!cms?.location?.address);

      return isValid;
    }
    case 6: {
      const isValid = Boolean(
        !!cms?.about?.companyName &&
          !!cms?.signedUrl &&
          (cms?.about?.dayAvailability?.length > 0 ||
            !!cms?.about?.alwaysAvailable) &&
          !!cms?.stylings?.colorScheme,
      );

      return isValid;
    }
    default:
      return true;
  }
};
