import React, { FC, useEffect, useRef, useState } from 'react';

import { Center } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { Image, ImageProps } from '@chakra-ui/image';

export const ImageItem: FC<ImageProps> = (props) => {
  const isUnmountedRef = useRef(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () => () => {
      isUnmountedRef.current = true;
    },
    [],
  );

  const onToggle = () => {
    if (isUnmountedRef.current) {
      return;
    }

    setIsLoading(false);
  };

  return (
    <Center h="150px" w="100%" position="relative">
      <Image
        {...props}
        opacity={isLoading ? 0.6 : 1}
        onLoad={onToggle}
        onError={onToggle}
      />
      {isLoading && <Spinner position="absolute" size="lg" zIndex={1} />}
    </Center>
  );
};
