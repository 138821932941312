class EventRegister {
  static subscribe(
    eventName: string,
    listener: EventListenerOrEventListenerObject,
  ) {
    document.addEventListener(eventName, listener);
  }

  static unsubscribe(
    eventName: string,
    listener: EventListenerOrEventListenerObject,
  ) {
    document.removeEventListener(eventName, listener);
  }

  static emit<T = unknown>(eventName: string, data: T) {
    const event = new CustomEvent(eventName, { detail: data });

    document.dispatchEvent(event);
  }
}

export { EventRegister };
