import React, { memo, useCallback, useRef, useState } from 'react';

import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/modal';
import { Button } from '@chakra-ui/button';
import { useToast } from '@chakra-ui/toast';
import { Spinner } from '@chakra-ui/spinner';
import { useDisclosure } from '@chakra-ui/hooks';
import { Box, Center, Flex, Stack, Text } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';

import { MdLink } from 'react-icons/md';
import { BiDotsHorizontal } from 'react-icons/bi';

import * as yup from 'yup';

import { Form, Formik, useField } from 'formik';

import placeholder from 'res/placeholder.png';

import {
  createPreviousWorkItem,
  deletePreviousWorkItem,
  updatePreviousWorkItem,
} from 'api/cms';
import { useMutation, useQueryCache } from 'react-query';

import FormikInputView from 'components/primitives/FormikInput';

import { Progress } from './Progress';
import { ImageItem } from './ImageItem';
import { TitleInput } from './TitleInput';
import { ThumbnailPicker } from './ThumbnailPicker';

import {
  Bg,
  IconBg,
  PickerContainer,
  scrollBarStyles,
} from './PreviousWorkSection.style';

const colorScheme = '#6728BB';

const validationSchema = yup.object().shape({
  url: yup.string().required(),
  title: yup.string(),
});

export const VideoPicker = memo(() => {
  const toast = useToast();
  const progressRef = useRef<any>(null);

  const queryCache = useQueryCache();

  const { value } = useField('previousWork')[0];
  const { value: stylings } = useField<any>('stylings')[0];

  const color = stylings?.colorScheme || colorScheme;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const videos = value?.filter((f: any) => !!f?.url);

  const initialValues: any = {
    id: undefined,
    url: '',
    title: '',
    file: null,
  };

  const [formValues, setFormValues] = useState(initialValues);

  const [create] = useMutation(
    ({ file, url, title }: { file?: File; url: string; title: string }) =>
      createPreviousWorkItem(file, url, title, (e) => {
        progressRef.current?.setProgress(
          Math.round((e.loaded / e.total) * 100),
        );
      }),
    {
      onSuccess: () => {
        toast({
          status: 'success',
          description: 'The files were successfully stored',
          duration: 2000,
          isClosable: true,
        });

        queryCache.invalidateQueries('fetch-my-cms', {
          exact: true,
        });
        queryCache.invalidateQueries(`fetch-my-cms-DRAFT`, {
          exact: true,
        });

        onClose();
      },
    },
  );

  const [update] = useMutation(
    ({
      file,
      url,
      title,
      id,
    }: {
      file?: File;
      url: string;
      title: string;
      id: string;
    }) =>
      updatePreviousWorkItem(file, url, title, id, (e) => {
        progressRef.current?.setProgress(
          Math.round((e.loaded / e.total) * 100),
        );
      }),
    {
      onSuccess: () => {
        toast({
          status: 'success',
          description: 'The files were successfully stored',
          duration: 2000,
          isClosable: true,
        });

        queryCache.invalidateQueries('fetch-my-cms', {
          exact: true,
        });
        queryCache.invalidateQueries(`fetch-my-cms-DRAFT`, {
          exact: true,
        });

        onClose();
      },
    },
  );

  const [onDelete, { isLoading }] = useMutation(deletePreviousWorkItem, {
    onSuccess: () => {
      toast({
        duration: 2000,
        isClosable: true,
        status: 'success',
        title: 'File Deleted',
      });

      queryCache.invalidateQueries('fetch-my-cms', {
        exact: true,
      });
      queryCache.invalidateQueries(`fetch-my-cms-DRAFT`, {
        exact: true,
      });
    },
  });

  const renderModal = useCallback(
    (isModalOpen: boolean, initial: object) => (
      <Modal
        size="xl"
        isCentered
        isOpen={isModalOpen}
        onClose={() => {
          setFormValues(initialValues);
          onClose();
        }}
        closeOnOverlayClick>
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={initial}
            onSubmit={async ({ file, title, url, id = '' }: any) => {
              if (id) {
                return await update({
                  file,
                  title,
                  url,
                  id,
                });
              } else {
                return await create({
                  file,
                  title,
                  url,
                });
              }
            }}
            validationSchema={validationSchema}
            enableReinitialize>
            {({ submitForm, isSubmitting }) => (
              <Form>
                <ModalHeader textAlign="center" color="#424242">
                  Add link for your video
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormikInputView name="url" label="URL" />
                  <FormikInputView name="title" label="Title" />
                  <ThumbnailPicker>
                    {isSubmitting && (
                      <Center
                        position="absolute"
                        top={0}
                        bottom={0}
                        left={0}
                        right={0}
                        borderRadius="5px">
                        <Box
                          h="100%"
                          w="100%"
                          opacity={0.6}
                          bg="#4f4f4f"
                          position="absolute"
                        />
                        <Progress ref={progressRef} />
                      </Center>
                    )}
                  </ThumbnailPicker>
                </ModalBody>
                <ModalFooter justifyContent="center">
                  <Button
                    w="200px"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    onClick={submitForm}>
                    {(initial as any)?.id ? 'UPDATE' : 'CREATE'}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    ),
    [],
  );

  if (videos?.length > 0) {
    return (
      <Flex overflowX="auto" css={scrollBarStyles}>
        <Flex mb={4} mt={1}>
          {[{ id: 'dummy' }, ...videos].map((item: any, index: any) =>
            item.id === 'dummy' ? (
              <Flex
                key={`${item.id}-${index}`}
                h="200px"
                w="220px"
                mr="15px"
                align="center"
                justify="center"
                direction="column"
                overflow="hidden"
                borderRadius="10px"
                border={`2px dashed ${color}`}
                position="relative"
                onClick={onOpen}>
                <Bg w="220px" h="200px" bg={color} />
                <Center w="80px" h="80px" position="relative">
                  <IconBg />
                  <MdLink fill={color} size="40px" color="#d8d8d8" />
                </Center>
                <Text m={2} color={color} textAlign="center">
                  Add your video link
                </Text>
              </Flex>
            ) : (
              <Flex
                key={`${item.id}-${index}`}
                mr="15px"
                h="200px"
                w="220px"
                overflow="hidden"
                direction="column"
                borderRadius="10px"
                position="relative"
                boxShadow={`0px 0px 5px 0px ${color}50`}>
                <ImageItem
                  h="150px"
                  w="100%"
                  src={item?.noAsset ? placeholder : item?.signedUrl}
                  objectFit="contain"
                  cursor="pointer"
                  userSelect="none"
                  fallbackSrc={placeholder}
                  onClick={() => {
                    if (!item?.url) {
                      return;
                    }

                    const isHttp =
                      item.url.includes('http') || item.url.includes('https');

                    if (isHttp) {
                      window.open(item.url, '_blank');
                    } else {
                      window.open(`https://${item.url}`, '_blank');
                    }
                  }}
                />
                <TitleInput item={item} />
                <Menu closeOnSelect={false}>
                  {({ onClose: onMenuClose }) => (
                    <>
                      {/*@ts-ignore*/}
                      <MenuButton
                        as={Button}
                        size="xs"
                        maxW="40px"
                        position="absolute"
                        right={0}
                        _focus={{ border: 'none' }}>
                        <BiDotsHorizontal size="18px" />
                      </MenuButton>
                      <MenuList minW="100px" fontSize="12px">
                        <MenuItem
                          onClick={() => {
                            setFormValues({
                              id: item.id,
                              title: item.title,
                              file: item.signedUrl
                                ? {
                                    preview: item.signedUrl,
                                  }
                                : null,
                              url: item.url,
                            });
                            onMenuClose();
                            onOpen();
                          }}
                          isDisabled={isLoading}
                          fontWeight="bold">
                          Edit
                        </MenuItem>
                        <MenuItem
                          isDisabled={isLoading}
                          fontWeight="bold"
                          onClick={async () => {
                            await onDelete(item.id);
                            onMenuClose();
                          }}>
                          <Text>Delete</Text>
                          {isLoading && <Spinner ml={2} size="xs" />}
                        </MenuItem>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Flex>
            ),
          )}
        </Flex>
        {renderModal(isOpen, formValues)}
      </Flex>
    );
  }

  return (
    <Stack maxW="700px" w="100%" alignSelf="center" onClick={onOpen}>
      <PickerContainer borderColor={color}>
        <Bg bg={color} />
        <Center w="80px" h="80px" position="relative">
          <IconBg bg={color} />
          <MdLink fill={color} size="40px" color="#d8d8d8" />
        </Center>
        <Text ml={2} color={color}>
          Add your video link
        </Text>
      </PickerContainer>
      {renderModal(isOpen, initialValues)}
    </Stack>
  );
});
