import React, { FC, useRef, useState } from 'react';

import {
  Flex,
  Text,
  Button,
  VStack,
  HStack,
  IconButton,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { MdAdd, MdArrowDropDown, MdRemove } from 'react-icons/md';

import { TimePickerProps } from './TimePicker.props';

const MotionFlex = motion.custom(Flex);

const TimePicker: FC<TimePickerProps> = ({
  onClick,
  onChange,
  brandColor = '#6728BB',
  ...props
}) => {
  const popoverRef = useRef(null);

  const buttonColorProps = {
    bg: brandColor,
    _hover: {
      bg: `${brandColor}95`,
    },
    _active: {
      bg: brandColor,
    },
  };

  const [selectedHour, setSelectedHour] = useState('01');
  const [selectedMinute, setSelectedMinute] = useState('00');
  const [selectedPeriod, setSelectedPeriod] = useState('AM');

  const hours = Array.from({ length: 12 }, (_, i) =>
    (i + 1).toString().padStart(2, '0'),
  );
  const minutes = ['00', '15', '30', '45'];

  const { isOpen, onOpen, onClose } = useDisclosure();

  useOutsideClick({
    ref: popoverRef,
    handler: onClose,
  });

  const handleChange = (_hour: string, _minutes: string, _period: string) => {
    const periodOffset = _period === 'PM' ? 12 : 0;
    const hour24 = parseInt(_hour, 10) + periodOffset;
    const time24 = `${hour24.toString().padStart(2, '0')}:${_minutes}`;

    onChange(time24);
  };

  const handleHourIncrement = () => {
    const index = hours.indexOf(selectedHour);
    const newHour = hours[(index + 1) % hours.length];

    setSelectedHour(newHour);
    handleChange(newHour, selectedMinute, selectedPeriod);
  };

  const handleHourDecrement = () => {
    const index = hours.indexOf(selectedHour);
    const newHour = hours[(index - 1 + hours.length) % hours.length];

    setSelectedHour(newHour);
    handleChange(newHour, selectedMinute, selectedPeriod);
  };

  const handleMinuteIncrement = () => {
    const index = minutes.indexOf(selectedMinute);
    const newMinutes = minutes[(index + 1) % minutes.length];

    setSelectedMinute(newMinutes);
    handleChange(selectedHour, newMinutes, selectedPeriod);
  };

  const handleMinuteDecrement = () => {
    const index = minutes.indexOf(selectedMinute);
    const newMinutes = minutes[(index - 1 + minutes.length) % minutes.length];

    setSelectedMinute(newMinutes);
    handleChange(selectedHour, newMinutes, selectedPeriod);
  };

  const handlePeriodToggle = () => {
    const period = selectedPeriod === 'AM' ? 'PM' : 'AM';

    setSelectedPeriod(period);
    handleChange(selectedHour, selectedMinute, period);
  };

  const handleFocus = (event: React.FocusEvent<HTMLDivElement>) => {
    event.currentTarget.style.borderColor = '#3182CE';
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    event.currentTarget.style.borderColor = '#E2E2E2';
  };

  return (
    <Flex alignItems="center" position="relative" ref={popoverRef}>
      <Flex
        px="10px"
        py="8px"
        align="center"
        fontSize="14px"
        whiteSpace="pre"
        borderRadius="5px"
        userSelect="none"
        cursor="pointer"
        borderWidth="1px"
        borderBottomRadius={isOpen ? '0px' : '5px'}
        onFocus={handleFocus}
        onBlur={handleBlur}
        tabIndex={0}
        onClick={(e) => {
          onClick?.(e);
          onOpen();
        }}
        {...props}>
        <Text minW="20px">{selectedHour}</Text>
        <Text mr={1}>:</Text>
        <Text minW="20px">{selectedMinute}</Text>
        <Text mr={1}> {selectedPeriod}</Text>
        <MdArrowDropDown size="20px" />
      </Flex>
      <AnimatePresence>
        {isOpen && (
          <MotionFlex
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            borderRadius="5px"
            borderTopRadius={isOpen ? '0' : '5px'}
            position="absolute"
            top="100%"
            bg="#fff"
            zIndex={2}
            right={0}
            left={0}
            justify="center"
            shadow="lg">
            <VStack spacing={2}>
              <HStack mt={2}>
                <IconButton
                  {...buttonColorProps}
                  aria-label="increment-hour"
                  isRound
                  size="xs"
                  icon={<MdRemove size="12px" />}
                  onClick={handleHourDecrement}
                />
                <Text textAlign="center" minW="20px" fontSize="14px">
                  {selectedHour}
                </Text>
                <IconButton
                  {...buttonColorProps}
                  aria-label="increment-hour"
                  isRound
                  size="xs"
                  icon={<MdAdd size="12px" />}
                  onClick={handleHourIncrement}
                />
              </HStack>

              <HStack>
                <IconButton
                  {...buttonColorProps}
                  aria-label="increment-hour"
                  isRound
                  size="xs"
                  icon={<MdRemove size="12px" />}
                  onClick={handleMinuteDecrement}
                />
                <Text textAlign="center" minW="20px" fontSize="14px">
                  {selectedMinute}
                </Text>
                <IconButton
                  {...buttonColorProps}
                  aria-label="increment-hour"
                  isRound
                  size="xs"
                  icon={<MdAdd size="12px" />}
                  onClick={handleMinuteIncrement}
                />
              </HStack>
              <HStack mb="10px">
                <Button
                  {...buttonColorProps}
                  w="80px"
                  size="xs"
                  onClick={handlePeriodToggle}>
                  {selectedPeriod}
                </Button>
              </HStack>
            </VStack>
          </MotionFlex>
        )}
      </AnimatePresence>
    </Flex>
  );
};

export default TimePicker;
