import { createEvent, forward, sample } from 'effector';
import { useStore } from 'effector-react';

import withStorage from 'effector-storage/sync';

import { Session } from 'types/session';

import * as events from './events';

import { SessionDomain } from './domain';
import { fetchActionRangeAddress, fetchUserFx } from './effects';

// 12 hours
const SESSION_DURATION = 12 * 60 * 60 * 1000;

interface SessionWithExpiryTime {
  sessionExpiryTime?: string;
}

export interface SessionStore extends SessionWithExpiryTime {
  initiated: boolean;
  session?: Session;
  isNewUser?: boolean;
  rememberMe?: boolean;
  isTutorialShown?: boolean;
}

const initialState: SessionStore = {
  initiated: false,
  isNewUser: false,
  rememberMe: false,
  isTutorialShown: true,
};

const createSessionStore = withStorage(SessionDomain.store, createEvent);

export const SessionStore = createSessionStore(initialState, {
  key: 'sessionStore',
})
  .on(events.loginEvent, (_, { session, rememberMe }) => {
    const stateWithSessionExpiry: SessionWithExpiryTime = {};

    if (rememberMe === false) {
      const expiryTime = new Date().getTime() + SESSION_DURATION;

      stateWithSessionExpiry.sessionExpiryTime = expiryTime.toString();
    }

    return {
      initiated: true,
      session,
      rememberMe,
      ...stateWithSessionExpiry,
    };
  })
  .on(events.signupEvent, (_, session) => {
    const expiryTime = new Date().getTime() + SESSION_DURATION;

    return {
      session,
      initiated: true,
      isNewUser: true,
      isTutorialShown: false,
      rememberMe: false,
      sessionExpiryTime: expiryTime.toString(),
    };
  })
  .on(
    events.toggleNewUser,
    (state, isNewUser) =>
      ({
        ...state,
        isNewUser,
      } as any),
  )
  .on(events.logoutEvent, () => ({
    initiated: true,
  }))
  .on(
    events.addActionRangeEvent,
    (state, actionRange) =>
      ({
        ...state,
        session: {
          ...state?.session,
          user: {
            ...state?.session?.user,
            radius: actionRange?.radius,
            range: {
              ...state?.session?.user?.range,
              coordinates: [actionRange?.lng, actionRange?.lat],
              address: actionRange?.address,
            },
          },
        },
      } as any),
  )
  .on(
    events.clearActionRangeEvent,
    (state) =>
      ({
        ...state,
        session: {
          ...state?.session,
          user: {
            ...state?.session?.user,
            range: {},
          },
        },
      } as any),
  )
  .on(
    events.toggleProEvent,
    (state, isPro) =>
      ({
        ...state,
        session: {
          ...state?.session,
          user: {
            ...state?.session?.user,
            pro: isPro,
          },
        },
      } as any),
  )
  .on(events.refreshTokenEvent, (state, tokens) => {
    return {
      ...state,
      session: {
        ...state?.session,
        tokens,
      },
    } as any;
  })
  .on(
    fetchActionRangeAddress.doneData,
    (state, address) =>
      ({
        ...state,
        session: {
          ...state?.session,
          user: {
            ...state?.session?.user,
            range: {
              ...state?.session?.user?.range,
              address,
            },
          },
        },
      } as any),
  )
  .on(
    events.updateUserEvent,
    (state, user) =>
      ({
        ...state,
        session: {
          ...state?.session,
          user: {
            ...state?.session?.user,
            ...user,
          },
        },
      } as any),
  )
  .on(
    events.hideTutorialEvent,
    (state) =>
      ({
        ...state,
        isTutorialShown: true,
      } as any),
  );

const $isSession = SessionStore.map((s) => !!s?.session);
const $user = SessionStore.map((s) => s?.session?.user);
const $isNewUser = SessionStore.map((s) => s?.isNewUser);
const $isPro = SessionStore.map((s) => s?.session?.user?.pro);
const $userId = SessionStore.map((s) => s?.session?.user?.id);
const $stripeId = SessionStore.map((s) => s?.session?.user?.stripeId);
const $accountType = SessionStore.map((s) => s?.session?.user?.accountType);
const $sessionExpiryTime = SessionStore.map((s) => s?.sessionExpiryTime);
const $isTutorialShown = SessionStore.map((s) => s?.isTutorialShown);

const $fetching = fetchUserFx.pending;

forward({
  from: events.fetchUser,
  to: fetchUserFx,
});

sample({
  clock: fetchUserFx.doneData,
  source: fetchUserFx.doneData.map(Boolean),
  fn: (source) => Boolean(source),
  target: events.toggleProEvent,
});

export const getUserId = $userId.getState;
export const getStripeId = $stripeId.getState;
export const getUserType = () =>
  SessionStore.getState()?.session?.user?.accountType || '';
export const getIsAccountActivatedStatus = () => $user.getState()?.isActivated;
export const getUserEmail = () => $user.getState()?.email || '';
export const getRememberMe = () => !!SessionStore.getState()?.rememberMe;
export const getSessionExpiryTime = () => $sessionExpiryTime.getState();
export const getRefreshToken = () => {
  const refreshToken = SessionStore.getState()?.session?.tokens?.refresh?.token;

  return refreshToken;
};
export const getIsNewUser = () => !!SessionStore.getState()?.isNewUser;

export const getAccessToken = () => {
  const accessToken = SessionStore.getState()?.session?.tokens?.access?.token;

  return accessToken;
};

export const shouldSessionPersist = (shouldReturnValue?: boolean) => {
  const rememberMe = getRememberMe();

  if (rememberMe) {
    return;
  }

  const sessionExpiryTime = getSessionExpiryTime();

  if (!sessionExpiryTime) {
    return;
  }

  const currentTime = new Date().getTime();
  const expiryTime = parseInt(sessionExpiryTime, 10);

  if (currentTime > expiryTime) {
    if (shouldReturnValue) {
      return true;
    }

    // clear session if rememberMe if false and session expiry time is past

    events.logoutEvent();

    window.location.reload();
  }
};

export const selectors = {
  useUser: () => useStore($user),
  useIsPro: () => useStore($isPro),
  useUserId: () => useStore($userId),
  useFetching: () => useStore($fetching),
  useStripeId: () => useStore($stripeId),
  useIsNewUser: () => useStore($isNewUser),
  useIsSession: () => useStore($isSession),
  useAccountType: () => useStore($accountType),
  useIsTutorialShown: () => useStore($isTutorialShown),
  useActionRange: () => {
    const user = useStore($user);

    return {
      radius: user?.radius,
      lat: user?.range?.coordinates?.[1],
      lng: user?.range?.coordinates?.[0],
      address: user?.range?.address,
    };
  },
};
