import { Tab, TabList, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const Spacer = styled.div`
  margin: 10px 20px;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid #ccc;
  height: 20px;
  width: 20px;

  @media (max-width: 1200px) {
    height: 0;
    width: 0;
    margin: 5px 8px;
    padding: 0;
    border: none;
  }
`;

export const SpacerFill = styled.div`
  margin: 10px 20px;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid #ccc;
  background: #fff;
  height: 20px;
  width: 20px;

  @media (max-width: 1200px) {
    height: 0;
    width: 0;
    margin: 5px 8px;
    padding: 0;
    border: none;
  }
`;

export const ResponsiveTabList = styled(TabList)`
  @media (min-width: 800px) and (max-width: 900px) {
    flex-direction: column;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ResponsiveButtonFlex = styled(Flex)`
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 1180px) {
    > div {
      flex-basis: calc(50% - 20px);
      flex-wrap: wrap;
    }
  }

  @media (max-width: 850px) {
    flex-direction: column;

    > div {
      flex-basis: 100%;
    }
  }

  @media (min-width: 500px) and (max-width: 800px) {
    flex-direction: row;

    > div {
      flex-basis: calc(50% - 20px);
      flex-wrap: wrap;
    }
  }
`;

export const ResponsivePackageBoxContainer = styled(Flex)`
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1200px) {
    > div {
      margin-bottom: 60px;
      flex-basis: calc(50% - 10px);
      flex-wrap: wrap;
    }
  }

  @media (min-width: 800px) and (max-width: 900px) {
    > div {
      margin-bottom: 50px;
      flex-basis: 100%;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 500px) {
    > div {
      margin-bottom: 50px;
      flex-basis: 100%;
      flex-wrap: wrap;
    }
  }
`;

export const ResponsiveTab = styled<any>(Tab)`
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const ResposiveContainerWithSidebar = styled(Flex)`
  @media (max-width: 1100px) {
    flex-direction: column-reverse;

    > div {
      width: 100%;
    }
  }
`;
