import React, { FC, useState } from 'react';

import {
  Box,
  Text,
  Flex,
  Stack,
  Center,
  Spinner,
  useToast,
} from '@chakra-ui/react';

import { AloftResponse } from 'types/mission';
import { ErrorMessage, useFormikContext } from 'formik';

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import GoogleMaps from 'components/primitives/GoogleMaps';
import PlacesAutocomplete from 'components/primitives/PlacesAutocomplete';

import { aloftCheck } from 'api/mission';

import { AloftToast } from 'components/primitives/AloftToast';
import { useIsMobile } from 'utils/hooks';
import { TitleWithIcon } from 'components/primitives/TitleWithIcon';

const css = {
  '::placeholder': {
    fontStyle: 'italic',
    fontSize: '12px',
  },
};
const brandColor = '#6728BB';
const fontSize = '14px';
const labelProps = {
  fontSize: '12px',
  color: 'red',
};

export const LocationDetails: FC = () => {
  const toast = useToast();
  const isMobile = useIsMobile();

  const { values, errors, setFieldTouched, setFieldValue } = useFormikContext<
    any
  >();

  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [response, setResponse] = useState<null | AloftResponse>(null);

  const coordinates = {
    lat: values?.clientDetails?.location?.lat || 0,
    lng: values?.clientDetails?.location?.lng || 0,
  };

  const checkAloftData = async (payload: {
    latitude: number;
    longitude: number;
  }) => {
    try {
      setIsChecking(true);

      const _response = await aloftCheck(payload);

      setIsChecking(false);

      setResponse(_response);

      return true;
    } catch (error: any) {
      setIsChecking(false);

      toast({
        status: 'error',
        description: error?.message || 'something went wrong',
        isClosable: true,
        duration: 2000,
      });

      return false;
    }
  };

  return (
    <Stack
      maxW={!isMobile ? { base: '280px', sm: '360px' } : { sm: '360px' }}
      width="100%">
      <PlacesAutocomplete
        infoColor={brandColor}
        value={values.clientDetails?.location?.address}
        name="clientDetails.location.address"
        labelText="Location"
        setFieldValue={setFieldValue}
        isMobile={isMobile}
        inputStyle={{
          background: '#F3F3F3',
          size: 'md',
          css,
          fontSize,
          mb: 0,
          onBlur: () => setFieldTouched('clientDetails.location.address', true),
        }}
        labelProps={{ ...labelProps, fontWeight: 'bold', mt: 0 }}
        searchOptions={{
          componentRestrictions: {
            country: 'USA',
          },
        }}
        suggestionsContainerProps={{
          top: '95px',
        }}
        onSelect={async (address) => {
          setFieldValue('clientDetails.location.address', address);

          const result = await geocodeByAddress(address);
          const geometry = await getLatLng(result[0]);

          const lat = geometry.lat;
          const lng = geometry.lng;

          const state =
            result?.[0]?.address_components?.find((i) =>
              i.types.includes('administrative_area_level_1'),
            )?.long_name ?? '';

          const zipcode =
            result?.[0]?.address_components?.find((i) =>
              i.types.includes('postal_code'),
            )?.long_name ?? '';

          setFieldValue('clientDetails.location', {
            address,
            lat,
            lng,
            state,
            zipcode,
          });

          await checkAloftData({ latitude: lat, longitude: lng });
        }}
      />
      {Boolean(errors.clientDetails) && (
        <Text color={'red.500'} fontSize={'12px'} fontWeight="bold">
          <ErrorMessage name="clientDetails.location.address" />
        </Text>
      )}
      <Box
        mb={6}
        minW={{ base: '250px', md: '340px' }}
        w={['100%']}
        height="240px"
        background="#fff"
        borderColor="#ccc"
        bg="inputColor"
        borderRadius="30px"
        overflow="hidden">
        <GoogleMaps
          mapContainerStyle={{
            width: '100%',
            height: '240px',
          }}
          initialCenter={coordinates}
          marker={coordinates}
          zoom={13}
        />
      </Box>
      <Flex direction="column" flex={1}>
        {isChecking ? (
          <Center>
            <Spinner />
          </Center>
        ) : response !== null ? (
          <AloftToast w="100%" {...response} size="sm" />
        ) : null}
      </Flex>
    </Stack>
  );
};
