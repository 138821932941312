import React, {
  FC,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import {
  Text,
  Flex,
  Image,
  Link,
  Stack,
  Button,
  Checkbox,
  useToast,
  useDisclosure,
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
} from '@chakra-ui/react';

import payment_success from 'res/payment-success.png';

import {
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { SaveACard } from 'components/primitives/SaveACard';
import { useMutation, useQueryCache } from 'react-query';
import { updateForPayment } from 'api/custom-mission';

const sx = {
  '.chakra-checkbox__control[data-checked]': {
    background: '#6728BB',
    borderColor: '#6728BB',
    color: '#fff',
  },
  '.chakra-checkbox__control[data-focus]': {
    boxShadow: 'none',
  },
};

// temp walkaround to force component render after delay inorder for stripe elements to mount
function useForceUpdate() {
  const [value, setValue] = useState(false);

  useEffect(() => {
    const forceUpdate = async () => {
      await new Promise((resolve: (value: null) => void) =>
        setTimeout(() => {
          setValue(true);
          resolve(null);
        }, 3000),
      );
    };

    forceUpdate();
  }, []);

  return value;
}

const PayButton: FC<any> = (props) => {
  const elements = useElements();

  const [isExpDisabled, setIsExpDisabled] = useState(true);
  const [isCvcDisabled, setIsCvcDisabled] = useState(true);
  const [isNumberDisabled, setIsNumberDisabled] = useState(true);

  const isUpdated = useForceUpdate();

  const { isLoading, payNow, isChecked } = props;

  const cardCvcEl = elements?.getElement(CardCvcElement);
  const cardExpEl = elements?.getElement(CardExpiryElement);
  const cardNumberEl = elements?.getElement(CardNumberElement);

  useEffect(() => {
    const unsubscribe = cardCvcEl?.on('change', (e) => {
      if (e.empty) {
        setIsCvcDisabled(true);
      } else if (e.error) {
        setIsCvcDisabled(true);
      } else if (!e.complete) {
        setIsCvcDisabled(true);
      } else if (e.complete) {
        setIsCvcDisabled(false);
      }
    });

    return () => {
      unsubscribe?.clear();
    };
  }, [cardCvcEl, isUpdated]);

  useEffect(() => {
    const unsubscribe = cardExpEl?.on('change', (e) => {
      if (e.empty) {
        setIsExpDisabled(true);
      } else if (e.error) {
        setIsExpDisabled(true);
      } else if (!e.complete) {
        setIsExpDisabled(true);
      } else if (e.complete) {
        setIsExpDisabled(false);
      }
    });

    return () => {
      unsubscribe?.clear();
    };
  }, [cardExpEl, isUpdated]);

  useEffect(() => {
    const unsubscribe = cardNumberEl?.on('change', (e) => {
      if (e.empty) {
        setIsNumberDisabled(true);
      } else if (e.error) {
        setIsNumberDisabled(true);
      } else if (!e.complete) {
        setIsNumberDisabled(true);
      } else if (e.complete) {
        setIsNumberDisabled(false);
      }
    });

    return () => {
      unsubscribe?.clear();
    };
  }, [cardNumberEl, isUpdated]);

  return (
    <Button
      fontSize="xs"
      w={['100px', '200px']}
      isLoading={isLoading}
      onClick={() => payNow()}
      isDisabled={
        !isChecked || isNumberDisabled || isCvcDisabled || isExpDisabled
      }>
      PAY NOW
    </Button>
  );
};

type Props = {
  clientSecret: string;
  customMissionId: string;
};

type Ref = { onOpen: () => void };

export const PaymentModal = forwardRef<Ref, Props>((props, ref) => {
  const { clientSecret, customMissionId } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();
  const stripe = useStripe();
  const elements = useElements();
  const queryCache = useQueryCache();

  const [isChecked, setIsChecked] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      onOpen,
    }),
    [],
  );

  const [payNow, { isLoading: isPaying, isSuccess }] = useMutation(
    async () => {
      if (!stripe || !elements) {
        throw 'Stripe not loaded';
      }

      const card = elements?.getElement(CardNumberElement);

      if (!card) {
        throw 'Stripe card not loaded';
      }

      const stripeResponse = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card },
      });

      if (stripeResponse.error) {
        throw stripeResponse.error;
      }

      await updateForPayment(customMissionId);

      return stripeResponse;
    },
    {
      onError: (err: any) => {
        toast({
          status: 'error',
          title: 'Payment Error',
          description: err?.message ?? '',
          isClosable: true,
          duration: 5000,
        });
      },
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Payment Confirmed',
          isClosable: true,
          duration: 5000,
        });

        queryCache.invalidateQueries(`fetch-custom-mission-${customMissionId}`);
      },
    },
  );

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody flex={1}>
          {isSuccess ? (
            <Flex p={6} flex={1} flexWrap="wrap">
              <Flex flex={1} mr={2}>
                <Image
                  w="200px"
                  h="180px"
                  src={payment_success}
                  objectFit="cover"
                  alignSelf="center"
                />
              </Flex>
              <Stack ml={3} spacing={4} minW="300px" flex={1}>
                <Text fontSize="34px" fontWeight="bold">
                  Success
                </Text>
                <Text>Your Mission Payment was successful</Text>
                <Flex>
                  <Button
                    w="120px"
                    variant="outline"
                    mr={4}
                    onClick={() => onClose()}>
                    CLOSE
                  </Button>
                </Flex>
              </Stack>
            </Flex>
          ) : (
            <Flex
              p={6}
              flex={1}
              flexWrap="wrap"
              align="center"
              justify="center">
              <Stack spacing={4} minW="300px" flex={1}>
                <Text textAlign="center" fontSize="18px" fontWeight="bold">
                  Your payment is just a click away
                </Text>
                <SaveACard
                  isPilotStripeAcc
                  titleProps={{ ml: 0, mt: 2, fontSize: '15px' }}
                />
                <Checkbox
                  w="100%"
                  my="20px"
                  size="md"
                  sx={sx}
                  display="flex"
                  alignItems="flex-start"
                  isChecked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}>
                  <Text fontSize="xs">
                    I acknowledge that I have read and agree the{' '}
                    <Link textDecoration="underline" color="buttonBgColor">
                      terms and confitions
                    </Link>
                  </Text>
                </Checkbox>
                <Flex flexWrap="wrap" justify="center">
                  <Button
                    w="200px"
                    mr="10px"
                    fontSize="xs"
                    variant="outline"
                    onClick={() => {
                      onClose();
                    }}>
                    CANCEL
                  </Button>
                  <PayButton
                    payNow={payNow}
                    isChecked={isChecked}
                    isLoading={isPaying}
                  />
                </Flex>
              </Stack>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
