import React from 'react';
import { FormLabel, Box, Button, Flex, Stack } from '@chakra-ui/react';
import FormikSwitchView from 'components/primitives/FormikSwitch';
import FormikTextAreaView from 'components/primitives/FormikTextArea';
import TestimonialInput from 'components/primitives/TestimonialInput';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdAddCircle, MdCheckCircle } from 'react-icons/md';
import { Testimony } from 'types/cms';

import TestimonialsContent from './TestimonialsContent';

import { useField } from 'formik';
import FormikImageUpload from '../FormikImageUpload';

type TestimonialInputProps = {
  id?: string;
  isEnabledTestimonials: boolean;
  testimonials: Array<Testimony>;
  addNewTestimony: () => void;
  isOpen: boolean;
  onOpen: () => void;
  color: any;
  onClose: () => void;
  onDelete?: () => void;
  onSaveTestimonial: () => void;
  onDeleteTestimony: (_testimony: Testimony) => void;
};

const labelProps = {
  fontWeight: 'bold',
  textColor: '#707070',
  fontSize: '12px',
};

const Testimonials = (props: TestimonialInputProps) => {
  const {
    isEnabledTestimonials,
    isOpen,
    color,
    testimonials,
    addNewTestimony,
    onDeleteTestimony,
    onOpen,
    onSaveTestimonial,
    onClose,
  } = props;
  const [values] = useField<Testimony>('testimony');

  const isFormEmpty = () => {
    if (values.value) {
      if (
        values.value.name &&
        values.value.position &&
        values.value.testimony
      ) {
        return false;
      } else true;
    }
    return true;
  };

  const onSave = () => {
    addNewTestimony();
    onClose();
  };

  return (
    <Flex id="testimonials" mt={4} flexDir="column">
      <Flex flex={1} alignItems="center" justifyContent="flex-start">
        <FormLabel
          mb="0"
          name="enableTestimonials"
          fontSize="14px"
          textColor="#707070"
          fontWeight="bold">
          Testimonials
        </FormLabel>
        <FormikSwitchView
          name="enableTestimonials"
          isChecked={isEnabledTestimonials}
          sx={{
            '.chakra-switch__track[data-checked]': {
              background: color,
            },
          }}
          onChangeCapture={() => {
            onSaveTestimonial();
          }}
        />
      </Flex>
      {isEnabledTestimonials ? (
        <Box mt={4}>
          <Flex
            display="block"
            maxWidth="90%"
            overflowX="auto"
            overflowY="hidden"
            wrap="nowrap">
            {testimonials?.map((testimony: Testimony, index: any) => {
              return (
                <Box
                  key={`${testimony.name}-${index}`}
                  borderWidth={2}
                  borderRadius={10}
                  mt={3}>
                  <TestimonialsContent
                    color={color}
                    testimony={testimony}
                    onDelete={onDeleteTestimony}
                  />
                </Box>
              );
            })}
          </Flex>
          {isOpen ? (
            <Flex
              flexDir="column"
              justifyContent="flex-start"
              alignItems="flex-start">
              <Stack mt={3} id="testimonials-input" justifyContent="flex-start">
                <Flex justifyContent="flex-start" mt={3} flexDir="row">
                  <Stack mt={2} p={10}>
                    <Flex
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      mt={[10, 10, 0]}
                      w="100%">
                      <FormikImageUpload
                        name="testimony.imageUrl"
                        uploadHandler={() => null}
                        height={100}
                        width={100}
                        buttonText="Select Picture"
                      />
                    </Flex>
                  </Stack>
                  <Stack gridGap={2}>
                    <Flex gridGap={5}>
                      <TestimonialInput
                        name="testimony.name"
                        label="Client Name"
                        placeholder="e.g., John Doe"
                        id="testimonials"
                        labelProps={labelProps}
                      />
                      <TestimonialInput
                        name="testimony.position"
                        label="Client Position"
                        placeholder="e.g., Marketing Manager"
                        labelProps={labelProps}
                      />
                    </Flex>
                    <FormikTextAreaView
                      name="testimony.testimony"
                      w="100%"
                      h="150px"
                      placeholder="''Working with this company was a fantastic experience because...''"
                      mb="20px"
                      label="Testimonial (max. 300 characters)"
                      maxLength={300}
                      labelProps={labelProps}
                    />
                  </Stack>
                </Flex>
              </Stack>
            </Flex>
          ) : null}
          <Flex mt={2}>
            <Button
              variant="ghost"
              leftIcon={
                isOpen ? (
                  <IoMdCloseCircle size="20px" />
                ) : (
                  <MdAddCircle size="20px" />
                )
              }
              onClick={() => {
                isOpen ? onClose() : onOpen();
              }}
              color={color}>
              {isOpen ? 'Close' : 'Add Testimonial'}
            </Button>
            {isOpen ? (
              <Button
                variant="ghost"
                leftIcon={<MdCheckCircle size="20px" />}
                onClick={() => {
                  onSave();
                  setTimeout(() => onSaveTestimonial(), 500);
                }}
                color={color}
                isDisabled={isFormEmpty()}>
                {'Add'}
              </Button>
            ) : null}
          </Flex>
        </Box>
      ) : null}
    </Flex>
  );
};

export default Testimonials;
