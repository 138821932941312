import React, { FC, useMemo } from 'react';

import { Button } from '@chakra-ui/button';
import { Center, Flex, Heading, Text } from '@chakra-ui/layout';

import hexTorgb from 'hex-rgb';

import { useCMS } from './Context';

import { motion } from 'framer-motion';

const MotionFlex = motion.custom(Flex);

const animation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

export const NotFound: FC = () => {
  const { brandColor } = useCMS();

  const rgbaColor = useMemo(
    () =>
      brandColor
        ? hexTorgb(brandColor, {
            format: 'css',
            alpha: 0.14,
          })
        : 'rgba(103, 40, 187, .23)',
    [brandColor],
  );

  return (
    <MotionFlex {...animation}>
      <Center
        bg="#fff"
        p={[5, 20]}
        flexDirection="column"
        w={['300px', '800px']}
        boxShadow={`0px 0px 50px 0px ${rgbaColor}`}
        mx={2}>
        <Heading
          fontWeight="200"
          color="buttonBgColor"
          fontSize={['4xl', '8xl']}
          fontFamily="Monument Extended">
          404
        </Heading>
        <Text
          fontWeight="200"
          fontSize={['20px', '30px']}
          fontFamily="Monument Extended">
          NOT FOUND
        </Text>
        <Button as="a" mt={8} w="260px" href="/">
          GO TO HOME
        </Button>
      </Center>
    </MotionFlex>
  );
};
