import React, { forwardRef, useImperativeHandle } from 'react';
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Image,
  Text,
  Button,
  ModalCloseButton,
} from '@chakra-ui/react';

import SuccessImg from './congrats.png';
import ErrorImg from './whoops.png';

type Props = {
  onDoneClick: () => void;
  isError: boolean;
  isOpen: boolean;
};

const MobileCreateMissionModal = (props: Props) => {
  const { onDoneClick, isError, isOpen } = props;

  const modalContent = [
    {
      title: 'Whoops!!!',
      body: 'It seems that there was a problem with the mission creation',
      img: ErrorImg,
    },
    {
      title: 'Congratulations!!!',
      body: 'Your mission has been created and sent successfully',
      img: SuccessImg,
    },
  ];

  return (
    <Flex
      minW="80vw"
      direction="column"
      alignSelf="flex-start"
      flex={1}
      h="100%">
      <Modal size="xs" isCentered isOpen={isOpen} onClose={onDoneClick}>
        <ModalOverlay zIndex={0} />
        <ModalContent>
          <ModalBody display="flex" flexDirection="column" alignItems="center">
            <ModalCloseButton />
            <Text
              mt={6}
              textAlign="center"
              fontFamily="Monument Extended"
              fontWeight="200"
              fontSize="20px"
              color="#373737"
              mb="15px">
              {isError
                ? `${modalContent[0].title}`
                : `${modalContent[1].title}`}
            </Text>
            <Image
              src={isError ? modalContent[0].img : modalContent[1].img}
              mb="20px"
            />
            <Text
              mt={6}
              textAlign="center"
              fontFamily="Monument Extended"
              fontWeight="200"
              fontSize="15px"
              color="#373737"
              mb="15px">
              {isError ? `${modalContent[0].body}` : `${modalContent[1].body}`}
            </Text>
            <Flex w="100%" justifyContent="center" mt="15px">
              <Button w="200px" mr="20px" fontSize="xs" onClick={onDoneClick}>
                DONE
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default MobileCreateMissionModal;
