// Banner.tsx

import React from 'react';
import { isMobile } from 'react-device-detect';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';

const Banner: React.FC = () => {
  return (
    <Flex
      p={0}
      pt={4}
      bg="black"
      color="white"
      borderRadius="ld"
      boxShadow="md"
      textAlign="center"
      align="center"
      justify="center"
      width="100%"
      height="160%"
      top={0}
      left={0}
      zIndex={3}
      right={0}>
      <Heading as="h2" size="sm">
        ‼️ IMPORTANT: You are currently viewing the preview version of your
        website. This site cannot be used for booking missions.
      </Heading>
    </Flex>
  );
};

export default Banner;
