import React, { FC, memo, useRef, useState } from 'react';

import {
  Flex,
  Text,
  Input,
  Modal,
  Button,
  useToast,
  Center,
  Spinner,
  FormLabel,
  ModalBody,
  FormControl,
  IconButton,
  InputGroup,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  InputRightElement,
} from '@chakra-ui/react';
import { BiDotsHorizontal } from 'react-icons/bi';
import { MdAddCircleOutline, MdDelete, MdEdit } from 'react-icons/md';

import { useField } from 'formik';

import { useCMS } from './Context';

import { aloftCheck } from 'api/mission';

import PlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from 'react-places-autocomplete';
import { AloftToast } from 'components/primitives/AloftToast';

import { AloftResponse, CustomMission } from 'types/mission';

type Props = {};

type Location = CustomMission['clientDetails']['location'];

const searchOptions = {
  componentRestrictions: {
    country: 'USA',
  },
};

export const MultiLocationInput = memo<Props>((props) => {
  const selectedAddressRef = useRef('');

  const { brandColor } = useCMS();
  const { isOpen, onOpen, onClose: _onClose } = useDisclosure();

  const [index, setIndex] = useState<0 | 1>(0);

  const [field, , helpers] = useField<Location[]>('additionalLocations');
  const [locationField, , locationHelpers] = useField<Location>('location');

  const onClose = () => {
    setIndex(0);
    _onClose();

    selectedAddressRef.current = '';
  };

  const renderAddresses = () => {
    return (
      <>
        <Flex direction="column" maxH="200px" overflowY="auto" gridGap="5px">
          {field.value.map((address) => (
            <Flex
              gridGap="5px"
              key={address.address}
              p="10px"
              borderRadius="5px"
              bg="#f1f1f1"
              align="center">
              <Text flex={1} noOfLines={1}>
                {address.address}
              </Text>
              <IconButton
                size="sm"
                bg="#E9E2F2"
                aria-label="remove-address"
                variant="ghost"
                icon={<MdEdit color="#6418C3" size="16px" />}
                onClick={() => {
                  selectedAddressRef.current = address.address;

                  setIndex(1);
                }}
              />
              <IconButton
                size="sm"
                bg="#EEE0E0"
                aria-label="remove-address"
                variant="ghost"
                icon={<MdDelete color="#D80101" size="16px" />}
                onClick={() => {
                  if (field.value.length === 1) {
                    locationHelpers.setValue({
                      address: '',
                      lat: 0,
                      lng: 0,
                      state: '',
                      zipcode: '',
                    });
                  }

                  helpers.setValue([
                    ...field.value.filter((a) => a.address !== address.address),
                  ]);

                  selectedAddressRef.current = '';
                }}
              />
            </Flex>
          ))}
        </Flex>
        <Button
          mt="10px"
          size="lg"
          bg="#F7F1FF"
          isFullWidth
          variant="ghost"
          borderRadius="5px"
          onClick={() => setIndex(1)}
          leftIcon={<MdAddCircleOutline size="20px" />}>
          Add new location
        </Button>
      </>
    );
  };

  const renderForm = () => {
    return (
      <LocationForm
        onSave={(_location) => {
          setIndex(0);

          const isFirstAddressChagned =
            locationField.value.address === selectedAddressRef.current &&
            selectedAddressRef.current !== _location.address;

          if (
            !locationField.value.address ||
            field.value.length === 0 ||
            field.value.length === 1 ||
            isFirstAddressChagned
          ) {
            locationHelpers.setValue(_location);
          }

          if (isFirstAddressChagned) {
            helpers.setValue([
              {
                ..._location,
              },
              ...field.value.filter(
                (a) => a.address !== selectedAddressRef.current,
              ),
            ]);
          } else {
            helpers.setValue([
              ...field.value.filter(
                (a) => a.address !== selectedAddressRef.current,
              ),
              {
                ..._location,
              },
            ]);
          }

          selectedAddressRef.current = '';
        }}
        defaultAddress={selectedAddressRef.current}
      />
    );
  };

  return (
    <>
      <FormLabel mt={5} fontWeight="bold">
        Location
      </FormLabel>
      <InputGroup size="lg" onClick={onOpen} minW="250px">
        <Input
          isReadOnly
          value={locationField.value.address}
          bg="inputColor"
          fontSize="14px"
          placeholder="Search Places ..."
          css={{
            '::placeholder': {
              fontStyle: 'italic',
              fontSize: '14px',
            },
          }}
        />
        <InputRightElement>
          <IconButton
            bg={`${brandColor}30`}
            variant="ghost"
            aria-label="address-menu"
            icon={<BiDotsHorizontal color={brandColor} size="20px" />}
          />
        </InputRightElement>
      </InputGroup>
      <Modal
        size="lg"
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent py="20px" borderRadius="20px">
          <ModalHeader textAlign="center">Select your addresses</ModalHeader>

          <ModalBody minH="300px">
            {index === 0 ? renderAddresses() : renderForm()}
          </ModalBody>
          <ModalFooter mt="20px" justifyContent="center">
            <Button
              mx="20px"
              onClick={() => {
                if (index === 0) {
                  onClose();
                } else {
                  setIndex(0);
                }
              }}
              isFullWidth>
              {index === 0 ? 'CLOSE' : 'CANCEL'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

const LocationForm: FC<{
  onSave: (location: Location) => void;
  defaultAddress?: string;
}> = ({ onSave, defaultAddress = '' }) => {
  const toast = useToast();

  const locationRef = useRef<Location | null>(null);

  const [address, setAddress] = useState(defaultAddress);
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [response, setResponse] = useState<null | AloftResponse>(null);

  const [field] = useField<Location[]>('additionalLocations');

  const checkAloftData = async (payload: {
    latitude: number;
    longitude: number;
  }) => {
    try {
      setIsChecking(true);

      const _response = await aloftCheck(payload);

      setIsChecking(false);

      setResponse(_response);

      return true;
    } catch (error: any) {
      setIsChecking(false);

      toast({
        status: 'error',
        description: error?.message || 'something went wrong',
        isClosable: true,
        duration: 2000,
      });

      return false;
    }
  };

  const handleSelect = async (_address: string) => {
    setAddress(_address);

    try {
      const result = await geocodeByAddress(_address);
      const geometry = await getLatLng(result[0]);

      const lat = geometry.lat;
      const lng = geometry.lng;

      const state =
        result?.[0]?.address_components?.find((i) =>
          i.types.includes('administrative_area_level_1'),
        )?.long_name ?? '';

      const zipcode =
        result?.[0]?.address_components?.find((i) =>
          i.types.includes('postal_code'),
        )?.long_name ?? '';

      await checkAloftData({ latitude: lat, longitude: lng });

      locationRef.current = {
        address: _address,
        lat,
        lng,
        state,
        zipcode,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = () => {
    if (locationRef.current === null) {
      return;
    }

    onSave(locationRef.current);

    locationRef.current = null;
  };

  return (
    <Flex
      bg="#f1f1f1"
      borderRadius="10px"
      p="20px"
      direction="column"
      gridGap="30px">
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
        searchOptions={searchOptions}>
        {({ loading, suggestions, getInputProps, getSuggestionItemProps }) => (
          <FormControl>
            <Input
              {...getInputProps()}
              bg="#fff"
              size="lg"
              fontSize="14px"
              placeholder="Search Places"
            />
            <Flex
              flexDirection="column"
              top="64px"
              width="100%"
              backgroundColor="#fff"
              zIndex="1">
              {loading && (
                <Flex padding="10px">
                  <Text fontSize="13px">Loading...</Text>
                </Flex>
              )}
              {suggestions.map((suggestion) => {
                const style = suggestion.active
                  ? {
                      backgroundColor: '#fafafa',
                      cursor: 'pointer',
                    }
                  : {
                      backgroundColor: '#ffffff',
                      cursor: 'pointer',
                    };
                return (
                  <Flex
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                    key={suggestion.placeId}
                    paddingY="5px"
                    paddingX="10px"
                    borderColor="#f3f33"
                    borderWidth="1px">
                    <Flex>{suggestion.description}</Flex>
                  </Flex>
                );
              })}
            </Flex>
          </FormControl>
        )}
      </PlacesAutocomplete>

      {isChecking ? (
        <Center>
          <Spinner />
        </Center>
      ) : response !== null ? (
        <>
          <AloftToast w="100%" {...response} size="sm" />
          <Button variant="outline" onClick={handleSave}>
            SAVE
          </Button>
        </>
      ) : null}
    </Flex>
  );
};
