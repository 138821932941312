import React, { useEffect } from 'react';

import { DashboardProps } from './Dashboard.props';
import DashboardView from './Dashboard.view';
import { SessionStore } from 'effector/session/store';
import usePackagesList from 'utils/hooks/usePackages';

const DashboardContainer = (props: DashboardProps): JSX.Element => {
  const sessionStore = SessionStore.getState();

  const { data } = usePackagesList();

  const packages = data?.results.filter((pack) => pack.pilot === null) || [];

  useEffect(() => {
    props.history.replace('/missions');
  }, []);

  return (
    <DashboardView
      user={sessionStore?.session?.user}
      tokens={sessionStore?.session?.tokens}
      packages={packages}
    />
  );
};

export default DashboardContainer;
