import React, { FC, useState } from 'react';

import {
  Box,
  Flex,
  Text,
  Input,
  Stack,
  Button,
  Checkbox,
  IconButton,
  CheckboxGroup,
  useDisclosure,
} from '@chakra-ui/react';

import { AiFillFire } from 'react-icons/ai';
import { MdEdit, MdCheck, MdDelete, MdAddCircleOutline } from 'react-icons/md';

import { getMyCMS } from 'api/cms';
import { useQuery } from 'react-query';

import { useField, useFormikContext } from 'formik';

import { TitleWithIcon } from 'components/primitives/TitleWithIcon';

import { CMS } from 'types/cms';
import { useResponsive } from 'utils/hooks';

const labelProps = {
  fontSize: '12px',
};

const color = '#6728BB';

const sx = {
  '.chakra-checkbox__control[data-checked]': {
    background: '#6728BB',
    borderColor: '#6728BB',
    color: '#fff',
  },
  '.chakra-checkbox__control[data-focus]': {
    boxShadow: 'none',
  },
};

export const TotalCost: FC = () => {
  const { values } = useFormikContext<any>();

  const rush = !!values?.rush;

  const additionalServicesCost: number =
    values?.additionalServices
      ?.map((i: any) => Number(i.price || '0'))
      ?.reduce((a: any, b: any) => a + b, 0) || 0;

  const lineItemsCost: number = values?.lineItems
    ?.map((i: any) => Number(i.count || '0') * Number(i.price || '0'))
    ?.reduce((a: any, b: any) => a + b, 0);

  const total = (additionalServicesCost || 0) + (lineItemsCost || 0);

  return (
    <Flex
      pt={6}
      pr={8}
      align="flex-end"
      justify="flex-end"
      borderTop="1px solid #ccc">
      <Stack spacing={1}>
        {rush && (
          <Flex
            w="100%"
            pb="10px"
            alignItems="center"
            justifyContent="space-between">
            <TitleWithIcon
              m={0}
              p={0}
              text="Rush Mission"
              TextIcon={
                <AiFillFire
                  size="20px"
                  color="#E9243F"
                  style={{ marginBottom: '2px' }}
                />
              }
            />
            <Box w="50px" />
          </Flex>
        )}
        <Text>Mission Total Price: ${total.toFixed(2)}</Text>
      </Stack>
    </Flex>
  );
};

export const SubmitButton: FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const { isValid } = useFormikContext<any>();

  return (
    <Button isDisabled={!isValid} type="submit" isLoading={isLoading} w="240px">
      SEND CUSTOM MISSION
    </Button>
  );
};

export const Deliverables: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { values, errors, touched, setFieldValue } = useFormikContext<any>();
  const { isMobile } = useResponsive();

  const initialValue = {
    count: '',
    name: '',
    price: '',
  };

  const [localValue, setLocalValue] = useState(initialValue);

  const isInvalid = Boolean(errors?.lineItems) && Boolean(touched.lineItems);

  const renderQuantityField = () => {
    return (
      <Stack>
        <Text {...labelProps} fontWeight="bold">
          Item Quantity
        </Text>
        <Input
          bg={isMobile ? 'white' : labelProps}
          isInvalid={isInvalid}
          type="number"
          value={localValue.count}
          onChange={(e) =>
            setLocalValue((prev) => ({
              ...prev,
              count: e.target.value,
            }))
          }
        />
      </Stack>
    );
  };

  const renderItemNameField = () => {
    return (
      <Stack>
        <Text {...labelProps} fontWeight="bold">
          Item Name
        </Text>
        <Input
          bg={isMobile ? 'white' : labelProps}
          isInvalid={isInvalid}
          value={localValue.name}
          onChange={(e) =>
            setLocalValue((prev) => ({
              ...prev,
              name: e.target.value,
            }))
          }
        />
      </Stack>
    );
  };

  const renderItemPriceField = () => {
    return (
      <Stack>
        <Text {...labelProps} fontWeight="bold">
          Item Price
        </Text>
        <Input
          isInvalid={isInvalid}
          bg={isMobile ? 'white' : labelProps}
          type="number"
          maxW="100px"
          value={localValue.price}
          onChange={(e) =>
            setLocalValue((prev) => ({
              ...prev,
              price: e.target.value,
            }))
          }
        />
      </Stack>
    );
  };

  const renderIconButtom = () => {
    return (
      <IconButton
        ml={3}
        aria-label="add-item"
        size="sm"
        icon={<MdCheck size="20px" />}
        isRound
        mb={1}
        isDisabled={!localValue.name || !localValue.price || !localValue.count}
        onClick={() => {
          setFieldValue('lineItems', [localValue, ...values.lineItems]);
          setLocalValue(initialValue);
          onClose();
        }}
      />
    );
  };

  return (
    <>
      {isOpen === true && (
        <>
          {isMobile ? (
            <Flex
              flexDirection="column"
              gridGap="10px"
              color="#908361"
              width="100%"
              mb="2rem">
              {renderItemNameField()}
              <Flex flexDirection="row" gridGap="10px" align="center">
                {renderQuantityField()}
                {renderItemPriceField()}
                {renderIconButtom()}
              </Flex>
            </Flex>
          ) : (
            <Flex align="flex-end" flexWrap="wrap">
              <Stack>
                <Text {...labelProps} fontWeight="bold">
                  Item Quantity
                </Text>
                <Input
                  isInvalid={isInvalid}
                  type="number"
                  maxW="100px"
                  value={localValue.count}
                  onChange={(e) =>
                    setLocalValue((prev) => ({
                      ...prev,
                      count: e.target.value,
                    }))
                  }
                />
              </Stack>
              <Box h="20px" w="30px" />
              <Stack>
                <Text {...labelProps} fontWeight="bold">
                  Item Name
                </Text>
                <Input
                  isInvalid={isInvalid}
                  maxW="200px"
                  value={localValue.name}
                  onChange={(e) =>
                    setLocalValue((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
              </Stack>
              <Box h="20px" w="30px" />
              <Stack>
                <Text {...labelProps} fontWeight="bold">
                  Item Price
                </Text>
                <Input
                  isInvalid={isInvalid}
                  type="number"
                  maxW="100px"
                  value={localValue.price}
                  onChange={(e) =>
                    setLocalValue((prev) => ({
                      ...prev,
                      price: e.target.value,
                    }))
                  }
                />
              </Stack>
              <Box h="20px" w="30px" />
              <Stack>
                <Text {...labelProps} fontWeight="bold">
                  Total
                </Text>
                <Input
                  isReadOnly
                  value={
                    Number(localValue.price || '0') *
                    Number(localValue.count || '0')
                  }
                  maxW="100px"
                  bg="#CFCFCF80"
                />
              </Stack>
              <IconButton
                ml={3}
                aria-label="add-item"
                size="sm"
                icon={<MdCheck size="20px" />}
                isRound
                mb={1}
                isDisabled={
                  !localValue.name || !localValue.price || !localValue.count
                }
                onClick={() => {
                  setFieldValue('lineItems', [localValue, ...values.lineItems]);
                  setLocalValue(initialValue);
                  onClose();
                }}
              />
            </Flex>
          )}
          {isInvalid && (
            <Text fontSize="sm" color="red.500">
              {errors?.lineItems || ''}
            </Text>
          )}
        </>
      )}
      {values?.lineItems?.map((l: any, i: any) => (
        <Flex
          key={`${l.name}-${i}`}
          minW="200px"
          maxW="580px"
          borderRadius="6px"
          bg={`${color}30`}
          align="center"
          border={`1px solid ${color}`}
          overflow="hidden">
          <Text
            my={1}
            mx={2}
            w="100%"
            color={color}
            fontWeight="bold"
            fontSize="14px">
            ({l.count}) - {l.name} (+${l.price})
          </Text>
          <IconButton
            aria-label="edit-1"
            icon={<MdEdit />}
            size="sm"
            variant="ghost"
            borderLeft={`1px solid ${color}`}
            borderRadius="0"
            _focus={{
              border: 'none',
              borderLeft: `1px solid ${color}`,
            }}
            color={color}
            onClick={() => {
              onOpen();
              setLocalValue({
                name: l.name,
                price: l.price,
                count: l.count,
              });
            }}
          />
          <IconButton
            aria-label="delete-item"
            icon={<MdDelete />}
            size="sm"
            variant="ghost"
            borderLeft={`1px solid ${color}`}
            borderRadius="0"
            _focus={{
              border: 'none',
              borderLeft: `1px solid ${color}`,
            }}
            color={color}
            onClick={() => {
              setFieldValue(
                'lineItems',
                values.lineItems.filter((_: any, p: any) => p !== i),
              );
            }}
          />
        </Flex>
      ))}
      {isOpen === false && (
        <>
          <Button
            variant="ghost"
            maxW="580px"
            w="100%"
            borderRadius="5px"
            bg="#F7F1FF"
            leftIcon={<MdAddCircleOutline size="20px" />}
            onClick={() => {
              onOpen();
              setLocalValue(initialValue);
            }}>
            Add new item
          </Button>
          <Text fontSize="sm" color="red.500">
            {errors?.lineItems || ''}
          </Text>
        </>
      )}
    </>
  );
};

type ServicesType = CMS['additionalServices'];

export const Services: FC = () => {
  const initialValue = {
    name: '',
    price: '',
  };

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
  });

  const [services, setServices] = useState<ServicesType>([]);
  const [localValue, setLocalValue] = useState(initialValue);

  const [, _, helpers] = useField<any[]>('additionalServices');

  useQuery(`fetch-my-cms-published`, () => getMyCMS('PUBLISH'), {
    onSuccess: (data) => {
      if (data.additionalServices) {
        setServices((prev) => prev.concat(data.additionalServices));
      }
    },
    retry: 0,
    onError: () => {
      console.log('');
    },
  });

  return (
    <Flex direction="column" flex={1}>
      <CheckboxGroup
        onChange={(values) => {
          const formatted = values.map((v) => {
            const _item = String(v).split('pri$e');

            return { name: _item[0], price: Number(_item[1]) };
          });

          helpers.setValue(formatted);
        }}>
        <Stack>
          {services.map((a, i) => (
            <Checkbox
              sx={sx}
              fontWeight="bold"
              key={`${a.name}-${a.price}-${i}`}
              value={`${a.name}pri$e${a.price}`}>
              {a.name} - ${a.price.toFixed(2)}
            </Checkbox>
          ))}
          {isOpen === true && (
            <Flex pt={4} align="flex-end" flexWrap="wrap">
              <Stack>
                <Text {...labelProps} fontWeight="bold">
                  Item Name
                </Text>
                <Input
                  maxW="200px"
                  value={localValue.name}
                  onChange={(e) =>
                    setLocalValue((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
              </Stack>
              <Box h="20px" w="30px" />
              <Stack>
                <Text {...labelProps} fontWeight="bold">
                  Item Price
                </Text>
                <Input
                  type="number"
                  maxW="100px"
                  value={localValue.price}
                  onChange={(e) =>
                    setLocalValue((prev) => ({
                      ...prev,
                      price: e.target.value,
                    }))
                  }
                />
              </Stack>
              <Box h="20px" w="30px" />
              <IconButton
                ml={3}
                aria-label="add-item"
                size="sm"
                icon={<MdCheck size="20px" />}
                isRound
                mb={1}
                isDisabled={!localValue.name || !localValue.price}
                onClick={() => {
                  setServices((prev) => [
                    ...prev,
                    {
                      name: localValue.name,
                      price: Number(localValue.price),
                    },
                  ]);

                  setLocalValue(initialValue);
                  onClose();
                }}
              />
            </Flex>
          )}
          {isOpen === false && (
            <Box pt={4}>
              <Button
                variant="ghost"
                maxW="580px"
                w="100%"
                borderRadius="5px"
                bg="#F7F1FF"
                leftIcon={<MdAddCircleOutline size="20px" />}
                onClick={() => {
                  onOpen();
                  setLocalValue(initialValue);
                }}>
                Add service
              </Button>
            </Box>
          )}
        </Stack>
      </CheckboxGroup>
    </Flex>
  );
};
