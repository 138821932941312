import React, { memo } from 'react';

import { PilotsTabsProps } from './PilotsTabs.props';

import PilotsTabsView from './PilotsTabs.view';

const PilotsTabsContainer = memo<PilotsTabsProps>(() => {
  return <PilotsTabsView />;
});

export default PilotsTabsContainer;
