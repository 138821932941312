import React from 'react';

import { Flex, IconButton, Text } from '@chakra-ui/react';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';

import { PaginationProps } from './Pagination.props';

const PaginationView = ({
  totalPages,
  currentPage,
  onPreviousClick,
  onNextClick,
  onPageNumberClick,
}: PaginationProps): JSX.Element => {
  return (
    <Flex>
      <IconButton
        mr={2}
        _focus={{ boxShadow: 'none' }}
        size="sm"
        variant="outline"
        aria-label="previous"
        borderRadius="10px"
        onClick={currentPage <= 1 ? undefined : onPreviousClick}
        icon={<AiFillCaretLeft />}
      />
      <Flex borderRadius="10px" border="1px solid #6418C3">
        <IconButton
          _focus={{ boxShadow: 'none' }}
          size="sm"
          variant="outline"
          borderRadius="10px"
          aria-label="previous_current"
          onClick={() =>
            currentPage <= 1 ? undefined : onPageNumberClick(currentPage - 1)
          }
          icon={
            <Text fontSize="12px">
              {currentPage <= 1 ? '..' : currentPage - 1}
            </Text>
          }
          border="none"
        />
        <IconButton
          borderRadius="10px"
          _focus={{ boxShadow: 'none' }}
          size="sm"
          variant="outline"
          bg="#6418C3"
          _hover={{ bg: '#6418C3', color: 'white' }}
          color="white"
          aria-label="current"
          border="none"
          icon={<Text fontSize="12px">{currentPage}</Text>}
        />
        <IconButton
          _focus={{ boxShadow: 'none' }}
          size="sm"
          variant="outline"
          borderRadius="10px"
          aria-label="next_page"
          onClick={() =>
            currentPage === totalPages
              ? undefined
              : onPageNumberClick(currentPage + 1)
          }
          border="none"
          icon={
            <Text fontSize="12px">
              {currentPage === totalPages ? '..' : currentPage + 1}
            </Text>
          }
        />
      </Flex>
      <IconButton
        ml={2}
        borderRadius="10px"
        _focus={{ boxShadow: 'none' }}
        size="sm"
        variant="outline"
        aria-label="next"
        onClick={currentPage === totalPages ? undefined : onNextClick}
        icon={<AiFillCaretRight />}
      />
    </Flex>
  );
};

export default PaginationView;
