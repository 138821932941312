import React, { FC, useRef, useState } from 'react';

import { Button, Checkbox, Flex, Text } from '@chakra-ui/react';

import { motion } from 'framer-motion';

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { useHistory } from 'react-router-dom';

import { Form, Formik } from 'formik';

import FormikImageUpload from '../FormikImageUpload';
import FormikInput from 'components/primitives/FormikInput';
import FormikDatePicker from 'components/primitives/FormikDatePicker';
import PlacesAutocompleteView from 'components/primitives/PlacesAutocomplete';

import {
  initialValues,
  PilotRegistrationSchema,
} from './MobileRegistration.utils';

import { MobileRegistrationProps } from './MobileRegistration.props';
import PilotCertificationModalView from '../PilotRegistration/PilotCertificationModal';
import DroneAdairLogo2Icon from 'components/icons/DroneAdairLogo2Icon';

const labelProps = {
  color: 'primaryColor',
  fontFamily: 'Roboto',
  fontSize: '0.875rem',
};

const animation = {
  initial: {
    x: -50,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
  },
  transition: {
    duration: 0.5,
    ease: 'easeInOut',
  },
};

const MotionFlex = motion.custom(Flex);

const MobileRegistrationView: FC<MobileRegistrationProps> = (props) => {
  const history = useHistory();
  const certificationAlertModalRef = useRef<any>(null);
  const [step, setStep] = useState(0);

  return (
    <Flex pt="100px" flex={1} direction="column" bgColor="#F5F5F5">
      <Flex
        zIndex={2}
        top={0}
        left={0}
        right={0}
        position="fixed"
        shadow="lg"
        p="20px"
        bg="#fff"
        align="flex-end"
        justify="space-between"
        alignItems="center"
        bgColor="#E0E0E0"
        borderRadius="0 0 20px 20px">
        <DroneAdairLogo2Icon />
        <Button
          onClick={() => {
            history.push('/login');
          }}
          fontSize="12px"
          bg="primaryColor"
          _focus={{ bg: 'primaryColor' }}
          fontFamily="roboto"
          width="6rem"
          height="1.99rem">
          LOG IN
        </Button>
      </Flex>

      <Flex
        px="20px"
        mb="50px"
        flex={1}
        direction="column"
        gridGap="20px"
        bgColor="#F5F5F5">
        <Text
          textAlign="center"
          color="primaryColor"
          fontStyle="normal"
          fontSize="1.25rem"
          fontWeight="bold"
          fontFamily="Kanit">
          Pilot registration
        </Text>
        <Formik
          initialValues={initialValues}
          validationSchema={PilotRegistrationSchema}
          onSubmit={props.onSubmit}>
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setTouched,
            handleSubmit,
            validateForm,
          }) => (
            <Form>
              {step === 0 && (
                <MotionFlex direction="column" flex={1} {...animation}>
                  <Flex
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    mt="10px"
                    w="100%">
                    <FormikImageUpload
                      name="image"
                      uploadHandler={() => null}
                      height={100}
                      width={100}
                      buttonText="SELECT PICTURE"
                      containerProps={{
                        flexDir: 'row',
                        gridGap: '20px',
                        align: 'center',
                        mb: '15px',
                      }}
                      buttonProps={{
                        size: 'md',
                        fontSize: '12px',
                        mt: '0',
                      }}
                    />
                  </Flex>

                  <Flex flexDir="column" w="100%">
                    <FormikInput
                      name="fullName"
                      label="Full Name*"
                      labelProps={labelProps}
                    />
                    <FormikInput
                      name="email"
                      label="Email Address*"
                      labelProps={labelProps}
                    />
                    <FormikInput
                      name="phone"
                      label="Phone*"
                      labelProps={labelProps}
                      inputMode="numeric"
                      pattern="[0-9]*"
                    />
                    <FormikInput
                      type="password"
                      name="password"
                      label="Password*"
                      labelProps={labelProps}
                    />

                    <FormikInput
                      type="password"
                      name="confirmPassword"
                      label="Password Confirmation*"
                      labelProps={labelProps}
                    />
                  </Flex>

                  <Flex flex={1} mt="40px" gridGap="20px">
                    <Button
                      w="100%"
                      fontSize="12px"
                      variant="outline"
                      onClick={() => history.push('/login')}>
                      CANCEL
                    </Button>

                    <Button
                      w="100%"
                      fontSize="12px"
                      isDisabled={Boolean(
                        errors?.fullName ||
                          errors?.email ||
                          errors?.phone ||
                          errors?.password ||
                          errors?.confirmPassword,
                      )}
                      onClick={async () => {
                        handleSubmit();

                        const _errors = await validateForm();

                        const isInValid =
                          _errors?.fullName ||
                          _errors?.email ||
                          _errors?.phone ||
                          _errors?.password ||
                          _errors?.confirmPassword;

                        if (isInValid) {
                          return;
                        }

                        setStep(1);
                      }}>
                      NEXT
                    </Button>
                  </Flex>
                </MotionFlex>
              )}

              {step === 1 && (
                <MotionFlex flex={1} direction="column" {...animation}>
                  {!values.isNotPilotCertNumber && (
                    <>
                      <FormikInput
                        type="number"
                        name="pilotCertNumber"
                        label="Remote Pilot Certificate Number*"
                        labelProps={labelProps}
                      />
                      <FormikDatePicker
                        name="pilotCertExpire"
                        label="Pilot Certificate Expiration*"
                        labelProps={{
                          mt: 4,
                          fontSize: 14,
                          color: `${labelProps.color}`,
                        }}
                        disablePastDates
                      />
                    </>
                  )}
                  <Checkbox
                    mt={3}
                    name="isNotPilotCertNumber"
                    colorScheme="purple"
                    isChecked={values.isNotPilotCertNumber}
                    alignItems="flex-start"
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        setTouched({
                          pilotCertNumber: true,
                        });
                        certificationAlertModalRef.current?.onOpen();
                      }
                      setFieldValue(
                        'isNotPilotCertNumber',
                        e.currentTarget.checked,
                      );
                    }}>
                    <Text fontSize={12}>
                      I don't have a remote pilot certificate yet
                    </Text>
                  </Checkbox>
                  <FormikInput
                    w="100%"
                    name="companyName"
                    label="Company Name"
                    labelProps={labelProps}
                    onBlur={() =>
                      setTouched({
                        pilotCertNumber: true,
                      })
                    }
                  />
                  <Flex flexDir="column" w="100%">
                    <PlacesAutocompleteView
                      value={values?.address || ''}
                      name="address"
                      labelText="Address"
                      labelProps={{
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                        color: 'primaryColor',
                      }}
                      setFieldValue={setFieldValue}
                      noPlaceholder
                      searchOptions={{
                        componentRestrictions: {
                          country: 'us',
                        },
                      }}
                      onSelect={async (address: string) => {
                        const result = await geocodeByAddress(address);
                        const { lat, lng } = await getLatLng(result?.[0]);

                        const state =
                          result?.[0]?.address_components?.find((i) =>
                            i.types.includes('administrative_area_level_1'),
                          )?.long_name ?? '';

                        const zipcode =
                          result?.[0]?.address_components?.find((i) =>
                            i.types.includes('postal_code'),
                          )?.long_name ?? '';

                        setFieldValue('state', state);
                        setFieldValue('zipcode', zipcode);
                        setFieldValue('address', address);
                        setFieldValue('latitude', lat);
                        setFieldValue('longitude', lng);
                      }}
                    />
                  </Flex>
                  <Flex gridGap="5px">
                    <FormikInput
                      name="state"
                      label="State"
                      labelProps={labelProps}
                    />
                    <FormikInput
                      type="number"
                      name="zipcode"
                      label="Zip Code"
                      labelProps={labelProps}
                    />
                  </Flex>
                  <Flex flex={1} mt="40px" gridGap="20px">
                    <Button
                      w="100%"
                      fontSize="12px"
                      variant="outline"
                      onClick={() => setStep(0)}>
                      BACK
                    </Button>

                    <Button
                      w="100%"
                      fontSize="12px"
                      type="submit"
                      isDisabled={props.isLoading}
                      isLoading={props.isLoading}>
                      CREATE
                    </Button>
                  </Flex>
                </MotionFlex>
              )}
            </Form>
          )}
        </Formik>
        <PilotCertificationModalView ref={certificationAlertModalRef} />
      </Flex>
    </Flex>
  );
};

export default MobileRegistrationView;
