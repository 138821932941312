import React, { FC } from 'react';

import { MdCheck } from 'react-icons/md';
import { Box, Flex, Stack, Text, SimpleGrid } from '@chakra-ui/layout';

import { useCMS } from './Context';

const getTitleProps = (color = '#6728BB') => ({
  fontSize: '28px',
  fontWeight: 'bold',
  color,
});

export const Equipment: FC = () => {
  const { cms, brandColor } = useCMS();

  const equipments = cms?.equipments || [];

  const drones = equipments.filter((e) => e.type === 'DRONE');
  const others = equipments.filter((e) => e.type === 'SENSOR');
  const cameras = equipments.filter((e) => e.type === 'CAMERA');

  return (
    <Flex my={10} mt={20} direction="column" align="center" id="Equipment">
      <Text
        mb={4}
        color="#222222"
        fontSize={{ base: '28px', md: '33px' }}
        userSelect="none"
        fontWeight="bold"
        textAlign="center">
        My equipment
      </Text>
      <SimpleGrid
        bg="#F3F6F6"
        p="20px 50px"
        spacing="100px"
        columns={[1, 2, 3]}
        borderRadius="20px">
        <Flex minW="200px" gridGap="20px" direction="column">
          <Text {...getTitleProps(brandColor)}>Drones</Text>
          <Stack>
            {drones.map((d) => (
              <Flex key={d.id} gridGap="10px" align="center">
                <Box p="2px" borderRadius="50px" bg={`${brandColor}20`}>
                  <MdCheck size="20px" color={brandColor} />
                </Box>
                <Text color="#4f4f4f" fontSize="18px">
                  {d.fileName}
                </Text>
              </Flex>
            ))}
          </Stack>
        </Flex>
        <Flex minW="200px" gridGap="20px" direction="column">
          <Text {...getTitleProps(brandColor)}>Cameras</Text>
          <Stack>
            {cameras.map((d) => (
              <Flex key={d.id} gridGap="10px" align="center">
                <Box p="2px" borderRadius="50px" bg={`${brandColor}20`}>
                  <MdCheck size="20px" color={brandColor} />
                </Box>
                <Text color="#4f4f4f" fontSize="18px">
                  {d.fileName}
                </Text>
              </Flex>
            ))}
          </Stack>
        </Flex>
        <Flex minW="200px" gridGap="20px" direction="column">
          <Text {...getTitleProps(brandColor)}>Others</Text>
          <Stack>
            {others.map((d) => (
              <Flex key={d.id} gridGap="10px" align="center">
                <Box p="2px" borderRadius="50px" bg={`${brandColor}20`}>
                  <MdCheck size="20px" color={brandColor} />
                </Box>
                <Text color="#4f4f4f" fontSize="18px">
                  {d.fileName}
                </Text>
              </Flex>
            ))}
          </Stack>
        </Flex>
      </SimpleGrid>
    </Flex>
  );
};
