import React, { FC } from 'react';

import { Box, Center, Flex, Text } from '@chakra-ui/layout';

import { motion } from 'framer-motion';

import { AloftToastProps } from './AloftToast.props';

const MotionFlex = motion.custom(Flex);

const AloftToastView: FC<AloftToastProps> = (props) => {
  const { h = '85px', w, color, overview, classes, size = 'md' } = props;

  const aloftClass = classes?.[0] ? `Class: ${classes[0]}` : '';

  return (
    <MotionFlex
      w={w}
      h={h}
      bg="#fff"
      shadow="lg"
      minW="200px"
      borderRadius="10px"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}>
      <Box
        h="100%"
        w="10px"
        bg={`${color?.name}.500`}
        borderRadius="10px 0 0 10px"
      />
      <Center
        h="100%"
        w="100px"
        bg={`${color?.name}.100`}
        color={`${color?.name}.700`}
        fontWeight="bold"
        fontSize={size === 'sm' ? '12px' : '13px'}>
        {aloftClass}
      </Center>
      <Box px={5} pt={1}>
        <Text
          fontWeight="bold"
          fontSize={size === 'sm' ? '16px' : '20px'}
          color={`${color?.name}.700`}>
          {overview?.short || ''}
        </Text>
        <Text
          fontSize={size === 'sm' ? '13px' : '15px'}
          fontWeight="bold"
          color={`${color?.name}.500`}>
          {overview?.full || ''}
        </Text>
      </Box>
    </MotionFlex>
  );
};

export default AloftToastView;
