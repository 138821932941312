import React, { FC, useRef } from 'react';

import {
  Text,
  Flex,
  Image,
  Stack,
  Button,
  Center,
  Heading,
  CircularProgress,
} from '@chakra-ui/react';

import stripeText from 'res/stripe.png';
import character from 'res/stripe-connect.png';

import { SkipModal } from './SkipModal';

import { StripeConnectGeneratedProps } from './StripeConnect.props';

const StripeConnectView: FC<StripeConnectGeneratedProps> = (props) => {
  const { fname, isLoading, onCreate } = props;

  const skipModalRef = useRef<any>(null);

  return (
    <Flex
      direction="column"
      flex={1}
      align="center"
      justify="center"
      padding={5}
      width="100%"
      bg="gray.50">
      <Heading
        mt={4}
        textAlign="center"
        whiteSpace="pre-line"
        color="primaryColor">
        Welcome onboard {fname}, you are{'\n'}almost there!
      </Heading>
      <Center
        mt={8}
        mb={20}
        p={6}
        minW={{ base: 'auto', md: '700px' }}
        bg="#ffff"
        shadow="lg"
        borderRadius="10px"
        alignSelf="center"
        flexDirection="column">
        <Heading
          fontSize="lg"
          color="primaryColor"
          fontFamily="Monument Extended">
          Set up your payment method
        </Heading>
        <Text mt={2} opacity={0.5} fontWeight="bold">
          This will allow us to process your payments faster
        </Text>
        <Image mt={8} maxH="300px" maxW="300px" src={character} />
        {isLoading ? (
          <>
            <Text mt={4} opacity={0.5} fontWeight="bold" fontSize="14px">
              Come back to this screen when you complete your set up
            </Text>
            <Center mt={2}>
              <CircularProgress size="20px" color="brand.500" isIndeterminate />
            </Center>
          </>
        ) : (
          <Stack mt={4} spacing={4}>
            <Button
              variant="ghost"
              color="#fff"
              bg="#2672F2"
              rightIcon={<Image src={stripeText} h="22px" />}
              onClick={() => onCreate()}>
              Connect
            </Button>
            <Button
              onClick={() => {
                skipModalRef.current?.onOpen?.();
              }}>
              SKIP FOR NOW
            </Button>
          </Stack>
        )}
      </Center>
      <SkipModal ref={skipModalRef} />
    </Flex>
  );
};

export default StripeConnectView;
