import React, { FC } from 'react';

import { Box, Text } from '@chakra-ui/react';
import { MdNotifications } from 'react-icons/md';

import { useNotifications } from 'utils/hooks';

import { Props } from './NotificationsIcon.props';

export const NotificationsIcon: FC<Props> = ({ type }) => {
  const { data: notifications } = useNotifications(type);

  if (!notifications) {
    return null;
  }

  const totalResults = notifications.totalResults || 0;

  if (totalResults === 0) {
    return null;
  }

  return (
    <Box
      p={1}
      ml={2}
      bg="#fff"
      borderRadius="25px"
      position="relative"
      border="1px solid #000">
      <MdNotifications fill="#000" size="12px" />
      <Text
        bg="red.500"
        px="5px"
        py="1px"
        borderRadius="20px"
        position="absolute"
        top={'-10px'}
        right={'-5px'}
        fontWeight="bold"
        color="#fff"
        fontSize="10px">
        {totalResults}
      </Text>
    </Box>
  );
};
