import React, { memo } from 'react';

import { Button, Center, Flex, Image, Link, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { EmptyMissionPageProps } from './EmptyMissionPage.props';

import noMissionsImg from 'res/no_missions.png';
import { MdLink } from 'react-icons/md';
import { useStore } from 'effector-react';
import { SessionStore } from 'effector/session/store';
import { AccountTypes } from 'types/accountTypes';

const EmptyMissionsPageView = (props: EmptyMissionPageProps): JSX.Element => {
  const { isPublished, onCopy, hasCopied } = props;

  const history = useHistory();
  const isPilot =
    useStore(SessionStore)?.session?.user?.accountType === AccountTypes.pilot;

  return (
    <Center flexDirection={'column'} p={20} gridGap={5}>
      <Image src={noMissionsImg} />
      {isPilot ? (
        <Flex flexDir={'column'} gridGap={2} alignItems="center">
          {isPublished ? (
            <>
              <Text textAlign="center">
                Maximize your reach! Share your website link with friends,
                fellow pilots, and social media to attract more missions and
                opportunities.
              </Text>
              <Link onClick={onCopy}>
                {hasCopied ? (
                  <Text fontWeight="600">Link copied</Text>
                ) : (
                  <Flex justifyContent="center" gridGap={2}>
                    <MdLink size="25px" />
                    <Text fontWeight="600">Copy your website link</Text>
                  </Flex>
                )}
              </Link>
            </>
          ) : (
            <>
              <Text textAlign="center">
                Start booking clients on demand by creating and publishing your
                website.
              </Text>
              <Button
                maxW={200}
                onClick={() => {
                  history.push('/my-website');
                }}>
                GO TO PUBLISH
              </Button>
            </>
          )}
        </Flex>
      ) : (
        <Text textAlign="center">
          Start creating custom missions by visiting a pilot's website!
        </Text>
      )}
    </Center>
  );
};

export default memo(EmptyMissionsPageView);
