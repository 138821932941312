import React, { FC, useState } from 'react';

import { add } from 'date-fns';

import { useHistory } from 'react-router-dom';

import { useQuery } from 'react-query';
import { getMissions } from 'api/mission';

import { selectors } from 'effector/session';

import { MissionListProps } from './MissionList.props';

import MissionListView from './MissionList.view';

const MISSIONS_PER_PAGE = 5;

const MissionListContainer: FC<MissionListProps> = () => {
  const history = useHistory();
  const userId = selectors.useUserId();

  const [page, setPage] = useState(1);

  const queryKey = [`fetch-accepted-uber-missions`, `pilot`, page];
  const queryFn = () =>
    getMissions({
      page,
      type: null,
      pilot: userId,
      limit: MISSIONS_PER_PAGE,
      dueDate: add(new Date(), { days: 50 }).toISOString(),
    });
  const options = {
    enabled: !!userId,
  };

  const { data, isLoading, isFetching } = useQuery(queryKey, queryFn, options);

  const missions = data?.results || [];
  const totalPages = data?.totalPages || 0;
  const isMissions = missions && missions?.length > 0;

  const onMissionClick = (id: string) => {
    history.push(`mission/details/${id}`);
  };

  return (
    <MissionListView
      page={page}
      setPage={setPage}
      missions={missions}
      isLoading={isLoading || isFetching}
      totalPages={totalPages}
      isMissions={isMissions}
      onMissionClick={onMissionClick}
    />
  );
};

export default MissionListContainer;
