import { getChargebeekeys } from 'api/chargebee';
import { isIOS } from 'react-device-detect';
import { useQuery } from 'react-query';
import { FETCH_KEYS } from 'utils/queryKeys';

declare global {
  interface Window {
    Chargebee: any;
  }
}

export const useChargebee = (): any => {
  const { data: cbInstance } = useQuery(FETCH_KEYS, async () => {
    let isInstance = false;

    const keys = await getChargebeekeys();

    try {
      isInstance = !!window.Chargebee.getInstance();
    } catch (_) {
      isInstance = false;
    }

    if (isInstance) {
      return window.Chargebee.getInstance();
    }

    await window.Chargebee.init({
      site: keys.siteName,
      publishableKey: keys.publicKey,
      iframeOnly: isIOS,
    });

    const instance = window.Chargebee.getInstance();

    return instance;
  });

  return cbInstance;
};
