import { useToast } from '@chakra-ui/toast';
import { updateMission } from 'api/mission';
import React from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { useHistory } from 'react-router';
import { Mission } from 'types/mission';
import useMission from 'utils/hooks/useMission';

import { MissionUpdateProps } from './MissionUpdate.props';
import MissionUpdateView from './MissionUpdate.view';

const MissionUpdateContainer = (props: MissionUpdateProps): JSX.Element => {
  const toast = useToast();
  const history = useHistory();
  const queryCache = useQueryCache();
  const missionId = props.match.params.missionId;
  const { data, isLoading } = useMission(missionId);

  const [update] = useMutation(
    async ({ payload, missionId }: { payload: Mission; missionId: string }) =>
      await updateMission(payload, missionId),
    {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Mission Updated Successfully',
          isClosable: true,
          duration: 5000,
        });

        queryCache.invalidateQueries(`fetch-mission-${missionId}`);
        queryCache.invalidateQueries(`fetch-client-missions`);

        setTimeout(() => {
          history.goBack();
        }, 500);
      },
    },
  );
  return (
    <MissionUpdateView
      mission={data}
      isLoading={isLoading}
      update={update}
      missionId={missionId}
    />
  );
};

export default MissionUpdateContainer;
